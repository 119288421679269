import React, { useEffect, useState } from "react";
import { getContentByType } from "../../services/contentService";
import EmailTemplateList from "./EmailTemplateList";

interface EmailTemplateListProps {
  onEmailTemplateSelect: (template: any) => void;
  setExpandedSections: any;
  expandedSections: any;
  handleDiscardEmail: any;
}

const PER_PAGE = 6;

const EmailTemplate: React.FC<EmailTemplateListProps> = ({
  onEmailTemplateSelect,
  setExpandedSections,
  expandedSections,
  handleDiscardEmail,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [templates, setTemplates] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const getContact = async () => {
      try {
        const response = await getContentByType("email_template");
        const data = response.data?.data;
        setTemplates(data);
        setTotal(data.length);
      } catch (error) {
        console.error("error", error);
      }
    };
    getContact();
  }, []);

  const handleSave = () => {
    // setSelectedTemplate(template);
    onEmailTemplateSelect(selectedTemplate);
  };

  const handleEmailTemplateSelect = (template: any) => {
    setSelectedTemplate(template);
  };

  return (
    <>
      <section className="auto-section-1">
        <div className="d-flex justify-content-between">
          <div className="align-items-center auto-section-1-sub-1">
            <label>Email templates ({templates.length}) </label>
          </div>
        </div>
      </section>

      <section className="auto-section-2">
        <div className="container">
          <div
            className="container_body"
            style={{ overflowY: "scroll", height: 350 }}
          >
            <EmailTemplateList
              templates={templates}
              onEmailTemplateSelect={handleEmailTemplateSelect}
            />
          </div>
          {total > PER_PAGE && (
            <div className="text-center">
              <button
                className="btn fw-bold auto_btn"
                style={{
                  background: "#3FAEFD",
                }}
                type="button"
                onClick={() => {
                  setPage((prev) => prev + 1);
                }}
                disabled={page >= total / PER_PAGE}
              >
                Load more
              </button>
            </div>
          )}
          <div className="d-flex justify-content-end">
            <button
              onClick={handleDiscardEmail}
              className="btn me-2"
              style={{
                background: "none",
                border: "none",
                color: "rgba(0, 0, 0, 0.5)",
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              Discard
            </button>
            <button onClick={() => handleSave()} className="btn">
              Save
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmailTemplate;
