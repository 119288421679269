// src/utils/WhatsAppMediaValidator.ts


export const IMAGE_SIZE_LIMIT = 5 * 1024 * 1024;  // 5MB
export const VIDEO_SIZE_LIMIT = 16 * 1024 * 1024; // 16MB
export const DOC_SIZE_LIMIT = 100 * 1024 * 1024;  // 100MB

export interface MediaConfig {
    MAX_SIZE: number;
    TYPES: {
      [key: string]: string[];
    };
    DESCRIPTION?: string;
  }
  
  export interface MediaConfigs {
    [key: string]: MediaConfig;
  }
  
  export interface ValidationResult {
    isValid: boolean;
    error?: string;
    warnings?: string[];
  }
  
  export const MEDIA_CONFIG: MediaConfigs = {
    AUDIO: {
      MAX_SIZE: 16 * 1024 * 1024, // 16MB
      TYPES: {
        'audio/aac': ['.aac'],
        'audio/amr': ['.amr'],
        'audio/mpeg': ['.mp3'],
        'audio/mp4': ['.m4a'],
        'audio/ogg': ['.ogg']
      },
      DESCRIPTION: 'Audio files must be in AAC, AMR, MP3, M4A, or OGG (OPUS codec) format'
    },
    DOCUMENT: {
      MAX_SIZE: 100 * 1024 * 1024, // 100MB
      TYPES: {
        'text/plain': ['.txt'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/vnd.ms-powerpoint': ['.ppt'],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
        'application/pdf': ['.pdf']
      },
      DESCRIPTION: 'Documents can be TXT, Excel, Word, PowerPoint, or PDF files'
    },
    IMAGE: {
      MAX_SIZE: 5 * 1024 * 1024, // 5MB
      TYPES: {
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png']
      },
      DESCRIPTION: 'Images must be JPEG or PNG format, 8-bit, RGB or RGBA'
    },
    VIDEO: {
      MAX_SIZE: 16 * 1024 * 1024, // 16MB
      TYPES: {
        'video/3gpp': ['.3gp'],
        'video/mp4': ['.mp4']
      },
      DESCRIPTION: 'Videos must be MP4 or 3GPP format with H.264 video codec and AAC audio codec'
    }
  };
  
  export class WhatsAppMediaValidator {
    /**
     * Validates a file against WhatsApp's media requirements
     */
    static validateMediaFile(file: File, mediaType: string): ValidationResult {
      const type = mediaType.toUpperCase();
      
      // Check if media type is supported
      if (!MEDIA_CONFIG[type]) {
        return { 
          isValid: false, 
          error: `Unsupported media type: ${mediaType}` 
        };
      }
  
      // Size validation
      if (file.size > MEDIA_CONFIG[type].MAX_SIZE) {
        const maxSizeMB = MEDIA_CONFIG[type].MAX_SIZE / (1024 * 1024);
        return { 
          isValid: false, 
          error: `File size must not exceed ${maxSizeMB}MB` 
        };
      }
  
      // MIME type validation
      const allowedMimeTypes = Object.keys(MEDIA_CONFIG[type].TYPES);
      if (!allowedMimeTypes.includes(file.type)) {
        return { 
          isValid: false, 
          error: `Unsupported file type. Allowed types: ${this.getReadableMimeTypes(type)}` 
        };
      }
  
      // Extension validation
      const allowedExtensions = MEDIA_CONFIG[type].TYPES[file.type];
      const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;
      
      if (!allowedExtensions.includes(fileExtension)) {
        return { 
          isValid: false, 
          error: `Invalid file extension. Allowed extensions: ${allowedExtensions.join(', ')}` 
        };
      }
  
      // Add warnings for specific media types
      const warnings: string[] = [];
      
      if (type === 'VIDEO') {
        warnings.push('Video must use H.264 video codec and AAC audio codec');
      }
      if (type === 'IMAGE') {
        warnings.push('Image must be 8-bit, RGB or RGBA');
      }
      if (type === 'AUDIO' && file.type === 'audio/ogg') {
        warnings.push('OGG audio must use OPUS codec');
      }
  
      return { 
        isValid: true,
        warnings: warnings.length > 0 ? warnings : undefined
      };
    }
  
    /**
     * Gets allowed extensions for a media type
     */
    static getAllowedExtensions(mediaType: string): string[] {
      const type = mediaType.toUpperCase();
      if (!MEDIA_CONFIG[type]) return [];
      
      return Object.values(MEDIA_CONFIG[type].TYPES)
        .flat()
        .sort();
    }
  
    /**
     * Gets maximum file size for a media type in MB
     */
    static getMaxFileSize(mediaType: string): number {
      const type = mediaType.toUpperCase();
      if (!MEDIA_CONFIG[type]) return 0;
      return MEDIA_CONFIG[type].MAX_SIZE / (1024 * 1024);
    }
  
    /**
     * Gets the description for a media type
     */
    static getTypeDescription(mediaType: string): string {
      const type = mediaType.toUpperCase();
      return MEDIA_CONFIG[type]?.DESCRIPTION || '';
    }
  
    /**
     * Gets the allowed MIME types for a media type
     */
    static getAllowedMimeTypes(mediaType: string): string[] {
      const type = mediaType.toUpperCase();
      if (!MEDIA_CONFIG[type]) return [];
      return Object.keys(MEDIA_CONFIG[type].TYPES);
    }
  
    private static getReadableMimeTypes(mediaType: string): string {
      const type = mediaType.toUpperCase();
      if (!MEDIA_CONFIG[type]) return '';
  
      return Object.keys(MEDIA_CONFIG[type].TYPES)
        .map(mime => mime.split('/')[1].toUpperCase())
        .join(', ');
    }
  
    /**
     * Checks if the file might need additional codec validation
     */
    static needsCodecValidation(file: File): boolean {
      return file.type === 'video/mp4' || 
             file.type === 'video/3gpp' || 
             file.type === 'audio/ogg';
    }
  }
  
  /**
   * Helper function to format file size
   */
  export function formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  }