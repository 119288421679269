// types.ts
import styled from "styled-components";

export const Error = styled.p`
  color: red;
  margin-left: 4px;
  margin-top: 4px;
  font-size: 12px;
`;

export interface ButtonType {
  type: "PHONE_NUMBER" | "URL" | "";
  text: string;
  url?: string;
  phone_number?: string;
}