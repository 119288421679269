import React, { useState } from "react";
import "./whatsapp.css";
import { Button, Grid, Input } from "antd";

import Profile from "./Profile";
import { MdDelete } from "react-icons/md";
import DrawerExistModal from "../../components/drawerExistModal";
import { deleteChat } from "../../services/whatsapp";
import toast from "react-hot-toast";

// import { Avatar } from "rsuite";
const { useBreakpoint } = Grid;

const InboxChatHead: React.FC<any> = (props) => {
  const { lg } = useBreakpoint();
  const [searchText, setSearchText] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);

  const onDeleteChat = async () => {
    try {
      setConfirmDelete(false);
      const response = await deleteChat(props?.users?._id);
      props.onDelete && props.onDelete();
      toast.success("Chat deleted successfully");
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="headItem">
        <Profile
          style={{ marginRight: "12px", width: "40px", height: "40px" }}
          src={props.users.userIcon}
          icon="fa-lg"
        />

        {/* <Avatar src={props.users.userIcon} onError={() => true} /> */}
        <div className="flex-fill">
          <div className="name">{props?.users?.name || "Unknown"}</div>
          <div className="mob">{props?.users?.mobile}</div>
        </div>

        <div className="action">
          {/* <span className="py-1">
            <i className="fa-sharp fa-regular fa-clock"></i>{" "}
            <p className="fw-bold ms-0">{props?.users?.timeStamp}</p>
          </span> */}
          {/* <button className="hw-30 rounded-8 bg-white">
            <i className="fa-sharp fa-magnifying-glass"></i>
          </button> */}

          <button
            onClick={() => setConfirmDelete(true)}
            className="hw-30 rounded-8 bg-white"
          >
            <MdDelete size={18} color="red" style={{ cursor: "pointer" }} />
          </button>

          {!lg && (
            <button
              onClick={() => {
                props.setShowDetails(true);
              }}
            >
              <i className="fa-regular fa-ellipsis-vertical"></i>
            </button>
          )}
        </div>
      </div>

      {props.search && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "8px",
            paddingBottom: "8px",
            gap: "8px",
          }}
        >
          <div className="w-100 d-flex justify-content-between">
            <Input
              value={searchText}
              className="form-control w-100 rounded rounded-3 me-2"
              placeholder="search.."
              onChange={(e: any) => setSearchText(e.target.value)}
            />
            <div className="d-flex align-items-center">
              <Button className="d-flex align-items-center justify-content-center p-2 rounded rounded-circle hw-30 ">
                <i className="ri-arrow-down-s-line fs-5 lh-normal"></i>
              </Button>
              <Button className="d-flex align-items-center justify-content-center p-2 rounded rounded-circle hw-30 mx-2">
                <i className="ri-arrow-up-s-line fs-5 lh-normal"></i>
              </Button>
              <Button
                style={{ borderRadius: "5px" }}
                onClick={() => setSearchText("")}
              >
                Clear
              </Button>
            </div>

            {/* )} */}
          </div>
        </div>
      )}

      <DrawerExistModal
        title="Are you sure you want delete the chat?"
        showExitModal={confirmDelete}
        toggleEditModal={() => setConfirmDelete(!confirmDelete)}
        handleDrawerClick={onDeleteChat}
      />
    </>
  );
};

export default InboxChatHead;
