import React, { useEffect, useState, useCallback } from "react";
import toast, { Toaster } from "react-hot-toast";
import ConfirmationModal from "../../components/confirmationModal";
import CreateTaskForm from "../../components/createTaskForm";
import DrawerComponent from "../../components/drawer";
import Header from "../../components/header";
import TasksTable from "../../components/tasksTable";
import { getAllFollowUps, taskById } from "../../services/followupService";
import { deleteTask } from "../../services/taskService";
import InfiniteScroll from "react-infinite-scroll-component";
import "./follwups.scss";
import FilterIcon from "../../assets/images/filter.svg";
import ReloadIcon from "../../assets/images/reload.svg";
import TaskFilter from "./TaskFilter";
import LeadDetails from "../leads/leadDetails";
import { LeadDataI } from "../leads/leads";
import { useSelector } from "react-redux";
import { activityById } from "../../services/activityService";
import { uploadFiles } from "../../services/utilityService";
import { leadFileList } from "../../actions/actions";
import { useDispatch } from "react-redux";
import LeadMenu from "./LeadMenu/leadMenu";
import { TbWindowMaximize } from "react-icons/tb";
import { TaskQueryParameters, initialQueryParams } from "../../utils/types";
import BackToTop from "../../components/BackToTop";
import { Spin } from "antd";
import GlobalDrawer from "../../components/GlobalDrawer";
import TaskDrawer from "../common/TaskDrawer";
import moment from "moment";

const FollowUps: React.FC = () => {
  const [todayTask, setTodayTask] = useState([]);
  const [overdueTask, setOverdueTask] = useState([]);
  const [upcomingTask, setUpcomingTask] = useState([]);
  const [completedTask, setCompletedTask] = useState([]);
  const [status, setStatus] = useState<
    "today" | "upcoming" | "overdue" | "completed"
  >("today");
  const [openWithHeader, setOpenWithHeader] = React.useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [action, setAction] = React.useState<string>("");
  const [selectedTask, setSelectedTask] = useState<string>("");
  const [updateTaskValue, setUpdateTaskValue] = useState<any>({});
  const [taskTabCount, setTaskTabCount] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTasks, setTotalTasks] = useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [openFilterMenu, setOpenFilterMenu] = useState<boolean>(false);
  const [selectedLead, setSelectedLead] = useState<LeadDataI>();
  const [leadId, setLeadId] = useState<any>();
  const [leadLabelData, setLeadLabelData] = useState<Array<string>>([]);
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });

  const [paramState, setParamState] = useState<TaskQueryParameters>({});
  // const [paramState, setParamState] = useState<TaskQueryParameters>(initialQueryParams);
  const [openLeadDrawer, setOpenLeadDrawer] = useState<boolean>(false);

  // let userPreferences = JSON.parse(
  //   localStorage.getItem("user_preferences") || ""
  // );

  let userPreferences: any = {};

  try {
    userPreferences = JSON.parse(
      localStorage.getItem("user_preferences") || ""
    );
  } catch (e) {
    userPreferences = {};
  }

  const fetchTaskStatusFollowUps = async () => {
    let params = {
      orderBy: "_id",
      isAscending: false,
      page: 1,
      perPage: 50,
      status: status,
    };
    setParamState({
      ...paramState,
      orderBy: "_id",
      page: 1,
      isAscending: false,
      status: status,
    });
    // const response = await getAllFollowUps(params);
    // if (response && response.status) {
    //   let responseData = response?.data;
    //   setTotalTasks(responseData.data?.length);
    //   setCurrentPage(1);
    //   setTaskTabCount(responseData.aggregation);
    //   if (status === "today") {
    //     setTodayTask(responseData.data);
    //   } else if (status === "upcoming") {
    //     setUpcomingTask(responseData.data);
    //   } else if (status === "overdue") {
    //     setOverdueTask(responseData.data);
    //   } else {
    //     setCompletedTask(responseData.data);
    //   }
    // }
  };
  const fetchTaskStatusOnScroll = (tab: string) => {
    if (tab === status) {
      setIsLoading(true);
      let increasePage = currentPage;
      if (increasePage === currentPage) {
        increasePage = increasePage + 1;
      }

      setParamState({
        ...paramState,
        orderBy: "_id",
        isAscending: false,
        page: increasePage,
        status: status,
      });
      setOpenFilterMenu(false);
    }
  };

  const refreshTab = () => {
    // fetchData()
    // const updateObject = {...paramState , page:1}
    const updateObject = {
      status,
      isAscending: false,
      orderBy: "_id",
      page: 1,
    };

    setParamState(updateObject);
    // setParamState(initialQueryParams)
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const requestParams: TaskQueryParameters = {};
      // Helper function to conditionally add parameters
      const addParam = (
        paramName: keyof TaskQueryParameters,
        paramValue: any
      ) => {
        if (paramValue !== undefined && paramValue !== "") {
          requestParams[paramName] = paramValue;
        }
      };

      // Add parameters conditionally using the helper function
      addParam("orderBy", paramState.orderBy);
      addParam("isAscending", paramState.isAscending);
      addParam("page", paramState.page);
      addParam("perPage", 50);

      // Check and add "status" with specific values
      if (
        paramState.status &&
        ["completed", "upcoming", "overdue", "today"].includes(
          paramState.status
        )
      ) {
        addParam("status", paramState.status);
      }

      addParam("leadId", paramState.leadId);
      addParam("userId", paramState.userId);

      if (paramState.byOrganization) {
        addParam("byOrganization", paramState.byOrganization);
        // addParam('team', paramState.team);
      } else {
        addParam("team", paramState.team);
      }
      addParam("assignToUser", paramState.taskAssign);
      addParam("taskType", paramState.taskType);
      addParam("startedAt", paramState.startedAt);
      addParam("endedAt", paramState.endedAt);

      // Add other parameters in a similar fashion...

      const response = await getAllFollowUps(requestParams);
      if (response && response.status) {
        const responseData = response?.data;
        // CLOSE FILTER DIALOG BOX
        setOpenFilterMenu(false);
        let tempArray: any = [];
        // paramState.page

        if (paramState.page && paramState.page > 1) {
          if (status === "today") {
            tempArray = [...todayTask];
            tempArray.push(...responseData.data);
            setTodayTask(tempArray);
          } else if (status === "upcoming") {
            tempArray = [...upcomingTask];
            tempArray.push(...responseData.data);
            setUpcomingTask(tempArray);
          } else if (status === "overdue") {
            tempArray = [...overdueTask];
            tempArray.push(...responseData.data);
            setOverdueTask(tempArray);
          } else {
            tempArray = [...completedTask];
            tempArray.push(...responseData.data);
            setCompletedTask(tempArray);
          }
        } else {
          tempArray = [...responseData.data];
          if (status === "today") {
            setTodayTask(tempArray);
          } else if (status === "upcoming") {
            setUpcomingTask(tempArray);
          } else if (status === "overdue") {
            setOverdueTask(tempArray);
          } else {
            setCompletedTask(tempArray);
          }
        }

        setCurrentPage(paramState?.page ?? 1);
        setIsLoading(false);
        setTotalTasks(tempArray?.length);
        setTaskTabCount(responseData.aggregation);
      }
    } catch (error) {
      // Handle error here
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTaskStatusFollowUps();
  }, [status]);

  const onStatusChange = (
    status: "today" | "upcoming" | "overdue" | "completed"
  ) => {
    setStatus(status);
  };

  const toggleDrawer = (action: React.SetStateAction<string>) => {
    setOpenWithHeader(true);
    setAction(action);
  };

  const onTaskDelete = () => {
    if (updateTaskValue) {
      setShowConfirmationModal(true);
      setSelectedTask(updateTaskValue._id);
    }
  };

  const toggleModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const onConfirmation = async () => {
    try {
      const response = await deleteTask(selectedTask);
      if (response && response.status) {
        toast.success(response?.data?.message);
        fetchTaskStatusFollowUps();
      }
    } catch (err) {
      toast.error("Error while deleting task!");
    }
    setShowConfirmationModal(false);
    setOpenWithHeader(false);
  };

  const handleEditTask = async (_id: any) => {
    try {
      const response = await taskById(_id);
      if (response && response.status) {
        setUpdateTaskValue(response.data.data);
        toggleDrawer("edit");
      }
    } catch (err) {
      toast.error("error while fetching activity data.");
    }
  };

  const toggleLeadFilterDrawer = useCallback(() => {
    setOpenFilterMenu(true);
  }, []);

  const handleLeadClick = (leadId: string) => {
    let selected = StoreData?.leads?.leads?.find(
      (lead: any) => lead._id === leadId
    );
    let tempArray = [];
    tempArray = StoreData?.user?.userPreferences?.labels
      ? StoreData?.user?.userPreferences?.labels
      : userPreferences?.labels;

    const mergedArray = tempArray.map((obj1: any) => {
      const matchingObjTeam = selected?.label.find(
        (obj2: any) => obj2 === obj1.value
      );
      let temp_obj = {};
      if (selected?.label.includes(obj1.value)) {
        temp_obj = {
          ...obj1,
          isLabelChecked: true,
        };
      } else {
        temp_obj = {
          ...obj1,
          isLabelChecked: false,
        };
      }

      if (matchingObjTeam) {
        return { ...temp_obj };
      }
      return obj1;
    });

    setSelectedLead(selected);
    setLeadId(leadId);
    setLeadLabelData(mergedArray);
    setOpenWithHeader(true);
    setOpenLeadDrawer(!openLeadDrawer);
  };

  const onClickSecondOption = () => {
    handleLeadClick(updateTaskValue?.lead?.[0]?._id);
  };

  const onApplyFilterClick = async (data: any) => {
    let increasePage = currentPage;
  
    // Ensure we're using the correct date fields
    const filters = {
      ...paramState,
      page: increasePage,
      team: data?.sortTeamsBy,
      taskType: data?.taskType,
      userId: data?.sortTeamMemberBy,
      orderBy: data?.orderBy,
      byOrganization: data.byOrganization,
      startedAt: data?.startDate || data?.startedAt, // Handle both naming conventions
      endedAt: data?.endDate || data?.endedAt,       // Handle both naming conventions
      taskAssign: data?.sortTaskAssignBy,
    };
  
    delete filters.status;
    console.log('Applied filters:', filters); // For debugging
    setParamState(filters);
  };

  useEffect(() => {
    if (Object.keys(paramState).length != 0) {
      fetchData();
    }
  }, [paramState]);
  // console.log('paramState ======>',paramState)
  return (
    <div id="main" className="main">
      <Header />
      <section className="content-section-1">
        <div className="row">
          <div className="col-md-12">
            <ul
              className="nav nav-pills mb-3 content-section-1-sub-1 justify-content-around d-flex"
              id="pills-tab"
              role="tablist"
            >
              <li
                onClick={() => onStatusChange("today")}
                className="nav-item"
                role="presentation"
              >
                <button
                  className="nav-link active px-4"
                  id="pills-duetoday-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-duetoday"
                  type="button"
                  role="tab"
                  aria-controls="pills-duetoday"
                  aria-selected="true"
                >
                  Today ({taskTabCount?.today ? taskTabCount?.today : 0})
                </button>
              </li>
              <li
                onClick={() => onStatusChange("upcoming")}
                className="nav-item"
                role="presentation"
              >
                <button
                  className="nav-link px-4"
                  id="pills-upcoming-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-upcoming"
                  type="button"
                  role="tab"
                  aria-controls="pills-upcoming"
                  aria-selected="false"
                >
                  Upcoming(
                  {taskTabCount?.upcoming ? taskTabCount?.upcoming : 0})
                </button>
              </li>
              <li
                onClick={() => onStatusChange("overdue")}
                className="nav-item"
                role="presentation"
              >
                <button
                  className="nav-link px-4"
                  id="pills-overdue-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-overdue"
                  type="button"
                  role="tab"
                  aria-controls="pills-overdue"
                  aria-selected="false"
                >
                  Overdue ({taskTabCount?.overdue ? taskTabCount?.overdue : 0})
                </button>
              </li>
              <li
                onClick={() => onStatusChange("completed")}
                className="nav-item"
                role="presentation"
              >
                <button
                  className="nav-link px-4"
                  id="pills-completed-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-completed"
                  type="button"
                  role="tab"
                  aria-controls="pills-completed"
                  aria-selected="false"
                >
                  Done ({taskTabCount?.completed ? taskTabCount?.completed : 0})
                </button>
              </li>
              <div className="d-flex">
                <li className="me-3">
                  <div
                    style={{
                      backgroundColor: "#EBF0F4",
                      borderRadius: "5px",
                      height: "34px",
                    }}
                    className="p-2 d-flex justify-content-center"
                  >
                    <img
                      alt="img"
                      onClick={toggleLeadFilterDrawer}
                      src={FilterIcon}
                      title="filter"
                      id="filter-img"
                      className="cursor-pointer "
                    />
                  </div>
                </li>
                <li>
                  <div
                    style={{
                      backgroundColor: "#EBF0F4",
                      borderRadius: "5px",
                      height: "34px",
                    }}
                    className="p-2 d-flex justify-content-center"
                    onClick={() => {
                      console.log("check thi refresh");
                      refreshTab();
                    }}
                  >
                    <img
                      alt="reload img"
                      src={ReloadIcon}
                      id="filter-img"
                      className="cursor-pointer"
                    />

                    {/* <span
                      //   onClick={() => getLeads()}
                      className="text-black fw-bold cursor-pointer bi bi-arrow-clockwise"
                      style={{ height: "37px" }}
                    ></span> */}
                  </div>
                </li>
              </div>
            </ul>
            <Spin size="large" tip="Loading..." spinning={isLoading}>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-duetoday"
                  role="tabpanel"
                  aria-labelledby="duetoday-tab"
                >
                  {/* @ts-ignore */}
                  <InfiniteScroll
                    dataLength={totalTasks}
                    next={() => fetchTaskStatusOnScroll("today")}
                    hasMore={true}
                    loader={isLoading ? <h4></h4> : null}
                  >
                    <div className="followups-section-1">
                      <TasksTable
                        data={todayTask}
                        handleEditTask={handleEditTask}
                      />
                    </div>
                  </InfiniteScroll>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-upcoming"
                  role="tabpanel"
                  aria-labelledby="upcoming-tab"
                >
                  {/* @ts-ignore */}
                  <InfiniteScroll
                    dataLength={totalTasks}
                    next={() => fetchTaskStatusOnScroll("upcoming")}
                    hasMore={true}
                    loader={isLoading ? <h4></h4> : null}
                  >
                    <div className="followups-section-1">
                      <TasksTable
                        data={upcomingTask}
                        handleEditTask={handleEditTask}
                      />
                    </div>
                  </InfiniteScroll>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-overdue"
                  role="tabpanel"
                  aria-labelledby="overdue-tab"
                >
                  {/* @ts-ignore */}
                  <InfiniteScroll
                    dataLength={totalTasks}
                    next={() => fetchTaskStatusOnScroll("overdue")}
                    hasMore={true}
                    loader={isLoading ? <h4></h4> : null}
                  >
                    <div className="followups-section-1">
                      <TasksTable
                        data={overdueTask}
                        handleEditTask={handleEditTask}
                      />
                    </div>
                  </InfiniteScroll>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-completed"
                  role="tabpanel"
                  aria-labelledby="completed-tab"
                >
                  {/* @ts-ignore */}
                  <InfiniteScroll
                    dataLength={totalTasks}
                    next={() => fetchTaskStatusOnScroll("completed")}
                    hasMore={true}
                    loader={isLoading ? <h4></h4> : null}
                  >
                    <div className="followups-section-1">
                      <TasksTable
                        data={completedTask}
                        handleEditTask={handleEditTask}
                      />
                    </div>
                  </InfiniteScroll>
                </div>
              </div>
            </Spin>
          </div>
        </div>
        <div className="row">
          <div className="addmessagesicon">
            <i
              className="bi bi-plus-circle-fill"
              onClick={() => toggleDrawer("add")}
            ></i>
          </div>
        </div>
        <TaskDrawer
          openWithHeader={openWithHeader}
          setOpenWithHeader={setOpenWithHeader}
          drawerTitle={action === "add" ? "Add Task" : "Edit Task"}
          onRedirectClick={() => onClickSecondOption()}
          onTaskDelete={onTaskDelete}
          size="xs"
        >
          <CreateTaskForm
            updateTaskValue={updateTaskValue}
            action={action}
            status={status}
            drawerClose={() => setOpenWithHeader(false)}
            fetchTaskStatusFollowUps={fetchTaskStatusFollowUps}
          />
        </TaskDrawer>
        {/* <DrawerComponent
          openWithHeader={openWithHeader}
          setOpenWithHeader={setOpenWithHeader}
          drawerTitle={action === "add" ? "Add Task" : "Edit Task"}
          secondOption={<TbWindowMaximize size={25} />}
          onClickSecondOption={() => onClickSecondOption()}
          size="xs"
        >
          <CreateTaskForm
            updateTaskValue={updateTaskValue}
            action={action}
            status={status}
            drawerClose={() => setOpenWithHeader(false)}
            onTaskDelete={onTaskDelete}
            fetchTaskStatusFollowUps={fetchTaskStatusFollowUps}
          />
        </DrawerComponent> */}
        <ConfirmationModal
          message="Are you sure you want to delete this task?"
          onConfirmation={onConfirmation}
          showModal={showConfirmationModal}
          toggleModal={toggleModal}
          title="Task"
        />
      </section>
      <GlobalDrawer
        openWithHeader={openFilterMenu}
        setOpenWithHeader={setOpenFilterMenu}
        drawerTitle="Task filter"
        size="xs"
      >
        <TaskFilter
          {...{
            modal: openFilterMenu,
            toggle: () => setOpenFilterMenu(false),
            filters: paramState,
            key: "filterMenu",
            applyFilter: (data) => onApplyFilterClick(data),
          }}
        />
      </GlobalDrawer>

      <Toaster position="top-right" reverseOrder={false} />
      <LeadMenu
        leadId={leadId}
        leadLabelData={leadLabelData}
        setOpenWithHeader={setOpenWithHeader}
        selectedLead={selectedLead}
        openLeadDrawer={openLeadDrawer}
        setOpenLeadDrawer={setOpenLeadDrawer}
      />

      {/* back to top button */}
      <BackToTop />
    </div>
  );
};

export default FollowUps;
