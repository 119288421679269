import axios from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions } from "./getHeaderOptions";

const API_ENDPOINT = API_URL + "api/v1";

export const generateOtp = (data: object) => {
  return axios.post(`${API_ENDPOINT}/auth/generate-otp`, data, {
    headers: getHeaderOptions(),
  });
};
export const signIn = (data: object) => {
  // return axios.post(`${API_ENDPOINT}/auth`, data, {});
  return axios.post(`${API_ENDPOINT}/auth`, data, {
    headers: getHeaderOptions(),
  });
};
// in services/authServices.ts
export const sendGoogleCode = async (code: string) => {
  try {
    const response = await axios.post(`${API_ENDPOINT}/user/profile`, {
      code
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getUserProfile = () => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/user/profile`, {
    headers: obj,
  });
};

export const updateProfile = () => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/user/profile`, { headers: obj });
};

export const getGoogleTokens = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/user/get-google-tokens`, data, {
    headers: obj,
  });
};

export const getGoogleSheets = () => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/user/get-sheet-list`, {
    headers: obj,
  });
};

export const getGoogleSheetsInfo = (sheetId: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(
    `${API_ENDPOINT}/user/get-sheet-header?spreadsheetId=${sheetId}`,
    {
      headers: obj,
    }
  );
};

export const importGoogleSheet = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead/google-sheet`, data, {
    headers: obj,
  });
};
