import React, { useState, useEffect } from "react";

function ScrollToTopButton() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {isScrolled && (
        <button
          className="btn fw-bold auto_btn px-2"
          style={{ background: "#3FAEFD" }}
          type="button"
          onClick={scrollToTop}
        >
          <i className="me-1 ri-arrow-up-line"></i>Back to top{" "}
        </button>
      )}
    </div>
  );
}

export default ScrollToTopButton;
