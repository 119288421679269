import { FaFacebook, FaWordpress } from "react-icons/fa";

export const CUSTOM_ACTIVITY_TYPE = [
  {
    label: "Incoming",
    value: "INCOMING",
  },
  {
    label: "Outgoing ",
    value: "OUTGOING",
  },
  {
    label: "Missed",
    value: "MISSED",
  },
  {
    label: "share",
    value: "share",
  },
  {
    label: "Viewed",
    value: "viewed",
  },
  {
    label: "Status Update",
    value: "status_updated",
  },
  {
    label: "Task Created",
    value: "task_created",
  },
  {
    label: "Task Updated",
    value: "task_updated",
  },
  {
    label: "Task Assigned",
    value: "task_assigned",
  },
  {
    label: "Task Completed",
    value: "task_completed",
  },
  {
    label: "Auto lead assigned",
    value: "auto_lead_assign",
  },
  {
    label: "Note Created",
    value: "note_created",
  },
  {
    label: "Quotation created",
    value: "quotation_created",
  },
  {
    label: "Quotation approved",
    value: "quotation_approved",
  },
  {
    label: "Lead copied",
    value: "lead_copied",
  },
  {
    label: "Lead moved",
    value: "lead_moved",
  },
  {
    label: "Lead details updated",
    value: "lead_details_updated",
  },
  {
    label: "Voice Note",
    value: "new_voice_note",
  },
  {
    label: "Lead score update",
    value: "lead_score_update",
  },
];

const integrationImageStyle = {
  height: 20,
  width: 20,
  marginRight: 10,
  objectFit: "contain",
};

export const integrationOptions = [
  {
    label: "Facebook",
    icon: <FaFacebook style={integrationImageStyle} />,
    link: "",
  },
  {
    label: "Indiamart",
    icon: <img style={integrationImageStyle} src="/assets/img/indiaMart.png" />,
    link: "",
  },
  {
    label: "Justdial",
    icon: <img style={integrationImageStyle} src="/assets/img/justdial.png" />,
    link: "",
  },
  {
    label: "Wordpress",
    icon: <FaWordpress style={integrationImageStyle} />,
    link: "",
  },
  {
    label: "Zapier",
    icon: <img style={integrationImageStyle} src="/assets/img/zapier.png" />,
    link: "",
  },
  {
    label: "Tradeindia",
    icon: (
      <img style={integrationImageStyle} src="/assets/img/tradeindia.png" />
    ),
    link: "",
  },
  {
    label: "Gmail",
    icon: <img style={integrationImageStyle} src="/assets/img/gmail.png" />,
    link: "",
  },
  {
    label: "Google leadform extensions",
    icon: (
      <img style={integrationImageStyle} src="/assets/img/extensions.png" />
    ),
    link: "",
  },
  {
    label: "99Acres",
    icon: <img style={integrationImageStyle} src="/assets/img/99Acres.png" />,
    link: "",
  },
  {
    label: "Housing.com",
    icon: <img style={integrationImageStyle} src="/assets/img/housing.png" />,
    link: "",
  },
  {
    label: "Magicbricks",
    icon: (
      <img style={integrationImageStyle} src="/assets/img/magicbricks.png" />
    ),
    link: "",
  },
  {
    label: "Brevo",
    icon: <img style={integrationImageStyle} src="/assets/img/brevo.png" />,
    link: "",
  },
  {
    label: "Fb messenger",
    icon: (
      <img style={integrationImageStyle} src="/assets/img/fbmessenger.png" />
    ),
    link: "",
  },
  {
    label: "Instagram messaging",
    icon: <img style={integrationImageStyle} src="/assets/img/instagram.png" />,
    link: "",
  },
];

export const countries = [
  { value: "+91", label: "India" },
  { value: "+1", label: "United States" },
  { value: "+86", label: "China" },
  { value: "+44", label: "United Kingdom" },
  { value: "+81", label: "Japan" },
  { value: "+7", label: "Russia" },
  { value: "+49", label: "Germany" },
  { value: "+82", label: "South Korea" },
  { value: "+92", label: "Pakistan" },
  { value: "+62", label: "Indonesia" },
  { value: "+55", label: "Brazil" },
  { value: "+234", label: "Nigeria" },
  { value: "+20", label: "Egypt" },
  { value: "+33", label: "France" },
  { value: "+39", label: "Italy" },
  { value: "+34", label: "Spain" },
  { value: "+62", label: "Malaysia" },
  { value: "+65", label: "Singapore" },
  { value: "+880", label: "Bangladesh" },
  { value: "+92", label: "Afghanistan" },
  { value: "+93", label: "Albania" },
  { value: "+213", label: "Algeria" },
  { value: "+376", label: "Andorra" },
  { value: "+244", label: "Angola" },
  { value: "+1", label: "Anguilla" },
  { value: "+1", label: "Antigua and Barbuda" },
  { value: "+54", label: "Argentina" },
  { value: "+374", label: "Armenia" },
  { value: "+297", label: "Aruba" },
  { value: "+61", label: "Australia" },
  { value: "+43", label: "Austria" },
  { value: "+994", label: "Azerbaijan" },
  { value: "+1", label: "Bahamas" },
  { value: "+973", label: "Bahrain" },
  { value: "+880", label: "Bangladesh" },
  { value: "+1", label: "Barbados" },
  { value: "+375", label: "Belarus" },
  { value: "+32", label: "Belgium" },
  { value: "+501", label: "Belize" },
  { value: "+229", label: "Benin" },
  { value: "+1", label: "Bermuda" },
  { value: "+975", label: "Bhutan" },
  { value: "+591", label: "Bolivia" },
  { value: "+387", label: "Bosnia and Herzegovina" },
  { value: "+267", label: "Botswana" },
  { value: "+55", label: "Brazil" },
  { value: "+246", label: "British Indian Ocean Territory" },
  { value: "+673", label: "Brunei" },
  { value: "+359", label: "Bulgaria" },
  { value: "+226", label: "Burkina Faso" },
  { value: "+257", label: "Burundi" },
  { value: "+855", label: "Cambodia" },
  { value: "+237", label: "Cameroon" },
  { value: "+1", label: "Canada" },
  { value: "+238", label: "Cape Verde" },
  { value: "+1", label: "Cayman Islands" },
  { value: "+236", label: "Central African Republic" },
  { value: "+235", label: "Chad" },
  { value: "+56", label: "Chile" },
  { value: "+61", label: "Christmas Island" },
  { value: "+57", label: "Colombia" },
  { value: "+269", label: "Comoros" },
  { value: "+242", label: "Congo" },
  { value: "+682", label: "Cook Islands" },
  { value: "+506", label: "Costa Rica" },
  { value: "+385", label: "Croatia" },
  { value: "+53", label: "Cuba" },
  { value: "+537", label: "Cyprus" },
  { value: "+420", label: "Czech Republic" },
  { value: "+45", label: "Denmark" },
  { value: "+253", label: "Djibouti" },
  { value: "+1", label: "Dominica" },
  { value: "+1", label: "Dominican Republic" },
  { value: "+593", label: "Ecuador" },
  { value: "+20", label: "Egypt" },
  { value: "+503", label: "El Salvador" },
  { value: "+240", label: "Equatorial Guinea" },
  { value: "+291", label: "Eritrea" },
  { value: "+372", label: "Estonia" },
  { value: "+251", label: "Ethiopia" },
  { value: "+500", label: "Falkland Islands" },
  { value: "+298", label: "Faroe Islands" },
  { value: "+679", label: "Fiji" },
  { value: "+358", label: "Finland" },
  { value: "+33", label: "France" },
  { value: "+594", label: "French Guiana" },
  { value: "+689", label: "French Polynesia" },
  { value: "+241", label: "Gabon" },
  { value: "+220", label: "Gambia" },
  { value: "+995", label: "Georgia" },
  { value: "+233", label: "Ghana" },
  { value: "+350", label: "Gibraltar" },
  { value: "+30", label: "Greece" },
  { value: "+299", label: "Greenland" },
  { value: "+1", label: "Grenada" },
  { value: "+590", label: "Guadeloupe" },
  { value: "+1", label: "Guam" },
  { value: "+502", label: "Guatemala" },
  { value: "+224", label: "Guinea" },
  { value: "+245", label: "Guinea-Bissau" },
  { value: "+592", label: "Guyana" },
  { value: "+509", label: "Haiti" },
  { value: "+504", label: "Honduras" },
  { value: "+852", label: "Hong Kong" },
  { value: "+36", label: "Hungary" },
  { value: "+354", label: "Iceland" },
  { value: "+91", label: "India" },
  { value: "+62", label: "Indonesia" },
  { value: "+98", label: "Iran" },
  { value: "+964", label: "Iraq" },
  { value: "+353", label: "Ireland" },
  { value: "+972", label: "Israel" },
  { value: "+39", label: "Italy" },
  { value: "+1", label: "Jamaica" },
  { value: "+81", label: "Japan" },
  { value: "+962", label: "Jordan" },
  { value: "+7", label: "Kazakhstan" },
  { value: "+254", label: "Kenya" },
  { value: "+686", label: "Kiribati" },
  { value: "+965", label: "Kuwait" },
  { value: "+996", label: "Kyrgyzstan" },
  { value: "+856", label: "Laos" },
  { value: "+371", label: "Latvia" },
  { value: "+961", label: "Lebanon" },
  { value: "+266", label: "Lesotho" },
  { value: "+231", label: "Liberia" },
  { value: "+218", label: "Libya" },
  { value: "+423", label: "Liechtenstein" },
  { value: "+370", label: "Lithuania" },
  { value: "+352", label: "Luxembourg" },
  { value: "+853", label: "Macau" },
  { value: "+389", label: "Macedonia" },
  { value: "+261", label: "Madagascar" },
  { value: "+265", label: "Malawi" },
  { value: "+60", label: "Malaysia" },
  { value: "+960", label: "Maldives" },
  { value: "+223", label: "Mali" },
  { value: "+356", label: "Malta" },
  { value: "+692", label: "Marshall Islands" },
  { value: "+596", label: "Martinique" },
  { value: "+222", label: "Mauritania" },
  { value: "+230", label: "Mauritius" },
  { value: "+262", label: "Mayotte" },
  { value: "+52", label: "Mexico" },
  { value: "+691", label: "Micronesia" },
  { value: "+373", label: "Moldova" },
  { value: "+377", label: "Monaco" },
  { value: "+976", label: "Mongolia" },
  { value: "+382", label: "Montenegro" },
  { value: "+1", label: "Montserrat" },
  { value: "+212", label: "Morocco" },
  { value: "+258", label: "Mozambique" },
  { value: "+95", label: "Myanmar" },
  { value: "+264", label: "Namibia" },
  { value: "+674", label: "Nauru" },
  { value: "+977", label: "Nepal" },
  { value: "+31", label: "Netherlands" },
  { value: "+599", label: "Netherlands Antilles" },
  { value: "+687", label: "New Caledonia" },
  { value: "+64", label: "New Zealand" },
  { value: "+505", label: "Nicaragua" },
  { value: "+227", label: "Niger" },
  { value: "+234", label: "Nigeria" },
  { value: "+683", label: "Niue" },
  { value: "+672", label: "Norfolk Island" },
  { value: "+850", label: "North Korea" },
  { value: "+47", label: "Norway" },
  { value: "+968", label: "Oman" },
  { value: "+92", label: "Pakistan" },
  { value: "+680", label: "Palau" },
  { value: "+970", label: "Palestine" },
  { value: "+507", label: "Panama" },
  { value: "+675", label: "Papua New Guinea" },
  { value: "+595", label: "Paraguay" },
  { value: "+51", label: "Peru" },
  { value: "+63", label: "Philippines" },
  { value: "+64", label: "Pitcairn Islands" },
  { value: "+48", label: "Poland" },
  { value: "+351", label: "Portugal" },
  { value: "+1", label: "Puerto Rico" },
  { value: "+974", label: "Qatar" },
  { value: "+262", label: "Reunion" },
  { value: "+40", label: "Romania" },
  { value: "+7", label: "Russia" },
  { value: "+250", label: "Rwanda" },
  { value: "+290", label: "Saint Helena" },
  { value: "+1", label: "Saint Kitts and Nevis" },
  { value: "+1", label: "Saint Lucia" },
  { value: "+508", label: "Saint Pierre and Miquelon" },
  { value: "+1", label: "Saint Vincent and the Grenadines" },
  { value: "+685", label: "Samoa" },
  { value: "+378", label: "San Marino" },
  { value: "+239", label: "Sao Tome and Principe" },
  { value: "+966", label: "Saudi Arabia" },
  { value: "+221", label: "Senegal" },
  { value: "+381", label: "Serbia" },
  { value: "+248", label: "Seychelles" },
  { value: "+232", label: "Sierra Leone" },
  { value: "+65", label: "Singapore" },
  { value: "+421", label: "Slovakia" },
  { value: "+386", label: "Slovenia" },
  { value: "+677", label: "Solomon Islands" },
  { value: "+252", label: "Somalia" },
  { value: "+27", label: "South Africa" },
  { value: "+82", label: "South Korea" },
  { value: "+34", label: "Spain" },
  { value: "+94", label: "Sri Lanka" },
  { value: "+249", label: "Sudan" },
  { value: "+597", label: "Suriname" },
  { value: "+268", label: "Swaziland" },
  { value: "+46", label: "Sweden" },
  { value: "+41", label: "Switzerland" },
  { value: "+963", label: "Syria" },
  { value: "+886", label: "Taiwan" },
  { value: "+992", label: "Tajikistan" },
  { value: "+255", label: "Tanzania" },
  { value: "+66", label: "Thailand" },
  { value: "+670", label: "Timor-Leste" },
  { value: "+228", label: "Togo" },
  { value: "+690", label: "Tokelau" },
  { value: "+676", label: "Tonga" },
  { value: "+1", label: "Trinidad and Tobago" },
  { value: "+216", label: "Tunisia" },
  { value: "+90", label: "Turkey" },
  { value: "+993", label: "Turkmenistan" },
  { value: "+1", label: "Turks and Caicos Islands" },
  { value: "+688", label: "Tuvalu" },
  { value: "+256", label: "Uganda" },
  { value: "+380", label: "Ukraine" },
  { value: "+971", label: "United Arab Emirates" },
  { value: "+44", label: "United Kingdom" },
  { value: "+1", label: "United States" },
  { value: "+598", label: "Uruguay" },
  { value: "+998", label: "Uzbekistan" },
  { value: "+678", label: "Vanuatu" },
  { value: "+58", label: "Venezuela" },
  { value: "+84", label: "Vietnam" },
  { value: "+1", label: "Virgin Islands" },
  { value: "+681", label: "Wallis and Futuna" },
  { value: "+967", label: "Yemen" },
  { value: "+260", label: "Zambia" },
  { value: "+263", label: "Zimbabwe" },
];
