// FilterBar.tsx

import React, { useState, Dispatch, SetStateAction, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import { ActivityTypeConfig } from '../../views/ActivityFeed/activityTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes, faCalendar, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';
import { IOrganizationEmployee, IOrganizationTeam } from '../../utils/types';

// Types

interface RootState {
    rootReducers: {
      user: {
        userData: {
          organizationTeams: IOrganizationTeam[];
          organizationEmployee: IOrganizationEmployee[];
        }
      }
    }
  }

interface Team {
    _id: string;
    name: string;
    organization: string;
}

interface TeamMember {
    _id: string;
    firstName: string;
    lastName: string | null;
}

interface FilterBarProps {
    dateOptions: Array<{label: string; value: string}>;
    activityTypes: Record<string, ActivityTypeConfig>;
    applyDateFilter: (range: string) => void;
    setFilters: Dispatch<SetStateAction<FilterState>>;
    filters: FilterState;
    className?: string;
}

interface FilterState {
    type: string;
    team: string;     // Changed from teams
    userId: string;   // Changed from userIds
    dateRange: string;
}

// Styled Components
const FilterContainer = styled.div`
    display: flex;
    gap: 8px; /* Reduced gap */
    align-items: center;
    background: #e7f1ff;  
    padding: 8px; /* Reduced padding */
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    max-width: 600px; /* Restrict width */
    margin: 0 auto;
    position: relative; /* To position Reset link */
    box-sizing: border-box;
`;

const ResetLink = styled.span`
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 12px;
    color: #007bff;
    cursor: pointer;

    &:hover {
        color: #0056b3;
    }
`;
const FilterButton = styled.button`
    display: flex;
    align-items: center;
    gap: 6px; /* Reduced gap */
    padding: 6px 10px; /* Reduced padding */
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    background: white;
    color: #374151;
    font-size: 12px; /* Smaller font size */
    cursor: pointer;

    &:hover {
        border-color: #0066FF;
    }
`;

const Select = styled.select`
    padding: 8px 12px;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    background: white;
    color: #374151;
    font-size: 14px;
    min-width: 120px; /* Minimum width to avoid resizing */
    width: 100%; /* Adjust as necessary */
`;

const Modal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);
    z-index: 1000;
    width: 400px;
    padding: 20px;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 999;
`;

const CheckboxGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin: 16px 0;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h3 {
        margin: 0;
        font-size: 18px;
        color: #111827;
    }
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 20px;
    padding-top: 16px;
    border-top: 1px solid #E5E7EB;
`;

const Button = styled.button<{$primary?: boolean}>`
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid ${p => p.$primary ? '#0066FF' : '#E5E7EB'};
    background: ${p => p.$primary ? '#0066FF' : 'white'};
    color: ${p => p.$primary ? 'white' : '#374151'};
    cursor: pointer;
`;

const DateDropdown = styled.div`
    position: relative;
    width: 100%; /* Set width for responsive dropdowns */
`;

const DateOptions = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    margin-top: 4px;
    z-index: 100;
    width: 200px;
`;

const DateOption = styled.div`
    padding: 8px 12px;
    cursor: pointer;
    &:hover {
        background: #F3F4F6;
    }
`;

// Main Component
const FilterBar: React.FC<FilterBarProps> = ({
    filters,
    setFilters,
    dateOptions,
    activityTypes,
    applyDateFilter,
    className
}) => {
    const [showTypesModal, setShowTypesModal] = useState(false);
    const [showDateOptions, setShowDateOptions] = useState(false);
    const [showCustomDate, setShowCustomDate] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState<string[]>(filters.type ? filters.type.split(',') : []);
    const userData = useSelector((state: any) => state?.rootReducers?.user?.userData);
    const teams = userData?.organizationTeams || [];
    const teamMembers = userData?.organizationEmployee || [];
    console.log('FilterBar Render - Current Filters:', filters);
    console.log('FilterBar Render - userData:', userData);
 

    const handleDateSelect = (value: string) => {

        if (value === 'custom') {
            setShowCustomDate(true);
        } else {
            setFilters(prev => ({ ...prev, dateRange: value }));
            applyDateFilter(value);
        }
        setShowDateOptions(false);
    };


    const handleTypeSelect = (type: string) => {
        setSelectedTypes(prev => {
            const newTypes = prev.includes(type) 
                ? prev.filter(t => t !== type)
                : [...prev, type];
            
            setFilters(current => ({
                ...current,
                type: newTypes.join(',')
            }));
            
            return newTypes;
        });
    };

    return (
        <FilterContainer className={className}>
          

            <FilterButton onClick={() => setShowTypesModal(true)}>
                <FontAwesomeIcon icon={faFilter} />
                {selectedTypes.length ? `${selectedTypes.length} Selected` : 'Activities'}
                <FontAwesomeIcon icon={faChevronDown} />
            </FilterButton>
  {/* Temporarily commented out team and member filters
            <Select
               value={filters.team}
               onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                   console.log('Team Select Change:', e.target.value);
                   setFilters(prev => {
                       console.log('Previous Filters:', prev);
                       const newFilters = { ...prev, team: e.target.value };
                       console.log('New Filters:', newFilters);
                       return newFilters;
                   });
               }}
           >
               <option value="">All Teams</option>
               {userData?.organizationTeams?.map((team: any) => {
                   console.log('Rendering Team Option:', team);
                   return (
                       <option key={team._id} value={team._id}>
                           {team.name}
                       </option>
                   );
               })}
           </Select>


           <Select
               value={filters.userId}
               onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                   console.log('User Select Change:', e.target.value);
                   setFilters(prev => {
                       console.log('Previous Filters:', prev);
                       const newFilters = { ...prev, userId: e.target.value };
                       console.log('New Filters:', newFilters);
                       return newFilters;
                   });
               }}
           >
               <option value="">All Users</option>
               {userData?.organizationEmployee?.map((member: any) => {
                   console.log('Rendering User Option:', member);
                   return (
                       <option key={member._id} value={member._id}>
                           {`${member.firstName} ${member.lastName || ''}`}
                       </option>
                   );
               })}
           </Select>
 */}
            <DateDropdown>
            <FilterButton onClick={() => setShowDateOptions(!showDateOptions)}>
                <FontAwesomeIcon icon={faCalendar} />
                {filters.dateRange || 'Last 7 Days'}
                <FontAwesomeIcon icon={faChevronDown} />
            </FilterButton>

            {showDateOptions && (
                <DateOptions>
                    {dateOptions.map(option => (
                        <DateOption key={option.value} onClick={() => handleDateSelect(option.value)}>
                            {option.label}
                        </DateOption>
                    ))}
                </DateOptions>
            )}
        </DateDropdown>
            {showTypesModal && (
            <>
                <Overlay onClick={() => setShowTypesModal(false)} />
                <Modal>
                    <ModalHeader>
                        <h3>Select Activities</h3>
                        <button onClick={() => setShowTypesModal(false)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </ModalHeader>
                    
                    <CheckboxGrid>
                        {Object.entries(activityTypes).map(([key, config]) => (
                            <label key={key}>
                                <input
                                    type="checkbox"
                                    checked={selectedTypes.includes(key)}
                                    onChange={() => handleTypeSelect(key)}
                                />
                                {config.label}
                            </label>
                        ))}
                    </CheckboxGrid>

                    <ModalFooter>
                        <Button onClick={() => setShowTypesModal(false)}>Cancel</Button>
                        <Button $primary onClick={() => setShowTypesModal(false)}>Apply</Button>
                    </ModalFooter>
                </Modal>
            </>
        )}
    </FilterContainer>
    );
};

export default FilterBar;