import React, { PropsWithChildren, useState } from "react";
import moment from "moment";
import {
  MdDoneAll,
  MdPhoneMissed,
  MdPlace,
  MdSend,
  MdInsertLink,
  MdOutlineEmail,
  MdTaskAlt,
  MdOutlinePhoneMissed,
  MdOutlineAssignmentInd,
  MdAddTask,
} from "react-icons/md";
import { TiPhoneOutline, TiVideoOutline } from "react-icons/ti";
import { FiPhoneOutgoing, FiPhoneIncoming } from "react-icons/fi";
import { IoIosCall, IoMdCog } from "react-icons/io";
import { IoReload, IoChatbubbleOutline } from "react-icons/io5";
import { HiOutlineMailOpen } from "react-icons/hi";
import { convertSecondsToMinutes, convertToIST } from "../utils/helpers";
import { BiMessage } from "react-icons/bi";
import { RiUserFollowLine } from "react-icons/ri";
import { FaEdit, FaVideo } from "react-icons/fa";
import { CiStickyNote } from "react-icons/ci";
import { TbFileUpload } from "react-icons/tb";
import { MdOutlineEdit } from "react-icons/md";
import "./taskItem.css";

// import { LuActivitySquare } from "react-icons/lu";

// interface Props {
//     activity: {
//         createdAt: Date,
//         createdBy: string,
//         createdTimestamp: Date,
//         extraDetails: any,
//         lead: {
//             _id: string,
//             name: string,
//             integrationId: string
//             integrationName: string
//         },
//         performedAt: Date,
//         type: string,
//         _id: string
//     }
// }
interface activityitemI {
  activity: any;
  name: string;
  key: any;
  handleEditModal: (name: string, id: any, action: string) => void;
}
const ActivityItem: React.FC<PropsWithChildren<activityitemI>> = ({
  activity,
  name,
  key,
  handleEditModal,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const activityFormat = (activityType: any, activityData: any) => {
    const activity_types = {
      label_update: `Lead label updated to ${
        activityData?.extraDetails?.prev_label
          ? activityData?.extraDetails?.prev_label
          : "NA"
      }`,
      status_update: `Lead status updated to ${
        activityData?.extraDetails?.prev_status
          ? activityData?.extraDetails?.prev_status
          : "NA"
      }`,
      task_create: `${activityData.extraDetails?.type} : Task created`,
      task_is_complete_update: `${activityData.extraDetails?.type} : Marked as completed`,
      task_due_date_update: `${activityData.extraDetails?.type} : Due date updated`,
      task_assign_update: `${
        activityData.extraDetails?.type
      } : Task assigned to ${
        activityData.extraDetails?.task_assigned_to_name || "NA"
      }`,
      note_create: `Added new note`,
      task_delete: `Task deleted`,
    };
    switch (activityType) {
      case "call":
        return {
          ltCntColor: "#e7efe8",
          ltCntIcon: (
            <TiPhoneOutline
              name={"phone-outline"}
              color={"#15661f"}
              size={40}
            />
          ),
          missedIcon: (
            <MdPhoneMissed name={"phone-missed"} color={"red"} size={40} />
          ),
          outGoingIcon: (
            <FiPhoneOutgoing
              name={"phone-outgoing"}
              color={"#15661f"}
              size={40}
            />
          ),
          incomingIcon: (
            <FiPhoneIncoming
              name={"phone-incoming"}
              color={"#3cb371"}
              size={40}
            />
          ),
          seenText: "was called by you.",
          seenTextClient: "called you",
          missedText: "missed you",
        };
      case "INCOMING":
        return {
          ltCntColor: "#e7efe8",
          ltCntIcon: (
            <FiPhoneOutgoing
              name={"phone-outgoing"}
              color={"#15661f"}
              size={40}
            />
          ),
          seenText: "called you",
        };
      case "checkin":
        return {
          ltCntColor: "#e7efe8",
          ltCntIcon: <MdPlace name={"place"} color={"#15661f"} size={40} />,
          seenText: "You checked in",
        };
      case "checkout":
        return {
          ltCntColor: "#e7efe8",
          ltCntIcon: <MdPlace name={"place"} color={"#15661f"} size={40} />,
          seenText: "You have checked out",
        };
      case "transfer_lead":
        return {
          ltCntColor: "#e7efe8",
          ltCntIcon: (
            <IoMdCog name={"cog-transfer"} color={"#15661f"} size={40} />
          ),
          seenText: "Lead transfered",
        };
      case "OUTGOING":
        return {
          ltCntColor: "#e7efe8",
          ltCntIcon: (
            <FiPhoneIncoming
              name={"phone-incoming"}
              color={"#3cb371"}
              size={40}
            />
          ),

          seenText: "was called by you.",
        };
      case "MISSED":
        return {
          ltCntColor: "#e7efe8",
          ltCntIcon: (
            <MdPhoneMissed name={"phone-missed"} color={"red"} size={40} />
          ),
          seenText: "missed you",
        };
      case "follow_up":
        return {
          ltCntColor: "#fbd9ff",
          ltCntIcon: <IoReload name={"reload"} color={"#ed66ff"} size={40} />,
          seenText: "was followed up by you",
          seenTextClient: "had a follow up with you",
        };
      case "send":
        return {
          ltCntColor: "#d4e4ff",
          ltCntIcon: <MdSend name={"send"} color={"#599aff"} size={40} />,
          seenText: "was send by you",
          seenTextClient: "send you",
        };
      case "email":
        return {
          ltCntColor: "#ffe8f0",
          ltCntIcon: (
            <HiOutlineMailOpen
              name={"email-open-outline"}
              color={"#ff216a"}
              size={40}
            />
          ),
          seenText: "was mailed by you",
          seenTextClient: "mailed you",
        };
      case "message":
        return {
          ltCntColor: "#ffe8f0",
          ltCntIcon: (
            <IoChatbubbleOutline
              name={"chatbubble-outline"}
              color={"#FF216A"}
              size={40}
            />
          ),
          seenText: "was messaged by you",
          seenTextClient: "messaged you",
        };
      case "video_call":
        return {
          // ltCntColor: `${theme.colors.themeCol2}2f`,
          ltCntIcon: (
            <TiVideoOutline
              name={"video-outline"}
              // color={theme.colors.themeCol2}
              size={40}
            />
          ),
          seenText: "was video called by you",
          seenTextClient: "called you",
        };
      case "view":
        return {
          ltCntColor: "#feefe8",
          ltCntIcon: (
            <MdInsertLink
              name={"insert-link"}
              color={"#f16520"}
              size={40}
              //   style={{ transform: [{ rotate: '-45deg' }] }}
            />
          ),
          seenText: "viewed your link",
          seenTextClient: "viewed your link",
        };
      default:
        return activity_types[activityType as keyof Object]
          ? {
              ltCntColor: "#feefe8",
              ltCntIcon: (
                <MdDoneAll name={"done-all"} color={"#f16520"} size={40} />
              ),
              seenText: `${activityData?.type?.replace(/_/g, " ")}`,
              seenTextClient: `${activityData?.type?.replace(/_/g, " ")}`,
            }
          : {
              ltCntColor: "#feefe8",
              ltCntIcon: (
                <MdDoneAll name={"done-all"} color={"#f16520"} size={40} />
              ),
              seenText: `Performed ${activityData?.type?.replace(/_/g, " ")}`,
              seenTextClient: `Performed ${activityData?.type?.replace(
                /_/g,
                " "
              )}`,
            };
    }
  };
  const formatting = activityFormat(activity?.type, activity);

  const TypeData = [
    "INCOMING",
    "OUTGOING",
    "MISSED",
    "checkin",
    "checkout",
    "call",
    "sms",
    "message",
    "transfer_lead",
  ];
  const ShowType =
    TypeData.filter((x: string) => x === activity.type).length > 0
      ? activity.type
      : activity.label !== null
      ? activity.label
      : activity.type;

  const timeFormat = moment.utc(activity.performedAt).toDate();

  // const performAtDate = convertToIST(moment(activity.toBePerformAt).format('DD MMM YYYY, hh:mm a')).split(',')[0].split('-')

  const [day, month] = convertToIST(
    moment(activity.toBePerformAt).format("DD MMM YYYY, hh:mm a")
  )
    .split(",")[0]
    .split("-");
  // const performAtDate = day + " " + month;

  const performAtDate = day;

  return (
    <div
      className="popup-section-4-right-1 d-flex align-items-center progress-step"
      style={{ background: "none" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="icon-div"
        style={{
          background: activity?.type == "OUTGOING" ? "#C91D1D" : "#3FAEFD",
          zIndex: 2,
        }}
      >
        {/* {[
          "INCOMING",
          "OUTGOING",
          "MISSED",
          "checkin",
          "checkout",
          "call",
          "sms",
          "message",
          "transfer_lead",
        ].includes(activity?.type)
          ? formatting?.ltCntIcon
          : activity?.type === "share"
            ? null
            : formatting?.ltCntIcon} */}

        {activity?.type == "call" ? (
          <span style={{ color: "#15661f" }} className={activity?.type}>
            <IoIosCall />
          </span>
        ) : activity.type == "video-meeting" ? (
          <span style={{ color: "#15661f" }} className={activity?.type}>
            <FaVideo />
          </span>
        ) : activity.type == "message" ? (
          <span style={{ color: "#FF216A" }} className={activity?.type}>
            <div
              className="d-flex align-items-center justify-content-center rounded-circle"
              style={{ width: "40px", height: "40px", background: "#2DC39F" }}
            >
              <svg
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 0C5.625 0 0 2.38667 0 5.33333C0.0625 6.76667 1.325 8.11333 3.4375 9C3.4375 9.4 2.9125 10.4467 0 12C2.9625 11.9267 5.8 11.3333 8.0875 10.3333C9.5125 10.5533 11.0125 10.6667 12.5 10.6667C19.375 10.6667 25 8.28 25 5.33333C25 2.38667 19.375 0 12.5 0ZM12.5 9.33333C6.975 9.33333 2.5 7.54 2.5 5.33333C2.5 3.12667 6.975 1.33333 12.5 1.33333C18.025 1.33333 22.5 3.12667 22.5 5.33333C22.5 7.54 18.025 9.33333 12.5 9.33333Z"
                  fill="white"
                />
              </svg>
            </div>
          </span>
        ) : activity.type == "email" ? (
          <span style={{ color: "#ff216a" }} className={activity?.type}>
            <MdOutlineEmail />
          </span>
        ) : activity.type == "follow-up" ? (
          <span style={{ color: "#ed66ff" }} className={activity?.type}>
            <RiUserFollowLine />
          </span>
        ) : activity?.type == "incomig" ? (
          <span style={{ color: "#15661f" }} className={activity?.type}>
            <span style={{ color: "#15661f" }} className={activity?.type}>
              <div
                className="d-flex align-items-center justify-content-center rounded-circle"
                style={{ width: "40px", height: "40px", background: "#3FAEFD" }}
              >
                <svg
                  width="25"
                  height="12"
                  viewBox="0 0 25 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.6111 8.33333C21.9444 8.33333 20.1389 8.2 18.6111 7.93333H18.1944C17.7778 7.93333 17.5 8 17.2222 8.13333L14.1667 9.6C10.2778 8.6 6.94444 7.06667 5 5.2L8.05556 3.73333C8.47222 3.53333 8.61111 3.26667 8.33333 3.06667C7.91667 2.33333 7.63889 1.46667 7.63889 0.666667C7.63889 0.333333 6.94444 0 6.25 0H1.38889C0.694444 0 0 0.333333 0 0.666667C0 6.93333 10.5556 12 23.6111 12C24.3056 12 25 11.6667 25 11.3333V9C25 8.66667 24.3056 8.33333 23.6111 8.33333ZM2.77778 1.33333H4.86111C5 1.93333 5.27778 2.53333 5.55556 3.06667L3.88889 3.86667C3.33333 3.06667 2.91667 2.2 2.77778 1.33333ZM22.2222 10.6667C20.4167 10.6 18.6111 10.4 16.9444 10.1333L18.6111 9.33333C19.7222 9.46667 20.9722 9.6 22.2222 9.6V10.6667ZM22.2222 5.33333V4.33333H17.3611L25 0.666667L23.6111 0L15.9722 3.66667V1.33333H13.8889V5.33333H22.2222Z"
                    fill="white"
                  />
                </svg>
              </div>
            </span>
          </span>
        ) : activity?.type == "OUTGOING" ? (
          <span style={{ color: "#15661f" }} className={activity?.type}>
            <div
              className="d-flex align-items-center justify-content-center rounded-circle"
              style={{ width: "40px", height: "40px", background: "#1D54A7" }}
            >
              <svg
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.6111 8.33333C21.9444 8.33333 20.1389 8.2 18.6111 7.93333H18.1944C17.7778 7.93333 17.5 8 17.2222 8.13333L14.1667 9.6C10.2778 8.6 6.94444 7.06667 5 5.2L8.05556 3.73333C8.47222 3.53333 8.61111 3.26667 8.33333 3.06667C7.91667 2.33333 7.63889 1.46667 7.63889 0.666667C7.63889 0.333333 6.94444 0 6.25 0H1.38889C0.694444 0 0 0.333333 0 0.666667C0 6.93333 10.5556 12 23.6111 12C24.3056 12 25 11.6667 25 11.3333V9C25 8.66667 24.3056 8.33333 23.6111 8.33333ZM2.77778 1.33333H4.86111C5 1.93333 5.27778 2.53333 5.55556 3.06667L3.88889 3.86667C3.33333 3.06667 2.91667 2.2 2.77778 1.33333ZM22.2222 10.6667C20.4167 10.6 18.6111 10.4 16.9444 10.1333L18.6111 9.33333C19.7222 9.46667 20.9722 9.6 22.2222 9.6V10.6667ZM16.6667 0V1H21.5278L13.8889 4.66667L15.2778 5.33333L22.9167 1.66667V4H25V0H16.6667Z"
                  fill="white"
                />
              </svg>
            </div>
          </span>
        ) : activity?.type == "Missed" ? (
          <span style={{ color: "#FF216A" }} className={activity?.type}>
            <MdOutlinePhoneMissed />
          </span>
        ) : activity?.type == "lead_assigned " ? (
          <span style={{ color: "#15661f" }} className={activity?.type}>
            <MdOutlineAssignmentInd />
          </span>
        ) : activity?.type == "status_updated" ||
          activity?.type == "label_updated" ? (
          <span style={{ color: "#15661f" }} className={activity?.type}>
            <div
              className="d-flex align-items-center justify-content-center rounded-circle"
              style={{ width: "40px", height: "40px", background: "#3FAEFD" }}
            >
              <svg
                width="21"
                height="15"
                viewBox="0 0 21 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9015 5L13.9981 5.78333L3.40619 13.3333H2.33301V12.5667L12.9015 5ZM17.101 0C16.8093 0 16.506 0.0833333 16.2844 0.241667L14.1497 1.76667L18.5241 4.89167L20.6588 3.36667C21.1137 3.04167 21.1137 2.5 20.6588 2.19167L17.9292 0.241667C17.6959 0.075 17.4043 0 17.101 0ZM12.9015 2.65833L0 11.875V15H4.37439L17.2759 5.78333L12.9015 2.65833Z"
                  fill="white"
                />
              </svg>
            </div>
          </span>
        ) : activity?.type == "note_created" ? (
          <span style={{ color: "#15661f" }} className={activity?.type}>
            <CiStickyNote />
          </span>
        ) : activity?.type == "task_created" ? (
          <span style={{ color: "#15661f" }} className={activity?.type}>
            <MdAddTask />
          </span>
        ) : activity?.type == "file_upload" ? (
          <span style={{ color: "#15661f" }} className={activity?.type}>
            <TbFileUpload />
          </span>
        ) : activity?.type == "all_other_activity " ? (
          <span style={{ color: "#15661f" }} className={activity?.type}>
            <MdTaskAlt />
          </span>
        ) : (
          <span style={{ color: "#fff" }} className={activity?.type}>
            <MdTaskAlt size={25} />
          </span>
        )}

        {name == "task" && <h6>{performAtDate}</h6>}
      </div>
      <div
        className="activity_info_container"
        style={{ backgroundColor: isHovered ? "#EBF0F4" : "transparent" }}
      >
        <h6 className="" key={key}>
          {name === "activity" && (
            <h6 className="text-dark fs-7">
              {moment(activity.performedAt).format("DD MMM hh:mm a")}{" "}
              {/* {moment(timeFormat).format("hh:mm a")} {" by "}{" "} */}
              {" by "}
              {activity?.createdBy && (
                <>
                  {activity?.createdBy?.firstName && (
                    <>
                      {`${activity.createdBy.firstName} ${
                        activity.createdBy.lastName
                          ? activity.createdBy.lastName
                          : ""
                      }`}
                    </>
                  )}
                  {!activity?.createdBy?.firstName && (
                    <> {activity?.createdBy} </>
                  )}
                </>
              )}
            </h6>
          )}
          <h5 className="text-capitalize mb-0 text-black fs-7">
            {name === "task" ? (
              <>
                {activity?.type} -{" "}
                {
                  convertToIST(
                    moment(activity.toBePerformAt).format(
                      "DD MMM YYYY, hh:mm a"
                    )
                  ).split(",")[1]
                }
                {/* {activity?.type} - {moment(timeFormat).format("hh:mm a")} */}
              </>
            ) : (
              ShowType
            )}
          </h5>

          {activity?.notes && (
            <h6 className="fs-8" style={{ color: "#00000080" }}>
              {activity?.notes ?? ""}
            </h6>
          )}

          {/* {name == "task" ? (
            <h6>
              {"Owner : "}
              {activity?.createdBy?.firstName ? (
                <>
                  {activity?.createdBy?.firstName &&
                  activity?.createdBy?.lastName ? (
                    <>
                      {" " +
                        activity?.createdBy?.firstName +
                        " " +
                        activity?.createdBy?.lastName}
                    </>
                  ) : (
                    <>{" " + activity?.createdBy?.firstName}</>
                  )}
                </>
              ) : (
                <>{" " + activity?.createdBy}</>
              )}
              At{" "}
              {" " +
                convertToIST(
                  moment(activity.createdAt).format("DD MMM YYYY, hh:mm a")
                )}
            </h6>
          ) : (
            <></>
          )} */}
        </h6>
        {isHovered && (
          <div
            className="activity_edit_icon"
            style={{ display: "flex", justifyContent: "center", top: "20%" }}
          >
            <MdOutlineEdit
              color={"#fff"}
              size={16}
              onClick={() => handleEditModal(name, activity._id, "edit")}
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
        {activity?.extraDetails && activity?.extraDetails?.notes ? (
          <p>Note: {activity?.extraDetails?.notes}</p>
        ) : null}

        {activity?.extraDetails && activity?.extraDetails?.duration ? (
          <p className="fs-8" style={{ color: "#00000080" }}>
            Duration:{" "}
            {convertSecondsToMinutes(activity?.extraDetails?.duration)}
          </p>
        ) : null}

        {activity.type === "OUTGOING" && !activity?.extraDetails?.duration ? (
          <p className="fs-8" style={{ color: "#00000080" }}>
            Unanswered
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default ActivityItem;
