export interface AgGridResponse {
  status: "success" | "fail";
  data: any;
  total: number;
}

export interface FilterParams {
  sort?: {
    orderBy?: string;
    isAscending?: boolean;
  };
  paginationParams?: {
    page: number;
    perPage: number;
  };
  search?: string;
  note?: string;
  date?: {
    startedAt: string;
    endedAt: string;
  };
  label?: string[];
  status?: string[];
  source?: string[];
  byOrganization?: boolean;
  teams?: string[];
  teamMembers?: string[];
  activityFilter?: {
    type: string;
    single: {
      key: string;
      value: string;
    };
    range: {
      start: {
        key: string;
        value: string;
      };
      end: {
        key: string;
        value: string;
      };
    };
  };
  taskFilter?: {
    type: string;
    range: {
      start: string;
      end: string;
    };
  };
  assign?: {
    isAssign: boolean;
    teamMembers: string[];
  };
  list?: string;
  isUntouched?: boolean;
  isNotCalled?: boolean;
  isFollowUp?: boolean;
  isNotAssign?: boolean;
  isStaled?: boolean;
  customFieldFilter?: {
    fieldName: string;
    single: {
      operator: string;
      value: string;
    };
    range: {
      start: {
        operator: string;
        value: string;
      };
      end: {
        operator: string;
        value: string;
      };
    };
  };
}

export interface UserDetails {
  search?: string;
  name?: string;
}

export const advanceFilterKeys = [
  "sort",
  "search",
  "note",
  "date",
  "label",
  "status",
  "source",
  "byOrganization",
  "teams",
  "teamMembers",
  "activityFilter",
  "taskFilter",
  "assign",
  "isUntouched",
  "isNotCalled",
  "isFollowUp",
  "isNotAssign",
  "customFieldFilter",
];
