import React, { PropsWithChildren, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { updateLeadLabel } from "../services/leadService";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface LabelI {
  labels: Array<{
    color: string;
    idDefault: boolean;
    name: string;
    value: string;
  }>;
}

interface PropsT {
  showLabelPopup: boolean;
  toggleLeadLabel: () => void;
  onLabelChange: (e: any, name: string) => void;
  selectedLabel: Array<string>;
  setShowLabelPopup: React.Dispatch<React.SetStateAction<boolean>>;
  from: string;
  id: string;
  setOpenWithHeader: React.Dispatch<React.SetStateAction<boolean>>;
  LabelData: Array<any>;
  getLeads: (leadId: any) => void;
  leadList: string;
  updateEditedRow?: (newData: any) => void;
}

const SelectLeadLabel: React.FC<PropsWithChildren<PropsT>> = ({
  showLabelPopup,
  toggleLeadLabel,
  onLabelChange,
  setShowLabelPopup,
  from,
  selectedLabel,
  id,
  getLeads,
  setOpenWithHeader,
  LabelData,
  leadList,
  updateEditedRow,
}) => {
  const onSave = async () => {
    let tempArray = [];
    for (let i = 0; i < LabelData.length; i++) {
      if (LabelData[i].isLabelChecked) {
        tempArray.push(LabelData[i].value);
      }
    }
    let ids = [];
    ids.push(id);
    try {
      const response = await updateLeadLabel({
        leadIDs: Array.isArray(id) ? id : ids,
        label: tempArray,
      });
      if (response && response.status) {
        toast.success(response?.data?.message);
        setOpenWithHeader(false);
        if (updateEditedRow) {
          updateEditedRow(response?.data.data);
        } else {
          getLeads(leadList);
        }
      }
    } catch (err) {
      toast.error("Error while updating labels!");
    }
    setShowLabelPopup(false);
  };

  return (
    <Modal isOpen={showLabelPopup} toggle={toggleLeadLabel}>
      <ModalHeader toggle={toggleLeadLabel}>Select Label</ModalHeader>
      <ModalBody>
        <LabelGrid>
          {LabelData?.map((item, key) => {
            return (
              <label key={key} htmlFor={`checkbox-${key}`}>
                <LabelCard isSelected={item.isLabelChecked} color={item.color}>
                  <LabelContent>
                    <ColorIndicator
                      color={item.color}
                      isSelected={item.isLabelChecked}
                    />
                    <LabelName title={item.name}>{item.name}</LabelName>
                  </LabelContent>
                  <input
                    id={`checkbox-${key}`}
                    className="form-check-input"
                    type="checkbox"
                    value={item.value}
                    checked={item?.isLabelChecked ? true : false}
                    onChange={(e) => onLabelChange(e, item.value)}
                  />
                  {/* <Checkbox
                  isSelected={item.isLabelChecked}
                  onChange={(e: any) => onLabelChange(e, item.value)}
                >
                  {item.isLabelChecked && (
                    <FontAwesomeIcon icon={faCheck} size="sm" />
                  )}
                </Checkbox> */}
                  {/* <input
                  className="form-check-input"
                  type="checkbox"
                  value={item.value}
                  checked={item?.isLabelChecked ? true : false}
                  id="flexCheckDefault"
                  onChange={(e) => onLabelChange(e, item.value)}
                />
                &nbsp;
                <i className="bi-circle-fill" style={{ color: item.color }}></i>
                &nbsp;
                <label className="form-check-label">{item?.name}</label> */}
                </LabelCard>
              </label>
            );
          })}
        </LabelGrid>
      </ModalBody>
      <ModalFooter>
        <BottomButtonContainer>
          <button
            onClick={toggleLeadLabel}
            type="button"
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button onClick={onSave} type="button" className="btn btn-primary">
            Save
          </button>
        </BottomButtonContainer>
      </ModalFooter>
      <Toaster position="top-right" reverseOrder={false} />
    </Modal>
  );
};

export default SelectLeadLabel;

const LabelGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  max-height: 60vh;
  overflow-y: auto;
  padding: 1rem;
`;

const LabelCard = styled.div<{ isSelected: boolean; color: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: 2px solid ${(props) => (props.isSelected ? props.color : "#e0e0e0")};
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? `${props.color}33` : "#ffffff"};
  font-size: 1rem;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`;

const LabelContent = styled.div`
  display: flex;
  align-items: center;
`;

const ColorIndicator = styled.span<{ color: string; isSelected: boolean }>`
  width: ${(props) => (props.isSelected ? "24px" : "16px")};
  height: ${(props) => (props.isSelected ? "24px" : "16px")};
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 1rem;
  flex-shrink: 0;
  transition: all 0.3s ease;
`;

const LabelName = styled.span`
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const Checkbox = styled.div<{ isSelected: boolean }>`
  width: 20px;
  height: 20px;
  border: 2px solid ${(props) => (props.isSelected ? "#007bff" : "#aaa")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: ${(props) =>
    props.isSelected ? "#007bff" : "transparent"};
`;

const UnselectAllButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
  margin: 0 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  button {
    width: 45% !important;
    height: 45px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
`;
