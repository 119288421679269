import { Button, Spin } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Filter } from 'lucide-react';
import callLogsDrawerManager from '../../components/CallLogsDrawerManager'; 
import { FunnelChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Header from "../../components/header";
import {
  fetchDashboardAllActivityCount,
  fetchDashboardCallLeaderBoard,
  fetchDashboardContentInfo,
  fetchDashboardFunnelChart,
  fetchDashboardGraphData,
  fetchDashboardLeadCount,
  fetchDashboardLeadLableGraphData,
  fetchDashboardLeadSourceGraphData,
  fetchDashboardMapcheckIn,
  fetchDashboardQuotationStatusChart,
  fetchDashboardSalesCount,
  fetchDashboardTaskStatusChart,
} from "../../services/dashboardService";
import DashboardAreaChart from "./DashboardAreaChart";
import DashboardHeader from "./DashboardHeader";
import DashboardPieChart, { PieChartData } from "./DashboardPieChart";
import DashboardTabs from "./DashboardTabs";
import { Tabs } from "./dashboard.types";
import DashboardCheckings from "./DashboardCheckins";

import DashboardTaskStatusPieChart from "./DashboardTaskStatusPieChart";
import DashboardQuotationStatusPieChart from "./DashboardQuotationStatusPieChart";
import DashboardCallLeaderBoard, {
  TableData,
} from "./DashboardCallLeaderBoard";
import { getDateRange } from "../../components/MomentFun";
import { Divider } from "rsuite";
import styled from "styled-components";


interface TabItem {
  key: string;
  label: string;
  icon: string;
}



function getTimeFormat(time: any) {
  // const time = Number(newTime.splice(0,4))
  if (time && typeof time === "number") {
    if (!isNaN(time) && isFinite(time)) {
      var date = new Date(0);
      date.setUTCSeconds(time);

      var timeString = date.toISOString().substr(11, 8);
      return timeString;
    } else {
      console.error("Invalid time value:", time);
      return null; // or return an error string, depending on your use case
    }
  } else {
    return;
  }
}


export interface ICount {
  leadAssignedCount: number;
  leadCreateCount: number;
  totalLead: number;
}
export interface DateRange {
  from: string;
  to: string;
}

const StyledDivider = styled(Divider)`
  height: 3rem;
  border-left: 1px solid #d9d9d9;
`;

const Dashboard: React.FC = () => {
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });

  const [filterFromDate, setFilterFromDate] = useState(7);
  const [fromDate, setFromDate] = useState("7d");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
    from: moment().subtract(7, "days").utc().format(),
    to: moment(new Date()).utc().format(),
  });
  const [customFromDate, setCustomFromDate] = useState("");
  const [customToDate, setCustomToDate] = useState("");
  const [leadTotals, setLeadTotals] = useState<any>({
    leadAssignedCount: 0,
    leadCreateCount: 0,
    totalLead: 0,
  });
  const [totalActivityCount, setTotalActivityCount] = useState<number>(0);
  const [totalCallCount, setTotalCallCount] = useState<number>(0);
  const [totalSalesCount, setTotalSalesCount] = useState<number>(0);
  const [activityTypes, setActivityTypes] = useState<any>([]);
  const [callingData, setCallingData] = useState<any>([]);
  const [callingDetails, setCallingDetails] = useState<any>({});
  const [leadSourceGraphX, setLeadSourceGraphX] = useState<any>([]);
  const [leadSourceGraphS, setLeadSourceGraphS] = useState<any>([]);
  const [saleFunnelGraphS, setSaleFunnelGraphS] = useState<any>([]);
  const [funnelColor, setFunnelColor] = useState<any>([]);
  const [mapCheckInData, setMapCheckInData] = useState<any>([]);
  const [isCustomDate, setIsCustomDate] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedTeamId, setSelectedTeamId] = useState("");
  const [isOrganization, setIsOrganization] = useState<boolean>(false);
  const [isPageLoad, setIsPageLoad] = useState<boolean>(false);
  const [leadBySource, setLeadBySource] = useState<PieChartData[]>([]);
  const [taskByStatus, setTaskByStatus] = useState<PieChartData[]>([]);
  const [quotationByStatus, setQuotationByStatus] = useState<PieChartData[]>(
    []
  );
  
  const [leadByLabel, setLeadByLabel] = useState<PieChartData[]>([]);
  const [callLeaderBoard, setCallLeaderBoard] = useState<TableData[]>([]);
  const [tabName, setTabName] = useState<Tabs>("lead");
  const [taskPerc, setTaskPec] = useState<any>("");
  const [contentCountInfo, setContentCountInfo] = useState<any>({
    share: 0,
    view: 0,
  });

  const handleOpenDrawer = () => {
    callLogsDrawerManager.openDrawer();
  };
  

  const [taskCount, setTaskCount] = useState([]);

  useEffect(() => {
    DashboardLeadCount();
    DashboardAllActivityData();
    DashboardSalesData();
    DashboardGraph(tabName);
    DashboardLeadLabelGraph();
    DashboardLeadSourceGraph();
    DashboardSaleFunnelGraph();
    DashboardTaskStatusGraph();
    DashboardCallLeaderBoardFun();
    DashboardQuotationStatusGraph();
    DashboardMapCheckInDetails();
    getContentCount();
  }, [filterFromDate]);

  useEffect(() => {
    if (customFromDate && customToDate) {
      DashboardLeadCount();
      DashboardAllActivityData();
      DashboardSalesData();
      DashboardGraph(tabName);
      DashboardLeadLabelGraph();
      DashboardLeadSourceGraph();
      DashboardSaleFunnelGraph();
      DashboardTaskStatusGraph();
      DashboardCallLeaderBoardFun();
      DashboardQuotationStatusGraph();
      DashboardMapCheckInDetails();
      getContentCount();

      setSelectedDateRange({ from: customFromDate, to: customToDate });
    }
  }, [customFromDate && customToDate]);

  useEffect(() => {
    if (selectedUserId) {
      DashboardLeadCount();
      DashboardAllActivityData();
      DashboardSalesData();
      DashboardGraph(tabName);
      DashboardLeadLabelGraph();
      DashboardLeadSourceGraph();
      DashboardSaleFunnelGraph();
      DashboardTaskStatusGraph();
      DashboardCallLeaderBoardFun();
      DashboardQuotationStatusGraph();
      DashboardMapCheckInDetails();
      getContentCount();
    }
  }, [selectedUserId]);

  useEffect(() => {
    if (selectedTeamId) {
      DashboardLeadCount();
      DashboardAllActivityData();
      DashboardSalesData();
      DashboardGraph(tabName);
      DashboardLeadLabelGraph();
      DashboardLeadSourceGraph();
      DashboardSaleFunnelGraph();
      DashboardTaskStatusGraph();
      DashboardCallLeaderBoardFun();
      DashboardQuotationStatusGraph();
      DashboardMapCheckInDetails();
      getContentCount();
    }
  }, [selectedTeamId]);

  useEffect(() => {
    if (isOrganization) {
      DashboardLeadCount();
      DashboardAllActivityData();
      DashboardSalesData();
      DashboardGraph(tabName);
      DashboardLeadLabelGraph();
      DashboardLeadSourceGraph();
      DashboardSaleFunnelGraph();
      DashboardTaskStatusGraph();
      DashboardCallLeaderBoardFun();
      DashboardQuotationStatusGraph();
      DashboardMapCheckInDetails();
      getContentCount();
    }
  }, [isOrganization]);

  const getContentCount = async () => {
    try {
      let fromDate = isCustomDate
        ? customFromDate
        : moment().subtract(filterFromDate, "d").utc().format();
      let toDate = isCustomDate
        ? customToDate
        : moment(new Date()).utc().format();
      const { data } = await fetchDashboardContentInfo(
        fromDate,
        toDate,
        isOrganization,
        selectedUserId,
        selectedTeamId
      );
      if (data?.data) {
        let share =
          data?.data?.contentTypeCount?.find((e: any) => e.type === "shared")
            ?.count || 0;

        let view =
          data?.data?.contentTypeCount?.find((e: any) => e.type === "view")
            ?.count || 0;

        setContentCountInfo({
          share,
          view,
        });
      }
    } catch (error) {}
  };

  const DashboardLeadCount = async () => {
    try {
      setIsPageLoad(true);
      let fromDate = isCustomDate
        ? customFromDate
        : moment().subtract(filterFromDate, "d").utc().format();
      let toDate = isCustomDate
        ? customToDate
        : moment(new Date()).utc().format();
      const response = await fetchDashboardLeadCount(
        fromDate,
        toDate,
        isOrganization,
        selectedUserId,
        selectedTeamId
      );
      if (response && response.status) {
        setLeadTotals(response.data.data?.count);
        setTimeout(() => {
          setIsPageLoad(false);
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setIsPageLoad(false);
    }
  };

  const DashboardAllActivityData = async () => {
    try {
      setIsPageLoad(true);
      let fromDate = isCustomDate
        ? customFromDate
        : moment().subtract(filterFromDate, "d").utc().format();
      let toDate = isCustomDate
        ? customToDate
        : moment(new Date()).utc().format();
      const response = await fetchDashboardAllActivityCount(
        fromDate,
        toDate,
        isOrganization,
        selectedUserId,
        selectedTeamId
      );
      if (response && response.status) {
        let resposneData = response.data.data;
        let tempCallingData = resposneData?.callingCount;
        let callCount = 0;
        tempCallingData.forEach((item: any) => {
          callCount += item.count;
        });
        setTotalCallCount(callCount);
        setTotalActivityCount(resposneData?.totalActivity);
        setActivityTypes(resposneData?.activityTypes);
        setCallingData(resposneData?.callingCount);
        setCallingDetails(resposneData?.callingDetails);
        setTimeout(() => {
          setIsPageLoad(false);
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setIsPageLoad(false);
    }
  };

  const DashboardSalesData = async () => {
    try {
      setIsPageLoad(true);
      let fromDate = isCustomDate
        ? customFromDate
        : moment().subtract(filterFromDate, "d").utc().format();
      let toDate = isCustomDate
        ? customToDate
        : moment(new Date()).utc().format();
      const response = await fetchDashboardSalesCount(
        fromDate,
        toDate,
        isOrganization,
        selectedUserId,
        selectedTeamId
      );
      if (response && response.status) {
        let resposneData = response.data.data;
        setTotalSalesCount(resposneData?.salesValue || 0);
        setTimeout(() => {
          setIsPageLoad(false);
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setIsPageLoad(false);
    }
  };

  const DashboardGraph = async (tab: string) => {
    try {
      setIsPageLoad(true);
      let fromDate = isCustomDate
        ? customFromDate
        : moment().subtract(filterFromDate, "d").utc().format();
      let toDate = isCustomDate
        ? customToDate
        : moment(new Date()).utc().format();
      const response = await fetchDashboardGraphData(
        fromDate,
        toDate,
        tab,
        isOrganization,
        selectedUserId,
        selectedTeamId
      );
      if (response && response.status) {
        let resposneData = response.data.data;
        if (resposneData.length > 0) {
          let tempXData = [];
          let tempSData = [];
          for (let i = 0; i < resposneData.length; i++) {
            tempXData.push(resposneData[i].count);
            tempSData.push(moment(resposneData[i].point).format("YYYY-MM-DD"));
          }
          setTimeout(() => {
            setIsPageLoad(false);
          }, 1000);
        } else {
          setIsPageLoad(false);
        }
      }
    } catch (err) {
      setIsPageLoad(false);
      console.log(err);
    }
  };

  const DashboardLeadLabelGraph = async (date?: any) => {
    try {
      setIsPageLoad(true);
      let fromDate = date?.from
        ? date.from
        : isCustomDate
        ? customFromDate
        : moment().subtract(filterFromDate, "d").utc().format();
      let toDate = date?.to
        ? date.to
        : isCustomDate
        ? customToDate
        : moment(new Date()).utc().format();
      const response = await fetchDashboardLeadLableGraphData(
        fromDate,
        toDate,
        isOrganization,
        selectedUserId,
        selectedTeamId
      );
      if (response && response.status) {
        let resposneData = response.data.data;
        if (resposneData.length > 0) {
          let tempXData = [];
          let tempSData = [];
          let color = [];
          let tempColorData = StoreData.user.userPreferences.labels;
          for (let j = 0; j < tempColorData.length; j++) {
            color.push(tempColorData[j].color);
          }
          for (let i = 0; i < resposneData.length; i++) {
            tempXData.push(resposneData[i].count);
            tempSData.push(resposneData[i].type);
          }
          setLeadByLabel(
            resposneData.map((r: any) => ({
              label: r.displayName,
              value: r.count,
            }))
          );
          setTimeout(() => {
            setIsPageLoad(false);
          }, 1000);
        } else {
          setIsPageLoad(false);
        }
      }
    } catch (err) {
      console.log(err);
      setIsPageLoad(false);
    }
  };

  const DashboardLeadSourceGraph = async (date?: any) => {
    try {
      setIsPageLoad(true);
      let fromDate = date?.from
        ? date.from
        : isCustomDate
        ? customFromDate
        : moment().subtract(filterFromDate, "d").utc().format();
      let toDate = date?.to
        ? date.to
        : isCustomDate
        ? customToDate
        : moment(new Date()).utc().format();
      const response = await fetchDashboardLeadSourceGraphData(
        fromDate,
        toDate,
        isOrganization,
        selectedUserId,
        selectedTeamId
      );
      if (response && response.status) {
        let resposneData = response.data.data;
  
        if (resposneData.length > 0) {
          // Sort and transform the data before setting leadBySource
          setLeadBySource(
            resposneData
              .sort((a: { count: number }, b: { count: number }) => b.count - a.count)
              .map((d: any) => ({
                label: d.integration || "unknown",
                value: d.count,
                rate: d.convertedRate,
              }))
          );
          let tempXData = [];
          let tempSData = [];
          for (let i = 0; i < resposneData.length; i++) {
            if (resposneData[i].integration) {
              tempXData.push(resposneData[i].count);
              tempSData.push(resposneData[i].integration);
            }
          }
          setLeadSourceGraphX(tempXData);
          setLeadSourceGraphS(tempSData);
          setTimeout(() => {
            setIsPageLoad(false);
          }, 1000);
        } else {
          setLeadSourceGraphX([]);
          setLeadSourceGraphS([]);
          setIsPageLoad(false);
        }
      }
    } catch (err) {
      console.log(err);
      setIsPageLoad(false);
    }
  };

  const DashboardSaleFunnelGraph = async (date?: any) => {
    try {
      setIsPageLoad(true);
      let fromDate = date?.from
        ? date.from
        : isCustomDate
        ? customFromDate
        : moment().subtract(filterFromDate, "d").utc().format();
      let toDate = date?.to
        ? date.to
        : isCustomDate
        ? customToDate
        : moment(new Date()).utc().format();
      const response = await fetchDashboardFunnelChart(
        fromDate,
        toDate,
        isOrganization,
        selectedUserId,
        selectedTeamId
      );
      if (response && response.status) {
        let resposneData = response.data.data;

        if (resposneData.length > 0) {
          let tempFunnelData = [];
          let tempFunnelColor = [];
          let tempColors = StoreData.user.userPreferences.status;
          for (let i = 0; i < resposneData.length; i++) {
            let X_label = _.capitalize(
              resposneData[i].type?.split("_")?.join(" ")
            );
            let X = `${resposneData[i].count} ${X_label}`;
            let tempObj = {
              name: X,
              value: resposneData[i].saleValue,
            };
            tempFunnelData.push(tempObj);
            for (let j = 0; j < tempColors.length; j++) {
              if (resposneData[i].type === tempColors[j].value) {
                tempFunnelColor.push(tempColors[j].color);
              }
            }
          }
          
          setSaleFunnelGraphS(tempFunnelData);
          setFunnelColor(tempFunnelColor);
          setTimeout(() => {
            setIsPageLoad(false);
          }, 1000);
        } else {
          setSaleFunnelGraphS([]);
          setIsPageLoad(false);
        }
      }
    } catch (err) {
      console.log(err);
      setIsPageLoad(false);
    }
  };

  const DashboardTaskStatusGraph = async (date?: any) => {
    try {
      setIsPageLoad(true);
      let fromDate = date?.from
        ? date.from
        : isCustomDate
        ? customFromDate
        : moment().subtract(filterFromDate, "d").utc().format();
      let toDate = date?.to
        ? date.to
        : isCustomDate
        ? customToDate
        : moment(new Date()).utc().format();

      const response = await fetchDashboardTaskStatusChart(
        fromDate,
        toDate,
        isOrganization,
        selectedUserId,
        selectedTeamId
      );
      if (response && response.status) {
        let resposneData = response.data.data;
        let data = [];
        setTaskPec(resposneData?.percentage);

        setTaskCount(resposneData?.taskTypeCounts);

        for (const element of Object.keys(resposneData.taskCounts)) {
          data.push({
            label: element,
            value: resposneData.taskCounts[element],
          });
        }
        setTaskByStatus(data);
        setTimeout(() => {
          setIsPageLoad(false);
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setIsPageLoad(false);
    }
  };

  function addTotalForIncomingAndOutgoing(data: any) {
    let total = 0;
    data.forEach((type: any) => {
      // Add to total if the type is INCOMING or OUTGOING
      if (type.type === "INCOMING" || type.type === "OUTGOING") {
        total += type.count;
      }
    });

    return total;
  }

  const DashboardCallLeaderBoardFun = async (date?: any) => {
    try {
      setIsPageLoad(true);
      let fromDate = date?.from
        ? date.from
        : isCustomDate
        ? customFromDate
        : moment().subtract(filterFromDate, "d").utc().format();
      let toDate = date?.to
        ? date.to
        : isCustomDate
        ? customToDate
        : moment(new Date()).utc().format();
      const response = await fetchDashboardCallLeaderBoard(
        fromDate,
        toDate,
        isOrganization,
        selectedUserId,
        selectedTeamId
      );
      if (response && response.status) {
        let resposneData: any = response?.data?.data ?? [];

        let tempRes = resposneData
          .map((e: any) => ({
            ...e,
            total: addTotalForIncomingAndOutgoing(e.types),
          }))
          ?.sort((a: any, b: any) => b.total - a.total);

        setCallLeaderBoard(tempRes);

        setTimeout(() => {
          setIsPageLoad(false);
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setIsPageLoad(false);
    }
  };

  const DashboardQuotationStatusGraph = async (date?: any) => {
    try {
      setIsPageLoad(true);
      let fromDate = date?.from
        ? date.from
        : isCustomDate
        ? customFromDate
        : moment().subtract(filterFromDate, "d").utc().format();
      let toDate = date?.to
        ? date.to
        : isCustomDate
        ? customToDate
        : moment(new Date()).utc().format();
      const response = await fetchDashboardQuotationStatusChart(
        fromDate,
        toDate,
        isOrganization,
        selectedUserId,
        selectedTeamId
      );
      if (response && response.status) {
        interface ResponseData {
          [key: string]: {
            displayName: string;
            count: number;
          };
        }

        interface Data {
          label: string;
          value: number;
        }

        let responseData: ResponseData = response.data.data;
        let data: Data[] = [];

        for (const key of Object.keys(responseData)) {
          const element = responseData[key];
          data.push({
            label: element.displayName,
            value: element.count,
          });
        }

        setQuotationByStatus(data);
        setTimeout(() => {
          setIsPageLoad(false);
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setIsPageLoad(false);
    }
  };

  const DashboardMapCheckInDetails = async () => {
    try {
      setIsPageLoad(true);
      let fromDate = isCustomDate
        ? customFromDate
        : moment().subtract(filterFromDate, "d").utc().format();
      let toDate = isCustomDate
        ? customToDate
        : moment(new Date()).utc().format();
      const response = await fetchDashboardMapcheckIn(
        fromDate,
        toDate,
        isOrganization,
        selectedUserId,
        selectedTeamId
      );
      if (response && response.status) {
        let resposneData = response.data.data;
        if (resposneData.length > 0) {
          if (Object.values(resposneData[0].extraDetails).length > 0) {
            setMapCheckInData(resposneData);
          } else {
            setMapCheckInData([]);
          }
          setTimeout(() => {
            setIsPageLoad(false);
          }, 1000);
        } else {
          setMapCheckInData([]);
          setIsPageLoad(false);
        }
      }
    } catch (err) {
      setIsPageLoad(false);
      console.log(err);
    }
  };

  const handleTabOnchange = (tab: Tabs) => {
    setTabName(tab);
    DashboardGraph(tab);
  };

  const handleFilteronChange = (date: string) => {
    switch (date) {
      case "today":
        setFilterFromDate(0);
        setFromDate(date);
        setIsCustomDate(false);
        break;
      case "yesterday":
        setFilterFromDate(1);
        setFromDate(date);
        setIsCustomDate(false);
        break;
      case "7d":
        setFilterFromDate(7);
        setFromDate(date);
        setIsCustomDate(false);
        break;
      case "15d":
        setFilterFromDate(15);
        setFromDate(date);
        setIsCustomDate(false);
        break;
      case "30d":
        setFilterFromDate(30);
        setFromDate(date);
        setIsCustomDate(false);
        break;
      case "custom":
        setFromDate(date);
        setIsCustomDate(true);
        break;
      default:
        break;
    }

    if (date !== "custom") {
      const range = getDateRange(date);
      setSelectedDateRange(range);
    }
  };

  
const selectFilterDate = (e: any) => {
  const { name, value } = e.currentTarget;
  const dateWithTime = `${value}T00:00:00`;
  const utcDate = moment(dateWithTime).utc(true).format();
  
  if (name === "from") {
    setCustomFromDate(utcDate);
    setFromDate('custom');
  } else {
    setCustomToDate(utcDate);
    setFromDate('custom'); 
  }
};
  const selectFilterUser = (value: string, tab: string) => {
    if (!value) {
      setSelectedTeamId("");
      setIsOrganization(false);
      setSelectedUserId("");
      return;
    }

    if (tab === "Emp") {
      setSelectedUserId(value);
    } else {
      if (value === "organization") {
        setIsOrganization(true);
      } else {
        setSelectedTeamId(value);
        setIsOrganization(false);
      }
    }
  };

  // console.log("leadTotals>", leadTotals);
  const secondaryTabsArray: TabItem[] = [
    { 
      key: "contacted", 
      label: "Leads contacted", 
      icon: "fas fa-user-check" 
    },
    { 
      key: "conversion", 
      label: "Lead conversion ratio", 
      icon: "fas fa-exchange-alt" 
    },
    { 
      key: "rate", 
      label: "Task completion rate", 
      icon: "fas fa-tasks" 
    },
    { 
      key: "views", 
      label: "Content views", 
      icon: "fas fa-eye" 
    },
    { 
      key: "shares", 
      label: "Content shares", 
      icon: "fas fa-share-alt" 
    }
  ];
  

  return (
    <div id="main" className="main">
      <Header />
      <section style={{ paddingLeft: 10 }}>
        <DashboardHeader
          fromDate={fromDate}
          handleFilteronChange={handleFilteronChange}
          selectFilterUser={selectFilterUser}
          selectFilterDate={selectFilterDate}
          isCustomDate={isCustomDate} toDate={""}        />
        <Spin tip="Loading..." spinning={isPageLoad}>
          <DashboardTabs
            handleTabOnchange={handleTabOnchange}
            selectedTab={tabName}
            totalLeadCount={leadTotals.totalLead}
            totalCallCount={totalCallCount}
            totalActivityCount={totalActivityCount}
            totalSalesCount={totalSalesCount}
            
          />

          {/*  */}
          

{/* Replace the existing secondary metrics section with: */}
<div className="dashboard-section-1">
  <div className="row">
    <ul className="nav nav-pills ml-0 justify-content-start d-flex dashboard_cards" style={{ gap: "8px", marginBottom: "16px", marginTop: "4px" }}>
      {secondaryTabsArray.map((tab: TabItem) => (
        <li
          key={tab.key}
          className="nav-item bg-transparent"
          role="presentation"
          style={{ flex: 1 }}
        >
          <button
            className="nav-link dashboard-section-1-sub-1 bg-white w-100"
            style={{
              background: "white",
              padding: "6px 12px", // Reduced vertical padding
              borderRadius: "4px !important",
              boxShadow: "0 2px 4px rgba(0,0,0,0.04)",
              border: "none",
              height: "40px", // Reduced height
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
            >
            <div className="d-flex align-items-center gap-2">
              <i className={tab.icon} style={{ color: "#64748b", fontSize: "14px" }} />
              <span style={{ color: "#64748b", fontSize: "14px", whiteSpace: "nowrap" }}>
                {tab.label}
              </span>
            </div>
            <span style={{ fontSize: "16px", fontWeight: "600", color: "#0f172a" }}>
              {tab.key === "views" && contentCountInfo?.view}
              {tab.key === "shares" && contentCountInfo?.share}
              {tab.key === "rate" && `${taskPerc ? Math.round(taskPerc) : 0}%`}
              {tab.key === "contacted" && `${leadTotals?.leadContactedRatio ? Math.round(leadTotals?.leadContactedRatio) : 0}%`}
              {tab.key === "conversion" && `${leadTotals?.leadConversionRatio ? Math.round(leadTotals?.leadConversionRatio) : 0}%`}
            </span>
          </button>
        </li>
      ))}
    </ul>
  </div>
</div>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="dashboard-lead"
              role="tabpanel"
              aria-labelledby="Calls-tab"
            ></div>
            <div
              className="tab-pane fade"
              id="dashboard-Calls"
              role="tabpanel"
              aria-labelledby="Calls-tab"
            >
              <div className="dashboard-section-2"></div>
            </div>
            <div
              className="tab-pane fade"
              id="dashboard-Activity"
              role="tabpanel"
              aria-labelledby="Activity-tab"
            ></div>
            <div
              className="tab-pane fade show active"
              id="dashboard-Sales"
              role="tabpanel"
              aria-labelledby="Calls-tab"
            ></div>
          </div>

          <Row className="dash_area_funnel_chart">
            <div className="col-md-7 colpl-0">
              {/* <DashboardFunnelChart /> */}
              <div>
                <Card>
                  <CardHeader>
                    <Row>
                    


{tabName === "activity" && (
  <div
    style={{
      maxHeight: "250px",
      overflowY: "auto",
      padding: "5px", // Reduced padding around the container
      borderRadius: "8px",
      backgroundColor: "#f9f9f9",
    }}
  >
    <Row className="g-1"> {/* Reduced gap between columns */}
      {activityTypes?.map(
        (
          item: {
            type: string;
            count: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined;
          },
          j: React.Key | null | undefined
        ) => (
          <Col
            xs={6}  // Ensure 4-5 cards in a row on larger screens
            sm={4}
            md={3}  // Add breakpoints for better responsiveness
            lg={2}  // For extra large screens
            key={j}
            className="border-end border-bottom border-right-dashed border-bottom-dashed"
            style={{
              padding: "4px",  // Reduced padding for each card
              backgroundColor: "#fff",
              borderRadius: "6px",  // Slightly smaller border radius for a compact feel
              transition: "transform 0.3s ease-in-out",
            }}
            onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.05)"}
            onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
          >
            <div
              className="fw-bold text-dark d-flex flex-column align-items-center text-center py-1"
              style={{
                fontFamily: "Gilroy, sans-serif",
                fontSize: "0.8rem",  // Smaller font for compactness
                color: "#333",
              }}
            >
              <span
                style={{
                  textTransform: "capitalize",
                  fontWeight: "500",  // Less bold for a cleaner look
                  color: "#5a5a5a",
                }}
              >
                {item.type?.split("_").join(" ")}
              </span>
              <span
                style={{
                  fontSize: "1rem",  // Slightly smaller size for count
                  color: "#007bff",
                }}
              >
                {item.count}
              </span>
            </div>
          </Col>
        )
      )}
    </Row>
  </div>
)}


{tabName === "lead" && (
  <div style={{
    maxHeight: "250px",
    overflowY: "auto",
    padding: "5px", 
    borderRadius: "8px",
    backgroundColor: "#f9f9f9"
  }}>
    <Row className="g-1">
      {[
        { label: "Created", count: leadTotals.leadCreateCount },
        { label: "Assigned", count: leadTotals.leadAssignedCount },
        { label: "Untouched", count: leadTotals.untouchedLeadCount },
        { label: "No task", count: leadTotals.noFollowUpLeadCount },
        { label: "Stale leads", count: leadTotals.staleLeadCount },
      ].map((item, idx) => (
        <Col key={idx} xs={6} sm={4} md={3} lg={2}
          className="border-end border-bottom border-right-dashed border-bottom-dashed"
          style={{
            padding: "4px",
            backgroundColor: "#fff",
            borderRadius: "6px",
            transition: "transform 0.3s ease-in-out"
          }}
          onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.05)"}
          onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
        >
          <div className="fw-bold d-flex flex-column align-items-center text-center py-1"
            style={{ fontFamily: "Gilroy, sans-serif" }}>
            <span style={{ fontSize: "0.8rem", color: "#5a5a5a" }}>{item.label}</span>
            <span style={{ fontSize: "1rem", color: "#007bff" }}>{item.count}</span>
          </div>
        </Col>
      ))}
    </Row>
  </div>
)}

{tabName === "call" && (
  <div style={{
    maxHeight: "250px",
    overflowY: "auto",
    padding: "5px", 
    borderRadius: "8px",
    backgroundColor: "#f9f9f9"
  }}>
    <Row className="g-1">
       <Col
        xs={3}
        className="border-end border-bottom border-right-dashed border-bottom-dashed"
        style={{
          padding: "4px",
          backgroundColor: "#fff",
          borderRadius: "6px",
          transition: "transform 0.3s ease-in-out",
        }}
        onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
        onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
      >
        <div
          className="fw-bold d-flex flex-column align-items-center text-center py-1"
          style={{ fontFamily: "Gilroy, sans-serif" }}
        >
          <span style={{ fontSize: "0.8rem", color: "#5a5a5a" }}>All</span>
          <span style={{ fontSize: "1rem", color: "#007bff" }}>{totalCallCount} Calls</span>
        </div>
        

      </Col>
      
      {callingData?.map((item: { type: string | undefined; count: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }, i: React.Key | null | undefined) => (
        item.type !== "unknown" && (
          <Col xs={3}
            key={i}
            className="border-end border-bottom border-right-dashed border-bottom-dashed"
            style={{
              padding: "4px",
              backgroundColor: "#fff", 
              borderRadius: "6px",
              transition: "transform 0.3s ease-in-out"
            }}
            onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.05)"}
            onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
          >
            <div className="fw-bold d-flex flex-column align-items-center text-center py-1"
              style={{ fontFamily: "Gilroy, sans-serif" }}>
              <span style={{ fontSize: "0.8rem", color: "#5a5a5a" }}>{_.capitalize(item.type)}</span>
              <span style={{ fontSize: "1rem", color: "#007bff" }}>{item.count} Calls</span>
            </div>
          </Col>
        )
      ))}

      <Col xs={3}
        className="border-end border-bottom border-right-dashed border-bottom-dashed"
        style={{
          padding: "4px",
          backgroundColor: "#fff",
          borderRadius: "6px",
          transition: "transform 0.3s ease-in-out"
        }}
        onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.05)"}
        onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
      >
        <div className="fw-bold d-flex flex-column align-items-center text-center py-1"
          style={{ fontFamily: "Gilroy, sans-serif" }}>
          <span style={{ fontSize: "0.8rem", color: "#5a5a5a" }}>Total Answered</span>
          <span style={{ fontSize: "1rem", color: "#007bff" }}>{callingDetails?.totalCallAnswered} Calls</span>
        </div>
      </Col>

      <Col xs={3}
        className="border-end border-bottom border-right-dashed border-bottom-dashed"
        style={{
          padding: "4px",
          backgroundColor: "#fff",
          borderRadius: "6px",
          transition: "transform 0.3s ease-in-out"
        }}
        onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.05)"}
        onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
      >
        <div className="fw-bold d-flex flex-column align-items-center text-center py-1"
          style={{ fontFamily: "Gilroy, sans-serif" }}>
          <span style={{ fontSize: "0.8rem", color: "#5a5a5a" }}>Total Outgoing Talktime</span>
          <span style={{ fontSize: "1rem", color: "#007bff" }}>
            {callingDetails?.totalOutgoingDuration
              ? `${getTimeFormat(callingDetails?.totalOutgoingDuration)} Minutes`
              : `0 Minutes`}
          </span>
        </div>
      </Col>

      <Col xs={3}
        className="border-end border-bottom border-right-dashed border-bottom-dashed"
        style={{
          padding: "4px",
          backgroundColor: "#fff",
          borderRadius: "6px",
          transition: "transform 0.3s ease-in-out"
        }}
        onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.05)"}
        onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
      >
        <div className="fw-bold d-flex flex-column align-items-center text-center py-1"
          style={{ fontFamily: "Gilroy, sans-serif" }}>
          <span style={{ fontSize: "0.8rem", color: "#5a5a5a" }}>Total Incoming Talktime</span>
          <span style={{ fontSize: "1rem", color: "#007bff" }}>
            {callingDetails?.totalIncomingDuration
              ? `${getTimeFormat(callingDetails?.totalIncomingDuration)} Minutes`
              : `0 Minutes`}
          </span>
        </div>
      </Col>

      <Col xs={3}
        className="border-end border-bottom border-right-dashed border-bottom-dashed"
        style={{
          padding: "4px",
          backgroundColor: "#fff",
          borderRadius: "6px",
          transition: "transform 0.3s ease-in-out"
        }}
        onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.05)"}
        onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
      >
        <div className="fw-bold d-flex flex-column align-items-center text-center py-1"
          style={{ fontFamily: "Gilroy, sans-serif" }}>
          <span style={{ fontSize: "0.8rem", color: "#5a5a5a" }}>Total Talktime</span>
          <span style={{ fontSize: "1rem", color: "#007bff" }}>
            {callingDetails?.totalTalkTime
              ? `${getTimeFormat(callingDetails?.totalTalkTime)} Minutes`
              : `0 Minutes`}
          </span>
        </div>
      </Col>
    </Row>
  </div>
)}

                    </Row>
                  </CardHeader>
                  <CardBody>
                    <DashboardAreaChart
                      filterFromDate={filterFromDate}
                      isOrganization={isOrganization}
                      selectedUserId={selectedUserId}
                      selectedTeamId={selectedTeamId}
                      tabName={tabName}
                      leadCount={leadTotals}
                    />
                  </CardBody>
                </Card>
              </div>

              {/* <div className="d-flex">
              <div className="col-md-4 p-1">
                <DashboardRadialChart
                  title="Sales Target"
                  series={[75]}
                  labels={["Target"]}
                />
              </div>
              <div className="col-md-8 p-1"> */}
              {tabName === "lead" && (
                <>
                  <DashboardPieChart
                    title="Leads by labels"
                    pieChartData={leadByLabel}
                    chartHeight={210}
                    dateRangeValue={selectedDateRange}
                    callBackFun={DashboardLeadLabelGraph}
                  />
                  <DashboardCheckings mapCheckInData={mapCheckInData} />
                </>
              )}

              {tabName === "call" && (
                <DashboardCallLeaderBoard
                  title="Call leaderboard"
                  dateRangeValue={selectedDateRange}
                  tableData={callLeaderBoard}
                  callBackFun={DashboardCallLeaderBoardFun}
                />
              )}

              {tabName === "lead" && (
                <>
                  <Col md={12}>
                    {" "}
                    <DashboardPieChart
                      title="Leads by source"
                      pieChartData={leadBySource}
                      chartHeight={210}
                      dateRangeValue={selectedDateRange}
                      callBackFun={DashboardLeadSourceGraph}
                    />
                  </Col>
                  <Col md={12}>
                    <DashboardQuotationStatusPieChart
                      title="Quotation by status"
                      dateRangeValue={selectedDateRange}
                      chartHeight={210}
                      pieChartData={quotationByStatus}
                      callBackFun={DashboardQuotationStatusGraph}
                    />
                  </Col>
                </>
              )}
            </div>
            <div className="col-md-5 colpr-0">
              

              {(tabName === "lead" || tabName === "call") && (
                <div className="dashboard-section-3">
                  <div className="dashboard_chart_1">
                    <h3>Sales Funnels</h3>
                    {/* <DashboardFunnelChart funnelData={saleFunnelGraphS} /> */}
                    {saleFunnelGraphS?.length > 0 ? (
                      <FunnelChart
                        data={saleFunnelGraphS}
                        pallette={funnelColor}
                        getToolTip={(row: any) => {
                          const { value } = row;
                          return `₹ ${value.toLocaleString(
                            "en-IN"
                          )} Sale value`;
                        }}
                      />
                    ) : (
                      <h2 className="no_data_found">No data found.</h2>
                    )}
                  </div>
                </div>
              )}
              {tabName === "activity" && (
                <DashboardTaskStatusPieChart
                  title="Task by status"
                  chartHeight={210}
                  dateRangeValue={selectedDateRange}
                  pieChartData={taskByStatus}
                  callBackFun={DashboardTaskStatusGraph}
                  taskCount={taskCount}
                />
              )}
            </div>
          </Row>
        </Spin>
      </section>
    </div>
  );
};

export default Dashboard;
