import React, { useState, useEffect, useRef } from 'react';

interface IOption {
  label: string;
  value: string;
}

interface ICustomDropdownWithCheckboxes {
  options: IOption[];
  selectedValues: string[];
  onChange: (selectedValues: string[]) => void;
}

const CustomDropdownWithCheckboxes: React.FC<ICustomDropdownWithCheckboxes> = ({
  options,
  selectedValues,
  onChange
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (value: string) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];
    onChange(newSelectedValues);
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className="dropdown-header " onClick={() => setIsOpen(!isOpen)}>
        Select Options
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option) => (
            <label key={option.value} className="dropdown-item">
            
              {option.label}
              <input
                type="checkbox"
                value={option.value}
                checked={selectedValues.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdownWithCheckboxes;
