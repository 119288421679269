import { Card, CardBody, CardHeader } from "reactstrap";
import Mapp from "./Map/googleMap";

interface IDashboardCheckings {
  mapCheckInData: any[];
}
const DashboardCheckings = ({ mapCheckInData }: IDashboardCheckings) => {
  return (
    <Card>
      <CardHeader>
        <DashboardCheckingsHeader title="Checkins" />
      </CardHeader>
      <CardBody className="px-1">
        {mapCheckInData?.length > 0 ? (
          <Mapp mapCheckInData={mapCheckInData} />
        ) : (
          <h2 className="no_data_found">No data found.</h2>
        )}
      </CardBody>
    </Card>
  );
};

export default DashboardCheckings;

interface IDashboardCheckingsHeader {
  title: string;
}

const DashboardCheckingsHeader = ({ title }: IDashboardCheckingsHeader) => {
  return (
    <div className="d-flex align-items-center">
      <div className="fw-bold text-dark d-flex flex-column align-items-center fm-gilroy">
        <span>{title}</span>
      </div>
    </div>
  );
};
