// src/services/callLogsService.ts

import axios from 'axios';
import { API_ENDPOINT } from './userService'; // Adjust the path if necessary

// Define TypeScript interfaces for type checking
export interface PaginationQuery {
  page: number;
  perPage?: number;
  search?: string;
  orderBy?: string;
  isAscending?: boolean;
  callType?: 'incoming' | 'outgoing' | 'missed';
  fromDate?: string;
  toDate?: string;
}


export interface CallLog {
  _id: string;
  type: 'incoming' | 'outgoing' | 'missed' | string; // Adjust based on your API's call types
  phone: string;
  duration: number; // Duration in seconds
  startedAt: string; // ISO date string
  lead?: {
    _id: string;
    name: string;
  };
  userContact?: {
    firstName: string;
  };
  concurrentCall?: number;
  file?: string; // URL or path to the call recording
}

export interface PaginatedResult<T, K> {
  data: T[];
  total: K;
}

// Helper function to get headers with Authorization token
const getHeaders = () => ({
  Authorization: `${localStorage.getItem('auth_token') || ''}`,
});

// Function to fetch all call logs with optional pagination and search
// Replace the existing getCallLogs function with:
export const getCallLogs = async (
  queryFilter: PaginationQuery
): Promise<PaginatedResult<CallLog, number>> => {
  try {
    const params = {
      page: queryFilter.page,
      perPage: queryFilter.perPage || 15,
      ...(queryFilter.search ? { search: queryFilter.search } : {}),
      ...(queryFilter.fromDate ? { fromDate: queryFilter.fromDate } : {}),
      ...(queryFilter.toDate ? { toDate: queryFilter.toDate } : {}),
      ...(queryFilter.callType ? { callType: queryFilter.callType } : {}),
      ...(queryFilter.orderBy ? { orderBy: queryFilter.orderBy } : {}),
      ...(typeof queryFilter.isAscending === 'boolean' ? { isAscending: queryFilter.isAscending } : {})
    };

    const response = await axios.get<PaginatedResult<CallLog, number>>(
      `${API_ENDPOINT}/user/call-logs`,
      {
        headers: getHeaders(),
        params,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching call logs:', error);
    throw error;
  }
};

// Function to fetch call logs for a specific phone number or identifier
export const getPhoneCallLogs = async (
  query: string
): Promise<PaginatedResult<CallLog, number>> => {
  try {
    const response = await axios.get<PaginatedResult<CallLog, number>>(
      `${API_ENDPOINT}/user/call-logs/${query}`,
      { headers: getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching phone call logs:', error);
    throw error;
  }
};
