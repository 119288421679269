// src/components/WhatsappHeader/HeaderSection.tsx

import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import styled from "styled-components";
import {
  WhatsAppMediaValidator,
  ValidationResult,
} from "./whatsapp-media-validator";
import { HeaderFilePreview } from "./HeaderFilePreview";
import { Radio, RadioGroup } from "rsuite";

const IMAGE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB
const VIDEO_SIZE_LIMIT = 16 * 1024 * 1024; // 16MB
const DOC_SIZE_LIMIT = 100 * 1024 * 1024; // 100MB

interface HeaderSectionProps {
  expandedSections: Record<string, boolean>;
  toggleSection: (sectionId: string) => void;
  values: {
    header: string;
    header_value: string;
  };
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFile: (file: File | null) => void;
  errorsObj: Record<string, string>;
  fileName: string;
  handleFileChange: (e: React.ChangeEvent<any>) => void;
  isSubmitting?: boolean;
}

interface DragState {
  isDragging: boolean;
  isDropValid: boolean;
}

const initialDragState: DragState = {
  isDragging: false,
  isDropValid: false,
};

export const HeaderSection: React.FC<HeaderSectionProps> = ({
  expandedSections,
  toggleSection,
  values,
  handleChange,
  setFile,
  errorsObj,
  fileName,
  handleFileChange,
  isSubmitting = false,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragState, setDragState] = useState<DragState>(initialDragState);
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };
  }, []);

  // Reset file input when header type changes
  useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      setFile(null);
      setValidationWarnings([]);
    }
  }, [values.header, setFile]);

  const validateFile = (file: File, header: string) => {
    switch (header) {
      case "image":
        if (file.size > IMAGE_SIZE_LIMIT) {
          toast.error("Please select file less than 5MB size");
          return false;
        }
        if (!file.type.includes("image/")) {
          toast.error("Please select a valid image file");
          return false;
        }
        break;

      case "video":
        if (file.size > VIDEO_SIZE_LIMIT) {
          toast.error("Please select file less than 16MB size");
          return false;
        }
        if (!file.type.includes("video/")) {
          toast.error("Please select a valid video file");
          return false;
        }
        break;

      case "document":
        if (file.size > DOC_SIZE_LIMIT) {
          toast.error("Please select file less than 100MB size");
          return false;
        }
        // Add document type validation if needed
        break;
    }
    return true;
  };

  const validateAndHandleFile = useCallback(
    (file: File): boolean => {
      if (!file) return false;

      const mediaType = values.header.toUpperCase();
      const validationResult: ValidationResult =
        WhatsAppMediaValidator.validateMediaFile(file, mediaType);

      if (!validationResult.isValid) {
        // Fix: Add fallback for undefined error message
        toast.error(validationResult.error || "File validation failed");
        return false;
      }

      if (validationResult.warnings) {
        setValidationWarnings(validationResult.warnings);
      } else {
        setValidationWarnings([]);
      }

      return true;
    },
    [values.header]
  );

  const handleDragEvents = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragState({ isDragging: true, isDropValid: true });
    } else if (e.type === "dragleave") {
      setDragState(initialDragState);
    }
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragState(initialDragState);

      const file = e.dataTransfer.files[0];
      if (!file) return;

      if (validateAndHandleFile(file)) {
        const syntheticEvent = {
          target: {
            files: [file],
            value: file.name,
            name: "header_value",
          },
        } as unknown as React.ChangeEvent<HTMLInputElement>;

        handleFileChange(syntheticEvent);
      }
    },
    [validateAndHandleFile, handleFileChange]
  );

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target?.files?.[0];
    if (!selectedFile) return;

    // Simple size validation based on type
    if (values.header === "image" && selectedFile.size > IMAGE_SIZE_LIMIT) {
      toast.error("Please select file less than 5MB size");
      return;
    }
    if (values.header === "video" && selectedFile.size > VIDEO_SIZE_LIMIT) {
      toast.error("Please select file less than 16MB size");
      return;
    }
    if (values.header === "document" && selectedFile.size > DOC_SIZE_LIMIT) {
      toast.error("Please select file less than 100MB size");
      return;
    }

    handleChange(e);
    handleFileChange(e);
  };

  return (
    <SectionBlock>
      <SectionHeader className={expandedSections["section1"] ? "" : "mb-0"}>
        <HeaderLabel>
          Header (optional)
          <InfoIcon className="ri-information-fill" />
        </HeaderLabel>

        <ExpandButton
          onClick={() => toggleSection("section1")}
          aria-expanded={expandedSections["section1"]}
          aria-label="Toggle header section"
        >
          <ArrowIcon $expanded={expandedSections["section1"]} />
        </ExpandButton>
      </SectionHeader>

      {expandedSections["section1"] && (
        <SectionContent>
          <StyledRadioGroup
            value={values.header}
            onChange={(value: string | number, event: React.SyntheticEvent) => {
              const syntheticEvent = {
                target: {
                  name: "header",
                  value: value.toString(), // convert to string if needed
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleChange(syntheticEvent);
              setFile(null);
              setValidationWarnings([]);
            }}
            disabled={isSubmitting}
          >
            <Radio value="none">None</Radio>
            <Radio value="text">Text</Radio>
            <Radio value="image">Image</Radio>
            <Radio value="video">Video</Radio>
            <Radio value="document">Document</Radio>
          </StyledRadioGroup>

          {values.header !== "none" &&
            (values.header === "text" ? (
              <TextInput
                type="text"
                placeholder="Enter header text..."
                name="header_value"
                value={values.header_value}
                onChange={handleChange}
                disabled={isSubmitting}
                aria-invalid={!!errorsObj["header_value"]}
                maxLength={60}
              />
            ) : (
              <FileUploadSection
                onDragEnter={handleDragEvents}
                onDragOver={handleDragEvents}
                onDragLeave={handleDragEvents}
                onDrop={handleDrop}
                $isDragging={dragState.isDragging}
              >
                <FileInput
                  ref={fileInputRef}
                  type="file"
                  accept={WhatsAppMediaValidator.getAllowedMimeTypes(
                    values.header
                  ).join(",")}
                  onChange={handleFileInputChange}
                  disabled={isSubmitting}
                  aria-label={`Upload ${values.header} file`}
                />

                <UploadInfo>
                  <strong>Click or drag file to upload</strong>
                  <small>
                    Allowed types:{" "}
                    {WhatsAppMediaValidator.getAllowedExtensions(
                      values.header
                    ).join(", ")}
                    <br />
                    Max size:{" "}
                    {WhatsAppMediaValidator.getMaxFileSize(values.header)}MB
                  </small>
                </UploadInfo>

                {fileName && (
                  <HeaderFilePreview
                    file={fileInputRef.current?.files?.[0]}
                    headerType={values.header}
                  />
                )}

                {validationWarnings.length > 0 && (
                  <WarningList>
                    {validationWarnings.map((warning, index) => (
                      <WarningItem key={index}>{warning}</WarningItem>
                    ))}
                  </WarningList>
                )}
              </FileUploadSection>
            ))}

          {errorsObj["header_value"] && (
            <ErrorMessage>{errorsObj["header_value"]}</ErrorMessage>
          )}
        </SectionContent>
      )}
    </SectionBlock>
  );
};

// Styled Components
const SectionBlock = styled.div`
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 16px;

  .rs-radio {
    margin-right: 0; // Override default margin
  }
`;
const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const HeaderLabel = styled.label`
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin: 0;
  display: flex;
  align-items: center;
`;

const InfoIcon = styled.i`
  margin-left: 8px;
  font-size: 16px;
  color: #666;
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;

  &:focus {
    outline: 2px solid #3faefd;
    border-radius: 4px;
  }
`;

const ArrowIcon = styled.span<{ $expanded: boolean }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,...");
  transform: rotate(${(props) => (props.$expanded ? "180deg" : "0deg")});
  transition: transform 0.2s ease;
`;

const SectionContent = styled.div`
  margin-top: 15px;
`;

const TextInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #3faefd;
    box-shadow: 0 0 0 2px rgba(63, 174, 253, 0.2);
  }
`;

const FileUploadSection = styled.div<{ $isDragging: boolean }>`
  position: relative;
  padding: 20px;
  border: 2px dashed ${(props) => (props.$isDragging ? "#3FAEFD" : "#e2e8f0")};
  border-radius: 8px;
  background: ${(props) =>
    props.$isDragging ? "rgba(63, 174, 253, 0.1)" : "#f8fafc"};
  transition: all 0.2s ease;
`;

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

const UploadInfo = styled.div`
  text-align: center;

  strong {
    display: block;
    margin-bottom: 8px;
    color: #1a202c;
  }

  small {
    color: #64748b;
    line-height: 1.5;
  }
`;

const WarningList = styled.ul`
  margin: 12px 0;
  padding: 12px;
  background: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 6px;
  list-style: none;
`;

const WarningItem = styled.li`
  color: #856404;
  font-size: 14px;
  margin: 4px 0;

  &:before {
    content: "⚠️";
    margin-right: 8px;
  }
`;

const ErrorMessage = styled.p`
  color: #e53e3e;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 0;
`;

export default HeaderSection;
