import axios, { AxiosResponse } from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions } from "./getHeaderOptions";

const API_ENDPOINT = API_URL + "api/v1";

export const getHeader = () => {
  return {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
};

export async function getIntegrations(): Promise<AxiosResponse<any, any>> {
  return await axios.get(`${API_ENDPOINT}/integration/user-integration`, {
    headers: getHeader(),
  });
}
