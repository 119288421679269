import React, { useEffect, useState } from "react";
import styled from "styled-components";
import successImg from "./../../assets/images/success.svg";
import MultipleOption from "../../components/webForm/MultipleOption";
import InputStyledComp from "../../components/webForm/InputStyledComp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faUpload,
  faLeftLong,
  faRightLong,
} from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";
import { useParams } from "react-router-dom";
import {
  addWebFormResponse,
  checkPaymentStatus,
  getPaymentLink,
  getWebFormView,
  uploadFile,
} from "../../services/formService";
import toast, { Toaster } from "react-hot-toast";
import { DatePicker, Modal } from "rsuite";
import { Spin } from "antd";
import PaymentFiels from "../../components/webForm/PaymentFiels";
// import { setWebForm } from "../../actions/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default function WebForm() {
  const [activeField, setActiveField] = useState<number>(1);
  const { _id } = useParams();

  const [formId, setFormId] = useState("");
  const [formData, setFormData] = useState<any>({});
  const [formOptions, setFormOptions] = useState<any>([]);
  const [newFormData, setNewFormData] = useState<any>({});
  const [disableButton, setDisableButton] = useState(false);
  const [isPageLoad, setIsPageLoad] = useState<boolean>(false);
  const [paymentLink, setPaymentLink] = useState("");
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [backStep, setBackStep] = useState<any>([1]);
  const [isSubmit, setIsSubmit] = useState(false);

  const { webForm } = useSelector((state: any) => {
    return state?.rootReducers;
  });

  useEffect(() => {
    if (isSubmit) {
      submitForm();
    }
  }, [newFormData, isSubmit]);

  const dispatch = useDispatch();

  const validateConditions = () => {
    const formObject = formData.form[activeField - 1];
    const field = formData.form[activeField - 1].fields[0].type;
    const checkField =
      newFormData[formData.form[activeField - 1].fields[0].fieldName];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+918\d{9}$/;
    console.log(
      "activeField < formData?.form?.length------",
      activeField,
      formData?.form?.length
    );
    if (activeField < formData?.form?.length) {
      if (formData.form[activeField - 1].fields[0].required) {
        // const field = formData.form[activeField-1].fields[0].fieldName
        // const checkField = newFormData[field]
        if (field == "select_Option" && checkField.length > 2) {
        } else if (field == "email" && !emailRegex.test(checkField)) {
          console.log("checkField  ==>", checkField, field);
          toast.error("Please Enter a valid email");
          return;
        }
        // else if (
        //   formObject.fields[0].type == "phone" &&
        //   !phoneRegex.test(checkField)
        // ) {
        //   toast.error("Please Enter a valid Phone Number");
        //   return;
        // }
        else if (
          field == "file_upload" &&
          formData.form[activeField - 1].fields[0].file.length > 0
        ) {
        } else if (formObject.fields[0].type == "payment") {
        } else if (checkField.length < 1) {
          toast.error("An answer is required ,Please answer and try again");
          return;
        }
      }

      if (formObject.conditionRule.length > 0) {
        let conditionMatched = false;
        for (const condition of formObject.conditionRule) {
          const { if: conditionType, value, then_go_to } = condition;
          const fieldValue = newFormData[formObject.fields[0].fieldName];
          if (
            conditionType === "is" &&
            fieldValue.toLowerCase() === value.toLowerCase()
          ) {
            const newVal = then_go_to.split("_");
            setActiveField(parseInt(newVal[1]));
            setBackStep([...backStep, parseInt(newVal[1])]);
            conditionMatched = true;
            break;
          } else if (
            conditionType === "is_not" &&
            fieldValue.toLowerCase() !== value.toLowerCase()
          ) {
            const newVal = then_go_to.split("_");
            setActiveField(parseInt(newVal[1]));
            setBackStep([...backStep, parseInt(newVal[1])]);
            conditionMatched = true;
            break;
          }
        }
        if (!conditionMatched) {
          if (activeField == formData?.form?.length - 1) {
            submitForm();
          } else {
            setActiveField(activeField + 1);
            setBackStep([...backStep, activeField + 1]);
          }
        }
      } else {
        // if(field == "email" && checkField.length > 0 && !emailRegex.test(checkField)) {
        //   console.log('checkField  ==>',checkField , field)
        //   toast.error("Please Enter a valid email");
        //   return;
        // }

        if (activeField == formData?.form?.length - 1) {
          if (formData.form[activeField - 1].fields[0].type == "file_upload") {
            getSignUrl(
              formData.form[activeField - 1].fields[0].file,
              formData.form[activeField - 1].question,
              formData.form[activeField - 1].fields[0].fieldName,
              "submit"
            );
          } else {
            submitForm();
          }
        } else {
          if (formData.form[activeField - 1].fields[0].type == "file_upload") {
            console.log(
              "formData.form[activeField-1].fields[0].type========>",
              formData.form[activeField - 1].fields[0].type
            );
            getSignUrl(
              formData.form[activeField - 1].fields[0].file,
              formData.form[activeField - 1].question,
              formData.form[activeField - 1].fields[0].fieldName,
              "next"
            );
          } else {
            setActiveField(activeField + 1);
            setBackStep([...backStep, activeField + 1]);
          }
        }
      }
    }
  };

  const submitForm = async () => {
    setDisableButton(true);
    try {
      const { end_screen, ...data } = newFormData;

      console.log("data.", data);
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== "")
      );

      const resp = await addWebFormResponse(formId, filteredData);
      if (resp && resp.status) {
        console.log(resp.data.message);
        setDisableButton(false);
        toast.success(resp.data.message);
        setActiveField(activeField + 1);
        setBackStep([...backStep, activeField + 1]);
        // dispatch(setWebForm({}));
      }
    } catch (err) {
      setDisableButton(false);
    }
  };

  const generatePaymentLink = async (field: string, amount: number) => {
    try {
      setIsPageLoad(true);
      const data = {
        customFormUniqueCode: formData.uniqueCode,
        amount: +amount * 100,
        // customer: {
        //     // name: "Magnabox pvt ltd",
        //     // contact: "+919000090000",
        //     // email: "user@example.com"
        // }
      };
      const resp = await getPaymentLink(data);
      if (resp && resp.status) {
        console.log("resp ====>", resp.data.data.paymentLink);
        setIsPageLoad(false);
        handleFormObject(field, resp.data.data.paymentLink, "payment");
        // setPaymentLink(resp.data.data.paymentLink);
        // setPaymentDialog(true);
      }
    } catch (err) {
      setIsPageLoad(false);
    }
  };

  const checkPayment = async (link: string) => {
    try {
      setIsPageLoad(true);
      const resp = await checkPaymentStatus(link);
      if (resp && resp.status) {
        setIsPageLoad(false);
        console.log("status", resp.data.data.status);
        if (resp.data.data.status == "captured") {
          validateConditions();
          setPaymentDialog(false);
        }
      }
    } catch (err) {
      setIsPageLoad(false);
    }
  };

  const handleBack = () => {
    if (activeField > 1) {
      setActiveField(backStep[backStep.length - 2]);
      const newArray = backStep;
      newArray.pop();
      setBackStep(newArray);
    }
  };
  const extractNumber = (val: string) => {
    const newVal = val.split("_");
    return newVal[1];
  };
  const handleEditFormValue = (
    step: string,
    type: string,
    keyName: string,
    value: string | boolean
  ) => {
    const updatedForm = formData.form.map((item: any) => {
      if (item.question === step) {
        const updatedFields = item.fields.map((field: any) => {
          if (field.type === type) {
            return {
              ...field,
              [keyName]: value,
            };
          }
          return field;
        });
        return {
          ...item,
          fields: updatedFields,
        };
      }
      return item;
    });
    const updatedFormData: FormData = {
      ...formData,
      form: updatedForm,
    };
    setFormData(updatedFormData);
  };
  const updateOptions = (item: any, option: any) => {
    const checkData = formOptions.filter(
      (i: any) => i.question == item.question
    );
    if (checkData.length == 0) {
      const newData = {
        ...item,
        fields: item.fields.map((i: any, ix: number) => {
          return { ...i, options: option };
        }),
      };
      setFormOptions([...formOptions, newData]);
    } else {
      const newData = {
        ...item,
        fields: item.fields.map((i: any, ix: number) => {
          return { ...i, options: option };
        }),
      };
      console.log("newData ===>", newData, formOptions);
      const update = formOptions.map((j: any) => {
        if (j.question == newData.question) {
          return newData;
        } else {
          return j;
        }
      });
      setFormOptions([...update]);
    }
    console.log("formOptions ====>", formOptions);
  };

  const getFormDetail = async (id: string) => {
    setIsPageLoad(true);
    try {
      const resp = await getWebFormView(id);

      if (resp && resp.status) {
        const newObject: { [key: string]: string } = {};
        resp?.data?.data.form.forEach((step: any) => {
          step.fields.forEach((field: any) => {
            newObject[field.fieldName] = ""; // Set a default value (e.g., "abc")
          });
        });
        setNewFormData(newObject);
        const object = resp?.data?.data;
        if (object && object.form && Array.isArray(object.form)) {
          object.form.forEach((step: any) => {
            if (step.fields && Array.isArray(step.fields)) {
              step.fields.forEach((field: any) => {
                if (field.type === "file_upload") {
                  field.file = []; // Adding a 'file' key with a default value
                }
              });
            }
          });
        }

        setIsPageLoad(false);
        setFormData(object);
      }
    } catch (err) {
      setIsPageLoad(false);
    }
  };

  const addFiles = (question: any, addFile: any) => {
    if (addFile) {
      const newData = formData.form.map((i: any, ix: number) => {
        if (i.question == question) {
          return {
            ...i,
            fields: i.fields.map((j: any) => {
              return {
                ...j,
                file: [...j.file, addFile],
              };
            }),
          };
        } else {
          return i;
        }
      });
      setFormData({ ...formData, form: newData });
    }
  };
  const deleteFile = (question: any, index: number) => {
    console.log("check delete ====>", question, index);
    const newData = formData.form.map((i: any, ix: number) => {
      if (i.question == question) {
        return {
          ...i,
          fields: i.fields.map((j: any) => {
            return {
              ...j,
              file: j.file.filter((a: any, ax: number) => {
                console.log("check delete ====>", a, ax, index);
                return ax != index;
              }),
            };
          }),
        };
      } else {
        return i;
      }
    });
    console.log("New  formData", question, formData);
    setFormData({ ...formData, form: newData });
  };
  const getSignUrl = async (
    files: any,
    fileName: string,
    fieldName: string,
    reqestType: string
  ) => {
    setIsPageLoad(true);
    try {
      if (files.length > 0) {
        const uploadedFiles = [];
        for (let i = 0; i < files.length; i++) {
          console.log(" ==== files [i] === ", !newFormData[fieldName]);
          if (
            !newFormData[fieldName] ||
            newFormData[fieldName]?.filter(
              (item: any) =>
                item == `custom-form/${formData.uniqueCode}/${files[i].name}`
            ).length == 0
          ) {
            const file = files[i];
            const fileName = `file_${i}.png`; // Example: You can generate file names dynamically
            const uploadResponse = await uploadFile(file, formId, fileName);
            uploadedFiles.push(uploadResponse.data.fileUrl);
          }
        }
        console.log("uploadedFiles ===>", uploadedFiles);

        if (uploadedFiles.length > 0) {
          toast.success("Successfully upload File");
          handleFormObject(fieldName, uploadedFiles);
          setIsPageLoad(false);
          if (reqestType == "submit") {
            // submitForm();
            setIsSubmit(true);
          } else {
            setActiveField(activeField + 1);
            setBackStep([...backStep, activeField + 1]);
          }
        } else {
          setIsPageLoad(false);
          if (reqestType == "submit") {
            submitForm();
          } else {
            setActiveField(activeField + 1);
            setBackStep([...backStep, activeField + 1]);
          }
        }
      } else {
        setIsPageLoad(false);
        if (reqestType == "submit") {
          submitForm();
        } else {
          setActiveField(activeField + 1);
          setBackStep([...backStep, activeField + 1]);
        }
      }
    } catch (err) {
      setIsPageLoad(false);
    }
  };
  const handleFormObject = (key: string, value: any, type = "all") => {
    console.log("============key====", key, value, type);
    if (type == "multiOption") {
      const updatedFormData = { ...newFormData };
      if (Array.isArray(updatedFormData[key])) {
        const index = updatedFormData[key].indexOf(value);
        if (index !== -1) {
          updatedFormData[key].splice(index, 1); // Remove the value if it exists in the array
        } else {
          updatedFormData[key].push(value); // If value is not present, add it to the array
        }
      } else {
        updatedFormData[key] = [value];
      }
      setNewFormData(updatedFormData);
      // dispatch(setWebForm({ [formId]: updatedFormData }));
    } else {
      const updatedFormData = { ...newFormData };
      if (key in updatedFormData) {
        updatedFormData[key] = value;
      } else {
        updatedFormData[key] = value;
      }

      setNewFormData(updatedFormData);
      // dispatch(setWebForm({ [formId]: updatedFormData }));
      if (key.toLowerCase() === "payment_amount") {
        window.open(value, "__self");
      }
    }
  };
  const formatDate = (fieldName: any, date: any) => {
    const d = new Date(date);
    const formattedDate = `${(d.getMonth() + 1).toString().padStart(2, "0")}-${d
      .getDate()
      .toString()
      .padStart(2, "0")}-${d.getFullYear()}`;
    handleFormObject(fieldName, formattedDate);
    return;
  };

  useEffect(() => {
    if (_id) {
      const params = new URLSearchParams(window.location.search);
      const paymentStatus = params.get("razorpay_payment_link_status");
      const paymentId = params.get("razorpay_payment_id");

      const cNewFormDataStr: any = localStorage.getItem("custom_new_form_data");
      const cFormDataStr: any = localStorage.getItem("custom_form_data");
      const active: any = localStorage.getItem("custom_form_activefield");
      const cFormBackStepStr: any = localStorage.getItem(
        "custom_form_backStep"
      );

      setFormId(_id);

      if (
        paymentStatus &&
        paymentId &&
        paymentStatus.toUpperCase() === "PAID" &&
        active &&
        cFormDataStr &&
        cNewFormDataStr
      ) {
        const cNewFormData: any = JSON.parse(cNewFormDataStr) || {};
        const cFormData: any = JSON.parse(cFormDataStr) || {};
        const cFormBackStep = JSON.parse(cFormBackStepStr) || {};

        if (
          activeField &&
          cFormData?.form &&
          cFormData?.form[+active - 1] &&
          cFormData?.form[+active - 1].fields &&
          cFormData?.form[+active - 1].fields[0] &&
          cFormData?.form[+active - 1].fields[0].amount
        ) {
          setActiveField(+active);
          setBackStep(cFormBackStep);
          // setNewFormData(cNewFormData);
          setFormData(cFormData);

          setPaymentDialog(true);
          setPaymentStatus("PAID");

          setTimeout(() => {
            setPaymentDialog(false);
            setIsPageLoad(false);
            setActiveField(+active + 1);
            checkPayment(cNewFormData?.Payment_Amount);
          }, 3000);
        } else {
          setIsPageLoad(true);
          getFormDetail(_id);
        }
      } else {
        setIsPageLoad(true);
        getFormDetail(_id);
      }
    }
  }, [_id]);

  useEffect(() => {
    if (newFormData) {
      localStorage.setItem("custom_new_form_data", JSON.stringify(newFormData));
      localStorage.setItem("custom_form_data", JSON.stringify(formData));
      localStorage.setItem("custom_form_activefield", `${activeField}`);
      localStorage.setItem("custom_form_backStep", JSON.stringify(backStep));
    }
  }, [newFormData, activeField, formData, backStep]);

  // useEffect(()=>{
  //   if(_id && webForm.formData[_id]){
  //     console.log('webForm.formData[formId]',webForm.formData[_id])
  //     setNewFormData(webForm.formData[_id])
  //   }
  // },[webForm.formData,_id])

  console.log("newFormData ====>", newFormData);
  return (
    // <div id="main" className="main">
    //     <Header />
    <Root>
      <div className="sigma_logo">
        <h4>{formData.name}</h4>
        <div className="logo_section">
          <img src={formData?.organization?.iconUrl} alt="img" />
          <div>
            <h2>{formData?.organization?.name}</h2>
            {/* <h3>Discription(optional)</h3> */}
          </div>
        </div>
      </div>
      <Spin size="large" tip="Loading..." spinning={isPageLoad}>
        <div className="form_box">
          {formData.form
            ?.filter((item: any) => {
              return item.question === `question_${activeField}`;
            })
            .map((i: any, index: number) => {
              console.log("i>>>", i);
              return i.fields[0].type == "short_text" ? (
                <InputStyledComp key={index}>
                  <div className="field_name sr_number">
                    <span>
                      {extractNumber(i.question)}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <h3 className="title ">{i.fields[0].displayName}</h3>
                    <p className="text_danger">
                      {i.fields[0].required && " *"}
                    </p>
                  </div>
                  <p className="discription">{i.fields[0].description}</p>
                  <input
                    type="text"
                    className="input_box"
                    placeholder={i.fields[0].placeholder}
                    value={newFormData[i.fields[0].fieldName]}
                    onChange={(e) => {
                      handleFormObject(i.fields[0].fieldName, e.target.value);
                    }}
                  />
                  {/* <input type='text' className='input_box' placeholder='Type your answer here' value={i.fields[0].fieldName} onChange={(e) => { handleEditFormValue(i.question, i.fields[0].type, 'fieldName', e.target.value) }} /> */}
                </InputStyledComp>
              ) : i.fields[0].type == "number" ? (
                <InputStyledComp key={index}>
                  <div className="field_name sr_number">
                    <span>
                      {extractNumber(i.question)}{" "}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <h3 className="title">{i.fields[0].displayName} </h3>
                    <p className="text_danger">
                      {i.fields[0].required && " *"}
                    </p>
                  </div>
                  <p className="discription">{i.fields[0].description}</p>
                  <input
                    type="number"
                    className="input_box"
                    placeholder={i.fields[0].placeholder}
                    value={newFormData[i.fields[0].fieldName]}
                    onChange={(e) => {
                      handleFormObject(i.fields[0].fieldName, e.target.value);
                    }}
                  />
                  {/* <input type='text' className='input_box' placeholder='Type your answer here' value={i.fields[0].fieldName} onChange={(e) => { handleEditFormValue(i.question, i.fields[0].type, 'fieldName', e.target.value) }} /> */}
                </InputStyledComp>
              ) : i.fields[0].type.toLowerCase() == "phone" ? (
                <InputStyledComp key={index}>
                  <div className="field_name sr_number">
                    <span>
                      {extractNumber(i.question)}{" "}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <h3 className="title">{i.fields[0].displayName} </h3>
                    <p className="text_danger">
                      {i.fields[0].required && " *"}
                    </p>
                  </div>
                  <p className="discription">{i.fields[0].description}</p>
                  <PhoneInput
                    value="Phone"
                    containerClass="phone_field"
                    inputClass="phone_input_left"
                    country="in"
                    masks={{ fr: "(...) ..-..-..", at: "(....) ...-...." }}
                    onChange={(val, country, e, formattedValue) =>
                      handleFormObject(
                        i.fields[0].fieldName,
                        e?.target?.value?.replace(/[ _-]/g, "")
                      )
                    }
                    preferredCountries={["in"]}
                    buttonStyle={{
                      backgroundColor: "#FFF",
                      borderRightWidth: 0,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                    }}
                  />
                </InputStyledComp>
              ) : i.fields[0].type == "email" ? (
                <InputStyledComp key={index}>
                  <div className="field_name sr_number">
                    <span>
                      {extractNumber(i.question)}{" "}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <h3 className="title">{i.fields[0].displayName}</h3>
                    <p className="text_danger">
                      {i.fields[0].required && " *"}
                    </p>
                  </div>
                  <p className="discription">{i.fields[0].description}</p>
                  {/* <input type='email' className='input_box' placeholder='Type your answer here' value={i.fields[0].fieldName} onChange={(e) => { handleEditFormValue(i.question, i.fields[0].type, 'fieldName', e.target.value) }} /> */}
                  <input
                    type="text"
                    className="input_box"
                    placeholder={i.fields[0].placeholder}
                    value={newFormData[i.fields[0].fieldName]}
                    onChange={(e) => {
                      handleFormObject(i.fields[0].fieldName, e.target.value);
                    }}
                  />
                </InputStyledComp>
              ) : i.fields[0].type == "file_upload" ? (
                <InputStyledComp key={index}>
                  <div className="field_name sr_number">
                    <span>
                      {extractNumber(i.question)}{" "}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <h3 className="title">{i.fields[0].displayName} </h3>
                    <p className="text_danger">
                      {i.fields[0].required && " *"}
                    </p>
                  </div>
                  <p className="discription">{i.fields[0].description}</p>
                  <div className="all_files">
                    {i.fields[0].file.map((j: any, jx: number) => {
                      return (
                        <span key={jx}>
                          {j.name}
                          {/* File {jx + 1}{" "} */}
                          <button
                            onClick={() => {
                              deleteFile(i.question, jx);
                            }}
                          >
                            <img src="/assets/img/cancleRed.png" />
                          </button>
                        </span>
                      );
                    })}
                  </div>

                  <div className="dragggable_file">
                    <span>
                      <FontAwesomeIcon icon={faUpload} /> Choose file or drag
                      file here{" "}
                    </span>
                    <input
                      type="file"
                      onChange={(e: any) => {
                        addFiles(i.question, e.target.files[0]);
                      }}
                    />
                    {/* <input type='file' onChange = {(e:any)=>{getSignUrl(e.target.files,i.question,i.fields[0].displayName)}}/> */}
                  </div>
                </InputStyledComp>
              ) : i.fields[0].type == "long_text" ? (
                <InputStyledComp key={index}>
                  <div className="field_name sr_number">
                    <span>
                      {extractNumber(i.question)}{" "}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <h3 className="title">{i.fields[0].displayName} </h3>
                    <p className="text_danger">
                      {i.fields[0].required && " *"}
                    </p>
                  </div>
                  <p className="discription">{i.fields[0].description}</p>
                  <textarea
                    rows={5} // Specify the number of visible rows
                    cols={50} // Specify the number of visible columns
                    placeholder={i.fields[0].placeholder}
                    // value={i.fields[0].fieldName} onChange={(e) => { handleEditFormValue(i.question, i.fields[0].type, 'fieldName', e.target.value) }}
                    value={newFormData[i.fields[0].fieldName]}
                    onChange={(e) => {
                      handleFormObject(i.fields[0].fieldName, e.target.value);
                    }}
                  />
                </InputStyledComp>
              ) : i.fields[0].type == "date" ? (
                <InputStyledComp key={index}>
                  <div className="field_name sr_number">
                    <span>
                      {extractNumber(i.question)}{" "}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <h3 className="title">{i.fields[0].displayName} </h3>
                    <p className="text_danger">
                      {i.fields[0].required && " *"}
                    </p>
                  </div>
                  <p className="discription">{i.fields[0].description}</p>
                  <div className="custome_date_box">
                    <DatePicker
                      format="MM/dd/yyyy"
                      size="lg"
                      appearance="subtle"
                      className="date_input_box"
                      onChange={(date) => {
                        formatDate(i.fields[0].fieldName, date);
                      }}
                      style={{ fontSize: "30px", backgroundColor: "none" }}
                    />
                    <input
                      type="text"
                      className="input_box"
                      value={newFormData[i.fields[0].fieldName]}
                      placeholder="Select Date"
                    />
                  </div>
                  {/* <input type="date" name="datePick" className='input_box' placeholder={i.fields[0].placeholder} value={newFormData[i.fields[0].fieldName]} onChange={(e) => { handleFormObject(i.fields[0].fieldName, e.target.value) }} /> */}
                </InputStyledComp>
              ) : i.fields[0].type == "address" ? (
                <InputStyledComp key={index}>
                  <div className="field_name sr_number">
                    <span>
                      {extractNumber(i.question)}{" "}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <h3 className="title">{i.fields[0].displayName} </h3>
                    <p className="text_danger">
                      {i.fields[0].required && " *"}
                    </p>
                  </div>
                  <p className="discription">{i.fields[0].description}</p>
                  <textarea
                    rows={4} // Specify the number of visible rows
                    cols={50} // Specify the number of visible columns
                    placeholder={i.fields[0].placeholder}
                    // value={i.fields[0].fieldName} onChange={(e) => { handleEditFormValue(i.question, i.fields[0].type, 'fieldName', e.target.value) }}
                    value={newFormData[i.fields[0].fieldName]}
                    onChange={(e) => {
                      handleFormObject(i.fields[0].fieldName, e.target.value);
                    }}
                  />
                </InputStyledComp>
              ) : i.fields[0].type == "payment" ? (
                <PaymentFiels>
                  <div className="field_name sr_number">
                    <span>
                      {extractNumber(i.question)}{" "}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <h3 className="title">
                      {i.fields[0].displayName}{" "}
                      <p className="text_danger">
                        {i.fields[0].required && " *"}
                      </p>
                    </h3>
                  </div>
                  <p className="discription">{i.fields[0].description}</p>
                  <h4 className="payment_amount">₹ {i.fields[0].amount}</h4>
                  <button
                    onClick={() => {
                      generatePaymentLink(
                        i.fields[0].fieldName,
                        i.fields[0].amount
                      );
                    }}
                    className="pay_btn"
                  >
                    Pay Now
                  </button>
                </PaymentFiels>
              ) : i.fields[0].type == "end_screen" ? (
                <InputStyledComp key={index}>
                  <h3 className="end_screen_box">{i.fields[0].description} </h3>
                  {/* <input type='text' className='input_box' placeholder={'End screen'} value={i.fields[0].description} onChange={(e) => { handleEditFormValue(i.question, i.fields[0].type, 'description', e.target.value) }} /> */}
                </InputStyledComp>
              ) : i.fields[0].type == "multiple_options" ? (
                <MultipleOption>
                  <div className="field_name sr_number">
                    <span>
                      {extractNumber(i.question)}{" "}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <h3 className="title">{i.fields[0].displayName} </h3>
                    <p className="text_danger">
                      {i.fields[0].required && " *"}
                    </p>
                  </div>
                  <p className="discription">{i.fields[0].description}</p>
                  <div className="mt-3 multi_option">
                    {i.fields[0].options.map((j: any, jx: number) => {
                      return (
                        <button
                          onClick={() => {
                            updateOptions(i, j);
                            if (i.fields[0].multipleSelection) {
                              handleFormObject(
                                i.fields[0].fieldName,
                                j.value,
                                "multiOption"
                              );
                            } else {
                              handleFormObject(
                                i.fields[0].fieldName,
                                j.value,
                                ""
                              );
                            }
                          }}
                          className={
                            newFormData[i.fields[0].fieldName].includes(j.value)
                              ? "active"
                              : ""
                          }
                        >
                          <span>{jx + 1}</span>
                          {j.value.split("_").join(" ")}
                        </button>
                      );
                    })}
                  </div>
                </MultipleOption>
              ) : (
                <div className="content_box"></div>
              );
            })}
          {formData.form?.filter((item: any) => {
            return item.question === `question_${activeField}`;
          })[0].fields[0].type == "end_screen" ? (
            ""
          ) : (
            <div className="action_btns">
              {activeField > 1 && (
                <button onClick={handleBack}>
                  <FontAwesomeIcon icon={faLeftLong} />
                  Back
                </button>
              )}
              {/* <button onClick={() => validateConditions()} disabled={disableButton}> {activeField == formData?.form?.length -1 ? 'Submit ' : 'Next '}<FontAwesomeIcon icon={faRightLong} /></button> */}
              <button
                className={`${activeField === 1 && "single"}`}
                onClick={() => validateConditions()}
                // onClick={() =>
                //   activeField == formData?.form?.length - 1
                //     ? submitForm()
                //     : validateConditions()
                // }
                disabled={disableButton}
              >
                {activeField == formData?.form?.length - 1
                  ? "Submit "
                  : "Next "}
                <FontAwesomeIcon icon={faRightLong} />
              </button>
            </div>
          )}
        </div>
      </Spin>

      <Modal
        size={"sm"}
        open={paymentDialog}
        onClose={() => {}}
        dialogClassName="payment_dialog_box_model"
      >
        <div
          className="dialog_model"
          style={{ background: "#EBF0F4", textAlign: "center" }}
        >
          <h5>Payment Successfully</h5>
          <p>
            Thanks you for your payment{" "}
            {formData?.form?.length &&
              formData?.form[activeField - 1].fields[0]?.amount}{" "}
            INR
          </p>
          <img src={successImg} />
          <p>Please wait ...</p>
          {/* 
          <Modal.Footer>
            <Button
              onClick={() => {validateConditions()}}
              appearance="primary"
              size="lg"
            >
              {activeField == formData?.form?.length - 1
                ? "Submit "
                : "Next "}

            </Button>
          </Modal.Footer> */}
        </div>
      </Modal>

      <Toaster position="top-center" reverseOrder={false} />
    </Root>
    // </div>
  );
}
const Root = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 90%;
  margin: auto;
  @media (max-width: 768px) {
    width: 100%;
  }

  h4 {
    font-size: 25px;
    text-transform: capitalize;
    color: #000;
  }

  .sigma_logo {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;

    .logo_section {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      padding: 10px;
      @media (max-width: 500px) {
        padding: 0px;
      }
    }
    img {
      width: 100px;
      height: 24px;
      object-fit: contain;
    }
    h2 {
      line-height: normal;
      font-size: 22px;
      color: #000;
    }
    h3 {
      font-size: 16px;
      line-height: normal;
      color: #000;
      font-weight: 400;
    }
    @media (max-width: 500px) {
      flex-direction: column-reverse;
      h4 {
        font-size: 20px;
      }
    }
  }
  .form_box {
    width: 100%;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    height: 80%;
    position: relative;
    .action_btns {
      position: absolute;
      bottom: 30px;
      right: 70px;

      button {
        background-color: #2220a7;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        padding: 6px;
        border-radius: 4px;
        margin-left: 20px;
        svg {
          margin: auto 10px;
        }
      }
    }

    @media (max-width: 500px) {
      height: 75vh;
      min-height: 30%;
      overflow: auto;
      .action_btns {
        position: fixed;
        bottom: 0;
        width: 100%;
        right: 0;
        left: 0;

        button {
          background-color: #2220a7;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          padding: 16px;
          border-radius: 4px;
          margin-left: 0;
          width: 50%;
          svg {
            margin: auto 10px;
          }
          &.single {
            width: 100%;
          }
        }
      }
    }
  }
  .ant-spin-nested-loading {
    height: 100%;
    overflow: hidden;
    .ant-spin-container {
      height: 100%;
    }
  }
`;
