// DateDivider.tsx - Updated floating date component
import React from 'react';

interface DateDividerProps {
  date: string;
  getFormattedTime: (date: string) => string;
}

export const DateDivider: React.FC<DateDividerProps> = ({ 
  date, 
  getFormattedTime 
}) => {
  return (
    <div className="floating-date-header">
      <div className="date-bubble">
        <span className="date-text">
          {getFormattedTime(date)}
        </span>
      </div>
    </div>
  );
};