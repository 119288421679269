import React, { FC, useEffect, useState } from "react";
import { getLeadDetail } from "../../services/leadService";
import { useSelector } from "react-redux";
import { IoDocumentOutline } from "react-icons/io5";

interface InformationProps {
  leadToBeUpdate: string;
  onEdit?: () => void;
}

interface FieldDataItem {
  name: string;
  values: string[];
}

type ValueType = string | number | boolean | object | any[];
interface LeadData {
  [key: string]: ValueType;
}

// Error Boundary Component
class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error('Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div className="p-4 text-center text-red-600">Something went wrong. Please try again.</div>;
    }
    return this.props.children;
  }
}

const Information: FC<InformationProps> = ({ leadToBeUpdate, onEdit }) => {
  const stateData = useSelector((state: any) => state?.rootReducers);
  const [leadData, setLeadData] = useState<LeadData>({});
  const [customFields, setCustomFields] = useState<LeadData>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (leadToBeUpdate) {
      fetchLeadDetails();
    }
  }, [leadToBeUpdate]);

  const fetchLeadDetails = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await getLeadDetail(leadToBeUpdate);
      
      if (!response?.data?.data) {
        throw new Error('Invalid response structure');
      }

      const extraDetails = response.data.data.extraDetails || {};

      const filterValues = stateData?.userData?.userDetails?.userPreference?.customForm?.map(
        ({ value }: { value: string }) => value
      ) || [];

      const processedFields = processExtraDetails(extraDetails);

      const customFieldsData: LeadData = {};
      const leadFieldsData: LeadData = {};

      Object.entries(processedFields).forEach(([key, value]) => {
        if (!key) return;
        const originalKey = key.toLowerCase().replace(/ /g, "_");
        if (filterValues.includes(originalKey)) {
          customFieldsData[key] = value;
        } else {
          leadFieldsData[key] = value;
        }
      });

      setLeadData(leadFieldsData);
      setCustomFields(customFieldsData);
    } catch (error) {
      console.error("Error fetching lead details:", error);
      setError(error instanceof Error ? error.message : 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };


  const processExtraDetails = (details: Record<string, any>): Record<string, ValueType> => {
    const processed: Record<string, ValueType> = {};
    
    Object.entries(details).forEach(([key, value]) => {
      if (!key) return;
  
      // Special handling for field_data
      if (key === 'field_data' && Array.isArray(value)) {
        value.forEach((field: FieldDataItem) => {
          if (field.name && field.values) {
            const fieldKey = field.name
              .replace(/_/g, ' ')
              .replace(/\?$/, '')
              .split(' ')
              .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
            processed[fieldKey] = Array.isArray(field.values) ? field.values.join(', ') : field.values;
          }
        });
        return;
      }
  
      // Handle forms object
      if (key === 'forms' && typeof value === 'object') {
        const forms = value as { id?: string; name?: string };
        processed['Form Details'] = `${forms.name || ''} ${forms.id ? `(ID: ${forms.id})` : ''}`.trim();
        return;
      }
  
      // Format regular keys
      const formattedKey = key
        .replace(/_/g, ' ')
        .replace(/\?$/, '')
        .split(' ')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
  
      processed[formattedKey] = value;
    });
  
    return processed;
  };
  

  const formatValue = (value: ValueType): React.ReactNode => {
    const MAX_LENGTH = 200;
  
    if (value === null || value === undefined) {
      return "-";
    }
  
    // Handle Arrays
    if (Array.isArray(value)) {
      if (!value.length) return "-";
      
      // Special handling for field_data array
      if (value[0]?.name && value[0]?.values) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {value.map((item: FieldDataItem, idx) => (
              <div key={idx}>
                <span style={{ fontWeight: 500 }}>
                  {item.name?.replace(/_/g, ' ')?.replace(/\?$/, '')}:
                </span>{' '}
                {Array.isArray(item.values) ? item.values.join(', ') : item.values}
              </div>
            ))}
          </div>
        );
      }
  
      // Regular arrays
      return value.join(', ');
    }
  
    // Handle Dates
    if (typeof value === 'string' && 
        (value.includes('T') || value.match(/^\d{4}-\d{2}-\d{2}/))) {
      try {
        const date = new Date(value);
        if (!isNaN(date.getTime())) {
          return date.toLocaleString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          });
        }
      } catch {
        // If date parsing fails, continue with normal string handling
      }
    }
  
    // Handle Objects
    if (typeof value === 'object' && value !== null) {
      try {
        // If object has name and values (field data format)
        if ('name' in value && 'values' in value) {
          const typedValue = value as { name: string; values: any[] };
          return `${typedValue.values.join(', ')}`;
        }
  
        // If object has id and name (forms format)
        if ('id' in value && 'name' in value) {
          const typedValue = value as { id: string; name: string };
          return `${typedValue.name} (ID: ${typedValue.id})`;
        }
  
        // Format other objects nicely
        return (
          <pre
            style={{
              background: "#f9f9f9",
              padding: "8px",
              borderRadius: "6px",
              maxHeight: "150px",
              overflow: "auto",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              maxWidth: "100%",
              margin: 0,
              fontSize: "13px"
            }}
          >
            {JSON.stringify(value, null, 2)}
          </pre>
        );
      } catch {
        return "-";
      }
    }
  
    // Handle Email addresses
    if (typeof value === 'string' && value.includes('@')) {
      return (
        <a
          href={`mailto:${value}`}
          style={{ color: "#1a73e8", textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      );
    }
  
    // Handle Phone Numbers
    if (typeof value === 'string' && /^\+?\d[\d\s-]+$/.test(value)) {
      return (
        <a
          href={`tel:${value}`}
          style={{ color: "#1a73e8", textDecoration: "none" }}
        >
          {value}
        </a>
      );
    }
  
    // Handle URLs
    if (typeof value === 'string' && 
        (value.startsWith('http') || value.startsWith('www'))) {
      return (
        <a
          href={value.startsWith('www') ? `https://${value}` : value}
          style={{ color: "#1a73e8", textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      );
    }
  
    // Handle Long Strings
    if (typeof value === 'string' && value.length > MAX_LENGTH) {
      return <ExpandableText text={value} maxLength={MAX_LENGTH} />;
    }
  
    // Handle Boolean
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
  
    // Handle Numbers
    if (typeof value === 'number') {
      return value.toLocaleString();
    }
  
    // Default String Handling
    return String(value) || "-";
  };

  if (isLoading) {
    return <div className="p-4 text-center">Loading...</div>;
  }

  if (error) {
    return <div className="p-4 text-center text-red-600">{error}</div>;
  }

  const renderTable = (data: LeadData, prefix: string) => (
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <tbody>
        {Object.entries(data).map(([key, value], index) => (
          <tr
            key={`${prefix}-${key}-${index}`}
            style={{
              background: index % 2 === 0 ? "#f9f9f9" : "#fff",
              borderBottom: "1px solid #e5e7eb",
            }}
          >
            <td
              style={{
                padding: "10px",
                fontWeight: 500,
                color: "#4a5568",
                width: "40%",
                fontSize: "14px",
              }}
            >
              {key}
            </td>
            <td
              style={{
                padding: "10px",
                color: "#2d3748",
                fontSize: "14px",
              }}
            >
              {formatValue(value)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <ErrorBoundary>
      <div
        style={{
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <h5
            style={{
              fontSize: "18px",
              fontWeight: 600,
              color: "#1a202c",
              marginBottom: "12px",
              borderBottom: "2px solid #e5e7eb",
              paddingBottom: "6px",
            }}
          >
            Leadform Fields
          </h5>
          {Object.keys(customFields).length > 0 ? (
            <ErrorBoundary>
              {renderTable(customFields, 'custom')}
            </ErrorBoundary>
          ) : (
            <EmptyState message="No Leadform Fields Available" />
          )}
        </div>

        <h5
          style={{
            fontSize: "18px",
            fontWeight: 600,
            color: "#1a202c",
            marginBottom: "12px",
            borderBottom: "2px solid #e5e7eb",
            paddingBottom: "6px",
          }}
        >
          General Information
        </h5>
        {Object.keys(leadData).length > 0 ? (
          <ErrorBoundary>
            {renderTable(leadData, 'lead')}
          </ErrorBoundary>
        ) : (
          <EmptyState message="No General Information Available" />
        )}
      </div>
    </ErrorBoundary>
  );
};

const EmptyState: FC<{ message: string }> = ({ message }) => (
  <div style={{ textAlign: "center", padding: "20px", color: "#6b7280" }}>
    <IoDocumentOutline
      size={40}
      style={{ marginBottom: "8px", color: "#9ca3af" }}
    />
    <p style={{ fontSize: "14px", fontWeight: 500 }}>{message}</p>
  </div>
);

const ExpandableText: FC<{ text: string; maxLength: number }> = ({
  text,
  maxLength,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      {isExpanded ? text : `${text.slice(0, maxLength)}...`}
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        style={{
          background: "none",
          border: "none",
          color: "#1a73e8",
          cursor: "pointer",
          padding: "0",
          marginLeft: "8px",
          fontSize: "14px",
        }}
      >
        {isExpanded ? "Show Less" : "See More"}
      </button>
    </div>
  );
};

export default Information;