import React, { useState } from "react";
import styled from "styled-components";
import "./whatsapp.css";
import Profile from "./Profile";
import moment from "moment";

interface User {
  id: string;
  name: string;
  mobile: string;
  email: string;
  userIcon: string;
  msg: string;
  timeStamp: string;
  msgStatus: string;
}

interface UserListProps {
  users: any;
  setSelectedUser: any;
  selectedUser?: any;
}

const getMomentDate = (date: any, format: string) => {
  return moment(date).format(format ? format : "DD/MM/YYYY");
};

const getDate = (date: any) => {
  const inputDate: any = moment(date);
  if (inputDate.isSame(moment(), "day")) {
    return inputDate.format("hh:mm A"); // Show time if today
  }

  // Check if the date is yesterday
  if (inputDate.isSame(moment().subtract(1, "days"), "day")) {
    return "Yesterday"; // Show "Yesterday" if date is yesterday
  }

  // Check if the date is within the last week but not today or yesterday
  if (
    inputDate.isAfter(moment().subtract(7, "days")) &&
    !inputDate.isSame(moment(), "day") &&
    !inputDate.isSame(moment().subtract(1, "days"), "day")
  ) {
    return inputDate.format("dddd"); // Show weekday if within last week
  }

  // For dates older than a week, show the date
  return inputDate.format("MMM D, YYYY");
};

const InboxList: React.FC<UserListProps> = (props) => {
  const { selectedUser } = props;

  return (
    <>
      {/* Render the list of users received through props */}
      {props?.users?.map((element: any, idx: any) => {
        return (
          <div key={idx}>
            {/* <p
              className="fm-gilroyBold mb-0 fs-8 mt-2 mb-2 mx-2 inbox_date"
              style={{ color: "#00000080" }}
            >
              {moment(element?.title).format("DD MMM YYYY")}
            </p> */}
            {element?.data?.map((user: any, index: any) => {
              let isSelected = user?.id === selectedUser?.id;

              return (
                <li className="mx-2 mb-2" key={index}>
                  <div
                    className={`item  ${isSelected ? "active_button" : ""}`}
                    style={{
                      background: "transparent",
                      borderBottom: "1px solid #eee",
                    }}
                    onClick={() => props.setSelectedUser(user)}
                  >
                    <Profile
                      style={{
                        marginRight: "8px",
                        width: "40px",
                        height: "40px",
                      }}
                      src={user.userIcon}
                      icon="fa-lg"
                    />
                    <div className="flex-fill d-flex flex-column">
                      <div className="d-flex mb-1">
                        <div className="mob">
                          {user?.name ? (
                            <span
                              className={`d-block ${
                                isSelected ? "text-light" : ""
                              }`}
                              style={{
                                fontSize: 14,
                                maxWidth: 160,
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {user?.name ?? "No Name"}
                            </span>
                          ) : null}

                          {!user?.name ? (
                            <span
                              className={`d-block ${
                                isSelected ? "text-light" : ""
                              }`}
                              style={{ fontSize: 14 }}
                            >
                              {user?.mobile}
                            </span>
                          ) : null}
                        </div>
                        <span
                          className={`time ${isSelected ? "text-light" : ""}`}
                        >
                          {getDate(user?.lastMessageAt)}
                        </span>
                      </div>
                      <div className="d-flex">
                        <div className="ellipsisTxt fm-gilroy">
                          <span
                            style={
                              isSelected
                                ? { color: "#fff" }
                                : { color: "#00000080" }
                            }
                          >
                            {user?.msgStatus == "delivered" ? (
                              <>
                                <i className="me-1">
                                  <svg
                                    viewBox="0 0 16 11"
                                    height="11"
                                    width="16"
                                    preserveAspectRatio="xMidYMid meet"
                                    fill="none"
                                  >
                                    <path
                                      d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </i>
                              </>
                            ) : user?.msgStatus == "read" ? (
                              <>
                                <i
                                  className="me-1"
                                  style={{ color: "#61bbfb" }}
                                >
                                  <svg
                                    viewBox="0 0 16 11"
                                    height="11"
                                    width="16"
                                    preserveAspectRatio="xMidYMid meet"
                                    fill="none"
                                  >
                                    <path
                                      d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </i>
                              </>
                            ) : null}

                            {typeof user?.msg === "string" ? user?.msg : "----------"}
                          </span>

                          {user?.unReadCount ? (
                            <div className="unread_container">
                              <span>{user?.unReadCount}</span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default InboxList;
