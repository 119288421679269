import React, { useEffect, useState } from "react";

export default function useDebounce(text: string, time: number = 500) {
  const [value, setValue] = useState(text);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setValue(text);
    }, time);
    return () => {
      clearTimeout(timeout);
    };
  }, [text]);

  return value;
}

export const removeEmptyValues = (
  obj: Record<string, any>
): Record<string, any> => {
  // Create a new object to store non-empty values
  const newObj: Record<string, any> = {};

  // Iterate through the keys of the input object
  for (const key in obj) {
    // Check if the value of the key is not empty (null, undefined, '', [], {})
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
      // Add the key-value pair to the new object
      newObj[key] = obj[key];
    }
  }

  return newObj;
};

export function includes<T>(array: T[], searchElement: T): boolean {
  for (const element of array) {
    if (element === searchElement) {
      return true;
    }
  }
  return false;
}

export const clearInputValueById = (id: string): void => {
  const input = document.getElementById(id) as HTMLInputElement | null;
  if (input) {
    input.value = "";
  }
};
