import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import toast from "react-hot-toast";
import { userPreferences } from "../../actions/actions";
import { putUserPreferences } from "../../services/leadService";
import { PreferenceI } from "./leadsGrid/leads-grid";

interface IManageLeadsColumns {
  defaultColumns?: { value: string; label: string }[];
  readOnlyColumns?: string[];
}

const ManageLeadsColumns: React.FC<PropsWithChildren<IManageLeadsColumns>> = ({
  defaultColumns = [],
  readOnlyColumns = [],
}) => {
  const dispatch = useDispatch();
  const preferences: PreferenceI = useSelector(
    (state: { rootReducers: { user: { userPreferences: any } } }) =>
      state?.rootReducers?.user?.userPreferences
  );

  const [columns, setColumns] = useState<{ value: string; label: string }[]>(
    []
  );
  const [showPopup, setShowPopup] = useState(false);
  const [checkedColumns, setCheckedColumns] = useState<string[]>(
    preferences?.leadGridPreferences?.columnOrders || []
  );

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updatedColumns = [
      ...(preferences.customForm ?? []),
      ...defaultColumns,
    ].map((c) => ({ label: c.label, value: c.value }));
    const uniqueColumns = updatedColumns.filter(
      (c, index, self) =>
        index === self.findIndex((obj) => obj.value === c.value)
    );
    setColumns(uniqueColumns);
  }, [preferences?.customForm, defaultColumns]);

  useEffect(() => {
    setCheckedColumns([
      ...(preferences.leadGridPreferences?.columnOrders || []),
      ...readOnlyColumns,
    ]);
  }, [preferences.leadGridPreferences, readOnlyColumns]);

  const onApply = () => {
    const uniqueCheckedColumns = [...new Set(checkedColumns)];
    const leadGridPreferences = {
      ...preferences.leadGridPreferences,
      columnOrders: uniqueCheckedColumns,
    };
    const isEq = isEqual(leadGridPreferences, preferences.leadGridPreferences);
    if (!isEq) {
      dispatch(
        userPreferences({
          ...preferences,
          leadGridPreferences,
        })
      );
      handleAddGridPreferences(leadGridPreferences);
    }
    setShowPopup(false);
  };

  const handleAddGridPreferences = async (data: any) => {
    try {
      const response = await putUserPreferences({ leadGridPreferences: data });
      if (response && response.status) {
        console.log(response.data.message);
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error("Error while updating Source!");
      }
    }
  };

  const handleColumnCheck = (value: string, isChecked: boolean) => {
    const updatedColumns = isChecked
      ? [...checkedColumns, value]
      : checkedColumns.filter((column) => column !== value);
    setCheckedColumns(updatedColumns);
  };

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  return (
    <div className="position-relative">
      <button
        onClick={() => setShowPopup(true)}
        className="btn lead_icon d-flex align-items-center"
        style={{
          backgroundColor: "#EBF0F4",
          borderRadius: "6px",
          fontFamily: "Gilroy, sans-serif",
        }}
      >
        <svg
          width="10"
          height="11"
          viewBox="0 0 10 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="me-1"
        >
          <path
            d="M9.91694 6.57105L9.41354 7.15L8.38157 5.96316L8.88497 5.38421C8.99069 5.25684 9.16688 5.25684 9.27259 5.38421L9.91694 6.12526C10.0277 6.24684 10.0277 6.44947 9.91694 6.57105ZM5.03398 9.80737L8.0896 6.29895L9.12157 7.48579L6.07098 11H5.03398V9.80737ZM1.0068 0H8.05437C8.32139 0 8.57747 0.121992 8.76628 0.339139C8.95509 0.556287 9.06116 0.850802 9.06116 1.15789V3.57211L7.13315 5.78947H5.03398V8.20368L4.11276 9.26316H1.0068C0.739777 9.26316 0.483694 9.14117 0.294884 8.92402C0.106073 8.70687 0 8.41236 0 8.10526V1.15789C0 0.850802 0.106073 0.556287 0.294884 0.339139C0.483694 0.121992 0.739777 0 1.0068 0ZM1.0068 2.31579V4.63158H4.02718V2.31579H1.0068ZM5.03398 2.31579V4.63158H8.05437V2.31579H5.03398ZM1.0068 5.78947V8.10526H4.02718V5.78947H1.0068Z"
            fill="black"
          />
        </svg>
        Edit columns
      </button>

      {showPopup && (
        <div
          ref={popupRef}
          className="position-absolute bg-white rounded shadow-sm"
          style={{
            top: "100%",
            right: 0,
            width: "320px",
            zIndex: 1000,
            marginTop: "8px",
          }}
        >
          <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
            <h6 className="m-0">Columns</h6>
            <div>
              <button
                className="btn btn-primary btn-sm me-2"
                style={{ width: 100 }}
                onClick={onApply}
              >
                Apply
              </button>
              <button
                className="btn btn-light btn-sm"
                onClick={() => setShowPopup(false)}
              >
                <span>&times;</span>
              </button>
            </div>
          </div>
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {columns.map((column) => (
              <div key={column.value} className="px-3 py-2 hover-bg-light">
                <label className="d-flex align-items-center m-0 cursor-pointer user-select-none">
                  <input
                    type="checkbox"
                    className="form-check-input me-3"
                    checked={checkedColumns.includes(column.value)}
                    onChange={(e) =>
                      handleColumnCheck(column.value, e.target.checked)
                    }
                    disabled={readOnlyColumns.includes(column.value)}
                  />
                  <span>{column.label}</span>
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageLeadsColumns;
