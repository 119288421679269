import React from "react";

interface DelayControlProps {
  value: number;
  onChange: (value: number) => void;
  error?: string;
}

const DelayControl: React.FC<DelayControlProps> = ({
  value = 1,
  onChange,
  error,
}) => {
  return (
    <div className="delay-control-wrapper">
      <div className="col-md-12 d-flex justify-content-center align-items-center">
        <div className="delay_plus_minus">
          <div
            className="plush_minus_box_left"
            onClick={() => {
              // CHANGED: Allow decrease to 0
              const newValue = Math.max(1, value - 1);
              onChange(newValue);
            }}
          >
            <i className="bi bi-dash"></i>
          </div>
          <input
            type="number"
            min="1" // CHANGED: min from 1 to 0
            max="365"
            className="form-control plus_minus"
            value={value}
            onChange={(e) => {
              const newValue = parseInt(e.target.value) || 0;
              if (newValue >= 0 && newValue <= 365) {
                console.log("Input delay changed to:", newValue);
                onChange(newValue);
              }
            }}
          />
          <div
            className="plush_minus_box_right"
            onClick={() => {
              const newValue = Math.min(365, value + 1);
              onChange(newValue);
            }}
          >
            <i className="bi bi-plus"></i>
          </div>
          <span className="delay-label">day delay</span>
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default DelayControl;
