import {
  MdOutlineEmail,
  MdTaskAlt,
  MdOutlinePhoneMissed,
  MdOutlineAssignmentInd,
  MdAddTask,
} from "react-icons/md";
import { IoIosCall } from "react-icons/io";
import { convertToIST } from "../utils/helpers";
import { RiUserFollowLine } from "react-icons/ri";
import { FaVideo } from "react-icons/fa";
import { CiStickyNote } from "react-icons/ci";
import { TbFileUpload } from "react-icons/tb";
import { BiMessageRounded } from "react-icons/bi";
import "./taskItem.css";
import moment from "moment";
import { useSelector } from "react-redux";

interface ITask {
  activity: any;
  onEditTask?: (e: any) => void;
}

const NewTaskItem = ({ activity, onEditTask }: ITask) => {
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const taskList = StoreData?.user?.userPreferences?.taskType ?? [];

  const getTagName = () => {
    let tag = "",
      color = "";

    if (activity?.isCompleted) {
      tag = "Done";
      color = "#0DA30A";
    } else if (
      moment()
        .startOf("day")
        .isSame(moment(activity?.toBePerformAt).startOf("day"))
    ) {
      tag = "Due today";
      color = "#3FAEFD";
    } else if (
      moment()
        .startOf("day")
        .isBefore(moment(activity?.toBePerformAt).startOf("day"))
    ) {
      tag = "Upcoming";
      color = "#061840";
    } else {
      tag = "Overdue";
      color = "#E11D1D";
    }

    return { tag, color };
  };

  const getTaskNameFromType = (type: string) => {
    let taskName = "";

    taskName = taskList?.find((item: any) => item.value === type)?.name;

    if (!taskName) {
      taskName = type;
    }

    return taskName;
  };

  return (
    <div className="container row">
      {/* right container */}
      <div className="task_left_container">
        <div className="task_left_icon_container">
          {activity?.type == "call" ? (
            <IoIosCall />
          ) : activity.type == "video-meeting" ? (
            <FaVideo />
          ) : activity.type == "message" ? (
            <BiMessageRounded size={20} />
          ) : activity.type == "email" ? (
            <MdOutlineEmail />
          ) : activity.type == "follow-up" ? (
            <RiUserFollowLine />
          ) : activity?.type == "incomig" ? (
            <div
              className="d-flex align-items-center justify-content-center rounded-circle"
              style={{ width: "40px", height: "40px", background: "#3FAEFD" }}
            >
              <svg
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.6111 8.33333C21.9444 8.33333 20.1389 8.2 18.6111 7.93333H18.1944C17.7778 7.93333 17.5 8 17.2222 8.13333L14.1667 9.6C10.2778 8.6 6.94444 7.06667 5 5.2L8.05556 3.73333C8.47222 3.53333 8.61111 3.26667 8.33333 3.06667C7.91667 2.33333 7.63889 1.46667 7.63889 0.666667C7.63889 0.333333 6.94444 0 6.25 0H1.38889C0.694444 0 0 0.333333 0 0.666667C0 6.93333 10.5556 12 23.6111 12C24.3056 12 25 11.6667 25 11.3333V9C25 8.66667 24.3056 8.33333 23.6111 8.33333ZM2.77778 1.33333H4.86111C5 1.93333 5.27778 2.53333 5.55556 3.06667L3.88889 3.86667C3.33333 3.06667 2.91667 2.2 2.77778 1.33333ZM22.2222 10.6667C20.4167 10.6 18.6111 10.4 16.9444 10.1333L18.6111 9.33333C19.7222 9.46667 20.9722 9.6 22.2222 9.6V10.6667ZM22.2222 5.33333V4.33333H17.3611L25 0.666667L23.6111 0L15.9722 3.66667V1.33333H13.8889V5.33333H22.2222Z"
                  fill="white"
                />
              </svg>
            </div>
          ) : activity?.type == "outgoing" ? (
            <span style={{ color: "#15661f" }} className={activity?.type}>
              <div
                className="d-flex align-items-center justify-content-center rounded-circle"
                style={{ width: "40px", height: "40px", background: "#1D54A7" }}
              >
                <svg
                  width="25"
                  height="12"
                  viewBox="0 0 25 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.6111 8.33333C21.9444 8.33333 20.1389 8.2 18.6111 7.93333H18.1944C17.7778 7.93333 17.5 8 17.2222 8.13333L14.1667 9.6C10.2778 8.6 6.94444 7.06667 5 5.2L8.05556 3.73333C8.47222 3.53333 8.61111 3.26667 8.33333 3.06667C7.91667 2.33333 7.63889 1.46667 7.63889 0.666667C7.63889 0.333333 6.94444 0 6.25 0H1.38889C0.694444 0 0 0.333333 0 0.666667C0 6.93333 10.5556 12 23.6111 12C24.3056 12 25 11.6667 25 11.3333V9C25 8.66667 24.3056 8.33333 23.6111 8.33333ZM2.77778 1.33333H4.86111C5 1.93333 5.27778 2.53333 5.55556 3.06667L3.88889 3.86667C3.33333 3.06667 2.91667 2.2 2.77778 1.33333ZM22.2222 10.6667C20.4167 10.6 18.6111 10.4 16.9444 10.1333L18.6111 9.33333C19.7222 9.46667 20.9722 9.6 22.2222 9.6V10.6667ZM16.6667 0V1H21.5278L13.8889 4.66667L15.2778 5.33333L22.9167 1.66667V4H25V0H16.6667Z"
                    fill="white"
                  />
                </svg>
              </div>
            </span>
          ) : activity?.type == "Missed" ? (
            <MdOutlinePhoneMissed />
          ) : activity?.type == "lead_assigned " ? (
            <MdOutlineAssignmentInd />
          ) : activity?.type == "status_updated" ||
            activity?.type == "label_updated" ? (
            <span style={{ color: "#15661f" }} className={activity?.type}>
              <div
                className="d-flex align-items-center justify-content-center rounded-circle"
                style={{ width: "40px", height: "40px", background: "#3FAEFD" }}
              >
                <svg
                  width="21"
                  height="15"
                  viewBox="0 0 21 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.9015 5L13.9981 5.78333L3.40619 13.3333H2.33301V12.5667L12.9015 5ZM17.101 0C16.8093 0 16.506 0.0833333 16.2844 0.241667L14.1497 1.76667L18.5241 4.89167L20.6588 3.36667C21.1137 3.04167 21.1137 2.5 20.6588 2.19167L17.9292 0.241667C17.6959 0.075 17.4043 0 17.101 0ZM12.9015 2.65833L0 11.875V15H4.37439L17.2759 5.78333L12.9015 2.65833Z"
                    fill="white"
                  />
                </svg>
              </div>
            </span>
          ) : activity?.type == "note_created" ? (
            <CiStickyNote />
          ) : activity?.type == "task_created" ? (
            <MdAddTask />
          ) : activity?.type == "file_upload" ? (
            <TbFileUpload />
          ) : activity?.type == "all_other_activity " ? (
            <MdTaskAlt />
          ) : (
            <MdTaskAlt />
          )}
        </div>

        <h5 className="text-black fw-bold fs-8">
          {moment(activity?.toBePerformAt).format("DD MMM")}
        </h5>
      </div>
      <div
        className="col cursor-pointer"
        onClick={() => {
          onEditTask && onEditTask(activity);
        }}
      >
        {/* Info Container */}
        <div className="task_info_container">
          <div className="task_heading_wrapper">
            <h5 className="text-capitalize text-black fw-bold fs-7">
              {getTaskNameFromType(activity?.type)} -{" "}
              {
                convertToIST(
                  moment(activity.toBePerformAt).format("DD MMM YYYY, hh:mm a")
                ).split(",")[1]
              }
            </h5>
            <div className="p-1 bg-white px-2 rounded ">
              <span
                className="fw-bold fs-8"
                style={{ color: getTagName()?.color }}
              >
                {getTagName()?.tag}
              </span>
            </div>
          </div>

          {/* {activity?.assignedTo?.length ? (
            <h6 className="fs-8">
              Assigned to {activity?.assignedTo?.[0]?.name || ""}
            </h6>
          ) : null} */}

          {activity?.repeat ? (
            <h6 className="fs-8">Repeat : {activity?.repeat || ""}</h6>
          ) : null}

          {activity?.note && <h6 className="fs-8">Note : {activity?.note}</h6>}
          {activity?.notes && (
            <h6 className="fs-8">Note : {activity?.notes}</h6>
          )}
          {activity?.extraDetails && activity.extraDetails?.description ? (
            <h6 className="fs-8">
              Note : {activity.extraDetails?.description}
            </h6>
          ) : null}
        </div>
        <h6 className="fs-8 mt-1 task_bottom_time">
          {moment(activity?.createdAt).format("DD MMM YY hh:mm a")} by{" "}
          {activity?.createdBy?.firstName ?? activity?.createdBy}
        </h6>
      </div>
    </div>
  );
};

export default NewTaskItem;
