import { FaFacebook, FaFileCsv, FaWordpress } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

const videoExtensions = [
  ".mpg",
  ".mp2",
  ".mpeg",
  ".mpe",
  ".mpv",
  ".mp4",
  ".MP4",
]; //you can add more extensions
const imageExtensions = [".gif", ".jpg", ".jpeg", ".png"];

export const isImage = (v) => {
  let status;
  for (let i in imageExtensions) {
    status = v.includes(imageExtensions[i]);
    if (status) {
      break;
    }
  }

  return status;
};
export const isVideo = (v) => {
  let status;
  for (let i in videoExtensions) {
    status = v.includes(videoExtensions[i]);
    if (status) {
      break;
    }
  }

  return status;
};

export const convertToIST = (utcDateString) => {
  const formattedDate = new Date(utcDateString).toLocaleString("en-IN", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "Asia/Kolkata",
  });
  return formattedDate;
};

export const convertTimeToIST = (timeString) => {
  const [hours, minutes] = timeString.split(":").map(Number);
  const istHours = (hours + 5 + Math.floor((minutes + 30) / 60)) % 24;
  const istMinutes = (minutes + 30) % 60;
  return `${istHours.toString().padStart(2, "0")}:${istMinutes
    .toString()
    .padStart(2, "0")} pm`;
};

export function areAllValuesUndefined(obj, keysToCheck) {
  for (const key of keysToCheck) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      if (!areAllValuesUndefined(obj[key], Object.keys(obj[key]))) {
        return false;
      }
    } else {
      if (typeof obj[key] !== "undefined") {
        return false;
      }
    }
  }
  return true;
}

export function convertSecondsToMinutes(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds}`;
}

export function addCountryCode(number) {
  // Convert the number to a string in case it's not
  let numberStr = number?.toString();

  if (numberStr?.startsWith("+")) {
    numberStr = numberStr?.substring(1);
  }

  // Check if the number has 10 digits and doesn't start with "91"
  if (numberStr?.length <= 10 && !numberStr?.startsWith("91")) {
    return "91" + numberStr;
  }

  // Return the number as it is if the conditions are not met
  return numberStr;
}

const integrationImageStyle = {
  height: 20,
  width: 20,
  marginRight: 10,
  objectFit: "contain",
};

export const typeFormat = (type) => {
  if (type.includes("csv")) {
    return {
      icon: <FaFileCsv style={integrationImageStyle} />,
      iconType: "icon",
      cntColor: "#20b2aa22",
      name: "CSV",
      width: "90%",
    };
  }
  if (type.includes("facebook")) {
    return {
      icon: (
        <img style={integrationImageStyle} src="/assets/img/facebook.png" />
      ),
      iconType: "icon",
      cntColor: "#e8f5fe",
      name: "Facebook",
    };
  }
  if (type.includes("justdial")) {
    return {
      icon: (
        <img style={integrationImageStyle} src="/assets/img/justdial.png" />
      ),
      iconType: "image",
      cntColor: "#fff0e5",
      name: "Just Dial",
      width: "90%",
    };
  }
  if (type.includes("brevo")) {
    return {
      icon: <img style={integrationImageStyle} src="/assets/img/brevo.png" />,
      iconType: "image",
      cntColor: "#fff0e5",
      name: "Brevo",
      width: "90%",
    };
  }
  if (type.includes("razorpay")) {
    return {
      icon: (
        <img 
        style={integrationImageStyle} 
        src={require("../assets/images/integration/razorpay.png")} 
        alt="Razorpay"
      />
      ),
      iconType: "image",
      cntColor: "#fff0e5",
      name: "razorpay",
      width: "90%",
    };
  }
  if (type.includes("zapier")) {
    return {
      icon: <img style={integrationImageStyle} src="/assets/img/zapier.png" />,
      iconType: "image",
      cntColor: "#F8F8F8",
      name: "Zapier",
      width: "90%",
    };
  }
  if (type.includes("indiamart")) {
    return {
      icon: (
        <img style={integrationImageStyle} src="/assets/img/indiaMart.png" />
      ),
      iconType: "image",
      cntColor: "#F8F8F8",
      name: "Indiamart",
      width: "90%",
    };
  }
  if (type.includes("whatsapp")) {
    return {
      icon: <IoLogoWhatsapp style={integrationImageStyle} />,
      iconType: "image",
      cntColor: "#edf7ee",
      name: "WhatsApp",
      width: "40%",
    };
  }
  if (type.includes("wordpress")) {
    return {
      icon: <FaWordpress style={integrationImageStyle} />,
      iconType: "image",
      cntColor: "#e8f5fe",
      width: "100%",
      name: "Wordpress Plugin",
    };
  }
  if (type.includes("lead_form")) {
    return {
      icon: (
        <img style={integrationImageStyle} src="/assets/img/extensions.png" />
      ),
      iconType: "image",
      cntColor: "#e8f5fe",
      width: "100%",
      name: "Google Lead Form",
    };
  }
  if (type.includes("typeform")) {
    return {
      icon: (
        <img style={integrationImageStyle} src="/assets/img/typeForm.png" />
      ),
      iconType: "image",
      cntColor: "#e8f5fe",
      width: "100%",
      name: "Type Form",
    };
  }
  if (type.includes("tradeindia")) {
    return {
      icon: (
        <img style={integrationImageStyle} src="/assets/img/tradeindia.png" />
      ),
      iconType: "image",
      cntColor: "#e8f5fe",
      width: "100%",
      name: "Trade India",
    };
  }
  if (type.includes("exporter_india")) {
    return {
      icon: (
        <img
          style={integrationImageStyle}
          src="/assets/img/ExportersIndia.webp"
        />
      ),
      iconType: "image",
      cntColor: "#e8f5fe",
      width: "100%",
      name: "Exporter india",
    };
  }
  if (type.includes("99acres")) {
    return {
      icon: <img style={integrationImageStyle} src="/assets/img/99Acres.png" />,
      iconType: "image",
      cntColor: "#e8f5fe",
      width: "100%",
      name: "99 Acere (Beta)",
    };
  }
  if (type.includes("housing")) {
    return {
      icon: <img style={integrationImageStyle} src="/assets/img/housing.png" />,
      iconType: "image",
      cntColor: "#e8f5fe",
      width: "100%",
      name: "Housing.com (Beta)",
    };
  }
  if (type.includes("magic_bricks")) {
    return {
      icon: (
        <img style={integrationImageStyle} src="/assets/img/magicbricks.png" />
      ),
      iconType: "image",
      cntColor: "#e8f5fe",

      width: "100%",
      name: "Magic Bricks",
    };
  }
  if (type.includes("pabbly")) {
    return {
      icon: <img style={integrationImageStyle} src="/assets/img/pabbly.png" />,
      iconType: "image",
      cntColor: "#e8f5fe",

      width: "100%",
      name: "Pabbly",
    };
  }
  return {
    icon: <img style={integrationImageStyle} src="/assets/img/housing.png" />,
    iconType: "image",
    cntColor: "#e8f5fe",
    width: "100%",
    name: "Housing.com (Beta)",
  };
};
