import React from "react";
import {
  MessagesSquare,
  ClipboardList,
  StickyNote,
  LucideIcon,
} from "lucide-react";

type EmptyStateType = "activities" | "tasks" | "notes";

interface EmptyStateConfig {
  icon: LucideIcon;
  message: string;
}

interface EmptyStateProps {
  type: EmptyStateType;
}

const config: Record<EmptyStateType, EmptyStateConfig> = {
  activities: {
    icon: MessagesSquare,
    message: "No activities yet",
  },
  tasks: {
    icon: ClipboardList,
    message: "No tasks yet",
  },
  notes: {
    icon: StickyNote,
    message: "No notes yet",
  },
};

const EmptyState: React.FC<EmptyStateProps> = ({ type }) => {
  const { icon: Icon, message } = config[type];

  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <Icon className="w-12 h-12 text-gray-300 mb-2" strokeWidth={1.5} />
        <p className="text-gray-400 text-base">{message}</p>
      </div>
    </div>
  );
};

export default EmptyState;
