export class MediaCache {
    private cache: Map<string, {
      url: string;
      type: string;
      mimeType: string;
      timestamp: number;
    }>;
    private STORAGE_KEY = 'whatsapp_media_cache';
    private MAX_AGE = 24 * 60 * 60 * 1000; // 24 hours
  
    constructor() {
      this.cache = new Map();
      this.loadFromStorage();
    }
  
    private loadFromStorage() {
      try {
        const stored = localStorage.getItem(this.STORAGE_KEY);
        if (stored) {
          const data = JSON.parse(stored);
          Object.entries(data).forEach(([key, value]: [string, any]) => {
            if (Date.now() - value.timestamp < this.MAX_AGE) {
              this.cache.set(key, value);
            }
          });
        }
      } catch (error) {
        console.error('Error loading media cache:', error);
      }
    }
  
    private saveToStorage() {
      try {
        const data = Object.fromEntries(this.cache.entries());
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
      } catch (error) {
        console.error('Error saving media cache:', error);
      }
    }
  
    public set(id: string, data: { url: string; type: string; mimeType: string }) {
      this.cache.set(id, {
        ...data,
        timestamp: Date.now()
      });
      this.saveToStorage();
    }
  
    public get(id: string) {
      const data = this.cache.get(id);
      if (data && Date.now() - data.timestamp < this.MAX_AGE) {
        return data;
      }
      this.cache.delete(id);
      return null;
    }
  
    public has(id: string): boolean {
      return this.get(id) !== null;
    }
  
    public clear() {
      this.cache.clear();
      localStorage.removeItem(this.STORAGE_KEY);
    }
  }