import React, { useCallback } from "react";
import PhoneInput from "react-phone-input-2";
import Inputs from "../../../../components/inputs";
import { Error } from "./types";
import { toast } from "react-hot-toast";

interface ButtonType {
  type: string;
  text: string;
  url?: string;
  phone_number?: string;
}

interface ButtonInputsProps {
  values: {
    buttons: ButtonType[];
  };
  handleChange: (e: React.ChangeEvent<any>) => void;
  errorsObj: Record<string, string>;
  setFieldValue?: (field: string, value: any) => void;
  isSubmitting?: boolean;
}

const MAX_BUTTON_TEXT_LENGTH = 25;
const MAX_URL_LENGTH = 2000;

const ButtonInputs: React.FC<ButtonInputsProps> = ({
  values,
  handleChange,
  errorsObj,
  setFieldValue,
  isSubmitting = false
}) => {
  const handlePhoneChange = useCallback((value: string, index: number) => {
    if (!setFieldValue) return;
    
    const cleanedValue = value.replace(/[^\d+]/g, '');
    const formattedValue = cleanedValue.startsWith('+') ? cleanedValue : '+' + cleanedValue;
    
    setFieldValue(`buttons.${index}.phone_number`, formattedValue);
  }, [setFieldValue]);

  const handleUrlChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (!setFieldValue) return;
    let value = e.target.value.trim();
    
    if (value.startsWith('www.')) {
      value = 'https://' + value;
    }
    
    if (!/^https?:\/\//i.test(value) && value.length > 0) {
      value = 'https://' + value;
    }

    if (value.length > MAX_URL_LENGTH) {
      toast.error(`URL cannot exceed ${MAX_URL_LENGTH} characters`);
      value = value.slice(0, MAX_URL_LENGTH);
    }
    
    setFieldValue(`buttons.${index}.url`, value);
  }, [setFieldValue]);

  // In ButtonInputs.tsx
// In ButtonInputs.tsx - Add to handleButtonTextChange:
const handleButtonTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
  if (!setFieldValue) return;
  const value = e.target.value;
  
  console.log("Button text input:", {
    originalValue: value,
    index: index
  });
  
  if (value.length > MAX_BUTTON_TEXT_LENGTH) {
    toast.error(`Button text cannot exceed ${MAX_BUTTON_TEXT_LENGTH} characters`);
    return;
  }
  
  const sanitizedValue = value
  .replace(/[^a-zA-Z0-9\s-]/g, '')  // Keep alphanumeric, spaces, hyphens
  .replace(/\s+/g, ' ')  // Replace multiple spaces with single space
  .slice(0, MAX_BUTTON_TEXT_LENGTH);
  
  console.log("Button text after sanitization:", {
    sanitizedValue,
    index: index,
    originalRegex: value.match(/[^\w\s-]/g)
  });
  
  setFieldValue(`buttons.${index}.text`, sanitizedValue);
}, [setFieldValue]);

  const renderButtonInputs = useCallback((index: number) => {
    const button = values.buttons[index];
    const otherIndex = index === 0 ? 1 : 0;

    return (
      <div className="row mt-3" key={index}>
        <div className="col-md-3">
          <label className="form-label">Type</label>
          <select
            name={`buttons.${index}.type`}
            value={button.type}
            onChange={handleChange}
            className="form-select py-2 h-45 bg-lighter"
            disabled={isSubmitting}
          >
            <option value="">Select Type</option>
            {values.buttons[otherIndex]?.type !== "PHONE_NUMBER" && (
              <option value="PHONE_NUMBER">Phone</option>
            )}
            {values.buttons[otherIndex]?.type !== "URL" && (
              <option value="URL">URL</option>
            )}
          </select>
          {errorsObj[`buttons.${index}.type`] && (
            <Error>{errorsObj[`buttons.${index}.type`]}</Error>
          )}
        </div>

        {button.type && (
          <>
            <div className="col-md-3">
              <label className="form-label">
                {button.type === "PHONE_NUMBER" ? "Button" : "Link"} Text
              </label>
              <Inputs
                name={`buttons.${index}.text`}
                type="text"
                autoComplete="off"
                className="form-control py-2 h-45 bg-lighter"
                placeholder={button.type === "PHONE_NUMBER" ? "Call Us" : "Visit Website"}
                value={button.text}
                onChange={(e) => handleButtonTextChange(e, index)}
                disabled={isSubmitting}
                label=""
                isRequired={true}
                options={[]}
                id={`button-${index}-text`}
              />
              {errorsObj[`buttons.${index}.text`] && (
                <Error>{errorsObj[`buttons.${index}.text`]}</Error>
              )}
              <small className="text-muted mt-1 d-block">
                {button.text.length}/{MAX_BUTTON_TEXT_LENGTH}
              </small>
            </div>

            {button.type === "PHONE_NUMBER" ? (
              <div className="col-md-6">
                <label className="form-label">Phone</label>
                <div className="overflow-hidden rounded-8 border">
                  <PhoneInput
                    country="in"
                    enableSearch={false}
                    disableDropdown={true}
                    placeholder="+91 Phone Number"
                    value={button.phone_number}
                    onChange={(value) => handlePhoneChange(value, index)}
                    inputProps={{
                      className: "form-control py-2 h-45",
                      disabled: isSubmitting
                    }}
                    countryCodeEditable={true}
                  />
                </div>
                {errorsObj[`buttons.${index}.phone_number`] && (
                  <Error>{errorsObj[`buttons.${index}.phone_number`]}</Error>
                )}
              </div>
            ) : button.type === "URL" && (
              <div className="col-md-6">
                <label className="form-label">Website URL</label>
                <Inputs
                  name={`buttons.${index}.url`}
                  type="text"
                  autoComplete="off"
                  className="form-control py-2 h-45 bg-lighter"
                  placeholder="www.example.com or https://example.com"
                  value={button.url || ""}
                  onChange={(e) => handleUrlChange(e, index)}
                  disabled={isSubmitting}
                  label=""
                  isRequired={true}
                  options={[]}
                  id={`button-${index}-url`}
                />
                {errorsObj[`buttons.${index}.url`] && (
                  <Error>{errorsObj[`buttons.${index}.url`]}</Error>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }, [values, handleChange, errorsObj, isSubmitting, handleButtonTextChange, handlePhoneChange, handleUrlChange]);

  return (
    <>
      {renderButtonInputs(0)}
      {renderButtonInputs(1)}
    </>
  );
};

export default ButtonInputs;