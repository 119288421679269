import { PropsWithChildren, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

interface PropsType {
  open: boolean;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  formDetails: any;
  formFields: any;
}
const options: any = { timeZone: "UTC", timeZoneName: "short" };
const MONTHS: Array<string> = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "June",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const FormResponse: React.FC<PropsWithChildren<PropsType>> = ({
  open,
  handleClose,
  formDetails,
  formFields,
}) => {
  const [formData, setFormData] = useState<any>(null);
  const [createdAt, setCreatedAt] = useState<any>();
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });

  useEffect(() => {
    if (formDetails && formDetails?._id) {
      const keysToRemove = ["createdBy", "integration", "organization", "list"];
      const filteredData = Object.fromEntries(
        Object.entries(formDetails.formData).filter(
          ([key]) => !keysToRemove.includes(key)
        )
      );
      setFormData(filteredData);

      // const fData: any = {};
      // formFields.map((item: any, idx: number)=> {
      //   if (formDetails.formData[item?.fields[0].fieldName] || formDetails.formData[item?.fields[0].fieldName.toLowerCase()]) {
      //     fData[item?.fields[0].fieldName] = formDetails.formData[item?.fields[0].fieldName] || formDetails.formData[item?.fields[0].fieldName.toLowerCase()];
      //   }
      // });
      // setFormData(fData)
      const date = new Date(formDetails?.createdAt);
      setCreatedAt(
        `${date.getDay()} ${
          MONTHS[date.getMonth()]
        } ${date.getFullYear()} at ${date.toLocaleTimeString("en-US")}`
      );
    }
  }, []);

  return (
    <Root className={open == true ? "active" : ""}>
      <div className="form_data">
        <div className="top_bar">
          <button
            className="rs-btn rs-btn-default"
            onClick={() => {
              handleClose(false);
            }}
          >
            Close
          </button>
        </div>
        {formData ? (
          <h2 className="form_title">Response submitted on {createdAt} </h2>
        ) : (
          <h2 className="form_title">No Record Found</h2>
        )}
        {formData &&
          Object.keys(formData).map((key: any, index: number) => {
            return (
              <div className="form_details" key={index}>
                <span>{index + 1}</span>
                <div>
                  <h2>{key.split("_").join(" ")}</h2>
                  {key?.toLowerCase() === "file_upload" ||
                  key?.toLowerCase() ===
                    "upload_your_pan_no_,_tax_id_,_gstin_no_...." ? (
                    formData[key].map((fileUrl: any, idx: number) => (
                      <a
                        href={`${StoreData?.userData?.userDetails?.bucketUrl}${fileUrl}`}
                        download
                        target="_blank"
                        key={idx}
                      >
                        {fileUrl.split("/")[fileUrl.split("/").length - 1]}
                      </a>
                    ))
                  ) : (
                    <h5>{formData[key] || formData[key.toLowerCase()]}</h5>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <button
        className="background_close"
        onClick={() => {
          handleClose(false);
        }}
      ></button>
    </Root>
  );
};
export default FormResponse;
const Root = styled.section`
  box-shadow: 0px 0px 3px 0px #00000061;
  position: fixed;
  top: 0px;
  left: 0%;
  height: 100%;
  width: 100%;
  /* transform: translateX(100%); */

  /* background: #fff; */
  /* display:none; */
  &.active {
    /* display:block; */
    .form_data {
      right: 0%;
      /* transform: translateX(0%); */
      width: fit-content;
      max-width: 500px;
    }
    button.background_close {
      width: 100%;
      height: 100%;
      left: 0px;
      position: fixed;
      top: 0px;
      z-index: 1;
      background: #00000017;
    }
  }

  .form_data {
    overflow: scroll;
    z-index: 9;
    width: 0px;
    transition: all 1s;
    position: absolute;
    background: #ffffff;
    height: 100%;
    padding: 20px;
    font-family: "Gilroy-Regular";
    .top_bar {
      text-align: right;
      position: sticky;
      top: 0px;
    }
    .form_title {
      margin-top: 10px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      color: #000000;
      @media (max-width: 500px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    .form_details {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      color: #000000;
      margin: 14px 0px;
      span {
        font-size: 20px;
        background: #ebf0f4;
        margin-top: 10px;
        padding: 0px 10px;
        border-radius: 6px;
        font-family: "Gilroy-Bold";
      }
      h2 {
        font-size: 22px;
        text-transform: capitalize;
      }
      h4 {
        font-size: 20px;
      }
      a {
        color: #3faefd;
        display: block;
        font-size: 20px;
        font-weight: 600;
        margin: 10px;
      }
      @media (max-width: 500px) {
        h2 {
          font-size: 16px;
          line-height: 20px;
        }
        h4 {
          font-size: 14px;
          line-height: 26px;
        }
        span {
          font-size: 15px;
          margin-top: 0px;
          padding: 0px 6px;
        }
      }
    }
  }
`;
