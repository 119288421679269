import React, { useState, useCallback, memo } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import DrawerComponent from "../../../components/drawer";
import LeadDetails from "../../leads/leadDetails";
import { LeadDataI } from "../../leads/leads";
import { leadFileList } from "../../../actions/actions";
import { uploadFiles } from "../../../services/utilityService";
import { activityById } from "../../../services/activityService";
import { taskById } from "../../../services/taskService";

interface LeadMenuProps {
  openLeadDrawer: boolean;
  setOpenLeadDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLead?: LeadDataI;
  leadId: any;
  leadLabelData: string[];
  setOpenWithHeader: React.Dispatch<React.SetStateAction<boolean>>;
  onClickShowStatusPopup?: () => void;
  onClickShowLabelPopup?: () => void;
}

const LeadMenu = memo(
  ({
    openLeadDrawer,
    setOpenLeadDrawer,
    selectedLead,
    leadId,
    setOpenWithHeader,
    onClickShowStatusPopup,
    onClickShowLabelPopup,
  }: LeadMenuProps) => {
    const [openFilterMenu, setOpenFilterMenu] = useState<boolean>(false);

    const StoreData = useSelector((state: any) => {
      return state?.rootReducers;
    });
    const [leadToBeUpdate, setLeadToBeUpdate] = useState<string>("");
    const [toBeRemoved, setToBeRemoved] = useState<string>("");
    const [moveLeadToListModal, setShowMoveLeadToList] =
      useState<boolean>(false);
    const [showLabelPopup, setShowLabelPopup] = useState<boolean>(false);
    const [showStatusPopup, setShowStatusPopup] = useState<boolean>(false);
    const [from, setFrom] = useState<string>("");
    const [activityformTitle, setActivityFormTitle] = useState<string>("Add");
    const [activityformName, setActivityFormName] = useState<string>("");
    const [updateModalValue, setUpdateModalValue] = useState<any>({});
    const [createNewLeadDrawer, showCreateNewLeadDrawer] =
      useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [leadFileObj, setLeadFileObj] = useState<any>({});
    const [showFileDeleteModal, setShowFileDeleteModal] =
      useState<boolean>(false);

    const [showConfirmationModal, setShowConfirmationModal] =
      useState<boolean>(false);

    const dispatch = useDispatch();

    let storedPreferences = localStorage.getItem("user_preferences");

    let userPreferences = storedPreferences
      ? JSON.parse(storedPreferences)
      : {};

    const toggleLeadFilterDrawer = useCallback(() => {
      setOpenFilterMenu(true);
    }, []);
    const handleLeadClick = (leadId: string) => {
      let selected = StoreData?.leads?.leads?.find(
        (lead: any) => lead._id === leadId
      );
      let tempArray = [];
      tempArray = StoreData?.user?.userPreferences?.labels
        ? StoreData?.user?.userPreferences?.labels
        : userPreferences?.labels;

      const mergedArray = tempArray.map((obj1: any) => {
        const matchingObjTeam = selected?.label.find(
          (obj2: any) => obj2 === obj1.value
        );
        let temp_obj = {};
        if (selected?.label.includes(obj1.value)) {
          temp_obj = {
            ...obj1,
            isLabelChecked: true,
          };
        } else {
          temp_obj = {
            ...obj1,
            isLabelChecked: false,
          };
        }

        if (matchingObjTeam) {
          return { ...temp_obj };
        }
        return obj1;
      });

      setOpenWithHeader(true);
      setOpenLeadDrawer(!openLeadDrawer);
    };

    const deleteLeadFromDrawer = (id: string) => {
      setLeadToBeUpdate(id);
      setShowConfirmationModal(true);
      setToBeRemoved("lead");
      setOpenWithHeader(false);
    };
    const copyLeadToList = () => {
      // setShowCopyLeadToList(!copyLeadToListModal);
    };
    const moveLeadToList = () => {
      setShowMoveLeadToList(!moveLeadToListModal);
    };
    const onLabelClick = () => {
      onClickShowLabelPopup?.();
      setShowLabelPopup(true);
    };
    const onStatusClick = () => {
      onClickShowStatusPopup?.();
      setShowStatusPopup(true);
    };
    const handleActivityModal = (title: string, name: string) => {
      let modalId = "";
      if (name === "Activity") {
        modalId = "#AddNewActivites";
        setActivityFormTitle(title);
      } else {
        modalId = "#AddNewTask";
        setActivityFormTitle(title);
      }
      $(modalId).modal("show");
      setActivityFormName(name);
    };
    const handleEditActivityModal = async (
      name: string,
      _id: any,
      action: string
    ) => {
      if (name === "activity") {
        try {
          const response = await activityById(_id);
          if (response && response.status) {
            let modalId = "#AddNewActivites";
            handleActivityModal(action, "Activity");
            setUpdateModalValue(response.data.data);
            $(modalId).modal("show");
          }
        } catch (err) {
          toast.error("error while fetching activity data.");
        }
      }
      if (name === "task") {
        try {
          const response = await taskById(_id);
          if (response && response.status) {
            let modalId = "#AddNewTask";
            handleActivityModal(action, "Task");
            setUpdateModalValue(response.data.data);
            $(modalId).modal("show");
          }
        } catch (err) {
          toast.error("error while fetching activity data.");
        }
      }
    };
    const onLeadFileUpload = async (e: any) => {
      let formData = new FormData();
      formData.append("files", e.target.files[0]);
      formData.append("type", "lead");
      formData.append("lead", leadId);
      try {
        const response = await uploadFiles(formData);
        if (response && response.status) {
          const tempArray = [...StoreData?.leadFile?.leadFile];
          tempArray?.unshift(response?.data?.data[0]);
          dispatch(leadFileList(tempArray));
          toast.success(response?.data?.message);
        }
      } catch (err) {
        toast.error("Error while uploading file!");
      }
    };
    const editLead = (e: { stopPropagation: () => void }, id: any) => {
      e.stopPropagation();
      showCreateNewLeadDrawer(true);
      setOpenWithHeader(false);
      setIsEditMode(true);
      setLeadToBeUpdate(id);
    };
    const fileToggleModal = () => {
      setShowFileDeleteModal(!showFileDeleteModal);
    };
    const leadFileDeleteToggle = (objData: any) => {
      setLeadFileObj(objData);
      fileToggleModal();
    };

    return (
      <div className="leadDetail_drawer">
        <DrawerComponent
          openWithHeader={openLeadDrawer}
          setOpenWithHeader={setOpenLeadDrawer}
          drawerTitle="Lead Details"
          size="lg"
          className="lead_details"
        >
          <LeadDetails
            _id={leadId || ""}
            name={selectedLead?.name || ""}
            integration={selectedLead?.integration || ""}
            customSource={selectedLead?.customSource || ""}
            createdAt={selectedLead?.createdAt || new Date()}
            status={selectedLead?.status || []}
            saleValue={selectedLead?.saleValue || ""}
            website={selectedLead?.website || ""}
            label={selectedLead?.label || []}
            deleteLeadFromDrawer={deleteLeadFromDrawer}
            copyLeadToList={copyLeadToList}
            moveLeadToList={moveLeadToList}
            onLabelClick={onLabelClick}
            onStatusClick={onStatusClick}
            setFrom={setFrom}
            handleEditModal={handleEditActivityModal}
            handleLeadDetailsModalOpen={handleActivityModal}
            onLeadFileUpload={onLeadFileUpload}
            editLead={editLead}
            leadFileDelete={leadFileDeleteToggle}
          />
        </DrawerComponent>
      </div>
    );
  }
);

export default LeadMenu;
