import React, { useState } from "react";
import * as yup from "yup";
import update from "immutability-helper";
// import { updateProfile } from '../../services/authServices';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config/config";
import axios from "axios";
export interface SignupFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;
  companyName: string;
  category: string;
  teamSize: string;
}

const Signup: React.FC = () => {
  const [signupData, setSignupData] = useState<SignupFormData>({
    firstName: "",
    lastName: "",
    email: localStorage.getItem("email") || "",
    phone: localStorage.getItem("phone") || "",
    countryCode: localStorage.getItem("countryCode") || "",
    companyName: "",
    category: "",
    teamSize: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    category: "",
    teamSize: "",
  });
  console.log("errors", errors);
  const navigate = useNavigate();
  const regExp = /\b\d{10}\b/;

  let schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    email: yup.string().email("Email is invalid").required("Email is required"),
    phone: yup
      .string()
      .required("Mobile Number is Required")
      .matches(/^\d{10}$/, "Mobile Number must be 10 digits"),
    companyName: yup.string().required("Company Name is required"),
    teamSize: yup.string().required("Team size is required"),
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setSignupData({
      ...signupData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isFormValid = await schema.isValid(signupData, {
      abortEarly: false, // Prevent aborting validation after first error
    });

    if (isFormValid) {
      try {
        const API_ENDPOINT = API_URL + "api/v1";
        let data = {
          profile: {
            firstName: signupData?.firstName,
            // lastName: signupData?.lastName,
            countryCode: "+91",
            phone: signupData?.phone,
            email: signupData?.email,
          },
          organization: {
            name: signupData?.companyName,
            teamSize: signupData?.teamSize,
          },
        };
        let localData: any = localStorage.getItem("userData");

        let header = {
          headers: {
            Authorization: JSON.parse(localData)?.token,
          },
        };

        axios
          .post(`${API_ENDPOINT}/user/onboard`, data, header)
          .then((response) => {
            console.log("response", response);
            toast.success(response?.data?.message);
            // navigate("/dashboard", { replace: true });
            navigate("/leads", { replace: true });
          })
          .catch((err) => {
            console.log("error", err);
            toast.error(err?.response?.data?.message);
          });
        // const response = await updateProfile({
        //     firstName: signupData?.firstName,
        //     lastName: signupData?.lastName,
        //     phone: signupData?.phone,
        //     email: signupData?.email
        // });
        // if (response && response.status) {
        //     toast.success(response?.data?.message);
        //     navigate("/dashboard", { replace: true });
        // }
      } catch (err) {
        toast.error("error while updating profile!");
      }
      // localStorage.removeItem('email');
    } else {
      schema.validate(signupData, { abortEarly: false }).catch((err) => {
        const errors = err.inner.reduce(
          (acc: any, error: { path: string; message: string }) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          },
          {}
        );

        setErrors((prevErrors) =>
          update(prevErrors, {
            $set: errors,
          })
        );
      });
    }
  };

  return (
    <div className="container">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="row justify-content-center login_section sign_up">
        <div className="col-12 col-md-8 col-lg-6 mt-2 login_card">
          <div className="login-card card">
            <div className="card-body">
              <div className="login-header text-center mt-3">
                <h2>Get started with 3sigma CRM</h2>
                <p>Please complete your profile to continue</p>
              </div>
              <form onSubmit={(e) => handleSubmit(e)} className="signup_form">
              <div className="form-group mb-3">
  <label htmlFor="firstName" className="form-label">Name</label>
  <input
    id="firstName"
    name="firstName"
    className="form-control"
    type="text"
    placeholder="Enter your name"
    onChange={(val) => handleChange(val)}
    value={signupData?.firstName}
  />
  {errors.firstName && <small className="text-danger d-block mt-1">{errors?.firstName}</small>}
</div>
                {localStorage.getItem("phone") && (
                  <div className="form-group mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                      id="email"
                      name="email"
                      className="form-control"
                      type="email"
                      placeholder="Enter your email"
                      onChange={(val) => handleChange(val)}
                      value={signupData?.email}
                    />
                    {errors.email && (
                      <small className="text-danger">{errors?.email}</small>
                    )}
                  </div>
                )}
                {localStorage.getItem("email") && (
                  <div className="form-group mb-3">
                    <label htmlFor="phone" className="form-label">Mobile Number</label>
                    <input
                      id="phone"
                      name="phone"
                      className="form-control"
                      type="tel"
                      placeholder="Enter your mobile number"
                      onChange={(val) => handleChange(val)}
                      value={signupData?.phone}
                    />
                    {errors.phone && (
                      <small className="text-danger">{errors?.phone}</small>
                    )}
                  </div>
                )}
                <div className="form-group mb-3">
                  <label htmlFor="companyName" className="form-label">Organisation name</label>
                  <input
                    id="companyName"
                    name="companyName"
                    className="form-control"
                    type="text"
                    placeholder="Enter your company name"
                    onChange={(val) => handleChange(val)}
                    value={signupData?.companyName}
                  />
                  {errors.companyName && (
                    <small className="text-danger">{errors?.companyName}</small>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="teamSize" className="form-label">Team size</label>
                  <select
                    id="teamSize"
                    name="teamSize"
                    className="form-control"
                    onChange={(val) => handleChange(val)}
                    value={signupData?.teamSize}
                  >
                    <option value="">Select team size</option>
                    <option value="1">Just me</option>
                    <option value="5">2-5</option>
                    <option value="10">6-10</option>
                    <option value="20">10-20</option>
                    <option value="50">20+</option>
                  </select>
                  {errors.teamSize && (
                    <small className="text-danger">{errors?.teamSize}</small>
                  )}
                </div>
                <button
  type="submit"
  className="btn btn-primary w-100 mt-4 py-2"
  style={{ backgroundColor: "#3A4B86" }}
>
  Let's Go <i className="bi bi-arrow-right-circle-fill ms-2"></i>
</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
