import moment from "moment";

export const getMomentDate = (date: Date | string, format?: string): string => {
  return moment(date).format(format ?? "DD/MM/YYYY");
};
export const getDateRange = (value: string): { from: string; to: string } => {
  const toDate: Date = new Date();
  let fromDate: Date = new Date();

  if (value === "today") {
    fromDate = toDate;
  } else if (value === "yesterday") {
    fromDate = new Date(toDate);
    fromDate.setDate(toDate.getDate() - 1);
  } else if (value.includes("d")) {
    const days: number = parseInt(value);
    fromDate = new Date(toDate);
    fromDate.setDate(toDate.getDate() - days);
  }

  return {
    from: fromDate.toISOString(),
    to: toDate.toISOString(),
  };
};
