import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import "./whatsappBroadcast.css";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { getBroadCasts, getCampaignById } from "../../services/broadcast";
import Header from "../../components/header";
import moment from "moment";
import { TableHeaders } from "./broadeCastGrid/broadCast.types";
import FacebookIntegration from "../../components/FacebookIntegration";
import { useSelector } from "react-redux";
import UltimatePlanInfo from "../../components/UltimatePlanInfo/UltimatePlanInfo";
import DrawerComponent from "../../components/drawer";
import CampaignForm, { CampaignFormData } from "./campaignForm";
const PER_PAGE = 20;

const WhatsappBroadcast: React.FC = () => {
  const [showFbButton, isShowFbButton] = useState(false);
  const [page, setPage] = useState(1);
  const [activeTab, setActvieTab] = useState("history");
  const [broadcast, setBroadcast] = useState<TableHeaders[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [messagingLimitTier, setMessagingLimitTier] = useState("");
  const [numericMessagingLimit, setNumericMessagingLimit] = useState("");
  const [mailLimit, setMailLimit] = useState("");

  const [isDetail, setIsDetail] = useState(false);
  const [formDetail, setFormDetail] = useState<any>({});

  const { userDetails } = useSelector((state: any) => {
    return state?.rootReducers.userData;
  });

  const [gridApi, setGridApi] = useState<any>(null);
  const [userdata, setUserdata] = useState<any>({});

  useEffect(() => {
    let local: any = window.localStorage?.getItem("userData");
    if (local) {
      setUserdata(JSON.parse(local));
    }
  }, []);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const fetchTemplated = async (page: any) => {
    if (broadcast.length >= PER_PAGE * page) {
      return;
    }
    setLoading(true);
    const { data } = await getBroadCasts({
      page,
      perPage: PER_PAGE,
    });

    setTotal(data.total);
    setBroadcast(data.data);
    setLoading(false);
  };

  const handleEdit = (id: any) => {
    navigate(`/editWhatsappBroadcast/${id}`);
  };

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: async (params: any) => {
          const page = params.endRow / PER_PAGE;

          try {
            const res = await getBroadCasts({
              page,
              perPage: PER_PAGE,
            });
            let mailLimit = res?.data?.dailyMailTracker?.reduce(
              (acc: number, i: any) => acc + i.todayRemainingLimit,
              0
            );

            setMailLimit(mailLimit);

            const meassage = res.data.messagingLimitTier;
            const numericLimit = meassage ? meassage.split("_")[1] : "";
            // setMessagingLimitTier(res.data.messagingLimitTier);
            setMessagingLimitTier(meassage);
            setNumericMessagingLimit(numericLimit);
            setTotal(res?.data.total);

            const temp = res.data?.data?.length
              ? res.data?.data.map((ele: any) => {
                  return {
                    id: ele?._id,
                    name: ele?.name,
                    scheduleAt: ele?.scheduleAt
                      ? moment(ele?.scheduleAt).format("Do MMM YY HH:mm A")
                      : "-",
                    totalQueued: ele?.totalQueued,
                    totalProcessed: ele?.totalProcessed,
                    type: ele?.serviceType,
                    createdBy: ele?.createdBy,
                    status: ele?.status,
                  };

                  // Log the 'scheduleAt' value for each entry
                  const formattedScheduleAt = ele?.scheduleAt
                    ? moment(ele?.scheduleAt).format("Do MMM YY HH:mm A")
                    : "-";

                  console.log(
                    `User: ${ele?.name}, Scheduled At: ${formattedScheduleAt}`
                  );
                })
              : [];
            params.successCallback(temp, res?.data?.total);
          } catch (e) {
            params.successCallback([], 0);
          }
        },
      };

      gridApi.setDatasource(dataSource);
    }
  }, [gridApi]);

  const columnDefs: ColDef<any>[] = [
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      filter: true,
      width: 200,
      cellRenderer: ({ data }: any) => {
        return <div className="broadcast_type_container">{data?.name}</div>;
      },
    },
    {
      headerName: "Scheduled At",
      field: "scheduleAt",
      sortable: true,
      filter: true,
      width: 150,
    },
    {
      headerName: "Recipients",
      field: "totalQueued",
      sortable: true,
      filter: true,
      width: 150,
    },
    {
      headerName: "Sent to",
      field: "totalProcessed",
      sortable: true,
      filter: true,
      width: 150,
    },
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      filter: true,
      width: 150,
      cellRenderer: ({ data }: any) => {
        return (
          <div className="broadcast_type_container">
            {data?.type?.toLowerCase() === "email" ? (
              <img alt="mail" src={"assets/img/email.png"} />
            ) : (
              <img alt="mail" src={"assets/img/whatsapp.png"} />
            )}
            {data?.type}
          </div>
        );
      },
    },
    {
      headerName: "Created by",
      field: "createdBy",
      sortable: true,
      filter: true,
      width: 150,
      cellRenderer: ({ data }: any) => {
        let userName = userDetails?.organizationEmployee?.find(
          (e: any) => e._id === data?.createdBy
        );
        return (
          <div className="broadcast_type_container">
            {userName?.firstName} {userName?.lastName ?? ""}
          </div>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: true,
      width: 150,
      cellRenderer: ({ data }: any) => {
        let color =
          (
            {
              pending: "#F7CB73",
              cancelled: "#E32C2C",
              approved: "#4CB050",
              completed: "#3FAEFD",
              rejected: "#FF4747",
            } as { [key: string]: string }
          )[data?.status?.toLowerCase() as string] || "green";

        return (
          <div
            className="broadcast_status_container"
            style={{ backgroundColor: color }}
          >
            {data?.status}
          </div>
        );
      },
    },
    // {
    //   headerName: "Action",
    //   field: "action",
    //   width: 150,
    //   cellRenderer: (params: any) => {
    //     return (
    //       <div className="action">
    //         <MdModeEdit
    //           size={18}
    //           className="editData"
    //           onClick={() => handleEdit(params?.data?.id)}
    //           color="#3faefc"
    //         />
    //         <MdDelete
    //           style={{ marginLeft: 20 }}
    //           className="deleteData"
    //           size={18}
    //           color="#E32C2C"
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    const userIntigrationsList = JSON.parse(
      localStorage.getItem("userData") || ""
    ).userIntegrations;

    const findData = userIntigrationsList.find(
      (item: any) =>
        item?.integration?._id === "646b9286ae8ef73b33caec2e" && item?.isActive
    );

    if (!findData) isShowFbButton(true);
  }, []);

  const rowData = useMemo(() => broadcast, [broadcast]);

  if (userdata?.subscriptionPlanType?.toLowerCase() !== "ultimate") {
    return (
      <div id="main" className="main">
        <Header />
        <UltimatePlanInfo />
      </div>
    );
  }

  const onRowClicked = async (row: any) => {
    if (!row?.data?.id) return;

    try {
      const { data } = await getCampaignById(row?.data?.id);
      if (data?.data) {
        setFormDetail(data?.data);
        setIsDetail(true);
      }
    } catch (error) {}
  };

  return (
    <div id="main" className="main px-2">
      <Header />
      {showFbButton ? (
        <FacebookIntegration onSuccess={() => isShowFbButton(false)} />
      ) : (
        <div className="whatsapp-broadcast" style={{ padding: "0 15px" }}>
          <section className="auto-section-1 px-0">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="align-items-center auto-section-1-sub-1">
                <label>Campaigns ({total})</label>
              </div>
              <div className="d-flex align-items-center ms-auto">
                <p className="messaging-limit me-3">
                  Messaging limit: {numericMessagingLimit} new conversations /
                  24 hours
                </p>
                <NavLink
                  className="btn fw-bold auto_btn w-auto"
                  to="/createWhatsappBroadcast"
                  style={{ fontSize: 14 }}
                >
                  <img
                    src="./assets/img/send-white.png"
                    style={{ objectFit: "contain", height: 16, width: 16 }}
                  />{" "}
                  New Campaigns
                </NavLink>
              </div>
            </div>
          </section>
          <Row>
            <Col>
              <div
                className="followups-section-1 ag-theme-alpine"
                style={{
                  height: "75vh",
                  width: "100%",
                }}
              >
                <AgGridReact
                  onRowClicked={onRowClicked}
                  columnDefs={columnDefs}
                  pagination={true}
                  paginationPageSize={PER_PAGE}
                  rowModelType={"infinite"}
                  cacheBlockSize={PER_PAGE}
                  defaultColDef={{ flex: 1 }}
                  onGridReady={onGridReady}
                />
              </div>

              {/* {total > page * PER_PAGE && (
                <div className="d-flex align-items-center justify-content-between w-100 mt-4">
                  <button
                    className="btn fw-bold auto_btn"
                    style={{ background: "#3FAEFD" }}
                    type="button"
                    onClick={() => {
                      setPage((prev) => prev - 1);
                    }}
                    disabled={page === 1}
                  >
                    <i className="fa fa-chevron-left"></i>&nbsp;Previous{" "}
                  </button>
                  <button
                    className="btn fw-bold auto_btn"
                    style={{ background: "#3FAEFD" }}
                    type="button"
                    onClick={() => {
                      setPage((prev) => prev + 1);
                    }}
                    disabled={page >= Math.ceil(total / PER_PAGE)}
                  >
                    Next &nbsp;<i className="fa fa-chevron-right"></i>
                  </button>
                </div>
              )} */}
            </Col>
          </Row>
        </div>
      )}

      <DrawerComponent
        openWithHeader={isDetail}
        setOpenWithHeader={setIsDetail}
        drawerTitle="Campaign Form"
        size="lg"
      >
        <CampaignForm formDetail={formDetail} />
      </DrawerComponent>
    </div>
  );
};

export default WhatsappBroadcast;
