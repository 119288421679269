import { io, Socket } from "socket.io-client";
import { SOCKET_API_URL } from "../config/config";

// Enhanced interfaces to match all BE events
interface ServerToClientEvents {
  message: (data: {
    createdAt: string;
    message: {
      from: string;
      id: string;
      timestamp: string;
      text: { body: string };
      type: string;
    };
    username: string;
  }) => void;
  roomData: (data: {
    room: string;
    users: { id: string; username: string; room: string }[];
  }) => void;
  error: (data: { message: string }) => void;
  joined: (message: { username: string; text: string; createdAt: number }) => void;
  leaved: (message: { username: string; text: string; createdAt: number }) => void;
  disconnected: (message: { username: string; text: string; createdAt: number }) => void;
  // Add the new event types
  conversationList: (data: any) => void; // Replace 'any' with your actual conversation list type
  messageList: (data: any) => void;
  messageDelivered: (data: any) => void;
  messageRead: (data: any) => void;
  newMessage: (data: any) => void;
  typing: (data: { userId: string; typing: boolean }) => void;
}

interface ClientToServerEvents {
  join: (
    options: { username: string; room: string },
    callback: (error?: string) => void
  ) => void;
  sendMessage: (message: string, callback: () => void) => void;
  "leave-room": (callback: () => void) => void;
  // Add client-to-server events if needed
  getConversations: () => void;
  getMessages: (conversationId: string) => void;
  markAsRead: (messageId: string) => void;
  startTyping: (conversationId: string) => void;
  stopTyping: (conversationId: string) => void;
}

// Enhanced Error interface for Socket.IO errors
interface SocketError extends Error {
  type?: string;
  description?: string;
}

// Custom logger
const socketLogger = {
  info: (event: string, data?: any) => {
    console.log(
      `%c[Socket][${event}]`,
      'color: #2196F3; font-weight: bold;',
      data || ''
    );
  },
  error: (event: string, error?: any) => {
    console.error(
      `%c[Socket][${event}][ERROR]`,
      'color: #f44336; font-weight: bold;',
      error || ''
    );
  },
  warn: (event: string, data?: any) => {
    console.warn(
      `%c[Socket][${event}][WARN]`,
      'color: #ff9800; font-weight: bold;',
      data || ''
    );
  },
  event: (direction: 'IN' | 'OUT', event: string, data?: any) => {
    console.log(
      `%c[Socket][${direction}][${event}]`,
      `color: ${direction === 'IN' ? '#4CAF50' : '#9C27B0'}; font-weight: bold;`,
      data || ''
    );
  }
};

const getValidToken = () => {
  const token = localStorage.getItem("auth_token");
  socketLogger.info('Token Check', { hasToken: !!token });
  if (!token) return "";
  const [bearer, actualToken] = token.split(" ");
  if (bearer !== "Bearer" || !actualToken) {
    socketLogger.warn('Invalid Token Format');
    return "";
  }
  return actualToken;
};

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(SOCKET_API_URL, {
  reconnectionDelayMax: 10000,
  query: {
    path: "/",
    token: getValidToken(),
  },
  transports: ["websocket"],
});


// Connection event handlers
socket.on("connect", () => {
  socketLogger.info('Connected', {
    id: socket.id,
    connected: socket.connected,
  });
});

socket.on("connect_error", (error: SocketError) => {
  socketLogger.error('Connection Error', {
    error: error.message,
    type: error.type || 'Unknown',
    description: error.description || 'No description available',
  });
});

socket.io.on("error", (error: Error) => {
  socketLogger.error('Socket Error', error.message);
});

socket.on("disconnect", (reason) => {
  socketLogger.warn('Disconnected', {
    reason,
    id: socket.id,
    connected: socket.connected,
  });
});

// Server event handlers
socket.on("error", (data) => {
  socketLogger.error('Server Error', data);
});

socket.on("joined", (message) => {
  socketLogger.event('IN', 'joined', message);
});

socket.on("leaved", (message) => {
  socketLogger.event('IN', 'leaved', message);
});

socket.on("disconnected", (message) => {
  socketLogger.event('IN', 'disconnected', message);
});

socket.on("message", (data) => {
  socketLogger.event('IN', 'message', data);
});

socket.on("roomData", (data) => {
  socketLogger.event('IN', 'roomData', data);
});

// Client functions with enhanced logging
const disconnectRoom = () => {
  socketLogger.event('OUT', 'leave-room');
  socket.emit("leave-room", (error?: string) => {
    if (error) {
      socketLogger.error('Disconnect', error);
      alert(error);
      return;
    }
    socketLogger.info('Room Left Successfully');
  });
};

const joinRoom = (username: string, room: string): void => {
  socketLogger.event('OUT', 'join', { username, room });
  socket.emit("join", { username, room }, (error?: string) => {
    if (error) {
      socketLogger.error('Join', error);
      alert(error);
      return;
    }
    socketLogger.info('Room Joined Successfully', { username, room });
  });
};

const sendMessageFun = (message: string): void => {
  socketLogger.event('OUT', 'sendMessage', { message });
  socket.emit("sendMessage", message, () => {
    socketLogger.info('Message Sent', { message });
  });
};
const getConversations = () => {
  socketLogger.event('OUT', 'getConversations');
  socket.emit('getConversations');
};
const getMessages = (conversationId: string) => {
  socketLogger.event('OUT', 'getMessages', { conversationId });
  socket.emit('getMessages', conversationId);
};

const markAsRead = (messageId: string) => {
  socketLogger.event('OUT', 'markAsRead', { messageId });
  socket.emit('markAsRead', messageId);
};

const startTyping = (conversationId: string) => {
  socketLogger.event('OUT', 'startTyping', { conversationId });
  socket.emit('startTyping', conversationId);
};

const stopTyping = (conversationId: string) => {
  socketLogger.event('OUT', 'stopTyping', { conversationId });
  socket.emit('stopTyping', conversationId);
};
const onMessage = (
  callback: (data: {
    createdAt: string;
    message: {
      from: string;
      id: string;
      timestamp: string;
      text: { body: string };
      type: string;
    };
    username: string;
  }) => void
): void => {
  socket.on("message", (data) => {
    socketLogger.event('IN', 'message', data);
    callback(data);
  });
};

// Debug helper to check socket state
const getSocketState = () => ({
  id: socket.id,
  connected: socket.connected,
  disconnected: socket.disconnected,
  active: socket.active,
  listeners: {
    connect: socket.listeners('connect').length,
    disconnect: socket.listeners('disconnect').length,
    message: socket.listeners('message').length,
  }
});

// Monitor reconnection attempts
socket.io.on("reconnect_attempt", (attempt) => {
  socketLogger.warn('Reconnection Attempt', { attempt });
});

socket.io.on("reconnect", (attempt) => {
  socketLogger.info('Reconnected', { attempt });
});

socket.io.on("reconnect_error", (error: Error) => {
  socketLogger.error('Reconnection Error', error.message);
});

socket.io.on("reconnect_failed", () => {
  socketLogger.error('Reconnection Failed');
});

export { 
  socket, 
  joinRoom, 
  sendMessageFun, 
  onMessage, 
  disconnectRoom,
  getSocketState,
  socketLogger,
  // New exports
  getConversations,
  getMessages,
  markAsRead,
  startTyping,
  stopTyping
};