import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/header";
import toast, { Toaster } from "react-hot-toast";
import MessagesBox from "./MessagesBox";
import styled from "styled-components";
import {
  createNewContent,
  getAllContent,
  updateContent,
} from "../../services/contentService";
import GlobalDrawer from "../../components/GlobalDrawer";
import { useSelector } from "react-redux";
import { deleteLeadFile, uploadFiles } from "../../services/utilityService";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import MapField from "./MapField";
import InputMaps from "../../components/InputMaps";
import { Spin } from "antd";
import ConfirmationModal from "../../components/confirmationModal";
import EmailDashboard from "./EmailDashboard";

interface ContentItem {
  name: string;
  value: string;
}

interface EmailData {
  title: string;
  tags: string[];
  subject: string;
  message: string;
  type?: string; // Add this line
}
export interface ContentI {
  _id: string;
  type: string;
  details: {
    title: string;
    tags: string[];
    description?: string;
    message?: string;
    files?: any;
    subject?: string;
    html?: string;
  };
  viewCount: number;
}
interface Tag {
  name: string;
  value: string;
}

const Content: React.FC = () => {
  interface MessageData {
    title: string;
    tags: string[];
    message: string;
  }

  const [activeTab, setActiveTab] = useState<string>("page");
  const [openMessageBox, setOpenMessageBox] = useState<boolean>(false);
  const [openPageBox, setOpenPageBox] = useState<boolean>(false);
  const [openFileBox, setOpenFileBox] = useState<boolean>(false);
  const [openEmailBox, setOpenEmailBox] = useState<boolean>(false);
  const [contentData, setContentData] = useState<Array<ContentI>>([]);
  const [messageTag, setMessageTags] = useState([]);
  const [isPageLoad, setIsPageLoad] = useState<boolean>(false);
  const [messageData, setMessageData] = useState<any>({
    title: "",
    tags: ["no_tag"],
    message: "",
  });
  const [emailData, setEmailData] = useState<ContentI["details"]>({
    title: "",
    tags: ["no_tag"],
    subject: "",
    message: "",
    html: "",
  });
  const [fileData, setFileData] = useState<any>({
    title: "",
    tags: ["no_tag"],
    files: [],
    description: "",
  });
  const [pageData, setPageData] = useState<any>({
    title: "",
    tags: ["no_tag"],
    description: "",
    videoUrl: "",
    location: {},
    address: "",
    files: [],
  });

  const [activeId, setActiveId] = useState("");
  const [mapAddress, setMapAddress] = useState("");
  const [mapLocation, setMapLocation] = useState({});
  const fetchContent = async () => {
    const data = {
      isAscending: false,
      page: 1,
      perPage: 500,
    };
    const response = await getAllContent(data);
    if (response && response.status) {
      setContentData(response?.data?.data);
    }
  };

  useEffect(() => {
    fetchContent();
    let userPreferences = JSON.parse(
      localStorage.getItem("user_preferences") || ""
    );
    setMessageTags(userPreferences.messageTags);
  }, []);

  const filterData = contentData.filter((item) => item.type === activeTab);

  const handleMessageForm = (key: string, value: string) => {
    if (key == "tags") {
      setMessageData({ ...messageData, [key]: [value] });
    } else {
      setMessageData({ ...messageData, [key]: value });
    }
  };

  const handleEmailForm = (key: string, value: string | string[]) => {
    if (key === "tags") {
      setEmailData({
        ...emailData,
        [key]: Array.isArray(value) ? value : [value],
      });
    } else {
      setEmailData({ ...emailData, [key]: value });
    }
  };

  const handleFileForm = (key: string, value: any) => {
    if (key == "tags") {
      setFileData({ ...fileData, [key]: [value] });
    } else if (key === "files") {
      setFileData({
        ...fileData,
        [key]: [...fileData.files, value.target.files[0]],
      });
    } else {
      setFileData({ ...fileData, [key]: value });
    }
  };

  const handlePageForm = (key: string, value: any) => {
    console.log("address =======>", key, value);
    if (key == "tags") {
      setPageData({ ...pageData, [key]: [value] });
    } else if (key === "files") {
      setPageData({
        ...pageData,
        [key]: [...pageData.files, value.target.files[0]],
      });
    } else {
      setPageData({ ...pageData, [key]: value });
    }
  };
  const addLeadName = () => {
    if (openMessageBox) {
      setMessageData({
        ...messageData,
        message: messageData.message + `@lead name`,
      });
    } else if (openPageBox) {
      setPageData({
        ...pageData,
        description: pageData.description + `@lead name`,
      });
    } else if (openEmailBox) {
      setEmailData({ ...emailData, message: emailData.message + `@lead name` });
    }
  };

  const deleteFile = (key: any, index: number) => {
    if (key == "file") {
      const newData = fileData.files.filter(
        (_: any, ix: number) => ix !== index
      );
      setFileData({ ...fileData, files: newData });
    } else if (key == "page") {
      const newData = pageData.files.filter(
        (_: any, ix: number) => ix !== index
      );
      setPageData({ ...pageData, files: newData });
    }
  };

  const getEmbeddedCode = (link: any) => {
    return link;
  };

  // API CALL
  const handleFormSubmit = async (data: any): Promise<any> => {
    setIsPageLoad(true);
    try {
      let response: any;
      let contentType = data.type || activeTab;
      let contentData = data.details || data;

      if (contentType === "email_template") {
        contentData = {
          title: contentData.title,
          tags: contentData.tags,
          subject: contentData.subject,
          message: contentData.message,
        };
      }

      if (activeId) {
        response = await updateContent({ details: contentData }, activeId);
      } else {
        response = await createNewContent({
          details: contentData,
          type: contentType,
        });
      }

      if (response.status === 200) {
        setContentData((prevData) => {
          if (activeId) {
            return prevData.map((item) =>
              item._id === activeId ? response.data.data : item
            );
          }
          return [response.data.data, ...prevData];
        });

        closeAllDrawers();
        clearData();
        return response.data;
      }
    } catch (err) {
      console.error(`Error saving ${activeTab}:`, err);
      throw err;
    } finally {
      setIsPageLoad(false);
    }
  };

  const closeAllDrawers = () => {
    setOpenMessageBox(false);
    setOpenPageBox(false);
    setOpenFileBox(false);
    setOpenEmailBox(false);
  };

  const handleFileUpload = async (id: any, file: any) => {
    if (!file.name) {
      return;
    }
    try {
      let formData = new FormData();
      formData.append("files", file);
      formData.append("type", "content");
      formData.append("content", id);
      const response = await uploadFiles(formData);
      if (response.status == 200) {
        setIsPageLoad(false);
        toast.success(response.data.message);
        return response.data.data;
      }
    } catch (err) {
      console.log("err =>", err);
      return;
    }
  };

  const deleteFileApi = async (path: any) => {
    setIsPageLoad(true);
    try {
      let obj = {
        filePaths: [path],
        type: "content",
        content: activeId,
      };
      const response = await deleteLeadFile(obj);
      if (response.status == 200) {
        toast.success(response.data.message);
        const filteredArray = contentData.map((obj: any) => {
          if (obj._id === activeId) {
            return {
              ...obj,
              files: obj.files?.filter((file: any) => file.filePath !== path),
            };
          } else {
            return { ...obj };
          }
        });
        setContentData([...filteredArray]);
        setOpenPageBox(false);
        setOpenFileBox(false);
        setIsPageLoad(false);

        return;
      }
      setIsPageLoad(false);
    } catch (err) {
      setIsPageLoad(false);
      console.log("err =>", err);
      return;
    }
  };

  const setFileCard = (data: any) => {
    let updateData = {
      title: data.details.title,
      tags: data.details.tags,
      description: data.details.description,
      files: data.files,
    };
    setFileData(updateData);
  };
  const setPageCard = (data: any) => {
    let updateData = {
      title: data.details?.title,
      tags: data.details?.tags,
      description: data?.details?.description,
      videoUrl: data.details?.videoUrl,
      location: data.details?.location,
      address: data.details?.address,
      files: data.files,
    };
    setPageData(updateData);
  };
  // const setMessageCard = (data:any)=>{
  //   if(data){
  //     console.log('datdatadataa =====>',data)
  //     const newData = {
  //       title: data.title,
  //       tags: data.tags,
  //       message: data.message,
  //       }
  //     setMessageData(newData)
  //     setOpenMessageBox(true)
  //   }

  // }

  // UI Component
  const filesDesign = (data: ContentI) => {
    return (
      <div
        className="message_box"
        onClick={() => {
          setOpenFileBox(true);
          setFileCard(data);
          setActiveId(data._id);
        }}
      >
        <div>
          <h4 className="title">{data?.details.title}</h4>
          <div className="tags">
            {/* {data?.details?.tags?.map((tag: string) => (
              <span>{tag.replace(/_/g, " ")}</span>
            ))} */}

            {Array.isArray(data?.details?.tags) &&
              data.details.tags.map((tag: string) => (
                <span>{tag.replace(/_/g, " ")}</span>
              ))}
          </div>
        </div>

        <div>
          <p className="description">
            {data.details?.description?.replace(
              /\n @lead name \n/g,
              "Lead Name"
            )}
          </p>
          <div className="view_count">Viewed {data?.viewCount || 0} times</div>
        </div>
      </div>
    );
  };

  const messageDesign = (data: ContentI) => {
    return (
      <div
        className="message_box"
        onClick={() => {
          setOpenMessageBox(true);
          setMessageData(data?.details);
          setActiveId(data._id);
        }}
      >
        <div>
          <h4 className="title">{data.details?.title}</h4>
          <div className="tags">
            {data.details?.tags?.map((tag: string) => (
              <span>{tag.replace(/_/g, " ")}</span>
            ))}
          </div>
        </div>

        <div>
          <p className="description">
            {data.details?.message?.replace(/\n @lead name \n/g, "Lead Name")}
          </p>
          <div className="view_count">Viewed {data?.viewCount || 0} times</div>
        </div>
      </div>
    );
  };

  const pageDesign = (data: ContentI) => {
    return (
      <div
        className="message_box"
        onClick={() => {
          setOpenPageBox(true);
          setPageCard(data);
          setActiveId(data._id);
        }}
      >
        <div>
          <h4 className="title">{data.details.title}</h4>
          <div className="tags">
            {data.details?.tags?.map((tag: string) => (
              <span>{tag.replace(/_/g, " ")}</span>
            ))}
          </div>
        </div>

        <div>
          <p className="description">
            {data.details.description?.replace(
              /\n @lead name \n/g,
              "Lead Name"
            )}
          </p>
          <div className="view_count">Viewed {data?.viewCount || 0} times</div>
        </div>
      </div>
    );
  };

  const emailDesign = (data: any) => {
    console.log("emailDesign ====== ui =====>", data);
    return (
      <div
        className="message_box"
        onClick={() => {
          setOpenEmailBox(true);
          setEmailData(data.details);
          setActiveId(data._id);
        }}
      >
        <div>
          <h4 className="title">{data.details?.title}</h4>
          <div className="tags">
            {data.details?.tags?.map((tag: string) => (
              <span>{tag.replace(/_/g, " ")}</span>
            ))}
          </div>
        </div>

        <div>
          <p className="description">Subject : {data.details.subject}</p>
          {/* <div className="view_count">Viewed {data.viewCount} times</div> */}
        </div>
        <p className="description">Body : {data.details.message}</p>
      </div>
    );
  };

  const clearData = () => {
    setActiveId("");
    setFileData({
      title: "",
      tags: ["no_tag"],
      files: [],
      description: "",
    });
    setEmailData({
      title: "",
      tags: ["no_tag"],
      subject: "",
      message: "",
    });
    setMessageData({
      title: "",
      tags: ["no_tag"],
      message: "",
    });
    setPageData({
      title: "",
      tags: ["no_tag"],
      description: "",
      videoUrl: "",
      location: {},
      address: "",
    });
  };
  console.log("messageData ===>", messageData);
  return (
    <Spin size="large" tip="Loading..." spinning={isPageLoad}>
      <Root id="main" className="main content_page">
        <Header />
        <div className="action_tabs">
          <button
            className={activeTab === "page" ? "active" : ""}
            onClick={() => {
              setActiveTab("page");
            }}
          >
            Pages
          </button>
          <button
            className={activeTab === "file" ? "active" : ""}
            onClick={() => {
              setActiveTab("file");
            }}
          >
            Files
          </button>
          <button
            className={activeTab === "message" ? "active" : ""}
            onClick={() => {
              setActiveTab("message");
            }}
          >
            Messages
          </button>
          <button
            className={activeTab === "email_template" ? "active" : ""}
            onClick={() => {
              setActiveTab("email_template");
            }}
          >
            Email
          </button>
        </div>
        <button
          className="add_button"
          onClick={() => {
            if (activeTab === "message") {
              setOpenMessageBox(true);
            } else if (activeTab === "page") {
              setOpenPageBox(true);
            } else if (activeTab === "file") {
              setOpenFileBox(true);
            } else if (activeTab === "email_template") {
              setOpenEmailBox(true);
            }
          }}
        >
          Add {activeTab.replace(/_/g, " ")}
        </button>

        <div className="content_section">
          {contentData &&
            filterData.length > 0 &&
            (activeTab === "page"
              ? filterData.map(pageDesign)
              : activeTab === "file"
              ? filterData.map(filesDesign)
              : activeTab === "message"
              ? filterData.map(messageDesign)
              : activeTab === "email_template"
              ? filterData.map(emailDesign)
              : "")}
        </div>
        {filterData.length == 0 && (
          <h1 className="text-center" style={{ fontSize: "20px" }}>
            No Record Found
          </h1>
        )}
        {/* Dialog for add Message */}

        <GlobalDrawer
          openWithHeader={openMessageBox}
          setOpenWithHeader={(e) => {
            setOpenMessageBox(e);
            clearData();
          }}
          drawerTitle={activeId.length > 1 ? "Edit Message" : "Add Message"}
          size="xs"
        >
          <div className="message_user_form_box d-flex flex-column h-100">
            <div className="add_message content_form h-100">
              <div className="user_form_box">
                <label>Title </label>
                <input
                  type="text"
                  value={messageData?.title}
                  onChange={(e) => {
                    handleMessageForm("title", e.target.value);
                  }}
                />
              </div>
              <div className="user_form_box">
                <label>Tags </label>
                <div className="all_tags mt-2">
                  {messageTag.map((tag: any, ix: number) => {
                    return (
                      <span
                        key={ix}
                        onClick={() => handleMessageForm("tags", tag.value)}
                        className={
                          messageData?.tags.includes(tag.value) ? "active" : ""
                        }
                      >
                        {tag.name}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="user_form_box">
                <label>Message </label>
                <textarea
                  rows={4}
                  cols={50}
                  value={messageData?.message}
                  onChange={(e) => {
                    handleMessageForm("message", e.target.value);
                  }}
                />
              </div>
              <button className="add_lead_name" onClick={addLeadName}>
                Add @lead name
              </button>
            </div>

            <button
              className="mt-3 btn fw-bold auto_btn w-100"
              onClick={() => handleFormSubmit(messageData)}
              style={{ padding: "10px" }}
            >
              Save
            </button>
          </div>
        </GlobalDrawer>

        {/* Dialog for add page */}

        <GlobalDrawer
          openWithHeader={openPageBox}
          setOpenWithHeader={(e) => {
            setOpenPageBox(e);
            clearData();
          }}
          drawerTitle={activeId.length > 1 ? "Edit Page " : "Add Page"}
          size="xs"
        >
          <div className="message_user_form_box d-flex flex-column h-100">
            <div className="add_message content_form h-100">
              <div className="user_form_box">
                <label>Title </label>
                <input
                  type="text"
                  value={pageData.title}
                  onChange={(e) => {
                    handlePageForm("title", e.target.value);
                  }}
                />
              </div>
              <div className="user_form_box">
                <label>Tags </label>
                <div className="all_tags mt-2">
                  {messageTag.map((tag: any) => {
                    return (
                      <span
                        onClick={() => handlePageForm("tags", tag.value)}
                        className={
                          pageData.tags.includes(tag.value) ? "active" : ""
                        }
                      >
                        {tag.name}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="user_form_box">
                <label>Description </label>
                <textarea
                  rows={4}
                  cols={50}
                  value={pageData.description}
                  onChange={(e) => {
                    handlePageForm("description", e.target.value);
                  }}
                />
              </div>
              <button className="add_lead_name" onClick={addLeadName}>
                Add @lead name
              </button>
              <div className="user_form_box">
                <label>YouTube Embedded Videos Urls </label>
                <input
                  type="text"
                  placeholder="Add youtube video"
                  value={pageData.videoUrl}
                  onChange={(e) => {
                    handlePageForm("videoUrl", e.target.value);
                  }}
                />
              </div>

              <div className="user_form_box">
                <label>Add Map</label>
                <MapField
                  address={pageData?.address}
                  locationCord={pageData?.location}
                  setAddress={(e: any) => {
                    setMapAddress(e);
                  }}
                  setLocationCord={(e: any) => {
                    setMapLocation(e);
                  }}
                />
              </div>

              <div className="user_form_box">
                <label>Upload File</label>
                <div
                  className="file_upload position-relative mb-3 "
                  style={{ width: "58px" }}
                >
                  <input
                    type="file"
                    // value={pageData.title}
                    accept=".mp4, .jpeg, .img, .png, .mov, .jpg"
                    className="position-relative"
                    style={{ zIndex: "9", opacity: 0 }}
                    onChange={(e) => {
                      handlePageForm("files", e);
                    }}
                  />
                  <span
                    className="position-absolute w-100 h-100 top-0 d-flex align-items-center justify-content-center"
                    style={{
                      border: "2px dashed #3FAEFDD1",
                      fontSize: "44px",
                      color: "#60bafc",
                      borderRadius: "18px",
                    }}
                  >
                    <IoMdAdd />
                  </span>
                </div>
                {pageData?.files?.map((files: any, index: number) => (
                  <p className="file_name">
                    {files.fileName || files.name}{" "}
                    <button
                      onClick={() => {
                        files.fileName
                          ? deleteFileApi(files.filePath)
                          : deleteFile("page", index);
                      }}
                    >
                      <RiDeleteBin6Fill />
                    </button>
                  </p>
                ))}
              </div>
            </div>
            <button
              className="mt-3 btn fw-bold auto_btn w-100"
              onClick={() => handleFormSubmit(pageData)}
              style={{ padding: "10px" }}
            >
              Save
            </button>
          </div>
        </GlobalDrawer>

        {/* Dialog box for files */}

        <GlobalDrawer
          openWithHeader={openFileBox}
          setOpenWithHeader={(e) => {
            setOpenFileBox(e);
            clearData();
          }}
          drawerTitle={activeId.length > 1 ? "Edit File" : "Add File"}
          size="xs"
        >
          <div className="message_user_form_box d-flex flex-column h-100">
            <div className="add_message content_form h-100">
              <div className="user_form_box">
                <label>Title</label>
                <input
                  type="text"
                  value={fileData?.title}
                  onChange={(e) => {
                    handleFileForm("title", e.target.value);
                  }}
                />
              </div>
              <div className="user_form_box">
                <label>Tags </label>

                <div className="all_tags mt-2">
                  {messageTag.map((tag: any, ix: number) => {
                    return (
                      <span
                        key={ix}
                        onClick={() => handleFileForm("tags", tag.value)}
                        className={
                          fileData.tags.includes(tag.value) ? "active" : ""
                        }
                      >
                        {tag.name}
                      </span>
                    );
                  })}
                </div>
              </div>

              <div className="user_form_box">
                <label>Description </label>
                <textarea
                  rows={4}
                  cols={50}
                  value={fileData?.description}
                  onChange={(e) => {
                    handleFileForm("description", e.target.value);
                  }}
                />
              </div>

              <div className="user_form_box">
                <label>Upload File</label>
                <div
                  className="file_upload position-relative mb-3 "
                  style={{ width: "58px" }}
                >
                  <input
                    type="file"
                    // value={messageData?.title}
                    accept=".mp4, .jpeg, .img, .png, .mov, .jpg"
                    className="position-relative"
                    style={{ zIndex: "9", opacity: 0 }}
                    onChange={(e) => {
                      handleFileForm("files", e);
                    }}
                  />
                  <span
                    className="position-absolute w-100 h-100 top-0 d-flex align-items-center justify-content-center"
                    style={{
                      border: "2px dashed #3FAEFDD1",
                      fontSize: "44px",
                      color: "#60bafc",
                      borderRadius: "18px",
                    }}
                  >
                    <IoMdAdd />
                  </span>
                </div>
                {fileData?.files?.map((files: any, index: number) => (
                  <p className="file_name">
                    {files?.fileName || files?.name}{" "}
                    <button
                      onClick={() => {
                        files.fileName
                          ? deleteFileApi(files.filePath)
                          : deleteFile("file", index);
                      }}
                    >
                      <RiDeleteBin6Fill />
                    </button>
                  </p>
                ))}
              </div>
            </div>

            <button
              className="mt-3 btn fw-bold auto_btn w-100"
              style={{ padding: "10px" }}
              onClick={() => handleFormSubmit(fileData)}
            >
              Save
            </button>
          </div>
        </GlobalDrawer>

        {/* Dialog Box for add Email */}

        <EmailDashboard
          openEmailBox={openEmailBox}
          setOpenEmailBox={(value) => {
            setOpenEmailBox(value);
            if (!value) clearData();
          }}
          activeId={activeId}
          handleFormSubmit={handleFormSubmit}
          messageTag={messageTag}
          initialData={{
            title: emailData.title || "",
            tags: emailData.tags || ["no_tag"],
            subject: emailData.subject || "",
            message: emailData.message || "",
          }}
        />

        <Toaster position="top-right" reverseOrder={false} />
      </Root>
    </Spin>
  );
};

export default Content;
const Root = styled.section`
  font-family: "Gilroy-Bold";
  .action_tabs {
    background: #fff;
    padding: 8px;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    > button {
      background: #ebf0f4;
      border-radius: 10px;
      padding: 4px 24px;
      font-family: "Gilroy-Bold";
      color: #000;
      font-size: 18px;
      &.active {
        color: #fff;
        background: #3faefdd1;
      }
    }
  }
  .message_box {
    background-color: #fff;
    border-radius: 10px;
    padding: 6px 18px;
    width: 100%;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .tags {
      display: flex;
      gap: 6px;
      > span {
        background-color: #3faefdd1;
        padding: 4px 10px;
        border-radius: 10px;
        color: #ffffff;
        font-size: 16px;
        min-width: 80px;
        text-align: center;
      }
    }
    .description {
      color: #000000b0;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-family: "Gilroy-Bold";
    }
    .view_count {
      width: 200px;
      text-align: right;
      font-size: 16px;
      color: #000000;
    }
  }
  .add_button {
    background: #64bdfe;
    color: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 18px;
    display: block;
    margin-left: auto;
    position: fixed;
    bottom: 24px;
    right: 16px;
    z-index: 9;
  }
  .content_section {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 18px;
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  .content_form {
    overflow: scroll;
    padding: 2px;
    label {
      display: block;
      color: #000;
      font-size: 20px;
      font-weight: 600;
    }
    input,
    textarea {
      width: 100%;
      display: block;
      padding: 10px;
      border-radius: 10px;
      border: none;
      margin-top: 12px;
      color: #000;
      font-size: 16px;
      font-family: Gilroy-regular;
    }
    button.add_lead_name {
      background: none;
      border: none;
      color: #60bafc;
    }
    .user_form_box:first-child {
      margin-top: 0px;
    }
  }
  .user_form_box {
    margin-top: 20px;
    label {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .all_tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    span {
      cursor: pointer;
      background: #dee3e9;
      color: #000000d6;
      border-radius: 10px;
      padding: 8px 16px;
      display: block;
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
    }
    span.active {
      background: #3faefdd1;
      color: #fff;
    }
  }
`;
