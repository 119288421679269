import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Card, Button, Spinner, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Activity } from './types';
import './ActivityFeed.css';
import { getAllActivity } from '../../services/activityService';
import activityTypes, { getActivityTypeConfig } from './activityTypes';
import FilterComponent from '../../components/FilterBar/FilterBar';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface ActivityFeedProps {
    initialStartDate?: string;
    initialEndDate?: string;
  }
  interface ActivityFilterState {
    type: string;  // Changed from string[] to string
    teams: string[];
    userIds: string[];
    dateRange: string;
  }
const formatDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) return 'Today';
    if (date.toDateString() === yesterday.toDateString()) return 'Yesterday';

    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
};

const ActivityFeed: React.FC<ActivityFeedProps> = ({ initialStartDate, initialEndDate }) => {
    // States
    const [displayedActivities, setDisplayedActivities] = useState<Activity[]>([]);
    const [floatingDate, setFloatingDate] = useState<string>('Today');
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);  // Changed to false initially
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    
const [filters, setFilters] = useState<ActivityFilterState>(() => ({
    type: '',  // Changed from [] to ''
    teams: [],
    userIds: [],
    dateRange: initialStartDate && initialEndDate 
      ? `${initialStartDate},${initialEndDate}`
      : 'Last 7 Days'
  }));
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [customDateRange, setCustomDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
        startDate: new Date(),
        endDate: new Date(),
    });
    const [hasMore, setHasMore] = useState(true);
    const [total, setTotal] = useState(0);
    
    const itemsPerPage = 15;
    const containerRef = useRef<HTMLDivElement>(null);

    // Core pagination logic
    const loadActivities = useCallback(async (page: number, reset = false) => {
        if (!reset && (isLoadingMore || !hasMore)) return;
    
        if (reset) {
            setIsLoading(true);
        } else {
            setIsLoadingMore(true);
        }
    
        try {
            let dateParams = {};
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
    
            switch(filters.dateRange) {
                case formatDate(today.toISOString()): // 'Today'
                {
                    const tomorrow = new Date(today);
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    dateParams = {
                        startedAt: today.toISOString(),
                        endedAt: tomorrow.toISOString()
                    };
                    break;
                }
                case formatDate(yesterday.toISOString()): // 'Yesterday'
                {
                    const todayStart = new Date(today);
                    dateParams = {
                        startedAt: yesterday.toISOString(),
                        endedAt: todayStart.toISOString()
                    };
                    break;
                }
                case 'Last 7 Days': {
                    const end = new Date();
                    end.setHours(23, 59, 59, 999);
                    const start = new Date();
                    start.setDate(start.getDate() - 7);
                    start.setHours(0, 0, 0, 0);
                    dateParams = {
                        startedAt: start.toISOString(),
                        endedAt: end.toISOString()
                    };
                    break;
                }
                case 'Last 30 Days': {
                    const end = new Date();
                    end.setHours(23, 59, 59, 999);
                    const start = new Date();
                    start.setDate(start.getDate() - 30);
                    start.setHours(0, 0, 0, 0);
                    dateParams = {
                        startedAt: start.toISOString(),
                        endedAt: end.toISOString()
                    };
                    break;
                }
                case 'This Month': {
                    const start = new Date(today.getFullYear(), today.getMonth(), 1);
                    start.setHours(0, 0, 0, 0);
                    const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                    end.setHours(23, 59, 59, 999);
                    dateParams = {
                        startedAt: start.toISOString(),
                        endedAt: end.toISOString()
                    };
                    break;
                }
                case 'Last Month': {
                    const start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                    start.setHours(0, 0, 0, 0);
                    const end = new Date(today.getFullYear(), today.getMonth(), 0);
                    end.setHours(23, 59, 59, 999);
                    dateParams = {
                        startedAt: start.toISOString(),
                        endedAt: end.toISOString()
                    };
                    break;
                }
                case 'This Year': {
                    const start = new Date(today.getFullYear(), 0, 1);
                    start.setHours(0, 0, 0, 0);
                    const end = new Date(today.getFullYear(), 11, 31);
                    end.setHours(23, 59, 59, 999);
                    dateParams = {
                        startedAt: start.toISOString(),
                        endedAt: end.toISOString()
                    };
                    break;
                }
                default: {
                    if (filters.dateRange.includes(',')) {
                        const [start, end] = filters.dateRange.split(',');
                        dateParams = {
                            startedAt: start,
                            endedAt: end
                        };
                    }
                }
            }
    
            const params = {
                page,
                perPage: itemsPerPage,
                type: filters.type,  // This will now be a string
                teams: filters.teams.join(','),  // Convert array to string
                userIds: filters.userIds.join(','), // Convert array to string
                orderBy: '_id',
                isAscending: false,
                byOrganization: false,
                ...dateParams
              };
    
            const filteredParams = Object.fromEntries(
                Object.entries(params).filter(([_, value]) => value !== '')
            );
            
            const response = await getAllActivity(filteredParams);
const activities = response.data.data;
setTotal(response.data.total);
    
            if (activities.length === 0) {
                setHasMore(false);
            } else {
                setDisplayedActivities(prev => reset ? activities : [...prev, ...activities]);
                setCurrentPage(page + 1);
            }
        } catch (error) {
            console.error("Error fetching activities:", error);
            setHasMore(false);
        } finally {
            setIsLoading(false);
            setIsLoadingMore(false);
        }
    }, [filters, isLoadingMore, hasMore]);

    // Reset function
    const resetActivityFeed = useCallback(() => {
        setCurrentPage(1);
        setDisplayedActivities([]);
        setHasMore(true);
        loadActivities(1, true);
    }, [loadActivities]);

    // Initial load and filter changes
    useEffect(() => {
        resetActivityFeed();
    }, [filters]);

    // Scroll handler
    const handleScroll = useCallback(() => {
        const container = containerRef.current;
        if (!container || isLoading || isLoadingMore || !hasMore) return;

        const bottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 5;
        if (bottom) {
            loadActivities(currentPage, false);
        }
    }, [currentPage, isLoading, isLoadingMore, hasMore, loadActivities]);

    // Date filter handlers
    const applyDateFilter = useCallback((range: string) => {
        setFilters((prev: ActivityFilterState) => ({ ...prev, dateRange: range }));
        if (range === 'Custom') setShowDatePicker(true);
      }, []);
    
    // Update the handleCustomDateApply function to use ActivityFilterState
const handleCustomDateApply = useCallback(() => {
    const { startDate, endDate } = customDateRange;
    if (!startDate || !endDate) return;
    
    setShowDatePicker(false);
    setFilters((prev: ActivityFilterState) => ({  // Changed from FilterState to ActivityFilterState
        ...prev,
        dateRange: `${startDate.toISOString()},${endDate.toISOString()}`,
    }));
}, [customDateRange]);

  

    const dateOptions = [
        { label: 'Today', value: 'Today' },
        { label: 'Yesterday', value: 'Yesterday' },
        { label: 'Last 7 Days', value: 'Last 7 Days' },
        { label: 'Last 30 Days', value: 'Last 30 Days' },
        { label: 'This Month', value: 'This Month' },
        { label: 'Last Month', value: 'Last Month' },
        { label: 'This Year', value: 'This Year' },
        { label: 'Custom', value: 'Custom' }
    ];

    return (
        <Container className="activity-feed-container" fluid>
  
            <Modal show={showDatePicker} onHide={() => setShowDatePicker(false)}>
                <Modal.Header closeButton><Modal.Title>Select Custom Date Range</Modal.Title></Modal.Header>
                <Modal.Body>
                    <DatePicker
                        selected={customDateRange.startDate ?? new Date()}
                        onChange={(date) => setCustomDateRange({ ...customDateRange, startDate: date ?? new Date() })}
                        selectsStart
                        startDate={customDateRange.startDate}
                        endDate={customDateRange.endDate}
                    />
                    <DatePicker
                        selected={customDateRange.endDate ?? new Date()}
                        onChange={(date) => setCustomDateRange({ ...customDateRange, endDate: date ?? new Date() })}
                        selectsEnd
                        startDate={customDateRange.startDate}
                        endDate={customDateRange.endDate}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDatePicker(false)}>Cancel</Button>
                    <Button variant="primary" onClick={handleCustomDateApply}>Apply</Button>
                </Modal.Footer>
            </Modal>

            <FilterComponent
  dateOptions={dateOptions}
  activityTypes={activityTypes}
  applyDateFilter={applyDateFilter}
  setFilters={setFilters as any}  // Temporary fix to avoid type conflict
  filters={filters as any}         // Temporary fix to avoid type conflict
  className="filter-bar"
/>

            {isLoading ? (
                <div className="loading-indicator d-flex justify-content-center my-4">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <div className="activity-list" onScroll={handleScroll} ref={containerRef}>
                    <div className="floating-date-bar">{floatingDate}</div>
                    <div className="timeline-line"></div>
                    {displayedActivities.map((activity) => {
                        const { label, icon } = getActivityTypeConfig(activity.type);
                        return (
                            <Card key={activity._id} className="activity-card" data-date={activity.performedAt}>
                                <Card.Body className="d-flex align-items-center">
                                    <FontAwesomeIcon icon={icon} flip="horizontal" />
                                    <div className="activity-info ms-3">
                                        <h6>{label}</h6>
                                        <p>{activity.lead?.name || 'N/A'}</p>
                                        <p>Created by {activity.createdBy} on {formatDate(activity.performedAt)}</p>
                                    </div>
                                    <Button variant="link"><FontAwesomeIcon icon={faExternalLinkAlt} /></Button>
                                </Card.Body>
                            </Card>
                        );
                    })}
                </div>
            )}
        </Container>
    );
};

export default ActivityFeed;