import React from "react";
import ButtonInputs from "./ButtonInputs";
import { Error, ButtonType } from "./types";  

interface ButtonSectionProps {
  expandedSections: Record<string, boolean>;
  toggleSection: (sectionId: string) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: {
    haveButtons: "yes" | "no";
    buttons: ButtonType[];  // Use imported ButtonType
  };
  errorsObj: Record<string, string>;
  setFieldValue?: (field: string, value: any) => void;
  isSubmitting?: boolean;
}

const ButtonSection: React.FC<ButtonSectionProps> = ({
  expandedSections,
  toggleSection,
  handleChange,
  values,
  errorsObj,
  setFieldValue,
  isSubmitting = false
}) => {
  return (
    <div className="section_block">
      <div className={`section_heading ${expandedSections["section3"] ? "" : "mb-0"}`}>
        <div className="me-auto">
          <label>Buttons (optional)</label>
          <p>Buttons that allow customers to respond to your message or initiate actions.</p>
        </div>
        <svg
          viewBox="0 0 24 24"
          focusable="false"
          className={`arrow-icon ${expandedSections["section3"] ? "expanded" : ""}`}
          aria-hidden="true"
          onClick={() => !isSubmitting && toggleSection("section3")}
        >
          <path
            fill="currentColor"
            d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
          />
        </svg>
      </div>

      {expandedSections["section3"] && (
        <>
          <div className="radioInputs">
            <label htmlFor="have_buttons_none">
              <input
                type="radio"
                id="have_buttons_none"
                name="haveButtons"
                value="no"
                checked={values.haveButtons === "no"}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <span>None</span>
            </label>
            <label htmlFor="have_buttons_cta">
              <input
                type="radio"
                id="have_buttons_cta"
                name="haveButtons"
                value="yes"
                checked={values.haveButtons === "yes"}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <span>Call to Action</span>
            </label>
          </div>

          {values.haveButtons === "yes" && (
            <ButtonInputs
              values={values}
              handleChange={handleChange}
              errorsObj={errorsObj}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ButtonSection;