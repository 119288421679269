import axios, { AxiosError } from 'axios';

interface GoogleTokens {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  expiry_date: number;
  scope?: string;
  token_type?: string;
  id_token?: string;
  status?: string;
}
interface GoogleTokens {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  expiry_date: number;
  scope?: string;
  token_type?: string;
  id_token?: string;
  status?: string;
}

interface TokenStatusResponse {
  statusCode: number;
  status: boolean;
  data: GoogleTokens;
}


interface ApiError {
  message: string;
  status?: number;
  code?: string;
}

interface ErrorResponse {
  message?: string;
  error?: string;
  tokens?: GoogleTokens;
}

interface TokenCheckResponse {
  statusCode: number;
  status: boolean;
  data: GoogleTokens;
}

// Add type for Axios error response data
interface ApiErrorResponse {
  message?: string;
  error?: string;
}

export class GoogleAuthService {
  private static readonly API_ENDPOINT = 'https://mapi2.3sigmacrm.com/api/v1';
  private static readonly TOKEN_STORAGE_KEY = 'google_oauth_tokens';
  private static readonly BEARER_TOKEN_KEY = 'auth_token';
  private static readonly CLIENT_ID = "322938904388-p7l4fso2n9g93n8irg3c9get69rn3vk4.apps.googleusercontent.com";
  // Add required scope constant
  
  public static readonly SHEETS_SCOPE = [
    'https://www.googleapis.com/auth/spreadsheets.readonly',
    'https://www.googleapis.com/auth/drive.file',
    'https://www.googleapis.com/auth/drive.metadata.readonly',
  ].join(' ');
  
  private static readonly REQUIRED_SCOPE = 'https://www.googleapis.com/auth/contacts.readonly';
  
  public static readonly SCOPE: string = [
    'https://www.googleapis.com/auth/spreadsheets.readonly',
    'https://www.googleapis.com/auth/drive.file',
    'openid',
    'profile',
    'https://www.googleapis.com/auth/contacts.readonly',
    'email'
  ].join(' ');

  public static getClientId(): string {
    return this.CLIENT_ID;
  }
  
  private static getBearerToken(): string | null {
    try {
      return localStorage.getItem(this.BEARER_TOKEN_KEY);
    } catch (error) {
      console.error('Error accessing localStorage:', error);
      return null;
    }
  }

  private static createHeaders(): Record<string, string> {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };

    const token = this.getBearerToken();
    if (token) {
      headers['Authorization'] = token.startsWith('Bearer') ? token : `Bearer ${token}`;
    }

    return headers;
  }
  public static async checkTokenStatus(): Promise<{
    isValid: boolean;
    hasRequiredScopes: boolean;
  }> {
    try {
      const headers = this.createHeaders();
      if (!headers.Authorization) {
        return { isValid: false, hasRequiredScopes: false };
      }

      const response = await axios.get<TokenStatusResponse>(
        `${this.API_ENDPOINT}/user/google-token-and-status`,
        { headers }
      );

      if (!response.data.status || response.data.statusCode !== 200) {
        return { isValid: false, hasRequiredScopes: false };
      }

      const tokenData = response.data.data;
      
      // Check if token is active and not expired
      const isValid = tokenData.status === 'active' && 
                     tokenData.expiry_date > Date.now();

      // Check if all required scopes are present
      const hasRequiredScopes = this.SHEETS_SCOPE.split(' ')
        .every(requiredScope => tokenData.scope?.includes(requiredScope));

      if (isValid) {
        this.storeTokens(tokenData);
      }

      return { isValid, hasRequiredScopes };
    } catch (error) {
      console.error('Token status check failed:', error);
      return { isValid: false, hasRequiredScopes: false };
    }
  }

  private static handleError(error: unknown): never {
    let message = 'An unexpected error occurred';
    let status: number | undefined;
    
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      status = axiosError.response?.status;
      message = axiosError.response?.data?.message || 
                axiosError.response?.data?.error || 
                axiosError.message;
    } else if (error instanceof Error) {
      message = error.message;
    }

    const apiError: ApiError = { message, status };
    console.error('GoogleAuthService Error:', apiError);
    throw apiError;
  }

  // New method to check existing tokens
  // In GoogleAuthService.ts

public static async checkExistingTokens(): Promise<{
  isValid: boolean;
  hasRequiredScopes: boolean;
  tokens?: GoogleTokens;
}> {
  try {
    const headers = this.createHeaders();
    
    if (!headers.Authorization) {
      return { isValid: false, hasRequiredScopes: false };
    }

    const response = await fetch(`${this.API_ENDPOINT}/user/google-token-and-status`, {
      method: 'GET',
      headers
    });

    if (!response.ok) {
      return { isValid: false, hasRequiredScopes: false };
    }

    const data = await response.json() as TokenCheckResponse;
    
    if (!data.status || data.statusCode !== 200 || !data.data) {
      return { isValid: false, hasRequiredScopes: false };
    }

    const tokens = data.data;
    // Check for contacts scope when called from contacts component
    const requiredScope = window.location.pathname.includes('contacts') 
      ? this.REQUIRED_SCOPE  // contacts scope
      : this.SHEETS_SCOPE;   // sheets scope

    const hasRequiredScopes = requiredScope.split(' ')
      .every(scope => tokens.scope?.includes(scope));
    const isValid = tokens.status === 'active';

    if (isValid) {
      this.storeTokens(tokens);
    }

    return {
      isValid,
      hasRequiredScopes,
      tokens: isValid ? tokens : undefined
    };
  } catch (error) {
    console.error('Token check failed:', error);
    return { isValid: false, hasRequiredScopes: false };
  }
}
  
  public static async disconnect(): Promise<boolean> {
    try {
      const headers = this.createHeaders();
      if (!headers.Authorization) {
        throw new Error('No authentication token available');
      }

      await axios.put(
        `${this.API_ENDPOINT}/integration/user-integration/google-disconnect`,
        { scope: this.SHEETS_SCOPE },
        { headers }
      );

      // Clear local storage
      this.logout();
      return true;
    } catch (error) {
      console.error('Failed to disconnect Google integration:', error);
      throw error;
    }
  }

  public static storeTokens(tokens: GoogleTokens): void {
    try {
      console.log('Storing tokens to localStorage:', tokens); // Debug log
      localStorage.setItem(this.TOKEN_STORAGE_KEY, JSON.stringify(tokens));
      
      // Verify storage
      const storedTokens = localStorage.getItem(this.TOKEN_STORAGE_KEY);
      console.log('Verified stored tokens:', storedTokens);
    } catch (error) {
      console.error('Failed to store tokens:', error);
      throw new Error('Failed to store authentication tokens');
    }
  }

  private static getStoredTokens(): GoogleTokens | null {
    try {
      const tokenStr = localStorage.getItem(this.TOKEN_STORAGE_KEY);
      if (!tokenStr) return null;
      return JSON.parse(tokenStr);
    } catch (error) {
      console.error('Failed to retrieve tokens:', error);
      return null;
    }
  }

  public static isAuthenticated(): boolean {
    try {
      const tokens = this.getStoredTokens();
      if (!tokens) return false;
      
      const isValid = tokens.expiry_date > (Date.now() + 5 * 60 * 1000);
      
      if (!isValid) {
        localStorage.removeItem(this.TOKEN_STORAGE_KEY);
      }
      
      return isValid;
    } catch (error) {
      console.error('Auth check failed:', error);
      return false;
    }
  }

  public static getAccessToken(): string | null {
    try {
      const storedTokens = localStorage.getItem(this.TOKEN_STORAGE_KEY);
      console.log('Raw stored tokens:', storedTokens); // Log raw stored value
      
      if (storedTokens) {
        const tokens = JSON.parse(storedTokens) as GoogleTokens;
        console.log('Access token details:', {
          tokenStart: tokens.access_token.substring(0, 20),
          tokenLength: tokens.access_token.length,
          hasWhitespace: /\s\s+/.test(tokens.access_token)
        });
        return tokens.access_token;
      }
      return null;
    } catch (error) {
      console.error('Failed to get access token:', error);
      return null;
    }
  }
  
  public static async sendGoogleCode(code: string): Promise<void> {
    if (!code) {
      throw new Error('Authorization code is required');
    }

    try {
      const headers = this.createHeaders();
      if (!headers.Authorization) {
        throw new Error('No authentication token available');
      }

      const response = await fetch(`${this.API_ENDPOINT}/user/get-google-tokens`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ code })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({} as ErrorResponse));
        throw new Error(errorData.message || errorData.error || 'Failed to authenticate with Google');
      }

      const data = (await response.json()) as ErrorResponse;
      if (data.tokens) {
        this.storeTokens(data.tokens);
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  public static async refreshAccessToken(): Promise<boolean> {
    try {
      const tokens = this.getStoredTokens();
      if (!tokens?.refresh_token) {
        throw new Error('No refresh token available');
      }

      const headers = this.createHeaders();
      if (!headers.Authorization) {
        throw new Error('No authentication token available');
      }

      const response = await fetch(`${this.API_ENDPOINT}/user/get-google-tokens/refresh`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ refresh_token: tokens.refresh_token })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({} as ErrorResponse));
        throw new Error(errorData.message || errorData.error || 'Failed to refresh token');
      }

      const newTokens = (await response.json()) as GoogleTokens;
      
      if (!newTokens.refresh_token) {
        newTokens.refresh_token = tokens.refresh_token;
      }
      
      newTokens.expiry_date = Date.now() + (newTokens.expires_in * 1000);
      
      this.storeTokens(newTokens);
      return true;
    } catch (error) {
      console.error('Token refresh failed:', error);
      return false;
    }
  }

  public static logout(): void {
    try {
      localStorage.removeItem(this.TOKEN_STORAGE_KEY);
    } catch (error) {
      console.error('Failed to clear tokens:', error);
    }
  }
}

export default GoogleAuthService;