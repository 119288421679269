import React, { PropsWithChildren, useEffect, useState } from "react";
import ErrorText from "../../components/errorText";

interface Props {
  actionDrawerTitle: string;
  selectedAction: string;
  handlePlusMinusValue: (e: any, tab: string) => void;
  SubActionSelectOnchange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  delayValue: number;
  statusData: any[];
  labelData: any[];
  leadListData: any[];
  userListData: any[];
  delayMinMaxMsg: string;
  onSaveDrawerClose: (obj: any) => void;
  updateActionDays: string;
  isActionError: boolean;
}

const ActionCommonDrawer: React.FC<PropsWithChildren<Props>> = ({
  actionDrawerTitle,
  selectedAction,
  handlePlusMinusValue,
  SubActionSelectOnchange,
  delayValue,
  statusData,
  labelData,
  leadListData,
  userListData,
  delayMinMaxMsg,
  onSaveDrawerClose,
  updateActionDays,
  isActionError,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const selectLabel = () => {
    switch (actionDrawerTitle) {
      case "Change status":
        return "Select Status";
      case "Change Label":
        return "Select Label";
      case "Move to list":
      case "Copy to list":
        return "Select Lead list";
      case "Send notification":
      case "Assign lead":
        return "Select user";
      case "Delay":
        return "Select number of days to wait";
      default:
        return "";
    }
  };

  const handleSave = () => {
    let configData: any = {};

    switch (selectedAction) {
      case "status_change":
        configData.status = updateActionDays;
        break;
      case "label_change":
        configData.label = [updateActionDays];
        break;
      case "move_to_list":
      case "copy_to_list":
        configData.targetListId = updateActionDays;
        break;
      case "push_notification":
      case "assign_lead":
        configData.assignToUser = updateActionDays;
        break;
    }

    onSaveDrawerClose(configData);
  };

  const renderOptions = () => {
    switch (actionDrawerTitle) {
      case "Change status":
        return statusData?.map((data: any, j: number) => (
          <option value={data.value} key={j}>
            {data.name}
          </option>
        ));
      case "Change Label":
        return labelData?.map((data: any, j: number) => (
          <option value={data.value} key={j}>
            {data.name}
          </option>
        ));
      case "Move to list":
      case "Copy to list":
        return leadListData?.map((data: any, j: number) => (
          <option value={data._id} key={j}>
            {data.name}
          </option>
        ));
      case "Send notification":
      case "Assign lead":
        return userListData?.map((data: any, j: number) => (
          <option value={data._id} key={j}>
            {data.firstName}
          </option>
        ));
      default:
        return null;
    }
  };

  return (
    <div className="row">
      <div className="form-group action_select">
        {actionDrawerTitle === "Delay" ? (
          <>
            <div>
              <label>{selectLabel()}</label>
              <div className="delay_plus_minus">
                <div className="plush_minus_box_left">
                  <i
                    className="bi bi-dash"
                    onClick={(e: any) => handlePlusMinusValue(e, "minus")}
                  />
                </div>
                <input
                  type="number"
                  min="1"
                  max="366"
                  name="delay"
                  className="form-control plus_minus"
                  value={delayValue}
                  onChange={(e: any) => handlePlusMinusValue(e, "")}
                />
                <div className="plush_minus_box_right">
                  <i
                    className="bi bi-plus"
                    onClick={(e: any) => handlePlusMinusValue(e, "plus")}
                  />
                </div>
              </div>
            </div>
            {delayMinMaxMsg && <p style={{ color: "red" }}>{delayMinMaxMsg}</p>}
          </>
        ) : (
          <>
            <label>{selectLabel()}</label>
            <select
              name={selectedAction}
              className="form-select"
              value={updateActionDays}
              onChange={SubActionSelectOnchange}
            >
              <option value="">Select {selectLabel()}</option>
              {renderOptions()}
            </select>
            {isActionError && (
              <ErrorText message={`${selectLabel()} is required.`} />
            )}
          </>
        )}
      </div>
      <div className="auto_action_form_btn">
        <button
          type="button"
          className="btn btn-primary-save"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ActionCommonDrawer;
