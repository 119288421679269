// src/components/CallLogItem.tsx

import React from 'react';
import { List, Avatar, Tag } from 'antd';
import {
  PhoneIncoming,
  PhoneOutgoing,
  PhoneMissed,
  Phone,
  User,
} from 'lucide-react';
import moment from 'moment';
import { CallLog } from '../services/callLogsService';
import styles from './CallLogItem.module.css'; // Ensure this file exists

interface CallLogItemProps {
  log: CallLog;
}

const getCallTypeDetails = (type: string) => {
  switch (type) {
    case 'incoming':
      return {
        icon: <PhoneIncoming size={20} color="#28a745" />,
        color: '#28a745',
        label: 'Incoming',
      };
    case 'outgoing':
      return {
        icon: <PhoneOutgoing size={20} color="#007bff" />,
        color: '#007bff',
        label: 'Outgoing',
      };
    case 'missed':
      return {
        icon: <PhoneMissed size={20} color="#dc3545" />,
        color: '#dc3545',
        label: 'Missed',
      };
    default:
      return {
        icon: <Phone size={20} color="#6c757d" />,
        color: '#6c757d',
        label: 'Unknown',
      };
  }
};

const CallLogItem: React.FC<CallLogItemProps> = ({ log }) => {
  const callType = getCallTypeDetails(log.type);

  return (
    <List.Item className={styles.listItem}>
      <List.Item.Meta
        avatar={
          <Avatar
            icon={<User size={20} />}
            src={log.lead?.name ? undefined : undefined} // Optionally, add image src if available
            style={{ backgroundColor: '#1890ff' }}
          />
        }
        title={
          <div className={styles.titleContainer}>
            <span className={styles.callName}>
              {log.lead ? log.lead.name : log.userContact ? log.userContact.firstName : 'Unknown'}
            </span>
            {log.concurrentCall && log.concurrentCall > 1 && (
              <Tag color="blue" className={styles.concurrentTag}>
                {log.concurrentCall} Calls
              </Tag>
            )}
          </div>
        }
        description={
          <>
            <div className={styles.descriptionLine}>
              <span className={styles.phoneNumber}>{log.phone}</span>
            </div>
            <div className={styles.descriptionLine}>
              <span className={styles.callTime}>
                {moment(log.startedAt).format('MMMM Do YYYY, h:mm A')}
              </span>
              <Tag color={callType.color} className={styles.callTypeTag}>
                {callType.label}
              </Tag>
            </div>
          </>
        }
      />
      <div className={styles.durationContainer}>
        <span className={styles.duration}>
          {moment.utc(log.duration * 1000).format('mm:ss')}
        </span>
        {log.file && (
          <a href={log.file} target="_blank" rel="noopener noreferrer" className={styles.recordingLink}>
            Recording
          </a>
        )}
      </div>
    </List.Item>
  );
};

export default CallLogItem;
