import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  fetchTeamList,
  getUserOrganizationList,
} from "../../services/dashboardService";
import { dashboardDates } from "../../utils/globalConstants";
import CustomDropdownWithCheckboxes from "./CustomDropdownWithCheckboxes";
import { IDashboardHeader } from './dashboard.types';


const DashboardHeader = ({
  fromDate,
  isCustomDate,
  selectFilterDate,
  handleFilteronChange,
  selectFilterUser,
}: IDashboardHeader) => {
  const [teamData, setTeamData] = useState<any>([]);
  const [organizationData, setOrganizationData] = useState<any>([]);
  const [filterUserData, setFilterUserData] = useState<any>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>(
    []
  );

  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });

  useEffect(() => {
    const getAllTeam = async () => {
      try {
        const response = await fetchTeamList();
        if (response && response.status) {
          setTeamData(response.data.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const getOrganizationData = () => {
      let tempOrgArray = [];
      let tempOrgData = {
        name: StoreData?.userData?.userDetails?.organization?.name,
        _id: StoreData?.userData?.userDetails?.organization?._id,
      };
      tempOrgArray.push(tempOrgData);
      setOrganizationData(tempOrgArray);
    };

    const handleGetUserOrganization = async () => {
      try {
        const response = await getUserOrganizationList({
          page: 1,
          perPage: 15,
        });
        if (response && response.status) {
          let tempArray = [...response.data.data];
          tempArray.forEach((el: any) => {
            let findRole =
              StoreData.userData.userDetails.organizationRoles.find(
                (x: any) => x._id === el.role
              ).displayName;
            el.roleName = findRole;
            el.organizationName =
              StoreData.userData.userDetails.organization.name;
          });

          if (
            StoreData.userData.userDetails.role.name === "super_admin" ||
            StoreData.userData.userDetails.role.name === "admin"
          ) {
            let tempObj = {
              firstName: StoreData.userData.userDetails.firstName,
              _id: StoreData.userData.userDetails._id,
              organizationName:
                StoreData.userData.userDetails.organization.name,
              roleName: StoreData.userData.userDetails.role.displayName,
            };
            tempArray.push(tempObj);
          }
          setFilterUserData(tempArray);
        }
      } catch (err) {
        console.log(err, "Error");
      }
    };

    getAllTeam();
    getOrganizationData();
    handleGetUserOrganization();
  }, []);

  let options = [
    ...teamData.map((item: any) => ({
      label: `${item.name} - Team`,
      value: item._id,
    })),
    ...organizationData.map((item: any) => ({
      label: `${item.name} - Organization`,
      value: item._id,
    })),
  ];

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="fs-4 fw-bold text-dark">Dashboard</div>
        <div className="dashboard_header">
          <div className="header_flex">
            <select
              name="type"
              className="form-select"
              value={fromDate}
              placeholder="Date"
              onChange={(e: any) => handleFilteronChange(e.target.value)}
            >
              {dashboardDates.map((item: any, index: number) => (
  <option key={`date-${index}`} value={item.value}>{item.name}</option>
))}
            </select>
          </div>
          <div className="header_flex">
            <CustomDropdownWithCheckboxes
              options={[
                ...teamData.map((item: any) => ({
                  label: `${item.name} - Team`,
                  value: item._id,
                })),
                ...organizationData.map((item: any) => ({
                  label: `${item.name} - Organization`,
                  value: item._id,
                })),
              ]}
              selectedValues={[...selectedTeams, ...selectedOrganizations]}
              onChange={(selectedValues) => {
                let isExist = options?.find(
                  (e: any) =>
                    e.value === selectedValues[selectedValues?.length - 1]
                );

                let isOrg =
                  isExist?.label?.split(" - ")?.[1] === "Organization";

                setSelectedTeams(
                  isOrg
                    ? []
                    : selectedValues.filter((value) =>
                        teamData.some((team: any) => team._id === value)
                      )
                );
                setSelectedOrganizations(
                  !isOrg
                    ? []
                    : selectedValues.filter((value) =>
                        organizationData.some((org: any) => org._id === value)
                      )
                );
                selectFilterUser(
                  selectedValues.join(","),
                  isOrg ? "organization" : "team"
                );
              }}
            />
          </div>
          {/* <div className="header_flex">
            <select
              name="type"
              className="form-select"
              onChange={(e: any) => {
                selectFilterUser(e.target.value, "team");
              }}
            >
              <option value="">Teams</option>
              {teamData?.map((item: any, t: number) => {
                return (
                  <option value={item?._id} key={t}>
                    <label>{item.name} - Team </label>
                  </option>
                );
              })}
              {organizationData?.map((item: any, t: number) => {
                return (
                  <option value={"organization"} key={t}>
                    <label>{item.name} - Organization</label>
                  </option>
                );
              })}
            </select>
          </div> */}
          <div className="header_flex">
            <select
              name="type"
              className="form-select"
              onChange={(e: any) => selectFilterUser(e.target.value, "Emp")}
            >
              <option value="">Team members</option>
              {filterUserData?.map((item: any) => (
  <option key={`user-${item?._id}`} value={item?._id}>
    {item.firstName}
  </option>
))}
           </select>          
</div>          
</div>       
</div>       
{isCustomDate && (         
  <div className="dashboard_header_row d-flex align-items-center">             
    <div className="header_flex">               
      <label className="me-2">From : </label>               
      <input                 
        type="date"                 
        name="from"                 
        className="form-to-date form-control"                                
        onChange={(e) => selectFilterDate(e)}               
      />             
    </div>             
    <div className="header_flex ms-4">               
      <label className="me-2">To : </label>               
      <input                 
        type="date"                 
        name="to"                 
        className="form-to-date form-control"                                
        onChange={(e) => selectFilterDate(e)}               
      />             
    </div>           
  </div>         
)}     
</div>   
); 
};  

export default DashboardHeader;
