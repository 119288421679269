import { Card, CardBody, CardHeader } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import RangeDatePicker from "../../components/RangeDatePicker";
import { DateRange } from "./dashboard";

interface IDashboardQuotationStatusPieChart {
  title: string;
  pieChartData: PieChartData[];
  legendPosition?: "top" | "right" | "bottom" | "left";
  chartHeight?: number;
  callBackFun: Function;
  dateRangeValue: DateRange;
}

export interface PieChartData {
  label: string;
  value: number;
}

const DashboardQuotationStatusPieChart = ({
  title,
  pieChartData,
  chartHeight,
  legendPosition,
  callBackFun,
  dateRangeValue,
}: IDashboardQuotationStatusPieChart) => {
  const chartOptions: ApexOptions = {
    labels: pieChartData.map((data) => data.label),
    series: pieChartData.map((data) => data.value),
    chart: {
      type: "pie",
    },
    legend: {
      show: true,
      position: legendPosition || "right",
    },
  };

  return (
    <Card>
      <CardHeader className="d-flex align-items-center justify-content-between">
        <DashboardQuotationStatusPieChartHeader title={title} />
        <RangeDatePicker
          dateRangeValue={dateRangeValue}
          callBackFun={callBackFun}
        />
      </CardHeader>
      <CardBody className="p-0">
        {pieChartData
          .map((data) => data.value)
          .reduce((total, currentValue) => total + currentValue, 0) <= 0 ? (
          <h2 className="no_data_found">No data yet.</h2>
        ) : (
          <ReactApexChart
            height={chartHeight}
            options={chartOptions}
            series={chartOptions.series}
            type="pie"
          />
        )}
      </CardBody>
    </Card>
  );
};

export default DashboardQuotationStatusPieChart;

interface IDashboardQuotationStatusPieChartHeader {
  title: string;
}

const DashboardQuotationStatusPieChartHeader = ({
  title,
}: IDashboardQuotationStatusPieChartHeader) => {
  return (
    <div className="d-flex align-items-center">
      <div className="fw-bold text-dark d-flex flex-column align-items-center fm-gilroy">
        <span>{title}</span>
      </div>
    </div>
  );
};
