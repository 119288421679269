import axios from "axios";
import _ from "lodash";
import { useEffect, useRef, useState, useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { APP_VERSION } from "../config/config";
import { FilterParams } from "../views/leads/leadsGrid/leads.types";
import { FaBolt, FaCalendarDay, FaRegUserCircle } from "react-icons/fa";
import { MdDashboard, MdFormatListBulleted, MdMoveToInbox } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { IoBookOutline } from "react-icons/io5";
import { BsMegaphone } from "react-icons/bs";
import { RiFileCopy2Fill } from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Home as LucideHome,
  Calendar as LucideCalendar,
  User as LucideUser,
  Bolt as LucideBolt,
  List as LucideList,
  Inbox as LucideInbox,
  Megaphone as LucideMegaphone,
  FileText as LucideFileText,
  HelpCircle as LucideHelpCircle,
  LayoutDashboard as LucideDashboard, // For dashboard icon
  Book as LucideBook, // For content icon
  LucidePlug,
  LucideSettings,
  LucideMessageCircle
} from "lucide-react";

const Header = ({
  onSearch,
  closeIconClick,
  isOpen,
  setIsOpen,
  onBulk,
  isSearch,
}: any) => {
  const { filterParam }: { filterParam: FilterParams } = useSelector(
    (state: any) => state.rootReducers.leads
  );
  const [filterval, setfilterval] = useState<string>("");
  const debouncedSearch = useRef(
    _.debounce((e: any) => {
      onSearch(e);
    }, 500)
  ).current;

  const openHelpWebsite = useCallback(() => {
    window.open("https://help.3sigmacrm.com", "_blank");
  }, []);

  const [userdata, setUserdata] = useState<any>({});

  const handleChange = (propogate?: boolean) => {
    setIsOpen(!isOpen);
    if (propogate) {
      closeIconClick && closeIconClick();
    }
  };

  useEffect(() => {
    setfilterval(filterParam?.search || "");
  }, [filterParam?.search]);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    let local: any = window.localStorage?.getItem("userData");
    if (local) {
      setUserdata(JSON.parse(local));
    } else {
      handleLogout();
    }
  }, []);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        handleLogout();
      }
      return Promise.reject(error);
    }
  );

  const handleSearchChange = _.debounce(
    (newValue) => onSearch && onSearch(newValue),
    200
  );

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
        style={{
          height: "48px",
          padding: "0 10px",
        }}
      >
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            {onBulk && (
              <li>
                <button onClick={onBulk} className="bulk_action_button">
                  Bulk Action
                </button>
              </li>
            )}

            {window.location.href.split("/").includes("leads") && (
              <li
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "10px",
                  height: "35px",
                  width: isOpen ? "320px" : "37px",
                  marginRight: "7px",
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {isOpen ? (
                  <div className="d-flex justify-content-center align-items-center w-100 leads-search-bar">
                    <i
                      className="fa fa-search cursor-pointer"
                      aria-hidden="true"
                      style={{ fontSize: "14px" }}
                    />
                    <input
                      type="text"
                      placeholder="Type to search..."
                      value={filterval}
                      className="form-control background-transparent"
                      style={{
                        outline: "none",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={(e) => {
                        setfilterval(e.target.value);
                        debouncedSearch(e);
                      }}
                    />
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleChange(filterval !== "")}
                    />
                  </div>
                ) : (
                  <i
                    className="fa fa-search cursor-pointer"
                    aria-hidden="true"
                    onClick={() => {
                      setfilterval("");
                      handleChange();
                    }}
                  />
                )}
              </li>
            )}

            <li
              className="nav-item dropdown mt-0"
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "10px",
                paddingRight: "10px",
                marginRight: 22,
              }}
            >
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0 user-info-dropdown-toggler"
                href="dashboard"
                data-bs-toggle="dropdown"
                style={{ textDecoration: "none" }}
              >
                <span className="d-md-block dropdown-toggle">
                  {userdata?.firstName} {userdata?.lastName}
                </span>
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.175 0L5 3.71159L8.825 0L10 1.14825L5 6L0 1.14825L1.175 0Z"
                    fill="black"
                  />
                </svg>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile user-info-dropdown">
                <li>
                  <div className="row gap-4 align-items-center">
                    <div className="col-md-4">
                      {!userdata?.bucketUrl || !userdata?.profile?.filePath ? (
                        <img
                          src={"assets/img/user.png"}
                          alt="Profile"
                          className="rounded-circle"
                        />
                      ) : (
                        <img
                          src={userdata?.bucketUrl + userdata?.profile?.filePath}
                          alt="Profile"
                          className="rounded-circle"
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      <h6 className="mb-1">
                        {userdata?.firstName} {userdata?.lastName}
                      </h6>
                      <p>Super admin</p>
                      <p>{userdata?.organization?.name}</p>
                    </div>
                  </div>
                  
                  <span className="phone-number">
                    + {userdata?.countryCode} {userdata?.phone}
                  </span>
                  
                  <div className="line" />
                  
                  <a onClick={handleLogout} className="logout mb-2 mt-2 d-flex align-items-center">
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 3.11111L12.231 4.20778L14.553 6.22222H5.4V7.77778H14.553L12.231 9.78444L13.5 10.8889L18 7M1.8 1.55556H9V0H1.8C0.81 0 0 0.7 0 1.55556V12.4444C0 13.3 0.81 14 1.8 14H9V12.4444H1.8V1.55556Z"
                        fill="#FF4747"
                        fillOpacity="0.82"
                      />
                    </svg>
                    <span className="ms-2">Logout</span>
                  </a>
                  
                  <div className="version text-center">
                    Version {APP_VERSION}
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>

      <aside id="sidebar" className="sidebar d-flex flex-column" style={{ width: "220px", overflowY: "auto" }}>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <a
            href="/dashboard"
            className="logo d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/3sigma_logo.png"
              alt="img"
              style={{ marginLeft: "14", marginRight: "auto" }}
            />
          </a>
        </div>

        <ul className="sidebar-nav flex-grow-1" id="sidebar-nav">
          <li className="nav-item">
          <NavLink className="nav-link" to="/dashboard">
  <LucideDashboard className="hw-20" opacity={0.5} />
  <span className="ms-2">Dashboard</span>
</NavLink>
<NavLink className="nav-link" to="/leads">
  <LucideHome className="hw-20" opacity={0.5} />
  <span className="ms-2">Leads</span>
</NavLink>
<NavLink className="nav-link" to="/content">
  <LucideFileText className="hw-20" opacity={0.5} />
  <span className="ms-2">Content</span>
</NavLink>
<NavLink className="nav-link" to="/tasks">
  <LucideCalendar className="hw-20" opacity={0.5} />
  <span className="ms-2">Task</span>
</NavLink>
<NavLink className="nav-link" to="/automation">
  <LucideSettings className="hw-20" opacity={0.5} />
  <span className="ms-2">Automation</span>
</NavLink>
<NavLink className="nav-link" to="/form">
  <LucideList className="hw-20" opacity={0.5} />
  <span className="ms-2">Form</span>
</NavLink>
{userdata?.role?.name === "super_admin" && (
  <NavLink className="nav-link" to="/integrations">
  <LucidePlug className="hw-20" opacity={0.5} />
  <span className="ms-2">Integrations</span>
</NavLink>
)}
<NavLink className="nav-link" to="/whatsappInbox">
  <LucideInbox className="hw-20" opacity={0.5} />
  <span className="ms-2">Inbox</span>
</NavLink>
<NavLink className="nav-link" to="/whatsappBroadcast">
  <LucideMegaphone className="hw-20" opacity={0.5} />
  <span className="ms-2">Campaigns</span>
</NavLink>
<NavLink className="nav-link" to="/template">
  <LucideMessageCircle className="hw-20" opacity={0.5} />
  <span className="ms-2">Template</span>
</NavLink>
<NavLink className="nav-link" to="/profile">
  <LucideUser className="hw-20" opacity={0.5} />
  <span className="ms-2">WABAprofile</span>
</NavLink>

          </li>
        </ul>

        <div className="mt-auto mb-3">
        <NavLink
            className="nav-link get-help-link"
            to="#"
            onClick={openHelpWebsite}
            style={{
              fontFamily: "Gilroy, sans-serif",
              fontSize: "0.9em",
              fontWeight: 600,
              padding: "0.7rem 1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)",
              color: "#495057",
              borderRadius: "8px",
              marginLeft: "2rem",
              marginRight: "1rem",
              boxShadow: "0 2px 4px rgba(0,0,0,0.04)",
              transition: "all 0.3s ease",
            }}
          >
            <LucideHelpCircle className="hw-16" opacity={0.5} />
            <span className="ms-2">Get Help</span>
          </NavLink>

        </div>
      </aside>
    </>
  );
};

export default Header;
