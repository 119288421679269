import React, { PropsWithChildren } from "react";
import { Drawer, Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import styled from "styled-components";
interface DrawerI {
  openWithHeader: boolean;
  setOpenWithHeader: React.Dispatch<React.SetStateAction<boolean>>;
  drawerTitle: string;
  onTaskDelete: () => void;
  onRedirectClick?: () => void;
  size: 'lg'|'sm'|'xs'|'full',
  showDelete?: boolean;
}
const TaskDrawer: React.FC<PropsWithChildren<DrawerI>> = ({
  openWithHeader,
  setOpenWithHeader,
  drawerTitle,
  children,
  onTaskDelete,
  onRedirectClick,
  showDelete,
  size,
}) => {
  return (
    <Root>
      <Drawer
        className="auto_action_from"
        size={size}
        enforceFocus={false}
        open={openWithHeader}
        onClose={() => setOpenWithHeader(false)}
      >
        <Drawer.Header>
          <Drawer.Title className="modal-title">
            <div style={{ fontSize: "20px", fontWeight: "600",display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {drawerTitle}
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {onRedirectClick && <i className="bi bi-box-arrow-up-right show_action" onClick={() => onRedirectClick()} />}
                {(showDelete && drawerTitle !== "add") && (
                  <i
                    className="fa fa-trash-o"
                    style={{
                      color: "red",
                      fontSize: "23px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      onTaskDelete();
                    }}
                  />
                )}
              </div>
            </div>
          </Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpenWithHeader(false)}>Cancel</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>{children}</Drawer.Body>
      </Drawer>
    </Root>
  );
};

export default TaskDrawer;

const Root = styled.section`
  /* Hide the cancel svg icon */
  &
    button.rs-drawer-body-close.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-sm {
    display: none;
  }
`;
