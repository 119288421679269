import { useSelector } from "react-redux";
import ErrorText from "../../components/errorText";

const TriggerSection = ({
  automationData,
  automationWhenTriggerData,
  isShowAutoEvent,
  isUpdateAuto,
  handleAutoFormChange,
  sourceByData,
  statusLabelData,
  viewPageData,
  viewFileData,
  taskData,
  activityListData,
  customActivityType,
  organizationLeadList,
  errors,
}: any) => {
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const { customSource } = StoreData?.user?.userPreferences;

  const getSourceLabel = (event: string): string => {
    switch (event) {
      case "create_lead":
        return "Lead source is";
      case "status_change":
        return "Status is updated to";
      case "label_change":
        return "Label is updated to";
      case "view_page":
        return "Page is";
      case "view_file":
        return "File is";
      case "create_activity":
        return "Activity is";
      case "create_task":
        return "Task type is";
      case "new_lead_added":
        return "List name";
      default:
        return "";
    }
  };
  return (
    <div className="col-md-12 when_then">
      <span className="auto_dot">
        <label className="dot_no">1</label>
      </span>
      <label className="when_then_label">When</label>
      <span className="when_then_trigger">
        Select a trigger to start your automation. Triggers are events like a
        new lead, task completion
      </span>
      <div className="col-md-12">
        <div className="col-md-4 auto_select">
          <select
            name="event"
            value={automationData?.event}
            className={
              isUpdateAuto ? "form-select disabled_input" : "form-select"
            }
            disabled={isUpdateAuto}
            onChange={(e) => handleAutoFormChange(e)}
          >
            <option value="">Select trigger</option>
            {automationWhenTriggerData.map((data: any, i: number) => (
              <option value={data.value} key={i}>
                {data.name}
              </option>
            ))}
          </select>
        </div>

        {isShowAutoEvent && (
          <div className="col-md-8 condition-group">
            <div className="d-flex align-items-center gap-2">
              <span className="and">AND</span>
              <span className="and_source">
                {getSourceLabel(automationData?.event)}
              </span>
              <select
                name="targetValues"
                className={
                  isUpdateAuto
                    ? "form-select and_select disabled_input"
                    : "form-select and_select"
                }
                disabled={isUpdateAuto}
                value={automationData?.targetValues}
                onChange={(e) => handleAutoFormChange(e)}
              >
                <option value="">Select</option>
                {automationData?.event === "create_lead" && (
                  <>
                    {[
                      ...customSource?.map((e: any) => ({
                        ...e,
                        _id: e.value,
                        isActive: true,
                      })),
                      ...sourceByData,
                    ]
                      .filter((item) => item.isActive)
                      .map((item: any, i: number) => {
                        return (
                          <option value={item._id} key={i}>
                            {item.name}
                          </option>
                        );
                      })}
                    <option value="any">Any</option>
                  </>
                )}

                {(automationData?.event === "status_change" ||
                  automationData?.event === "label_change") && (
                  <>
                    {statusLabelData.map((item: any, i: number) => (
                      <option value={item.value} key={i}>
                        {item.name}
                      </option>
                    ))}
                    <option value="any">Any</option>
                  </>
                )}

                {automationData?.event === "view_page" && (
                  <>
                    {viewPageData.map((item: any, i: number) => (
                      <option value={item._id} key={i}>
                        {item.details.title}
                      </option>
                    ))}
                    <option value="any">Any</option>
                  </>
                )}

                {automationData?.event === "view_file" && (
                  <>
                    {viewFileData.map(
                      (item: any, i: number) => (
                        // item.files?.[0]?.fileName && (
                        <option value={item._id} key={i}>
                          {/* {item.files[0].fileName} */}
                          {item?.details?.title}
                        </option>
                      )
                      // )
                    )}
                    <option value="any">Any</option>
                  </>
                )}

                {automationData?.event === "create_task" && (
                  <>
                    {taskData.map((item: any, i: number) => (
                      <option value={item.value} key={i}>
                        {item.name}
                      </option>
                    ))}
                    <option value="any">Any</option>
                  </>
                )}

                {automationData?.event === "edit_task" && (
                  <>
                    <option value="toBePerformAt">Due date is updated</option>
                    <option value="isCompleted">Task is completed</option>
                    <option value="any">Any</option>
                  </>
                )}

                {automationData?.event === "create_activity" && (
                  <>
                    {[...activityListData, ...customActivityType].map(
                      (item: any, i: number) => (
                        <option value={item.value} key={i}>
                          {item.name}
                        </option>
                      )
                    )}
                    <option value="any">Any</option>
                  </>
                )}

                {automationData?.event === "new_lead_added" && (
                  <>
                    {organizationLeadList.map((item: any, i: number) => (
                      <option value={item._id} key={i}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>
          </div>
        )}
        {errors.event && <ErrorText message={errors.event} />}
      </div>
    </div>
  );
};

export default TriggerSection;
