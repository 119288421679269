import React, { useState } from 'react'

export default function Test() {
    const [file,setFile] = useState()
    const handleFile = (e)=>{
        console.log('handleFile ======>',e.target.files[0].name.split('.'))
    }
  return (
    <div>
        <h1>Test page </h1>
        <input type='file' onChange={(e)=>{handleFile(e)}}/>
    </div>
  )
}
