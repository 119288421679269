import { SET_USER } from "../actions/actionTypes";

interface AuthStateI {}

const defaultAuthState: AuthStateI = {};

const initialState = {
  leadFile: [],
};

const authReducer = (
  state: AuthStateI = initialState,
  action: { type: string; payload: any }
): AuthStateI => {
  switch (action?.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
