type PropsType = {
  disabled?: boolean;
  name: string;
  className: string;
  type: string;
  value: string;
  placeholder: string;
  onChange: (e: any) => void;

  label: string;
  autoComplete: string;
  isRequired: boolean;
  options: any;
  id: string;
  des?: string;
};

export default function Inputs(Props: PropsType) {
  const { label, isRequired, ...rest } = Props;

  return (
    <>
      <div>
        {Props.label != "" && (
          <label className="form-label">
            {label} <span className="text-danger">{isRequired && "*"}</span>
          </label>
        )}
        {Props.label === "Client Name" ? (
          <img src={"assets/img/person.png"} alt="img" className="form-icons" />
        ) : Props.label === "Email ID" ? (
          <img src={"assets/img/mail.png"} alt="img" className="form-icons" />
        ) : Props.label === "Phone Number" ? (
          <img src={"assets/img/call.png"} alt="img" className="form-icons" />
        ) : Props.label === "Sale Value" ? (
          <img src={"assets/img/rupees.png"} alt="img" className="form-icons" />
        ) : (
          ""
        )}

        {Props.des ? (
          <p className="fs-8 mb-2" style={{ marginTop: "-6px" }}>
            {Props.des}
          </p>
        ) : null}

        {label !== "Notes" && label !== "Description" ? (
          <>
            {Props.type !== "selection" ? (
              <>
                <input
                  {...rest}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                />
              </>
            ) : (
              <div className="position-relative">
                <select {...rest}>
                  <option value="">Select {label}</option>
                  {Props.options?.map((type: any, i: number) => (
                    <option value={type} key={i}>
                      {type}
                    </option>
                  ))}
                </select>
                <i className="ri-arrow-down-s-line position-absolute end-0 top-50 translate-middle-y fs-5 me-2"></i>
              </div>
            )}
          </>
        ) : (
          <textarea style={{ height: "120px" }} {...rest} />
        )}
      </div>
    </>
  );
}
