import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Checkbox, List, Avatar, Typography, Space, Modal, Spin, Select } from 'antd';
import { SearchOutlined, CloseCircleFilled, ImportOutlined, SyncOutlined } from '@ant-design/icons';
import { FaAddressBook, FaGoogle } from 'react-icons/fa';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import DrawerComponent from "../../components/drawer";
import DynamicConfirmationModal from "../../components/DynamicConfirmationModal";
import { toast } from "react-hot-toast";
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleAuthService } from '../../services/GoogleAuthService';
import { LogOut } from 'lucide-react';
import { Modal as AntModal } from 'antd';
import { debounce } from 'lodash';


const { Text } = Typography;


// Types and Interfaces
interface GooglePeopleContact {
  resourceName: string;
  names?: Array<{
    displayName: string;
    givenName?: string;
    familyName?: string;
  }>;
  phoneNumbers?: Array<{
    value: string;
    type?: string;
  }>;
  emailAddresses?: Array<{
    value: string;
    type?: string;
  }>;
  photos?: Array<{
    url: string;
  }>;
}


interface LeadList {
  _id: string;
  name: string;
  status: boolean;
  distributionType: string;
  isDistributionActive: boolean;
  recipients: null;
  readAccess: any[];
  createdBy: {
    _id: string;
    firstName: string;
    lastName: string;
  };
  organization: string;
  team: null;
}

interface ContactData {
  id: string;
  name: string;
  email: string;
  phone?: string;
  imageUrl: string;
  sortKey?: string;
}
interface GooglePeopleResponse {
  connections: GooglePeopleContact[];
  nextPageToken?: string;
  totalItems: number;
}

interface Props {
  selectedList: {
    id: string;
    name: string;
  };
}



interface GoogleContactLead {
  name: string;
  email?: string;
  phone?: string;
  leadListId?: string;
  customSource: string;
  integration: string;
}

// Styled Components
const StyledSearchInput = styled.input`
  width: 100%;
  padding: 8px 12px 8px 36px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
  transition: all 0.3s;

  &:focus {
    border-color: #1677ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
  }
`;

const SyncModalContent = styled.div`
  padding: 20px 0;
  
  .description {
    margin-bottom: 24px;
    color: #666;
  }
  
  .list-select {
    width: 100%;
    margin-bottom: 24px;
  }
`;
const ModalWrapper = styled.div`
  .ant-modal {
    z-index: 9999 !important;
  }
  
  .ant-modal-mask {
    z-index: 9998 !important;
  }
  
  .ant-modal-wrap {
    z-index: 9999 !important;
  }
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: white;
  border-top: 1px solid #f0f0f0;
  display: flex;
  gap: 12px;
  z-index: 1000;
`;

const ListSelect = styled(Select)`
  width: 100%;
`;

const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

const SearchContainer = styled.div`
  position: relative;
  padding: 12px 16px;
  background: white;
  border-bottom: 1px solid #f0f0f0;
`;
const StyledModal = styled(Modal)`
  z-index: 1001 !important; // Higher than ButtonContainer's z-index (1000)
  
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }

  // Target the modal mask to ensure it's also above other content
  &.ant-modal-wrap {
    z-index: 1001 !important;
  }
`;
const SearchIcon = styled(SearchOutlined)`
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
`;

const ClearButton = styled.button`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #9ca3af;
  display: flex;
  align-items: center;
  padding: 4px;

  &:hover {
    color: #666;
  }
`;

const Header = styled.div`
  padding: 12px 16px;
  background: #f9fafb;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeaderControls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ContactItem = styled(List.Item)`
  padding: 8px 16px !important;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: 1px solid #f0f0f0;

  &:hover {
    background-color: #f5f5f5;
  }

  .ant-checkbox-wrapper {
    margin-left: auto;
    padding-left: 12px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  margin-left: 8px;
`;

const ContactName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #111827;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContactDetail = styled.div`
  color: #6b7280;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Find ScrollContainer styled component and update its height
const ScrollContainer = styled.div`
  height: calc(100vh - 260px); // Adjusted to accommodate new buttons
  overflow-y: auto;
  background: white;
  padding-bottom: 120px; // Add padding for button container
`;



const ImportButton = styled(Button)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  height: 48px;
  border-radius: 0;
  font-weight: 500;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.08);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  text-align: center;
  min-height: 400px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #202124;
  margin-bottom: 16px;
`;

const Description = styled.p`
  font-size: 16px;
  color: #5f6368;
  max-width: 400px;
  margin-bottom: 32px;
  line-height: 1.5;
`;

const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ImportGoogleContacts: React.FC<Props> = ({ selectedList }) => {
  // State declarations
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authLoading, setAuthLoading] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [formCloseModel, setFormCloseModel] = useState<boolean>(false);
  const [contacts, setContacts] = useState<ContactData[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageToken, setPageToken] = useState<string | null>(null);
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState<boolean>(false);
  const [lastLoadTime, setLastLoadTime] = useState<number>(0);
  const LOAD_THROTTLE = 1000;
  const [tokenCheckInProgress, setTokenCheckInProgress] = useState<boolean>(true);
  const [needsReauth, setNeedsReauth] = useState<boolean>(false);
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [leadLists, setLeadLists] = useState<LeadList[]>([]);
  const [selectedListId, setSelectedListId] = useState<string>('');
  const [isSyncing, setIsSyncing] = useState(false);
  const [listsLoading, setListsLoading] = useState(false);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  // Google Login Setup
  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      try {
        setIsLoading(true);
        // Send auth code to backend
        await GoogleAuthService.sendGoogleCode(codeResponse.code);
        
        // Wait for token to be available
        const token = await waitForToken();
        if (!token) {
          throw new Error('Failed to get access token after authentication');
        }
        
        // Check if we got valid tokens with required scopes
        const { isValid, hasRequiredScopes } = await GoogleAuthService.checkExistingTokens();
        if (isValid && hasRequiredScopes) {
          setIsAuthenticated(true);
          setNeedsReauth(false);
          
          // Reset contacts state
          setContacts([]);
          setPageToken(null);
          setHasMore(true);
          setLastLoadTime(0);
          
          // Now load contacts
          await loadContacts();
          toast.success('Successfully connected to Google');
        } else {
          throw new Error('Failed to obtain required permissions');
        }
      } catch (error) {
        console.error('Auth error:', error);
        toast.error('Authentication failed. Please try again.');
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    },
    onError: () => {
      setIsLoading(false);
      setIsAuthenticated(false);
      toast.error('Google login failed. Please try again.');
    },
    scope: GoogleAuthService.SCOPE
  });
  
  const handleShowSyncModal = useCallback(async () => {
    if (!isAuthenticated) {
      toast.error('Please connect to Google Contacts first');
      return;
    }
    
    // Close the drawer first
    setCurrentStep(-1);
    
    // Load lists and show modal
    try {
      setListsLoading(true);
      const lists = await fetchLeadLists();
      setLeadLists(lists);
      setShowSyncModal(true);
    } catch (error) {
      toast.error('Failed to load lead lists');
    } finally {
      setListsLoading(false);
    }
  }, [isAuthenticated]);
  
  
  // Handler for sync confirmation
  const handleConfirmSync = async () => {
    if (!selectedListId) {
      toast.error('Please select a list first');
      return;
    }
  
    try {
      setIsSyncing(true);
      await initiateSync(selectedListId);
      const selectedListName = leadLists.find(l => l._id === selectedListId)?.name;
      toast.success(`Sync initiated successfully  with list: ${selectedListName} ,it might take a few minutes to complete`);
      setShowSyncModal(false);
      // Remove this line since drawer is already closed
      // setCurrentStep(-1); 
    } catch (error) {
      console.error('Sync failed:', error);
      toast.error('Failed to initiate sync. Please try again.');
    } finally {
      setIsSyncing(false);
    }
  };
  const waitForToken = async (maxAttempts = 5, delayMs = 1000): Promise<string | null> => {
    let attempts = 0;
    
    while (attempts < maxAttempts) {
      const token = GoogleAuthService.getAccessToken();
      if (token) return token;
      
      await new Promise(resolve => setTimeout(resolve, delayMs));
      attempts++;
    }
    
    return null;
  };
  const loadLeadLists = async () => {
    try {
      setListsLoading(true);
      const lists = await fetchLeadLists();
      setLeadLists(lists);
    } catch (error) {
      toast.error('Failed to load lead lists');
    } finally {
      setListsLoading(false);
    }
  };

  // Render sync modal
  const renderSyncModal = () => (
    <AntModal
      title="Import & Sync Google Contacts"
      open={showSyncModal}
      onCancel={() => setShowSyncModal(false)}
      footer={[
        <Button key="cancel" onClick={() => setShowSyncModal(false)}>
          Cancel
        </Button>,
        <Button
          key="sync"
          type="primary"
          icon={<SyncOutlined spin={isSyncing} />}
          onClick={handleConfirmSync}
          loading={isSyncing}
        >
          Import & Sync
        </Button>
      ]}
      style={{ top: 20 }}
    >
      <div style={{ marginBottom: 24, color: '#666' }}>
        This will continuously sync your Google contacts with the selected list. 
        New contacts added to Google will automatically be imported to your lead list.
      </div>

      <Select
        style={{ width: '100%' }}
        loading={listsLoading}
        placeholder="Select a list for import & sync"
        onChange={setSelectedListId}
        value={selectedListId}
      >
        {leadLists.map(list => (
          <Select.Option key={list._id} value={list._id}>
            {list.name}
          </Select.Option>
        ))}
      </Select>
    </AntModal>
  );

  const handleSync = async () => {
    if (!selectedListId) {
      toast.error('Please select a list first');
      return;
    }

    try {
      setIsSyncing(true);
      await initiateSync(selectedListId);
      const selectedListName = leadLists.find(l => l._id === selectedListId)?.name;
      toast.success(`Sync initiated with list: ${selectedListName}`);
      setCurrentStep(-1); // Close drawer
    } catch (error) {
      console.error('Sync failed:', error);
      toast.error('Failed to initiate sync. Please try again.');
    } finally {
      setIsSyncing(false);
    }
  };
  const fetchLeadLists = async (): Promise<LeadList[]> => {
    try {
      const authToken = localStorage.getItem('auth_token');
      if (!authToken) {
        throw new Error('Authentication token not found');
      }

      const response = await fetch('https://mapi2.3sigmacrm.com/api/v1/lead-list?orderBy=_id', {
        headers: {
          'Authorization': authToken, // Use token directly since it already has Bearer prefix
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch lead lists');
      }

      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error('Error fetching lead lists:', error);
      throw error;
    }
  };


 
  const initiateSync = async (listId: string): Promise<void> => {
    try {
      const authToken = localStorage.getItem('auth_token');
      if (!authToken) {
        throw new Error('Authentication token not found');
      }

      const response = await fetch('https://mapi2.3sigmacrm.com/api/v1/lead/google-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken // Use token directly
        },
        body: JSON.stringify({ list: listId })
      });

      if (!response.ok) {
        throw new Error('Failed to initiate sync');
      }
    } catch (error) {
      console.error('Error initiating sync:', error);
      throw error;
    }
  };
  const canLoadMore = useCallback(() => {
    const now = Date.now();
    if (now - lastLoadTime < LOAD_THROTTLE) {
      console.log('Loading throttled');
      return false;
    }
    if (loading) {
      console.log('Already loading');
      return false;
    }
    return true;
  }, [lastLoadTime, loading]);

  const loadContacts = useCallback(async (searchQuery?: string) => {
    if (!canLoadMore()) return;
  
    try {
      setLoading(true);
      setLastLoadTime(Date.now());
      
      const accessToken = await waitForToken();
      if (!accessToken) {
        setNeedsReauth(true);
        setIsAuthenticated(false);
        throw new Error('No access token available');
      }
  
      let response;
      
      if (searchQuery) {
        // Use search endpoint for queries
        response = await fetch(
          `https://people.googleapis.com/v1/people:searchContacts?` +
          `query=${encodeURIComponent(searchQuery)}` +
          `&readMask=names,emailAddresses,phoneNumbers,photos`,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            }
          }
        );
      } else {
        // Use connections endpoint for normal listing
        response = await fetch(
          `https://people.googleapis.com/v1/people/me/connections` + 
          `?personFields=names,emailAddresses,phoneNumbers,photos` +
          `&pageSize=100` +
          `&sortOrder=FIRST_NAME_ASCENDING` + 
          (pageToken ? `&pageToken=${pageToken}` : ''),
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            }
          }
        );
      }
  
      if (!response.ok) {
        setNeedsReauth(true);
        setIsAuthenticated(false);
        throw new Error('Failed to fetch contacts');
      }
  
      const data = await response.json();
      
      // Handle different response structures for search vs list
      let contactsToProcess = [];
      if (searchQuery) {
        // Search results come in data.results[].person format
        contactsToProcess = data.results?.map((result: any) => result.person) || [];
      } else {
        contactsToProcess = data.connections || [];
      }
  
      const newContacts: ContactData[] = contactsToProcess
        .map((contact: GooglePeopleContact): ContactData => ({
          id: contact.resourceName,
          name: contact.names?.[0]?.displayName || 'No Name',
          phone: contact.phoneNumbers?.[0]?.value,
          email: contact.emailAddresses?.[0]?.value || '',
          imageUrl: contact.photos?.[0]?.url || 
            `https://ui-avatars.com/api/?name=${encodeURIComponent(contact.names?.[0]?.displayName || 'No Name')}`
        }))
        .filter((contact: ContactData) => contact.phone || contact.email);
  
      if (searchQuery) {
        setContacts(newContacts);
        setHasMore(false);
        setIsSearching(true);
      } else {
        setContacts(prev => [...prev, ...newContacts]);
        setPageToken(data.nextPageToken || null);
        setHasMore(!!data.nextPageToken);
        setIsSearching(false);
      }
      
      setHasInitiallyLoaded(true);
  
    } catch (error) {
      console.error('Error loading contacts:', error);
      toast.error('Failed to load contacts. Please reconnect your Google account.');
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [pageToken, canLoadMore, setLoading, setLastLoadTime, setNeedsReauth, 
      setIsAuthenticated, setContacts, setPageToken, setHasMore, setHasInitiallyLoaded]);

      const debouncedSearch = useMemo(
        () => debounce(async (term: string) => {
          if (term.length === 0) {
            setContacts([]);
            setPageToken(null);
            setHasMore(true);
            setIsSearching(false);
            void loadContacts();
            return;
          }
          
          if (term.length < 2) return;
          
          void loadContacts(term);
        }, 500),
        [loadContacts]
      );

  // Initial auth check
 
  // Utility Functions
  
  const sortContactsAlphabetically = useCallback((a: ContactData, b: ContactData): number => {
    // Generate sort keys if not present
    if (!a.sortKey) {
      a.sortKey = a.name.toLowerCase().trim().replace(/[^a-z0-9]/g, '');
    }
    if (!b.sortKey) {
      b.sortKey = b.name.toLowerCase().trim().replace(/[^a-z0-9]/g, '');
    }
  
    // Handle special cases first
    if (a.name === 'No Name') return 1;
    if (b.name === 'No Name') return -1;
    
    // Compare sort keys for stable sorting
    return a.sortKey.localeCompare(b.sortKey, 'en', {
      sensitivity: 'base',
      numeric: true
    });
  }, []);

  useEffect(() => {
    if (currentStep === -1) {
      setContacts([]);
      setSelectedContacts([]);
      setSearchTerm('');
      setPageToken(null);
      setHasMore(true);
      setHasInitiallyLoaded(false);
    }
  }, [currentStep]);
  
  const sortContactArray = (contacts: ContactData[]): ContactData[] => {
    return [...contacts].sort(sortContactsAlphabetically);
  };

  const renderHeader = () => (
    <Header>
      <Text strong>Selected: {selectedContacts.length}</Text>
      <HeaderControls>
        <Button type="primary" size="small" onClick={handleSelectAll}>
          Select All
        </Button>
        <Button size="small" onClick={handleUnselectAll}>
          Unselect All
        </Button>
      </HeaderControls>
    </Header>
  );
  const renderDrawerTitle = () => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <span>Import from Google Contacts</span>
      {isAuthenticated && (
        <Space>
          <Button 
            size="small"
            icon={<LogOut size={16} />}
            onClick={handleSignOut}
          >
            Sign Out
          </Button>
          <Button 
            size="small" 
            type="primary" 
            danger
            onClick={() => setFormCloseModel(true)}
          >
            Cancel
          </Button>
        </Space>
      )}
    </div>
  );
  

  const handleSignOut = useCallback(async () => {
    try {
      setIsLoading(true);
      
      const bearerToken = localStorage.getItem('auth_token');
      if (!bearerToken) {
        throw new Error('No authentication token available');
      }
  
      const response = await fetch(
        'https://mapi2.3sigmacrm.com/api/v1/integration/rule/user-integration/google-disconnect',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': bearerToken // Removed duplicate Bearer
          },
          body: JSON.stringify({
            scope: 'https://www.googleapis.com/auth/contacts.readonly' // Only contacts scope
          })
        }
      );
  
      if (!response.ok) {
        throw new Error('Failed to disconnect Google account');
      }
  
      // Clear local tokens and state
      GoogleAuthService.logout();
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Sign out failed:', error);
      toast.error('Failed to disconnect Google account');
    } finally {
      setIsLoading(false);
    }
  }, []);
  
  

  // Core Contact Loading Function
  


  const handleContactsResponse = useCallback((data: any) => {
    const newContacts: ContactData[] = data.connections?.map((contact: GooglePeopleContact) => ({
      id: contact.resourceName,
      name: contact.names?.[0]?.displayName || 'No Name',
      phone: contact.phoneNumbers?.[0]?.value,
      email: contact.emailAddresses?.[0]?.value || '',
      imageUrl: contact.photos?.[0]?.url || 
        `https://ui-avatars.com/api/?name=${encodeURIComponent(contact.names?.[0]?.displayName || 'No Name')}`
    })) || [];

    const validContacts = newContacts
      .filter(contact => contact.phone || contact.email)
      .sort(sortContactsAlphabetically);

    setContacts(prev => {
      const combined = [...prev, ...validContacts];
      return [...combined].sort(sortContactsAlphabetically);
    });
    
    setPageToken(data.nextPageToken || null);
    setHasMore(!!data.nextPageToken);
    setHasInitiallyLoaded(true);
  }, [sortContactsAlphabetically]);

 const loadMoreContacts = useCallback(() => {
  if (!hasMore || loading || searchTerm) {
    console.log('Skip loading more:', { hasMore, loading, searchTerm });
    return;
  }
  console.log('Loading more contacts');
  loadContacts();
}, [hasMore, loading, searchTerm, loadContacts]);

  // Event Handlers
  const handleContactSelect = useCallback((contactId: string): void => {
    setSelectedContacts(prev => 
      prev.includes(contactId) 
        ? prev.filter(id => id !== contactId)
        : [...prev, contactId]
    );
  }, []);
  
  const handleSelectAll = useCallback((): void => {
    const selectableContacts = contacts
      .filter(contact => contact.name !== 'No Name' && contact.phone)
      .map(contact => contact.id);
    setSelectedContacts(selectableContacts);
  }, [contacts]);

  const handleUnselectAll = useCallback((): void => {
    setSelectedContacts([]);
  }, []);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  }, [debouncedSearch]);
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const clearSearch = useCallback((): void => {
    setSearchTerm('');
  }, []);

  const handleOpenDrawer = useCallback(async () => {
    console.log('Opening drawer');
    setCurrentStep(0);
    
    try {
      setAuthLoading(true);
      const { isValid, hasRequiredScopes } = await GoogleAuthService.checkExistingTokens();
      
      if (isValid && hasRequiredScopes) {
        setIsAuthenticated(true);
        setNeedsReauth(false);
        
        // Reset and load contacts
        setContacts([]);
        setPageToken(null);
        setHasMore(true);
        setLastLoadTime(0);
  
        // Set the selectedListId if we have a list passed as prop
        if (selectedList?.id) {
          setSelectedListId(selectedList.id);
        }
  
        await loadContacts();
      } else {
        setIsAuthenticated(false);
        if (!hasRequiredScopes) {
          setNeedsReauth(true);
        }
      }
    } catch (error) {
      console.error('Token check failed:', error);
      setIsAuthenticated(false);
    } finally {
      setAuthLoading(false);
    }
  }, [setCurrentStep, setAuthLoading, setIsAuthenticated, setNeedsReauth, 
      setContacts, setPageToken, setHasMore, setLastLoadTime, loadContacts, selectedList]);

      const handleImportLeads = useCallback(async (): Promise<void> => {
        const listId = selectedListId || selectedList?.id;
        
        if (!listId) {
          toast.error('Please select a list first');
          return;
        }
      
        try {
          setIsLoading(true);
          
          const bearerToken = localStorage.getItem('auth_token');
          if (!bearerToken) {
            throw new Error('No authentication token available');
          }
      
          const selectedContactsData = contacts.filter(contact => 
            selectedContacts.includes(contact.id) && contact.phone
          );
      
          if (selectedContactsData.length === 0) {
            toast.error('Please select contacts with phone numbers to import');
            return;
          }
      
          const leadsToCreate = selectedContactsData.map(contact => ({
            name: contact.name,
            email: contact.email || undefined,
            phone: contact.phone?.replace(/\s/g, ''),
            leadListId: listId,
            customSource: "phonebook",
            integration: "64109561e6c7e737227a6382"
          }));
      
          const response = await fetch('https://mapi2.3sigmacrm.com/api/v2/lead', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': bearerToken
            },
            body: JSON.stringify(leadsToCreate)
          });
      
          const result = await response.json();
      
          if (response.ok && result.status) {
            const successfullyImported = selectedContactsData.map(contact => contact.id);
            
            setContacts(prev => prev.filter(c => !successfullyImported.includes(c.id)));
            setSelectedContacts(prev => prev.filter(id => !successfullyImported.includes(id)));
            
            toast.success(`Successfully imported ${selectedContactsData.length} contact${selectedContactsData.length > 1 ? 's' : ''}`);
      
            if (contacts.length === successfullyImported.length) {
              setCurrentStep(-1);
            }
          } else {
            throw new Error(result.message || 'Failed to import contacts');
          }
        } catch (error) {
          console.error('Failed to import leads:', error);
          toast.error(error instanceof Error ? error.message : 'Failed to import contacts. Please try again.');
        } finally {
          setIsLoading(false);
        }
      }, [contacts, selectedContacts, selectedListId, selectedList, setCurrentStep]);

  // Computed Values
  // Update the filtered contacts memo
  const filteredContacts = useMemo((): ContactData[] => {
    return contacts.filter(contact => 
      contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (contact.phone && contact.phone.includes(searchTerm))
    );
  }, [contacts, searchTerm]);

  // Render Functions
  const renderAuthContent = () => (
    <Container>
      <Title>Connect to Google Contacts</Title>
      <Description>
        {needsReauth 
          ? 'Additional permissions are required to access your contacts. Please reconnect your Google account.'
          : 'Streamline your lead import process by connecting directly to your Google Contacts.'}
      </Description>
      <Button 
        type="primary" 
        icon={<FaGoogle />} 
        onClick={() => login()}
        loading={isLoading}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          backgroundColor: '#4285f4',
          height: '44px',
          padding: '0 24px'
        }}
      >
        {needsReauth ? 'Reconnect Google Account' : 'Connect with Google'}
      </Button>
    </Container>
  );

  const renderContactsList = () => (
    <div className="flex flex-col h-full">
      {renderHeader()}
      
      <SearchContainer>
        <SearchIcon />
        <StyledSearchInput
          placeholder="Search contacts..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {searchTerm && (
          <ClearButton onClick={clearSearch}>
            <CloseCircleFilled />
          </ClearButton>
        )}
      </SearchContainer>
      <ScrollContainer id="scrollableDiv">
      <InfiniteScroll
        dataLength={contacts.length}
        next={loadMoreContacts}
        hasMore={hasMore && !isSearching}
        loader={
          <div className="text-center py-4">
            <Spin />
            <div className="mt-2 text-gray-500">Loading more contacts...</div>
          </div>
        }
        scrollableTarget="scrollableDiv"
        endMessage={
          <div className="text-center py-4 text-gray-500">
            {contacts.length > 0 
              ? searchTerm 
                ? "Search results complete"
                : "You've reached the end of your contacts list!" 
              : "No contacts found."}
          </div>
        }
      >
        <List<ContactData>
          dataSource={contacts}
          renderItem={(contact: ContactData) => (
              <ContactItem 
                onClick={() => handleContactSelect(contact.id)}
                className={selectedContacts.includes(contact.id) ? 'selected' : ''}
              >
                <ContactContainer>
                  <Avatar src={contact.imageUrl} size={32} />
                  <ContactInfo>
                    <ContactName>{contact.name}</ContactName>
                    {contact.phone && <ContactDetail>{contact.phone}</ContactDetail>}
                  </ContactInfo>
                  <Checkbox
                    checked={selectedContacts.includes(contact.id)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => handleContactSelect(contact.id)}
                  />
                </ContactContainer>
              </ContactItem>
            )}
          />
        </InfiniteScroll>
      </ScrollContainer>

      <ButtonContainer>
      <Button
        type="default"
        icon={<SyncOutlined />}
        onClick={handleShowSyncModal}
        style={{ flex: 1 }}
      >
        Import & Sync
      </Button>
      
      <Button
        type="primary"
        icon={<ImportOutlined />}
        onClick={handleImportLeads}
        disabled={selectedContacts.length === 0} // Remove selectedListId check
        loading={isLoading}
        style={{ flex: 1 }}
      >
        Import Selected ({selectedContacts.length})
      </Button>
    </ButtonContainer>
  </div>
);

  const renderContent = () => {
    if (authLoading) {
      return (
        <LoadingOverlay>
          <Spin size="large" />
        </LoadingOverlay>
      );
    }

    if (!isAuthenticated) {
      return renderAuthContent();
    }

    return renderContactsList();
  };

  // Main Return
  return (
    <>
      <li
        className="dropdown-item"
        onClick={handleOpenDrawer}
        style={{ border: "none !important" }}
      >
        <span role="img" aria-label="contacts" style={{ marginRight: "8px" }}>
          <FaAddressBook color="#3faefc" />
        </span>
        Import from Google Contacts
      </li>

      <DrawerComponent
        openWithHeader={currentStep !== -1}
        setOpenWithHeader={() => setFormCloseModel(true)}
        drawerTitle="Import from Google Contacts"
        size="sm"
        secondOption={
          isAuthenticated && (
            <Space>
              <Button 
                size="small"
                onClick={handleSignOut}
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                <LogOut size={16} />
                Sign Out
              </Button>
            </Space>
          )
        }
      >
        {renderContent()}
      </DrawerComponent>

      {renderSyncModal()}

      <DynamicConfirmationModal
        onConfirmation={() => {
          setCurrentStep(-1);
          setFormCloseModel(false);
        }}
        showModal={formCloseModel}
        toggleModal={() => setFormCloseModel(false)}
        title="Are you sure you want to cancel the import process?"
      />
    </>
  );
};
export default ImportGoogleContacts;