import React from "react";

interface EmptyStateProps {
  onCreateNew: () => void;
  isPageLoad: boolean;
}

const EmptyState: React.FC<EmptyStateProps> = ({ onCreateNew, isPageLoad }) => {
  return (
    <div
      style={{ maxWidth: "700px", margin: "20px auto", textAlign: "center" }}
    >
      <h1
        style={{
          fontSize: "32px",
          color: "#2d3748",
          marginBottom: "20px",
          fontWeight: "600",
          fontFamily: "Gilroy",
        }}
      >
        No Automation Rules Yet
      </h1>

      <p
        style={{
          color: "#4a5568",
          marginBottom: "40px",
          lineHeight: "1.6",
          fontSize: "16px",
        }}
      >
        Streamline your workflow with powerful automation rules. Create tasks,
        send messages, and manage leads automatically based on custom triggers.
      </p>

      <div
        style={{
          maxWidth: "560px",
          margin: "0 auto 40px",
          textAlign: "left",
          backgroundColor: "#fff",
          padding: "24px",
          borderRadius: "8px",
        }}
      >
        <h3
          style={{
            fontSize: "18px",
            color: "#2d3748",
            marginBottom: "16px",
          }}
        >
          Benefits and Features ✨
        </h3>
        <div
          style={{
            color: "#4a5568",
            lineHeight: "2.2",
            fontSize: "15px",
          }}
        >
          * Auto-Organize Your Leads Effortlessly 📋
          <br />
          * Get Instant Notifications for Important Events 🔔
          <br />
          * Leads Auto-Updated Based on Activities 🔄
          <br />
          * Create Tasks ✅<br />
          * Assign or Reassign Leads Automatically 🔁
          <br />
          * Send Automated WhatsApp Messages & Emails 📩📱
          <br />
          * Run Drip Campaigns & Marketing Sequences 💡
          <br />* Plan Follow-Ups with WhatsApp & Tasks 🗓️✨
        </div>
      </div>

      <div
        style={{
          maxWidth: "560px",
          margin: "0 auto",
          backgroundColor: "#fff",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/McvKjl6jurs"
          title="How to use Automation Rules"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default EmptyState;
