import React, { useEffect, useState, useCallback } from "react";
import { Spin } from "antd";
import moment from "moment";
import styled from "styled-components";
import FormTable from "../userForm/FormTable";
import debounce from "lodash/debounce";
import axios from "axios";
import {
  Search,
  Send,
  Eye,
  MessageSquareReply,
  AlertCircle,
} from "lucide-react";

const API_URL = "https://mapi2.3sigmacrm.com/api/v1";

export interface CampaignFormData {
  _id: string;
  name: string;
  createdBy: {
    firstName: string;
    lastName?: string;
  };
  createdAt: string;
  totalSent: number;
  totalRead: number;
  totalReplied: number;
  totalFailed: number;
  totalLeads: number;
  status?: string;
}

interface CampaignFormProps {
  formDetail: CampaignFormData;
}

const CampaignForm: React.FC<CampaignFormProps> = ({ formDetail }) => {
  const [formData, setFormData] = useState<CampaignFormData | null>(null);
  const [isPageLoad, setIsPageLoad] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  // Improved fetchCampaignData with proper error handling and logging
  const fetchCampaignData = useCallback(
    async (search?: string, status?: string) => {
      if (!formDetail?._id) {
        console.log("No formDetail._id available, skipping fetch");
        return;
      }

      try {
        console.log("Fetching campaign data with:", {
          search,
          status,
          formId: formDetail._id,
        });
        setIsPageLoad(true);

        const params = new URLSearchParams();
        if (search) params.append("search", search);
        if (status) params.append("status", status);

        const response = await axios.get(
          `${API_URL}/campaign/${formDetail._id}${
            params.toString() ? `?${params.toString()}` : ""
          }`,
          {
            headers: {
              Authorization: localStorage.getItem("auth_token") || "",
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data?.data) {
          console.log(
            "Campaign data fetched successfully:",
            response.data.data
          );
          setFormData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      } finally {
        setIsPageLoad(false);
      }
    },
    [formDetail?._id]
  );

  // Improved debounced search with cleanup
  const debouncedSearch = useCallback(
    debounce((search: string) => {
      console.log("Debounced search executing with:", search);
      fetchCampaignData(search, selectedStatus);
    }, 300),
    [selectedStatus, fetchCampaignData]
  );

  const handleSearch = useCallback(
    (value: string) => {
      console.log("Search input changed:", value);
      setSearchTerm(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const handleStatusChange = useCallback(
    (status: string) => {
      console.log("Status changed to:", status);
      setSelectedStatus(status);
      fetchCampaignData(searchTerm, status);
    },
    [searchTerm, fetchCampaignData]
  );

  useEffect(() => {
    let mounted = true;

    const updateFormData = () => {
      if (mounted && formDetail) {
        if (formDetail._id && formDetail.name) {
          console.log("Initializing form data with:", formDetail);
          setFormData(formDetail);
        }
      }
    };

    updateFormData();

    // Cleanup function
    return () => {
      mounted = false;
      debouncedSearch.cancel();
    };
  }, [formDetail, debouncedSearch]);

  return (
    <Root>
      <Spin size="large" tip="Loading..." spinning={isPageLoad}>
        <div className="mt-2">
          {/* Header Section - Kept exactly as original */}
          <div className="row mb-3">
            <div>
              <CampaignName className="btn text-capitalize mb-1">
                {formData?.name || "Untitled Campaign"}
              </CampaignName>
              <SentBy>
                Sent by {formData?.createdBy?.firstName || "Unknown"} on{" "}
                {formData?.createdAt
                  ? moment(formData.createdAt).format("Do MMM YYYY")
                  : "Invalid Date"}{" "}
                at{" "}
                {formData?.createdAt
                  ? moment(formData.createdAt).format("hh:mm A")
                  : "Invalid Time"}
              </SentBy>
            </div>
          </div>

          {/* Metrics Section */}
          <div className="row mt-3">
            <div className="d-flex gap-3">
              <InfoButtons
                active={selectedStatus === "sent"}
                onClick={() => handleStatusChange("sent")}
              >
                <div>
                  <h5 className="alert-heading">Sent</h5>
                  <Send size={16} color="#0397FED1" />
                </div>
                <h5 className="alert-heading">{formData?.totalSent ?? 0}</h5>
              </InfoButtons>
              <InfoButtons
                active={selectedStatus === "read"}
                onClick={() => handleStatusChange("read")}
              >
                <div>
                  <h5 className="alert-heading">Read</h5>
                  <Eye size={16} color="#4CB050" />
                </div>
                <h5 className="alert-heading">{formData?.totalRead ?? 0}</h5>
              </InfoButtons>
              <InfoButtons
                active={selectedStatus === "replied"}
                onClick={() => handleStatusChange("replied")}
              >
                <div>
                  <h5 className="alert-heading">Replied</h5>
                  <MessageSquareReply size={16} color="#774A4A91" />
                </div>
                <h5 className="alert-heading">{formData?.totalReplied ?? 0}</h5>
              </InfoButtons>
              <InfoButtons
                active={selectedStatus === "failed"}
                onClick={() => handleStatusChange("failed")}
              >
                <div>
                  <h5 className="alert-heading">Failed</h5>
                  <AlertCircle size={16} color="#ED0A0A" />
                </div>
                <h5 className="alert-heading">{formData?.totalFailed ?? 0}</h5>
              </InfoButtons>
            </div>
          </div>

          <hr className="divider my-2" />

          {/* Search Section */}
          <div className="nav justify-content-between d-flex mb-2 align-items-center">
            <TotalSentButton className="px-3 py-1">
              {selectedStatus
                ? `${
                    selectedStatus.charAt(0).toUpperCase() +
                    selectedStatus.slice(1)
                  } (${formData?.totalLeads ?? 0})`
                : `Sent (${formData?.totalLeads ?? 0})`}
            </TotalSentButton>
            <SearchContainer>
              <Search size={16} color="#666" />
              <SearchInput
                type="text"
                placeholder="Search leads..."
                value={searchTerm}
                onChange={(e: any) => handleSearch(e.target.value)}
              />
            </SearchContainer>
          </div>

          {/* Table Section */}
          {formData ? (
            <FormTable tableData={formData} />
          ) : (
            <div className="d-flex justify-content-center align-items-center p-4">
              <p>No data available</p>
            </div>
          )}
        </div>
      </Spin>
    </Root>
  );
};

// Styled Components - Kept exactly as original
const Root = styled.div`
  padding: 0 16px;
`;

const CampaignName = styled.h4`
  font-size: 18px;
  font-family: "Gilroy-Bold";
  padding: 0;
  margin: 0;
`;

const TotalSentButton = styled.button`
  font-family: "Gilroy-Semibold";
  background-color: #fff;
  font-size: 15px;
  border: none;
`;

const SentBy = styled.p`
  font-size: 11px;
  font-family: "Gilroy-Semibold";
  float: right;
  margin-top: 8px;
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 8px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 240px;
`;

const SearchInput = styled.input`
  border: none;
  padding: 8px;
  width: 100%;
  font-family: "Gilroy-Medium";
  font-size: 14px;

  &:focus {
    outline: none;
  }
`;

const InfoButtons = styled.button<{ active?: boolean }>`
  min-width: 120px;
  background-color: ${(props) => (props.active ? "#f8f9fa" : "#fff")};
  height: 60px;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid ${(props) => (props.active ? "#0397FED1" : "#eee")};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border-color: #0397fed1;
    background-color: #f8f9fa;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;

    svg {
      margin-left: 4px;
      margin-bottom: 0;
    }
  }

  h5 {
    font-size: 14px;
    color: #000;
    font-family: "Gilroy-Semibold";
    margin: 0;
  }
`;

export default CampaignForm;
