import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { setLeadFilter } from "../../actions/actions";
import { useSelector } from "react-redux";
import { FilterParams } from "./leadsGrid/leads.types";

interface IProps {
  onChange: () => void;
}

const LeadsNewFilter = ({ onChange }: IProps) => {
  const dispatch = useDispatch();
  const { filterParam }: { filterParam: FilterParams } = useSelector(
    (state: any) => state.rootReducers.leads
  );

  const onAddFilter = (key: string) => {
    dispatch(setLeadFilter({ ...filterParam, [key]: true }));
    onChange && onChange();
  };

  const onRemoveFilter = (key: string) => {
    dispatch(setLeadFilter({ ...filterParam, [key]: undefined }));
    onChange && onChange();
  };

  return (
    <Root className="leads-section-1">
      <ul className="">
        <li className={filterParam?.isUntouched ? "active_filter" : ""}>
          <span onClick={() => onAddFilter("isUntouched")}>Untouched</span>
          <RiCloseFill onClick={() => onRemoveFilter("isUntouched")} />
        </li>
        <li className={filterParam?.isNotCalled ? "active_filter" : ""}>
          <span onClick={() => onAddFilter("isNotCalled")}>No call</span>
          <RiCloseFill onClick={() => onRemoveFilter("isNotCalled")} />
        </li>
        <li className={filterParam?.isNotAssign ? "active_filter" : ""}>
          <span onClick={() => onAddFilter("isNotAssign")}>Unassigned</span>
          <RiCloseFill onClick={() => onRemoveFilter("isNotAssign")} />
        </li>
        <li className={filterParam?.isFollowUp ? "active_filter" : ""}>
          <span onClick={() => onAddFilter("isFollowUp")}>No task</span>
          <RiCloseFill onClick={() => onRemoveFilter("isFollowUp")} />
        </li>

        <li className={filterParam?.isStaled ? "active_filter" : ""}>
          <span onClick={() => onAddFilter("isStaled")}>Stale</span>
          <RiCloseFill onClick={() => onRemoveFilter("isStaled")} />
        </li>

        {/* <li>Search all list</li> */}
      </ul>
    </Root>
  );
};

export default LeadsNewFilter;

const Root = styled.section`
  padding: 10px 15px 0px;

  ul {
    display: flex;
    column-gap: 20px;
    list-style-type: none;
    padding: 0px 0px 10px 0px;

    li {
      color: #000;
      cursor: pointer;
      position: relative;
      margin-right: 20px;
      font-size: 12px;
      font-weight: 600;

      svg {
        margin-left: 5px;
        margin-bottom: 2px;
        display: none;
      }

      &::after {
        content: "";
        width: 1px;
        height: auto;
        background-color: #0000004d;
        position: absolute;
        right: -20px;
        top: 0;
        bottom: 0;
      }

      &:last-child::after {
        width: 0px;
      }
    }
  }

  .active_filter {
    color: #2298ff !important;
    svg {
      display: inline-flex;
    }
  }
`;
