import React, { useState, useEffect } from "react";
import { Modal, Input, List, Spin } from "antd";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import axios from "axios";
import debounce from "lodash/debounce";
import { API_URL } from "../../config/config";
import { getHeaderOptions } from "../../services/getHeaderOptions";
import VirtualList from "rc-virtual-list";

interface AddChatProps {
  visible: boolean;
  onClose: () => void;
  onStartChat: (data: { phone: string; name?: string }) => Promise<void>;
}

const CONTAINER_HEIGHT = 400;
const ITEM_HEIGHT = 64;

const AddChat: React.FC<AddChatProps> = ({ visible, onClose, onStartChat }) => {
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);

  // Reset states when modal opens OR closes
  useEffect(() => {
    setSearchResults([]);
    setSearchTerm("");
    setPhoneNumber("91");
    setSendingMessage(false);
  }, [visible]);

  const debouncedSearch = debounce(async (value: string) => {
    if (!value?.trim()) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    try {
      console.log("Searching for:", value);
      const response = await axios.get(`${API_URL}api/v1/lead/search`, {
        headers: {
          ...getHeaderOptions,
          Authorization: localStorage.getItem("auth_token") || "",
        },
        params: {
          search: value,
          orderBy: "_id",
          isAscending: false,
          page: 1,
          perPage: 50,
        },
      });

      if (response.data?.data) {
        setSearchResults(response.data.data);
      }
    } catch (err) {
      console.error("Search failed:", err);
      toast.error("Search failed");
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  }, 500);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleStartNewChat = (phone: string, name?: string) => {
    setSendingMessage(true);
    onStartChat({ phone, name })
      .then(() => {
        onClose();
      })
      .catch((err) => {
        console.error("Failed to start chat:", err);
        toast.error("Failed to start chat");
      })
      .finally(() => {
        setSendingMessage(false);
      });
  };

  const isValidPhone = () => {
    const cleanPhone = phoneNumber.replace(/[^0-9]/g, "");
    return cleanPhone.length >= 10 && cleanPhone.length <= 15;
  };

  const renderListItem = (item: any) => (
    <List.Item
      key={item._id}
      className="cursor-pointer hover:bg-gray-50 px-4 py-2"
      onClick={() => handleStartNewChat(item.phone, item.name)}
    >
      <div className="flex justify-between w-full">
        <div>
          <div className="font-medium text-base">{item.name || "Unknown"}</div>
          <div className="text-sm text-gray-600">{item.phone}</div>
        </div>
      </div>
    </List.Item>
  );

  return (
    <Modal
      title={<span className="fm-gilroy">New Chat</span>}
      open={visible}
      onCancel={onClose}
      footer={null}
      width={500}
      bodyStyle={{ maxHeight: "80vh", overflow: "hidden" }}
    >
      <div className="mb-4">
        <Input.Search
          placeholder="Search by name or number..."
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          loading={loading}
          className="fm-gilroy"
          enterButton
        />
      </div>

      <div className="search-results">
        <Spin spinning={loading || sendingMessage}>
          {searchResults.length > 0 ? (
            <List>
              <VirtualList
                data={searchResults}
                height={CONTAINER_HEIGHT}
                itemHeight={ITEM_HEIGHT}
                itemKey="id"
              >
                {(item) => renderListItem(item)}
              </VirtualList>
            </List>
          ) : searchTerm ? (
            <div className="text-center p-4">
              <p className="fm-gilroy text-gray-500 mb-4">
                No leads found. Enter a phone number to start new chat.
              </p>
              <div className="mt-4">
                <PhoneInput
                  country={"in"}
                  value={phoneNumber}
                  onChange={(value) => setPhoneNumber(value)}
                  containerClass="mb-4"
                  inputClass="w-full"
                  specialLabel=""
                />
                <button
                  className={`w-full p-2 rounded fm-gilroy ${
                    isValidPhone() && !sendingMessage
                      ? "bg-blue-600 text-white cursor-pointer"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed"
                  }`}
                  onClick={() => handleStartNewChat(phoneNumber)}
                  disabled={!isValidPhone() || sendingMessage}
                >
                  {sendingMessage ? "Starting Chat..." : "Start New Chat"}
                </button>
              </div>
            </div>
          ) : (
            <div className="text-center p-4">
              <p className="fm-gilroy text-gray-500">
                Search leads to start a conversation
              </p>
            </div>
          )}
        </Spin>
      </div>
    </Modal>
  );
};

export default AddChat;
