import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import toast, { Toaster } from "react-hot-toast";
import { generateOtp, signIn } from "../../services/authServices";
import { setLogId } from "../../actions/actions";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import googleIcon from "../../assets/images/google-logo.svg";
import facebookIcon from "../../assets/images/facebook-logo.svg";
import { gapi } from "gapi-script";

const Login: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("91");
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastRequestTime, setLastRequestTime] = useState<number>(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Only check for auth token
    const authToken = localStorage.getItem("auth_token");
    if (authToken) {
      navigate("/leads", { replace: true });
    }
  }, [navigate]);

  const validatePhoneNumber = useCallback((number: string): boolean => {
    const phoneNumberPattern = /^[1-9]\d{9}$/;
    return phoneNumberPattern.test(number);
  }, []);

  const handlePhoneChange = useCallback(
    (value: string, country: any) => {
      const newCountryCode = country.dialCode;
      const numberWithoutCode = value.slice(newCountryCode.length);

      setPhoneNumber(numberWithoutCode);
      setCountryCode(newCountryCode);
      setIsValid(validatePhoneNumber(numberWithoutCode));
    },
    [validatePhoneNumber]
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validatePhoneNumber(phoneNumber)) {
        setIsValid(false);
        return;
      }

      const currentTime = Date.now();
      if (currentTime - lastRequestTime < 60000) {
        toast.error("Please wait a moment before requesting another OTP");
        return;
      }

      setIsLoading(true);

      try {
        const response = await generateOtp({
          phone: phoneNumber,
          countryCode: countryCode,
          otpType: "sms",
        });

        if (response && response.status) {
          localStorage.setItem("phone", phoneNumber);
          localStorage.setItem("countryCode", countryCode);
          toast.success("OTP Sent Successfully!");
          dispatch(setLogId(response?.data?.data?.LogID));
          setLastRequestTime(currentTime);
          navigate("./otp-verify");
        }
      } catch (error) {
        console.error("Error in API call:", error);
        toast.error(
          "Error occurred while sending OTP. Please try again later."
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      phoneNumber,
      countryCode,
      lastRequestTime,
      validatePhoneNumber,
      navigate,
      dispatch,
    ]
  );

  const responseGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        console.log("Google Token Response:", tokenResponse);

        const userInfoResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          }
        );

        console.log("Google User Info:", userInfoResponse.data);

        localStorage.setItem("email", userInfoResponse.data.email);

        const data = {
          loginType: "google",
          loggedFrom: "web",
          identityToken: tokenResponse.access_token,
        };

        console.log("Data being sent to signIn:", data);

        const resp = await signIn(data);
        console.log("Sign In Response:", resp);

        if (resp && resp.status) {
          localStorage.setItem("auth_token", resp?.data?.data?.token);
          localStorage.setItem("userData", JSON.stringify(resp?.data?.data));

          if (resp?.data?.data?.isOnboardCompleted) {
            if (resp?.data?.data?.isSubscriptionActive) {
              toast.success("Logged In Successfully!");
              navigate("/leads", { replace: true });
            } else {
              toast.error("Your subscription is inactive.");
              navigate("/", { replace: true });
            }
          } else {
            navigate("./sign-up");
          }
        }
      } catch (err) {
        console.error("Login Error:", err);
        toast.error("Error occurred while logging in!");
      }
    },
    onError: (error) => {
      console.error("Google Login Error:", error);
      toast.error("Error occurred while logging in!");
    },
  });

  const responseFacebook = async (response: any) => {
    console.log("facebook response", response);

    if (response.accessToken) {
      try {
        const body = {
          loggedFrom: "web",
          identityToken: response.accessToken,
          loginType: "facebook",
        };

        const resp = await signIn(body);
        if (resp && resp.status) {
          if (resp?.data?.data?.isSubscriptionActive) {
            if (resp?.data?.data?.isOnboardCompleted) {
              localStorage.setItem("auth_token", resp?.data?.data?.token);
              localStorage.setItem(
                "userData",
                JSON.stringify(resp?.data?.data)
              );
              toast.success("Logged In Successfully!");
              navigate("/leads", { replace: true });
            } else {
              navigate("/sign-up", { replace: true });
            }
          } else {
            toast.error("Your subscription is inactive.");
            navigate("/", { replace: true });
          }
        }
      } catch (err) {
        toast.error("Error occurred while logging in!");
      }
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center login_section">
        <div className="col-12 col-md-6 col-lg-5 col-xl-4 login_card">
          <div
            className="card login-card shadow-lg p-4"
            style={{
              maxWidth: "650px",
              width: "100%",
              margin: "0 auto",
              borderRadius: "15px",
              backgroundColor: "#ebeff4",
            }}
          >
            <div
              className="d-flex flex-column align-items-center"
              style={{
                width: "100%",
              }}
            >
              <img
                src="assets/img/3sigma_logo.png"
                alt="logo"
                className="mb-4"
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
              <div className="login-header text-center mb-4">
                <h2
                  style={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "1.75rem",
                  }}
                >
                  Login to Your Account
                </h2>
                <p className="text-muted mt-2" style={{ fontSize: "0.95rem" }}>
                  We'll send a verification code to this phone number.
                </p>
              </div>
              <form onSubmit={handleSubmit} className="login_form w-100">
                <div className="form_container_login">
                  <PhoneInput
                    country="in"
                    value={`${countryCode}${phoneNumber}`}
                    onChange={handlePhoneChange}
                    inputClass={`phone_input_left ${
                      !isValid ? "is-invalid" : ""
                    }`}
                    containerStyle={{ marginBottom: "1rem", width: "100%" }}
                    inputStyle={{
                      width: "100%",
                      height: "50px",
                      fontSize: "16px",
                    }}
                    buttonStyle={{
                      backgroundColor: "#FFF",
                      borderRightWidth: 0,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                    }}
                  />
                  {!isValid && (
                    <small className="text-danger mb-3 ps-0">
                      Please enter a valid mobile number
                    </small>
                  )}
                  <button
                    type="submit"
                    className="btn btn-primary login-btn w-100 mt-3"
                    style={{
                      backgroundColor: "#3A4B86",
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderRadius: "8px",
                    }}
                    disabled={isLoading || !isValid}
                  >
                    {isLoading ? "Sending Code..." : "Get OTP Code"}
                  </button>
                </div>
              </form>
              <div className="login-footer mt-4 w-100">
                <p
                  className="text-center text-muted mb-3"
                  style={{ fontWeight: "500" }}
                >
                  or login using
                </p>
                <div className="circle-container d-flex justify-content-center">
                  <FacebookLogin
                    appId="620404531989611"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <div
                        className="social-combobox cursor-pointer mx-2"
                        onClick={renderProps.onClick}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          backgroundColor: "#fff",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={facebookIcon}
                          alt="facebook"
                          className="social-log"
                          style={{ width: "60px", height: "60px" }}
                        />
                      </div>
                    )}
                  />

                  <div
                    className="social-combobox cursor-pointer mx-2"
                    onClick={() => responseGoogle()}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={googleIcon}
                      alt="google"
                      className="social-log"
                      style={{ width: "60px", height: "60px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center text-muted mt-4" style={{ fontSize: "0.8rem" }}>
                  By logging in, you agree to our <a href="https://www.3sigmacrm.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="https://www.3sigmacrm.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </p>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Login;
