import React, { useState } from "react";

interface Template {
  _id: number;
  title: string;
  type: string;
  details: any;
  message: string;
  subject: string;
  createdBy: string;
  createdAt: string;
  tag: string;
  data: any;
}

interface EmailTemplateListProps {
  templates: Template[];
  onEmailTemplateSelect: (template: Template) => void;
  selectedId?: number;
}

const EmailTemplateList: React.FC<EmailTemplateListProps> = ({
  templates,
  onEmailTemplateSelect,
  selectedId,
}) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    selectedId || null
  );

  const handleEmailTemplateChange = (template: Template) => {
    setSelectedTemplateId(template._id);
    onEmailTemplateSelect && onEmailTemplateSelect(template);
  };

  return (
    <>
      {templates.map((item: any, key: any) => (
        <div
          className={`container_body_template border ${
            selectedTemplateId === item._id ? "selected_template" : ""
          }`}
          key={key}
          onClick={() => {
            handleEmailTemplateChange(item);
          }}
        >
          <div className="container_body_template_header">
            <div>
              <h6 style={{ fontWeight: 600 }}>
                {item?.details?.title?.split("_").join(` `)}
              </h6>
              <h6 style={{ fontWeight: 400, fontSize: "12px" }}>
                {item?.details?.subject}
              </h6>
            </div>
            <div>
              <div>
                {item?.details?.tags?.map((tag: string, index: number) => (
                  <div
                    key={index}
                    className="label rounded-8 fs-7 py-1 px-2 mb-1"
                  >
                    {tag}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="container_body_template_body p-2">
            <p style={{ fontSize: 16 }}>{item?.details?.message}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default EmailTemplateList;
