import { ErrorMessage } from "formik";
import { Error } from "./createTemplate";
import MyToolTip from "../../../components/MyToolTip";
import Inputs from "../../../components/inputs";
import { Fragment, useState, useRef } from "react";
import GlobalDrawer from "../../../components/GlobalDrawer";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

interface TeamMember {
  value: string;
  label: string;
  type: string;
}

const MAX_CHARS = 1024;

const BodySection = ({
  expandedSections,
  toggleSection,
  bodyValue,
  setBodyValue,
  values,
  errorsObj,
  handleChange,
}: any) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [assignLeadDrawer, setAssignLeadDrawer] = useState(false);

  const StateData = useSelector((state: any) => {
    return state?.rootReducers;
  });

  const teamMembers: TeamMember[] =
    StateData?.userData?.userDetails?.userPreference?.customForm?.map(
      (item: any) => {
        return {
          value: item?.value,
          label: item?.name,
          type: item?.type,
        };
      }
    ) || [];

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    if (newValue.length <= MAX_CHARS) {
      setBodyValue(newValue);
    } else {
      toast.error(`Maximum ${MAX_CHARS} characters allowed`);
    }
  };

  const insertLeadField = (item: TeamMember) => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const textBefore = bodyValue.substring(0, start);
    const textAfter = bodyValue.substring(end);
    const fieldText = `{{${item.value}}}`;

    const newValue = `${textBefore}${fieldText}${textAfter}`;

    if (newValue.length <= MAX_CHARS) {
      setBodyValue(newValue);
      setAssignLeadDrawer(false);

      // Restore cursor position
      setTimeout(() => {
        textarea.focus();
        textarea.setSelectionRange(
          start + fieldText.length,
          start + fieldText.length
        );
      }, 0);
    } else {
      toast.error(
        `Adding this field would exceed the ${MAX_CHARS} character limit`
      );
    }
  };

  return (
    <Fragment>
      <div className="section_block">
        <div
          className={`section_heading ${
            expandedSections["section2"] ? "" : "mb-0"
          }`}
        >
          <div className="me-auto">
            <label>
              Body (mandatory){" "}
              <MyToolTip
                id={`BodyTooltip`}
                text={`Enter the main content of your message here, using placeholders such as {{1}} for dynamic information (e.g., customer name, code).Example: "Your OTP is {{1}}. Use this to verify your account."`}
              >
                <i className="lh-normal text-muted fs-5 ri-information-fill ms-1"></i>
              </MyToolTip>
            </label>
          </div>
          <button
            className="border-0 bg-transparent"
            type="button"
            onClick={() => {
              setAssignLeadDrawer(true);
            }}
          >
            Insert lead field{" "}
          </button>
          <svg
            viewBox="0 0 24 24"
            focusable="false"
            className={`arrow-icon ${
              expandedSections["section2"] ? "expanded" : ""
            }`}
            aria-hidden="true"
            onClick={() => toggleSection("section2")}
          >
            <path
              fill="currentColor"
              d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
            ></path>
          </svg>
        </div>

        {expandedSections["section2"] && (
          <>
            <textarea
              ref={textareaRef}
              rows={5}
              placeholder={`${"{{name}}"}\n\nthank you for your inquiry. Someone from our team will be in touch`}
              name="body"
              id="templateBody"
              value={bodyValue}
              onChange={handleTextChange}
              className="form-control py-2 bg-lighter"
            ></textarea>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ErrorMessage name="body" component={Error} />
              <p className="charLength">
                {bodyValue?.length}/{MAX_CHARS}
              </p>
            </div>

            <div className="section_heading">
              <div>
                <label className="fs-8">
                  Footer{" "}
                  <MyToolTip
                    id={`footerTooltip`}
                    text={`You can add a small footer message for additional information (e.g., "Contact support for any help."). This section is optional.`}
                  >
                    <i className="lh-normal text-muted fs-5 ri-information-fill ms-1"></i>
                  </MyToolTip>
                </label>
              </div>
            </div>
            <Inputs
              name="footer"
              type="text"
              autoComplete="off"
              className="form-control py-2 h-45 bg-lighter"
              placeholder="Footer text for unsubscribe or a tagline etc.."
              value={values.footer}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e)
              }
              label=""
              isRequired={false}
              options={""}
              id={``}
            />
            {errorsObj["footer"] && <Error>{errorsObj["footer"]}</Error>}
          </>
        )}
      </div>

      <GlobalDrawer
        openWithHeader={assignLeadDrawer}
        setOpenWithHeader={() => {
          setAssignLeadDrawer(false);
        }}
        drawerTitle={"Insert Lead"}
        size="xs"
      >
        <div>
          {teamMembers?.map((item: TeamMember, ix: number) => {
            return (
              <button
                key={ix}
                onClick={(e) => {
                  e.preventDefault();
                  insertLeadField(item);
                }}
                className="d-block bg-white w-100 p-3 rounded mb-2"
                style={{ margin: "8px 0px", textAlign: "left" }}
              >
                {item.label}
              </button>
            );
          })}
        </div>
      </GlobalDrawer>
    </Fragment>
  );
};

export default BodySection;
