import React, { useState } from "react";
import "./whatsappTemplate.css";
import moment from "moment";

interface Template {
  _id: number;
  name: string;
  type: string;
  body: string;
  language: string;
  createdBy: string;
  createdAt: string;
  components: any;
  category: string;
  status: string;
}

interface TemplateListProps {
  templates: Template[];
  onTemplateSelect: (template: Template) => void;
  selectedId?: number;
}

const TemplateList: React.FC<TemplateListProps> = ({
  templates,
  onTemplateSelect,
  selectedId,
}) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    selectedId || null
  );

  const handleTemplateChange = (template: Template) => {
    setSelectedTemplateId(template._id);
    onTemplateSelect && onTemplateSelect(template);
  };

  return (
    <>
      {templates
        ?.sort((a: any, b: any) => {
          const aId = a._id.toString();
          const bId = b._id.toString();
          if (bId === selectedTemplateId?.toString()) return 1;
          if (aId === selectedTemplateId?.toString()) return -1;
          return aId.localeCompare(bId);
        })
        ?.map((item, key) => (
          <div
            className={`container_body_template border ${
              selectedTemplateId === item._id ? "selected_template" : ""
            }`}
            key={key}
            onClick={() => {
              handleTemplateChange(item);
            }}
          >
            <div className="container_body_template_header">
              <div>
                <h6 style={{ fontWeight: 600 }}>
                  {item.name?.split("_").join(` `)}
                </h6>
                <h6 style={{ fontWeight: 400, fontSize: "12px" }}>
                  {item.category}
                </h6>
              </div>
              <div>
                <div className="label rounded-8 fs-7 py-1 px-2">
                  {item.status}
                </div>
              </div>
            </div>
            <div className="container_body_template_body p-2">
              <p style={{ fontSize: 16 }}>
                {
                  item?.components?.find((item: any) => item.type === "BODY")
                    ?.text
                }
              </p>
            </div>
            <div className="container_body_template_footer">
              <h6 className="fs-6 fw-bold fm-gilroyBold bg-lighter rounded-8 py-1 px-2 text-dark text-uppercase">
                {item.language?.split("_").join(` `)}
              </h6>
              <h6 className="fs-11 fw-bold text-end">
                Created By {item.createdBy} on{" "}
                {moment(item.createdAt).format("Do MMM YYYY")}
              </h6>
            </div>
          </div>
        ))}
    </>
  );
};

export default TemplateList;
