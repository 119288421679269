import { AlertCircle, ArrowUpCircle, ExternalLink } from 'lucide-react';
import { useState, useEffect } from 'react';
import styles from "./ultimatePlan.module.scss";

const UltimatePlanInfo = () => {
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    // Add a slight delay before showing the component for a smooth entrance
    const timer = setTimeout(() => setIsVisible(true), 100);
    console.log('UltimatePlanInfo mounted');
    return () => {
      clearTimeout(timer);
      console.log('UltimatePlanInfo unmounted');
    };
  }, []);

  return (
    <div className={styles.ultimate_container}>
      <div className={`${styles.ultimate_content} ${!isVisible ? styles.hidden : ''}`}>
        <div className={styles.ultimate_icon}>
          <AlertCircle 
            strokeWidth={1.5} 
            className={styles.alert_icon}
          />
        </div>
        
        <h5 className={styles.ultimate_title}>
          Ultimate Plan Required
        </h5>
        
        <p className={styles.ultimate_description}>
          Access powerful advanced features and capabilities by upgrading to our Ultimate Plan subscription.
        </p>

        <div className={styles.ultimate_actions}>
          <a 
            href="https://wa.me/918814048362" 
            target="_blank" 
            rel="noopener noreferrer"
            className={styles.ultimate_button}
          >
            <ArrowUpCircle />
            <span>Upgrade Now</span>
          </a>
          
          <a 
            href="https://help.3sigmacrm.com/pricing" 
            target="_blank" 
            rel="noopener noreferrer"
            className={styles.ultimate_link}
          >
            <ExternalLink />
            <span>Learn more about plans</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default UltimatePlanInfo;