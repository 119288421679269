import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import toast, { Toaster } from "react-hot-toast";
import ErrorText from "../../components/errorText";
import Switch from "../../components/switch";
import {
  createLeadList,
  formPutLeadList,
  getSingleLeadList,
} from "../../services/leadListService";
import {
  IOrganizationEmployee,
  IOrganizationRole,
  IOrganizationTeam,
} from "../../utils/types";
import TeamMemberItem from "./teamMemberItem";

import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

export interface TeamMembersT {
  _id: string;
  name: string;
  organization: string;
  role: string;
}

interface Props {
  mode: string;
  id: string;
  getLeadList: () => void;
  setShowAddListDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

let userData = localStorage.getItem("userData") || "{}";
if (userData === "undefined") {
  userData = "{}";
}
const teamMembersList: IOrganizationEmployee[] =
  JSON.parse(userData).organizationEmployee;
const organizationTeams: IOrganizationTeam[] =
  JSON.parse(userData).organizationTeams;
const organizationRoles: IOrganizationRole[] =
  JSON.parse(userData).organizationRoles;

  const AddEditList: React.FC<PropsWithChildren<Props>> = ({
    mode,
    id,
    getLeadList,
    setShowAddListDrawer,
  }) => {
  const [teamMembers, setTeamMembers] = useState<Array<TeamMembersT>>([]);
  const [enableLeadDistribution, setEnableLeadDistribution] =
    useState<boolean>(false);
  const [roundRobin, setRoundRobin] = useState<boolean>(true);
  const [distributionType, setDistributionType] =
    useState<string>("round-robin");
  const [weighted, setWeighted] = useState<boolean>(false);
  const [listName, setListName] = useState<string>("");
  const [checkedState, setCheckedState] = useState<{
    [_id: string]: { recepient: boolean; access: boolean };
  }>({});
  const [weightedState, setWeightedState] = useState<Array<number>>([]);
  const [recipients, setRecipients] = useState<Array<string>>([]);
  const [error, setError] = useState({
    listName: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchLeadList = useCallback(async (id: string) => {
    setIsLoading(true);
    try {
      const response = await getSingleLeadList(id);
      if (response && response.status === 200) {
        const data = response.data.data;
        setListName(data.name);
        setEnableLeadDistribution(data.isDistributionActive);
        setDistributionType(data.distributionType);
        const recipients = data.recipients;
        const readAccess = data.readAccess;
        const checkedStateObject: {
          [_id: string]: { recepient: boolean; access: boolean };
        } = {};
        if (recipients && typeof recipients === "object") {
          Object.keys(recipients.ids).forEach((id: string) => {
            const recipient = recipients.ids[id];
            if (recipient.status && recipient.waitage === 1) {
              checkedStateObject[id] = { recepient: true, access: false };
            }
          });
        }
        if (readAccess && Array.isArray(readAccess)) {
          readAccess.forEach((id: string) => {
            if (checkedStateObject[id]) {
              checkedStateObject[id].access = true;
            } else {
              checkedStateObject[id] = { recepient: false, access: true };
            }
          });
        }
        setCheckedState(checkedStateObject);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (mode === "edit" && id) {
      fetchLeadList(id);
    }
  }, [mode, id, fetchLeadList]);

  const onEnterListName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setListName(e.target.value);
    setError({ ...error, listName: "" });
  };

  const handleChange = (type: "recepient" | "access", id: string) => {
    const updatedCheckedState = {
      ...checkedState[id],
      [type]: !checkedState[id]?.[type],
    };
    setCheckedState({ ...checkedState, [id]: updatedCheckedState });
  };

  const renderTeamMembers = (type: "recepient" | "access") => {
    return teamMembersList
      ?.filter((m) => m.isActive)
      ?.map((member, i) => {
        const teamOrganization = organizationTeams.find(
          (o) => o.organization === member.organization
        );
        const organizationRole = organizationRoles.find(
          (o) => o.organization === member.organization
        );

        const teamMember: TeamMembersT = {
          name: member.lastName
            ? `${member.firstName} ${member.lastName}`
            : member.firstName,
          organization: teamOrganization?.name ?? "",
          _id: member._id,
          role: organizationRole?.displayName ?? "",
        };

        return (
          <div className="form-group2" key={i}>
            <div className="d-flex justify-content-between">
              <TeamMemberItem
                teamMember={teamMember}
                checked={checkedState[member._id]?.[type]}
                handleChange={(id: string) => handleChange(type, id)}
              />
            </div>
          </div>
        );
      });
  };

  const handleSubmit = async (id?: string) => {
    if (listName) {
      const recipientsObject = Object.keys(checkedState).reduce(
        (accumulator: any, id) => {
          if (checkedState[id]?.recepient) {
            accumulator[id] = {
              status: checkedState[id]?.recepient || false,
              waitage: checkedState[id]?.recepient ? 1 : 0,
            };
          }
          return accumulator;
        },
        {}
      );

      const data = {
        name: listName,
        isDistributionActive:
          enableLeadDistribution &&
          Object.keys(checkedState).some((id) => checkedState[id]?.recepient),
        distributionType: distributionType,
        readAccess: Object.keys(checkedState)?.filter(
          (id) => checkedState[id]?.access
        ),
        recipients: {
          ids: recipientsObject,
        },
      };

      try {
        let response;
        if (id) {
          response = await formPutLeadList(id, data);
        } else {
          response = await createLeadList(data);
        }
        if (response && response.status) {
          getLeadList();
          toast.success(response?.data?.message);
          setShowAddListDrawer(false);
        }
      } catch (err) {
        toast.error("Error while adding lead list !");
      }
    } else {
      setError({
        ...error,
        listName: "List Name is required",
      });
    }
  };

  const CustomToggle = ({ eventKey }: { eventKey: string }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-play-circle-fill"
        viewBox="0 0 16 16"
        onClick={useAccordionButton(eventKey)}
      >
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
      </svg>
    );
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <form className="addleadsform add-edit-list" style={{ position: 'relative', paddingBottom: '60px' }}>
        <div className="form-container">
          <div className="product_group">
            <div className="form-group">
              <label
                className="form-label"
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                List Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={isLoading ? "Loading..." : "Enter list name"}
                value={listName}
                onChange={(e) => onEnterListName(e)}
                style={{
                  borderRadius: "10px",
                  height: "43px",
                  padding: "0 15px",
                  marginBottom: "7px",
                }}
                disabled={isLoading}
              />
              {error.listName ? <ErrorText message={error?.listName} /> : ""}
            </div>
            <Accordion className="edit-list-accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h3>Lead distribution</h3>
                  <p>Equally distribute all leads in this list automatically</p>
                </Accordion.Header>
                <Accordion.Body>
                  {renderTeamMembers("recepient")}
                </Accordion.Body>
                <CustomToggle eventKey="0" />
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h3>List access</h3>
                  <p>Grant access to all leads in this list</p>
                </Accordion.Header>
                <Accordion.Body>{renderTeamMembers("access")}</Accordion.Body>
                <CustomToggle eventKey="1" />
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="auto_form_btn">
             <button
   type="button"
   onClick={() => handleSubmit(id)}
   className="btn btn-primary-save"
   disabled={isLoading || listName.trim() === ''}
    style={{
      width: '100%',
      maxWidth: '300px',
      margin: '0 auto',
      display: 'block',
    }}
  >
              {mode === "add" ? "Add List" : "Update List"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditList;
