import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  leadFileList,
  resetLeadFilter,
  setLeadFilter,
  setLeadList,
  setLeadName,
  setLeadTaskList,
} from "../../actions/actions";
import Header from "../../components/header";
import { deleteLeadList, getAllLeadList } from "../../services/leadListService";
import {
  copyLeadToLeadList,
  deleteLead,
  moveLeadToLeadList,
} from "../../services/leadService";
import { deleteLeadFile } from "../../services/utilityService";
import CreateLeadForm from "./createLeadForm";
import LeadFilter from "./leadFilter";
import LeadsList from "./leadsList";
// import _ from "lodash";
import { ColDef } from "ag-grid-community";
import toast, { Toaster } from "react-hot-toast";
import { CSSProperties } from "styled-components";
import ConfirmationModal from "../../components/confirmationModal";
import CopyLeadToList from "../../components/copyLeadToList";
import CreateLeadDetailsTaskForm from "../../components/createLeadTaskForm";
import DrawerComponent from "../../components/drawer";
import DrawerExistModal from "../../components/drawerExistModal";
import SelectLeadLabel from "../../components/label";
import LeadDrawerComponent from "../../components/leadDrawer";
import MoveLeadToList from "../../components/moveLeadToList";
import SelectLeadStatus from "../../components/status";
import { API_URL } from "../../config/config";
import { activityById } from "../../services/activityService";
import { deleteTask, taskById } from "../../services/taskService";
import { uploadFiles } from "../../services/utilityService";
import ManageLeadsColumns from "./ManageColumns";
import AddEditList from "./addEditList";
import CustomLeadForm from "./customLeadForm";
import LeadDetails from "./leadDetails";
import LeadsGrid from "./leadsGrid/leads-grid";
import { FilterParams } from "./leadsGrid/leads.types";
import UploadCsvFile from "./uploadCsvFile";
import { FiFilter } from "react-icons/fi";
import LeadsNewFilter from "./leadsNewFilter";

const API_ENDPOINT = API_URL + "api/v1";

const leadsWrapperStyle: CSSProperties = {
  marginTop: 0,
  position: "relative",
  top: "30px",
};
export interface LeadDataI {
  _id: string;
  name: string;
  integration: string;
  customSource: string;
  createdAt: Date;
  status: Array<string>;
  saleValue: string;
  website: string;
  phone: string;
  email: string;
  label: Array<any>;
}
export interface LeadItemI {
  _id: string;
  label: Array<any>;
  // Add other properties as needed
}

const Leads: React.FC = () => {
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const { leadCount } = useSelector((state: any) => state.rootReducers.leads);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [createNewLeadDrawer, showCreateNewLeadDrawer] =
    useState<boolean>(false);
  const [copyLeadToListModal, setShowCopyLeadToList] = useState<boolean>(false);
  const [moveLeadToListModal, setShowMoveLeadToList] = useState<boolean>(false);
  const [showAddListDrawer, setShowAddListDrawer] = useState<boolean>(false);
  const [openWithHeader, setOpenWithHeader] = React.useState<boolean>(false);
  const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false);
  const [showStatusPopup, setShowStatusPopup] = useState<boolean>(false);
  const [showLabelPopup, setShowLabelPopup] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [leadToBeUpdate, setLeadToBeUpdate] = useState<string>("");
  const [selectedLabel, setSelectedLabel] = useState<Array<string>>([]);
  const [selectedLead, setSelectedLead] = useState<LeadDataI>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [leadListId, setLeadListId] = useState<string>("");
  const [from, setFrom] = useState<string>("");
  const [mode, setMode] = useState<string>("");
  const [toBeRemoved, setToBeRemoved] = useState<string>("");
  const [updateModalValue, setUpdateModalValue] = useState<any>({});
  const [activityformTitle, setActivityFormTitle] = useState<string>("Add");
  const [activityformName, setActivityFormName] = useState<string>("");
  const [leadLabelData, setLeadLabelData] = useState<Array<string>>(
    StoreData?.user?.userPreferences?.labels ?? []
  );
  const [leadListData, setLeadListData] = useState<Array<any>>([]);
  const [taskShowConfirmationModal, setTaskShowConfirmationModal] =
    useState<boolean>(false);
  const [taskDeteleId, setTaskDeleteId] = useState<string>("");
  const [leadId, setLeadId] = useState<any>();
  const [showExitModal, setShowExitModal] = useState<boolean>(false);
  const [selectedLeadList, setSelectedLeadList] = useState<any>({});
  const [leadListObj, setLeadListObj] = useState<Array<string>>([]);
  const [leadFileObj, setLeadFileObj] = useState<any>({});
  const leadsGridRef = useRef<any>(null);
  const { filterParam }: { filterParam: FilterParams } = useSelector(
    (state: any) => state.rootReducers.leads
  );
  const [showFileDeleteModal, setShowFileDeleteModal] =
    useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isBulk, setIsBulk] = useState(false);

  const dispatch = useDispatch();

  // let userPreferences = JSON.parse(
  //   localStorage.getItem("user_preferences") || ""
  // );

  let userPreferences: any = {};

  try {
    userPreferences = JSON.parse(
      localStorage.getItem("user_preferences") || ""
    );
  } catch (e) {
    userPreferences = {};
  }

  useEffect(() => {
    setLeadLabelData(StoreData?.user?.userPreferences?.labels ?? []);
  }, [StoreData?.user?.userPreferences?.labels]);

  const hasKey = userPreferences.hasOwnProperty("custom_form");

  useEffect(() => {
    refreshData();
  }, [selectedLeadList]);

  const onAddList = (mode: React.SetStateAction<string>) => {
    setShowAddListDrawer(true);
    setMode(mode);
    setLeadListId("");
  };

  const onEditList = (mode: React.SetStateAction<string>, id: any) => {
    setShowAddListDrawer(true);
    setMode(mode);
    setLeadListId(id);
  };

  const handleLeadNameChange = (id: any, name: string) => {
    let tempObj = {
      id: id,
      name: name,
    };
    dispatch(setLeadName(tempObj));
    setSelectedLeadList(tempObj);
    leadsGridRef.current && leadsGridRef.current.fetchLeadsWrapper();
  };

  const getLeadList = useCallback(async () => {
    try {
      const resp = await getAllLeadList({
        isAscending: false,
        page: 1,
        perPage: 50,
      });
      if (resp && resp.status) {
        dispatch(setLeadList(resp?.data?.data));
      }
    } catch (err) {}
  }, [dispatch]);

  const onLeadClick = (rowData: any) => {
    let tempArray = [];
    tempArray = StoreData?.user?.userPreferences?.labels
      ? StoreData?.user?.userPreferences?.labels
      : userPreferences?.labels;
    const updateArray = tempArray.map((obj: any) => {
      return {
        ...obj,
        isLabelChecked: rowData?.label?.includes(obj.value) || false,
      };
    });
    setSelectedLead(rowData);
    if (rowData?.status) {
      setSelectedStatus(rowData?.status[0]);
    }
    setLeadId(rowData?._id);
    setLeadLabelData(updateArray);
    setOpenWithHeader(true);
  };

  const toggleLeadFilterDrawer = () => {
    setShowFilterDrawer(true);
  };

  const handleDrawerClick = () => {
    setShowExitModal(!showExitModal);
    showCreateNewLeadDrawer(false);
  };
  const handleDrawerModalClose = () => {
    setShowExitModal(false);
    showCreateNewLeadDrawer(false);
  };
  const toggleEditModal = () => {
    setShowExitModal(!showExitModal);
  };

  const openCreateLeadDrawer = () => {
    showCreateNewLeadDrawer(true);
    setIsEditMode(false);
  };

  const editLead = (e: { stopPropagation: () => void }, id: any) => {
    e.stopPropagation();
    showCreateNewLeadDrawer(true);
    setOpenWithHeader(false);
    setIsEditMode(true);
    setLeadToBeUpdate(id);
  };

  const removeLead = (e: { stopPropagation: () => void }, id: any) => {
    e.stopPropagation();
    setLeadToBeUpdate(id);
    setShowConfirmationModal(true);
    setToBeRemoved("lead");
  };

  const deleteLeadFromDrawer = (id: string) => {
    setLeadToBeUpdate(id);
    setShowConfirmationModal(true);
    setToBeRemoved("lead");
    setOpenWithHeader(false);
  };

  const toggleModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const onConfirmation = async () => {
    if (toBeRemoved === "lead") {
      try {
        const deleteResponse = await deleteLead(leadToBeUpdate);
        if (deleteResponse && deleteResponse.status) {
          toast.success(deleteResponse?.data?.message);
          refreshData();
        }
      } catch (err) {
        toast.error("Error while deleting the lead");
      }
    } else {
      try {
        const response = await deleteLeadList(leadListId);
        if (response && response.status) {
          toast.success(response?.data?.message);
          getLeadList();
        }
      } catch (err) {
        toast.error("error while deleting lead-list");
      }
    }
    setShowConfirmationModal(false);
  };

  const onDeleteClick = (id: string) => {
    setShowConfirmationModal(true);
    setToBeRemoved("leadlist");
    setLeadListId(id);
  };

  const copyLeadToList = () => {
    // setShowCopyLeadToList(!copyLeadToListModal);
  };

  const onCopy = async (id: string) => {
    let leadIds = [];
    leadIds.push(selectedLead?._id);
    try {
      const response = await copyLeadToLeadList({
        leadIDs: leadIds,
        listID: id,
      });
      if (response && response.status) {
        toast.success(response?.data?.message);
        copyLeadToList();
      }
    } catch (err) {
      toast.error("Error while copying lead to list");
      copyLeadToList();
    }
  };

  const moveLeadToList = () => {
    setShowMoveLeadToList(!moveLeadToListModal);
  };

  const onMove = async (id: string) => {
    let leads = [];
    leads.push(selectedLead?._id);
    try {
      const response = await moveLeadToLeadList({
        leadIDs: leads,
        toListID: id,
      });
      if (response && response.status) {
        toast.success(response?.data?.message);
        moveLeadToList();
      }
    } catch (err) {
      toast.error("Error while copying lead to list");
      moveLeadToList();
    }
  };

  const onSearch = async (search?: string) => {
    dispatch(setLeadFilter({ ...filterParam, search: search || undefined }));
    setShowFilterDrawer(false);
    leadsGridRef.current && leadsGridRef.current.fetchLeadsWrapper();
  };

  const onLabelClick = () => {
    setShowLabelPopup(true);
  };

  const toggleLeadLabel = () => {
    setShowLabelPopup(!showLabelPopup);
  };

  const onStatusClick = () => {
    setShowStatusPopup(true);
  };

  const toggleLeadStatus = () => {
    setShowStatusPopup(!showStatusPopup);
    setSelectedStatus("");
  };

  const onLabelChange = async (e: any) => {
    if (!e?.target?.value) return;

    let Value = e.target.value;
    let newArray: any = [...selectedLabel];
    let labelArray: any = [...leadLabelData];
    let tempObj: any = {};
    tempObj = labelArray.find((x: any) => x.value === Value);
    if (tempObj?.isLabelChecked) {
      tempObj.isLabelChecked = false;
    } else {
      tempObj.isLabelChecked = true;
    }
    if (selectedLabel.includes(Value)) {
      let index = newArray.findIndex((d: string) => d === Value);
      newArray.splice(index, 1);
    } else {
      newArray = newArray.filter((d: string) => d !== Value);
      newArray.push(Value);
    }
    setLeadLabelData(await labelArray);
    setSelectedLabel(newArray);
  };

  const onStatusChange = (e: any) => {
    setSelectedStatus(e.target.value);
  };

  const handleEditActivityModal = async (
    name: string,
    _id: any,
    action: string
  ) => {
    if (name === "activity") {
      try {
        const response = await activityById(_id);
        if (response && response.status) {
          let modalId = "#AddNewActivites";
          handleActivityModal(action, "Activity");
          setUpdateModalValue(response.data.data);
          $(modalId).modal("show");
        }
      } catch (err) {
        toast.error("error while fetching activity data.");
      }
    }
    if (name === "task") {
      try {
        const response = await taskById(_id);
        if (response && response.status) {
          let modalId = "#AddNewTask";
          handleActivityModal(action, "Task");
          setUpdateModalValue(response.data.data);
          $(modalId).modal("show");
        }
      } catch (err) {
        toast.error("error while fetching activity data.");
      }
      let userPreferences = JSON.parse(
        localStorage.getItem("user_preferences") || ""
      );
    }
  };

  const handleActivityModal = (title: string, name: string) => {
    let modalId = "";
    if (name === "Activity") {
      modalId = "#AddNewActivites";
      setActivityFormTitle(title);
    } else {
      modalId = "#AddNewTask";
      setActivityFormTitle(title);
    }
    $(modalId).modal("show");
    setActivityFormName(name);
  };
  const taskToggleModal = () => {
    let modalId = "#AddNewTask";
    setActivityFormTitle("Edit");
    $(modalId).modal("show");
    setTaskShowConfirmationModal(!taskShowConfirmationModal);
  };

  const onTaskDelete = (id: string) => {
    let modalId = "#AddNewTask";
    setActivityFormTitle("Add");
    $(modalId).modal("hide");
    setTaskDeleteId(id);
    setTaskShowConfirmationModal(true);
  };
  const leadTaskDelete = async () => {
    try {
      const response = await deleteTask(taskDeteleId);
      if (response && response.status) {
        const tempArray = [...StoreData?.leadTask?.leadTask];
        const tempData = tempArray.findIndex((x) => x._id == taskDeteleId);
        tempArray.splice(tempData, 1);
        dispatch(setLeadTaskList(tempArray));
        toast.success(response?.data?.message);
      }
    } catch (err) {
      toast.error("Error while deleting task!");
    }
    setTaskShowConfirmationModal(false);
  };
  const onLeadFileUpload = async (e: any) => {
    let formData = new FormData();
    formData.append("files", e.target.files[0]);
    formData.append("type", "lead");
    formData.append("lead", leadId);
    try {
      const response = await uploadFiles(formData);
      if (response && response.status) {
        const tempArray = [...StoreData?.leadFile?.leadFile];
        tempArray?.unshift(response?.data?.data[0]);
        dispatch(leadFileList(tempArray));
        toast.success(response?.data?.message);
      }
    } catch (err) {
      toast.error("Error while uploading file!");
    }
  };
  const fileToggleModal = () => {
    setShowFileDeleteModal(!showFileDeleteModal);
  };
  const leadFileDeleteToggle = (objData: any) => {
    setLeadFileObj(objData);
    fileToggleModal();
  };

  const refreshData = () => {
    dispatch(resetLeadFilter());
    leadsGridRef.current && leadsGridRef.current.fetchLeadsWrapper();
  };

  const leadFileDelete = async () => {
    try {
      let objData = {
        type: "lead",
        lead: selectedLead?._id,
        filePaths: [leadFileObj.filePath],
      };
      const response = await deleteLeadFile(objData);
      if (response && response.status) {
        const tempArray = [...StoreData.leadFile.leadFile];
        const tempData = tempArray.findIndex(
          (x) => x.filePath == leadFileObj.filePath
        );
        tempArray.splice(tempData, 1);
        toast.success(response?.data?.message);
        dispatch(leadFileList(tempArray));
        setLeadFileObj({});
        fileToggleModal();
      }
    } catch (err) {
      toast.error("error while deleting rule.");
    }
  };

  const LeadFilterDataList = async (
    objectFilterData: Partial<FilterParams>
  ) => {
    dispatch(setLeadFilter({ ...filterParam, ...objectFilterData }));
    setShowFilterDrawer(false);
    leadsGridRef.current && leadsGridRef.current.fetchLeadsWrapper();
  };

  const handleGetLeadList = async () => {
    try {
      const response = await getAllLeadList({
        isAscending: false,
        page: 1,
        perPage: 50,
      });
      if (response && response.status) {
        setLeadListObj(response.data.data);
      }
    } catch (err) {
      console.log(err, "Error");
    }
  };

  const setStatus = (e: string[]) => {
    // const updatedData: LeadDataI = { ...selectedLead, status: e };
    // console.log('selectedStatus', e, selectedLead);
    // setSelectedLead(updatedData);
  };

  const updateEditedRow = (updatedRows: any[]) => {
    let rows = Array.isArray(updatedRows)
      ? updatedRows
      : typeof updatedRows === "object"
      ? [updatedRows]
      : [];

    if (rows && rows.length > 0) {
      leadsGridRef.current &&
        leadsGridRef.current.refreshCurrentPageInGridFn(rows);

      if (leadsGridRef.current?.getSelectedLeadIds()?.length) {
        leadsGridRef.current?.fetchLeadsWrapper();
      }
    }
  };

  useEffect(() => {
    let lead_name = StoreData.leadName.leadName;
    if (!("id" in lead_name)) {
      dispatch(setLeadName({ id: "0", name: "Lead list" }));
    }
    lead_name = StoreData.leadName.leadName;
    setSelectedLeadList(
      Object.keys(lead_name).length > 0
        ? lead_name
        : { id: "0", name: "Lead list" }
    );
    handleGetLeadList();
  }, []);

  useEffect(() => {
    if (
      !showConfirmationModal &&
      !showFilterDrawer &&
      !showExitModal &&
      !showLabelPopup &&
      !showStatusPopup
    ) {
      document.body.style.overflow = "auto";
    }
  }, [
    showConfirmationModal,
    showFilterDrawer,
    showExitModal,
    showLabelPopup,
    showStatusPopup,
  ]);

  const [selectedItemCount, setSelectedItemCount] = useState<number>(0);

  return (
    <div>
      <Header
        onSearch={(e: any) => onSearch(e.target.value)}
        closeIconClick={() => onSearch()}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        onBulk={() => setIsBulk(true)}
      />
      <div id="main" className="main" style={leadsWrapperStyle}>
        {!isBulk && selectedItemCount === 0 ? (
          <section className="leads-section-1">
            <div className="d-flex justify-content-between leads-section-1-1">
              <div className="align-items-center leads-section-1-sub-1">
                <div
                  style={{
                    backgroundColor: "#EBF0F4",
                    borderRadius: "6px",
                    width: "27px",
                    height: "26px",
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <FiFilter
                    size={15}
                    onClick={toggleLeadFilterDrawer}
                    id="filter-img"
                    color="#000"
                    className="cursor-pointer"
                  />
                </div>
                <LeadsList
                  onDeleteClick={onDeleteClick}
                  leadLists={StoreData.leadList.leadList}
                  getLeadList={getLeadList}
                  onEditList={onEditList}
                  onAddList={onAddList}
                  leadData={leadCount}
                  LeadNameChange={handleLeadNameChange}
                  selectedLeadList={selectedLeadList}
                />
              </div>

              <div className="dropdown d-flex align-items-center">
                <div
                  style={{
                    backgroundColor: "#EBF0F4",
                    borderRadius: "6px",
                    height: "26px",
                    width: "27px",
                    marginRight: 5,
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <svg
                    width="11"
                    height="11"
                    onClick={() => refreshData()}
                    className="text-black fw-bold cursor-pointer"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.38437 1.61562C8.3875 0.61875 7.01937 0 5.5 0C4.04131 0 2.64236 0.579463 1.61091 1.61091C0.579463 2.64236 0 4.04131 0 5.5C0 6.95869 0.579463 8.35764 1.61091 9.38909C2.64236 10.4205 4.04131 11 5.5 11C8.06437 11 10.2025 9.24687 10.8144 6.875H9.38437C8.82062 8.47688 7.29437 9.625 5.5 9.625C4.40598 9.625 3.35677 9.1904 2.58318 8.41682C1.8096 7.64323 1.375 6.59402 1.375 5.5C1.375 4.40598 1.8096 3.35677 2.58318 2.58318C3.35677 1.8096 4.40598 1.375 5.5 1.375C6.64125 1.375 7.65875 1.84938 8.40125 2.59875L6.1875 4.8125H11V0L9.38437 1.61562Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <ManageLeadsColumns
                  defaultColumns={leadsGridRef.current
                    ?.getColunmmnDefs()
                    ?.map((c: ColDef) => ({
                      label: c.headerName,
                      value: c.colId,
                    }))}
                  readOnlyColumns={[
                    "sno",
                    "name",
                    "phone",
                    "integration",
                    "status",
                    "label",
                    "lastActivity",
                    "assignedTo",
                  ]}
                />
                <div
                  className="dropdown ms-1"
                  style={{
                    backgroundColor: "#EBF0F4",
                    borderRadius: "6px",
                    height: "26px",
                  }}
                >
                  <button
                    className="btn dropdown-toggle text-black fw-bold lead_icon"
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Create new lead
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-play-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                    </svg>
                  </button>
                  <ul
                    className="leads_dropdown new_lead dropdown-menu pt-0 pb-0"
                    aria-labelledby="dropdownMenuButton2"
                    id="lists-dropdown"
                  >
                    <li
                      className="dropdown-item"
                      onClick={openCreateLeadDrawer}
                    >
                      Create new lead
                    </li>
                    <li
                      className="dropdown-item active"
                      onClick={openCreateLeadDrawer}
                    >
                      <svg
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 3H15L13.5 5L12 3ZM2 0H16C17.11 0 18 0.9 18 2V18C18 19.11 17.11 20 16 20H2C0.9 20 0 19.11 0 18V2C0 0.9 0.9 0 2 0ZM2 2V6H16V2H2ZM2 18H16V8H2V18ZM4 10H14V12H4V10ZM4 14H14V16H4V14Z"
                          fill="black"
                        />
                      </svg>
                      Leadform
                    </li>
                    <UploadCsvFile selectedList={selectedLeadList} />
                  </ul>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        {isOpen && (
          <LeadsNewFilter
            onChange={() =>
              leadsGridRef.current && leadsGridRef.current.fetchLeadsWrapper()
            }
          />
        )}

        <LeadsGrid
          ref={leadsGridRef}
          leadListData={leadListData}
          setLeadListData={setLeadListData}
          handleLeadClick={onLeadClick}
          onEditStatus={() => setShowStatusPopup(true)}
          onEditLabel={() => setShowLabelPopup(true)}
          setSelectedItemCount={setSelectedItemCount}
          selectedItemCount={selectedItemCount}
          showBulk={isBulk}
          onResetBulk={() => setIsBulk(false)}
        />

        <DrawerComponent
          openWithHeader={showFilterDrawer}
          setOpenWithHeader={setShowFilterDrawer}
          drawerTitle="Leads Filter"
          customHeader={false}
          size="xs"
        >
          <LeadFilter
            onLabelClick={onLabelClick}
            onStatusClick={onStatusClick}
            selectedStatus={selectedStatus}
            selectedLabel={selectedLabel}
            setShowFilterDrawer={setShowFilterDrawer}
            LeadFilterDataList={LeadFilterDataList}
          />
        </DrawerComponent>

        <DrawerComponent
          openWithHeader={showAddListDrawer}
          customHeader={true}
          setOpenWithHeader={setShowAddListDrawer}
          drawerTitle={mode === "add" ? "Add List" : "Edit lead list"}
          size="xs"
          onDeleteClick={onDeleteClick}
          id={leadListId}
        >
          <AddEditList
            mode={mode}
            id={leadListId}
            getLeadList={getLeadList}
            setShowAddListDrawer={setShowAddListDrawer}
          />
        </DrawerComponent>
        <LeadDrawerComponent
          openWithHeader={createNewLeadDrawer}
          setOpenWithHeader={toggleEditModal}
          drawerTitle={!isEditMode ? "Add New lead" : "Update lead"}
          size="lg"
        >
          {hasKey ? (
            <CustomLeadForm isEditMode={isEditMode} />
          ) : (
            <CreateLeadForm
              isDetailsBox={false}
              isEditMode={isEditMode}
              showCreateNewLeadDrawer={showCreateNewLeadDrawer}
              getLeads={refreshData}
              leadToBeUpdate={leadToBeUpdate}
              handleDrawerClick={handleDrawerModalClose}
              selectedLeadId={selectedLeadList.id}
              leadList={leadListObj}
            />
          )}
        </LeadDrawerComponent>

        {selectedLead && (
          <CreateLeadDetailsTaskForm
            leadIds={[{ id: selectedLead?._id }]}
            modalId="#AddNewTask"
            updateModalValue={updateModalValue}
            action={activityformTitle}
            formName={activityformName}
            onTaskDelete={onTaskDelete}
          />
        )}
        <div className="leadDetail_drawer">
          <DrawerComponent
            openWithHeader={openWithHeader}
            setOpenWithHeader={setOpenWithHeader}
            customHeader={false}
            drawerTitle="Lead Details"
            size="lg"
            className="lead_details"
          >
            <LeadDetails
              _id={selectedLead?._id || ""}
              name={selectedLead?.name || ""}
              integration={selectedLead?.integration || ""}
              customSource={selectedLead?.customSource || ""}
              createdAt={selectedLead?.createdAt || new Date()}
              status={selectedLead?.status || []}
              saleValue={selectedLead?.saleValue || ""}
              website={selectedLead?.website || ""}
              label={selectedLead?.label || []}
              leadListId={StoreData.leadName.leadName?.id}
              deleteLeadFromDrawer={deleteLeadFromDrawer}
              copyLeadToList={copyLeadToList}
              moveLeadToList={moveLeadToList}
              onLabelClick={onLabelClick}
              onStatusClick={onStatusClick}
              setFrom={setFrom}
              handleEditModal={handleEditActivityModal}
              handleLeadDetailsModalOpen={handleActivityModal}
              onLeadFileUpload={onLeadFileUpload}
              editLead={editLead}
              leadFileDelete={leadFileDeleteToggle}
              refreshData={refreshData}
              onDeleteLead={() => {
                leadsGridRef.current &&
                  leadsGridRef.current.fetchLeadsWrapper();
                setOpenWithHeader(false);
              }}
            />
          </DrawerComponent>
        </div>
        <ConfirmationModal
          onConfirmation={onConfirmation}
          showModal={showConfirmationModal}
          toggleModal={toggleModal}
          message={`Are you sure you want to delete this ${
            toBeRemoved === "lead" ? "lead" : "lead list"
          }`}
          title="Lead"
        />
        <ConfirmationModal
          onConfirmation={leadTaskDelete}
          showModal={taskShowConfirmationModal}
          toggleModal={taskToggleModal}
          message="Are you sure you want to delete this task?"
          title="Task"
        />
        <ConfirmationModal
          onConfirmation={leadFileDelete}
          showModal={showFileDeleteModal}
          toggleModal={fileToggleModal}
          message="Are you sure you want to delete this file?"
          title="File"
        />
        <CopyLeadToList
          copyLeadToListModal={copyLeadToListModal}
          copyLeadToList={copyLeadToList}
          leadList={StoreData.leadList.leadList}
          onCopy={onCopy}
        />
        <MoveLeadToList
          moveLeadToList={moveLeadToList}
          leadList={StoreData.leadList.leadList}
          moveLeadToListModal={moveLeadToListModal}
          onMove={onMove}
        />
        <SelectLeadLabel
          showLabelPopup={showLabelPopup}
          toggleLeadLabel={toggleLeadLabel}
          onLabelChange={onLabelChange}
          selectedLabel={selectedLabel}
          setShowLabelPopup={setShowLabelPopup}
          from={from}
          id={
            leadsGridRef.current?.getSelectedLeadIds()?.length
              ? leadsGridRef.current?.getSelectedLeadIds()
              : selectedLead?._id || selectedLead?._id || ""
          }
          leadList={selectedLeadList.id}
          getLeads={refreshData}
          setOpenWithHeader={setOpenWithHeader}
          LabelData={leadLabelData}
          updateEditedRow={updateEditedRow}
        />
        <SelectLeadStatus
          showStatusPopup={showStatusPopup}
          toggleLeadStatus={toggleLeadStatus}
          onStatusChange={onStatusChange}
          selectedStatus={selectedStatus}
          setShowStatusPopup={setShowStatusPopup}
          from={from}
          id={
            leadsGridRef.current?.getSelectedLeadIds()?.length
              ? leadsGridRef.current?.getSelectedLeadIds()
              : selectedLead?._id || selectedLead?._id || ""
          }
          leadList={selectedLeadList.id}
          getLeads={refreshData}
          setOpenWithHeader={setOpenWithHeader}
          updateEditedRow={updateEditedRow}
        />
        <DrawerExistModal
          title="Are you sure you want to exit ?"
          showExitModal={showExitModal}
          toggleEditModal={toggleEditModal}
          handleDrawerClick={handleDrawerClick}
        />
        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </div>
  );
};

export default Leads;
