import axios, { AxiosResponse } from "axios";
import { API_ENDPOINT } from "./userService";
import { getHeader } from "./templates";

export async function getBroadCasts(data: any) {
  return axios.get(`${API_ENDPOINT}/campaign`, {
    headers: getHeader(),
    params: data,
  });
}

export async function createBroadCastFun(data: any) {
  return axios.post(`${API_ENDPOINT}/campaign`, data, {
    headers: getHeader(),
  });
}

export async function getCampaignById(id: string) {
  return axios.get(`${API_ENDPOINT}/campaign/${id}`, {
    headers: getHeader(),
  });
}
