import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getUserProfile } from "../services/authServices";
import { Spin } from "antd";

export default function PrivateRoute({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const data = localStorage.getItem("auth_token");

  const fetchUserProfile = async () => {
    try {
      const response = await getUserProfile();

      if (response?.data) {
        localStorage.setItem("userData", JSON.stringify(response?.data?.data));
        localStorage.setItem(
          "user_preferences",
          JSON.stringify(response.data.data.userPreference)
        );
      }

      setIsLoading(false); // Set loading to false after successfully fetching user profile
    } catch (error) {
      console.log(`[ERROR] ${error.message}`);
      setIsLoading(false); // Set loading to false even in case of error
    }
  };

  useEffect(() => {
    if (data) fetchUserProfile();
    else setIsLoading(false); // No data available, so set loading to false
  }, [data]);

  // Render loading indicator or children based on the loading state
  if (isLoading) {
    return (
      <div className="vh-100 w-100 d-flex align-items-center justify-content-center">
        <Spin size="large" tip="Loading..." spinning={true}></Spin>
      </div>
    );
  }

  // If authenticated, render children, otherwise navigate to login page
  return data ? children : <Navigate to="/" />;
}
